define("linced-admin/enums/base-enum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Object.extend({
    values: null,
    init: function init() {
      // Set "constants" for all values.
      var conformName = function conformName(name) {
        return name.toUpperCase().replace(/[^A-Z0-9_]/g, '_');
      };
      this.values.forEach(function (val) {
        this[conformName(val.label)] = val.value;
      }, this);
    },
    getValue: function getValue(label) {
      var obj = this.values.find(function (x) {
        return x.label === label;
      });
      if (Ember.isEmpty(obj)) {
        return null;
      }
      return obj.value;
    },
    getLabel: function getLabel(value) {
      var obj = this.values.find(function (x) {
        return x.value === value;
      });
      if (Ember.isEmpty(obj)) {
        return null;
      }
      return obj.label;
    }
  });
  _exports.default = _default;
});