define("linced-admin/routes/assessments/assessment", ["exports", "linced-admin/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    model: function model(params) {
      var store = this.store;
      return Ember.RSVP.hash({
        assessment: store.findRecord('assessments/assessment', params.assessment_id),
        modules: store.query('assessments/module', {
          assessmentId: params.assessment_id
        }),
        gradeschedules: store.findAll('assessments/grade-schedule')
      });
    }
  });
  _exports.default = _default;
});