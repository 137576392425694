define("linced-admin/controllers/goals/index", ["exports", "@linc-technologies/linced-common/enums/subject-type"], function (_exports, _subjectType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // External imports
  var _default = Ember.Controller.extend({
    // Services
    currentUser: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    toaster: Ember.inject.service(),
    // Attrs
    subjectDialogOpen: false,
    subjectSaveInProgress: false,
    editingSubject: null,
    subjectLabel: '',
    subjectDescription: '',
    subjectTrackable: false,
    subjectBestFits: false,
    subjectVisibleToTeachers: true,
    subjectVisibleToParents: true,
    subjectVisibleToLearners: true,
    subjectType: Object.freeze({
      value: 0,
      label: 'Not set'
    }),
    showRadialDials: true,
    entityToDelete: null,
    deleteErrorMessage: null,
    deleteDialogOpen: false,
    // Sorting
    sortDialogOpen: false,
    goalsSort: Object.freeze(['sequence', 'label']),
    sortedAndFilteredGoals: Ember.computed.sort('filteredGoals', 'goalsSort'),
    sortedGoals: Ember.computed.sort('model.subjects', 'goalsSort'),
    // Usage sets attributes
    currentSubject: null,
    usageSetDialogOpen: false,
    selectedUsageSets: Ember.A(),
    initialUsageSets: Ember.A(),
    // Subject Types
    subjectTypes: _subjectType.default,
    // Filters
    remainingUsageSetsFilter: Ember.computed.setDiff('model.usageSets', 'selectedUsageSetsFilter'),
    selectedUsageSetsFilter: Ember.A(),
    filteredGoals: Ember.computed('sortedGoals.[]', 'selectedUsageSetsFilter.[]', function () {
      var _this = this;
      return this.get('sortedGoals').filter(function (goal) {
        if (!Ember.isEmpty(_this.selectedUsageSetsFilter)) {
          return _this.selectedUsageSetsFilter.some(function (usageSet) {
            return goal.get('usageSetIds').includes(usageSet);
          });
        }
        return true;
      });
    }),
    updateUsageSets: function updateUsageSets(category) {
      return category.get('usageSetIds').then(function (usageSetIds) {
        if (!Ember.isEmpty(usageSetIds)) {
          var promises = [];
          usageSetIds.forEach(function (usageSetId) {
            promises.push(usageSetId.save());
          });
          return Promise.all(promises);
        }
      });
    },
    updatePageTemplates: function updatePageTemplates(category) {
      return this.store.query('posts/page', {
        goalSubjectIds: JSON.stringify([category.id])
      }).then(function (pageTemplates) {
        if (!Ember.isEmpty(pageTemplates)) {
          var promises = [];
          pageTemplates.forEach(function (pageTemplate) {
            promises.push(pageTemplate.save());
          });
          return Promise.all(promises);
        }
      });
    },
    actions: {
      handleTypeSelect: function handleTypeSelect(selectedOption) {
        if (selectedOption.value === null) {
          // Close the dropdown without changing selection
        } else {
          // Update the selection
          this.set('subjectType', selectedOption);
        }
      },
      addSubject: function addSubject() {
        this.resetSubject();
        this.set('subjectDialogOpen', true);
      },
      editSubject: function editSubject(subject) {
        this.resetSubject();
        var subjectType = {
          value: 0,
          label: 'Not set'
        };
        if (typeof subject.subjectType === 'number') {
          var foundSubjectType = _subjectType.default.values.filter(function (e) {
            return e.value === subject.subjectType;
          }).firstObject;
          if (foundSubjectType) {
            subjectType = foundSubjectType;
          }
        }
        this.setProperties({
          editingSubject: subject,
          subjectLabel: subject.label,
          subjectDescription: subject.description,
          subjectTrackable: subject.trackable,
          subjectBestFits: subject.calculateBestFits,
          subjectVisibleToTeachers: subject.visibleToTeachers,
          subjectVisibleToParents: subject.visibleToParents,
          subjectVisibleToLearners: subject.visibleToLearners,
          showRadialDials: subject.showRadialDials,
          subjectIsTopic: subject.isTopic,
          subjectType: subjectType,
          subjectDialogOpen: true
        });
      },
      closeSubjectDialog: function closeSubjectDialog() {
        this.set('subjectDialogOpen', false);
      },
      saveSubject: function saveSubject() {
        var _this2 = this;
        var subject = this.editingSubject;
        var toaster = this.paperToaster;
        if (Ember.isEmpty(subject)) {
          subject = this.store.createRecord('goals/subject', {
            schoolId: this.get('currentUser.school')
          });
        }
        subject.setProperties({
          label: this.subjectLabel,
          description: this.subjectDescription,
          trackable: this.subjectTrackable,
          calculateBestFits: this.subjectBestFits,
          visibleToTeachers: this.subjectVisibleToTeachers,
          visibleToParents: this.subjectVisibleToParents,
          visibleToLearners: this.subjectVisibleToLearners,
          showRadialDials: this.showRadialDials,
          isTopic: this.subjectIsTopic,
          sequence: this.get('model.subjects.length') + 1,
          subjectType: this.subjectType.value || 0
        });
        subject.save().then(function () {
          _this2.set('subjectDialogOpen', false);
          toaster.show("Goals subject \"".concat(subject.get('label'), "\" saved."), {
            duration: 4000,
            position: 'top right'
          });
        }).catch(function () {
          toaster.show("Error saving goals subject.", {
            duration: 4000,
            position: 'top right'
          });
        });
      },
      deleteSubject: function deleteSubject(subject) {
        this.setProperties({
          entityToDelete: subject,
          deleteDialogOpen: true,
          deleteErrorMessage: ''
        });
      },
      confirmDelete: function confirmDelete(confirmed) {
        var _this3 = this;
        if (!confirmed) {
          // Not confirmed the deletion
          this.setProperties({
            deleteDialogOpen: false,
            entityToDelete: null,
            deleteErrorMessage: ''
          });
          return;
        }
        return new Ember.RSVP.Promise(function (resolve, reject) {
          // Deletion confirmed; actually delete the record.
          _this3.set('deleteInProgress', true);
          var subject = _this3.get('entityToDelete');
          // Delete record (locally + pushed to server).
          return subject.destroyRecord().then(function () {
            // Update the usage sets relationships
            var relationshipPromises = [];
            // Update the usage sets relationships
            relationshipPromises.push(_this3.updateUsageSets(_this3.entityToDelete));
            // Update the page template's relationship
            relationshipPromises.push(_this3.updatePageTemplates(_this3.entityToDelete));
            return Promise.all(relationshipPromises);
          }).then(function () {
            // Tidy up / close dialog.
            _this3.setProperties({
              deleteDialogOpen: false,
              entityToDelete: null,
              deleteErrorMessage: ''
            });
            _this3.toaster.success("Goal subject deleted successfully.");
            _this3.resetForm();
            resolve();
          }).catch(function (err) {
            if (!Ember.isEmpty(err)) {
              if (err.errors && err.errors.length > 0) {
                _this3.set('deleteErrorMessage', err.errors[0].detail);
                return;
              }
              if (err.message) {
                _this3.set('deleteErrorMessage', err.message);
                return;
              }
            }
            _this3.set('deleteErrorMessage', 'An unknown error occurred. Please try again.');
            // Remove 'deleted' flag so that entity can be interacted with again.
            _this3.entityToDelete.rollbackAttributes();
            reject();
          }).finally(function () {
            _this3.set('deleteInProgress', false);
          });
        });
      },
      closeDeleteDialog: function closeDeleteDialog() {
        this.setProperties({
          deleteDialogOpen: false,
          entityToDelete: null
        });
      },
      openUsageSetDialog: function openUsageSetDialog(subject) {
        var _this4 = this;
        subject.get('usageSetIds').then(function (usageSetsArray) {
          _this4.setProperties({
            usageSetDialogOpen: true,
            currentSubject: subject,
            selectedUsageSets: usageSetsArray.slice(),
            initialUsageSets: usageSetsArray
          });
        }).catch(function () {
          _this4.toaster.error("Error trying to edit the goal subject's usage sets");
        });
      },
      closeUsageSetDialog: function closeUsageSetDialog() {
        this.setProperties({
          usageSetDialogOpen: false,
          currentSubject: null,
          selectedUsageSets: Ember.A(),
          initialUsageSets: Ember.A()
        });
      },
      addUsageSet: function addUsageSet(usageSet, checked) {
        if (checked) {
          this.selectedUsageSets.pushObject(usageSet);
        } else {
          this.selectedUsageSets.removeObject(usageSet);
        }
      },
      addUsageSetFilter: function addUsageSetFilter(usageSet) {
        this.selectedUsageSetsFilter.pushObject(usageSet);
      },
      removeUsageSetFilter: function removeUsageSetFilter(usageSet) {
        this.selectedUsageSetsFilter.removeObject(usageSet);
      },
      saveSubjectAndUsageSet: function saveSubjectAndUsageSet() {
        var _this5 = this;
        this.set('saveInProgress', true);
        var promises = [];
        var changedUsageSets = Ember.A();

        // Update the usage sets
        var removedUsageSets = this.initialUsageSets.filter(function (usageSet) {
          return !_this5.selectedUsageSets.includes(usageSet);
        });
        var addedUsageSets = this.selectedUsageSets.filter(function (usageSet) {
          return !_this5.initialUsageSets.includes(usageSet);
        });
        changedUsageSets = changedUsageSets.concat(removedUsageSets, addedUsageSets);
        if (!Ember.isEmpty(changedUsageSets)) {
          // Usage sets a page has been newly added to/removed from
          changedUsageSets.forEach(function (changedUsageSet) {
            promises.push(changedUsageSet.save());
          });
        }

        // Save the goal subject at the end - this is done so the comparison is most accurate
        // as this.initialUsageSets is a reference to usageSetIds
        this.currentSubject.set('usageSetIds', this.selectedUsageSets);
        promises.push(this.currentSubject.save());
        return Promise.all(promises).then(function () {
          // After saving the page and all involved usage sets
          _this5.toaster.success("Successfully saved the the goal subject and its usage sets");
          _this5.currentSubject.reload();
          _this5.setProperties({
            usageSetDialogOpen: false,
            currentSubject: null,
            selectedUsageSets: Ember.A(),
            initialUsageSets: Ember.A(),
            saveInProgress: false
          });
        }).catch(function () {
          _this5.toaster.error("Error saving the goal subject's usage sets");
          _this5.currentSubject.rollbackAttributes();
        });
      },
      // unpublishGoalSubject will remove the goal subject from all usage sets so it becomes unusable
      unpublishGoalSubject: function unpublishGoalSubject(subject) {
        var _this6 = this;
        subject.get('usageSetIds').then(function (usageSetsArray) {
          _this6.setProperties({
            selectedUsageSets: Ember.A(),
            initialUsageSets: usageSetsArray,
            currentSubject: subject
          });
          _this6.send('saveSubjectAndUsageSet');
        });
      }
    },
    resetSubject: function resetSubject() {
      this.setProperties({
        subjectLabel: '',
        subjectDescription: '',
        subjectTrackable: false,
        subjectBestFits: false,
        subjectVisibleToTeachers: true,
        subjectVisibleToParents: true,
        subjectVisibleToLearners: true,
        showRadialDials: false,
        isTopic: false,
        subjectType: 0
      });
    }
  });
  _exports.default = _default;
});