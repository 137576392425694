define("linced-admin/components/chart-donut-progression", ["exports", "@linc-technologies/linced-common/components/chart-donut-progression"], function (_exports, _chartDonutProgression) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _chartDonutProgression.default;
    }
  });
});