define("linced-admin/controllers/assessments/grade-schedules", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    toaster: Ember.inject.service(),
    // Deletion attributes
    deleteDialogOpen: false,
    deleteErrorMessage: '',
    deleteInProgress: false,
    entityToDelete: null,
    associatedAssessments: null,
    associatedModules: null,
    deleteWarningDialogOpen: false,
    actions: {
      editSchedule: function editSchedule(schedule) {
        this.set('editingSchedule', schedule);
        this.set('dialogOpen', true);
      },
      delete: function _delete(schedule) {
        var _this = this;
        // Find any modules or assessments that have this grade schedule
        // We need to warn them be
        var promises = [];
        promises.push(this.store.query('assessments/assessment', {
          gradeScheduleId: schedule.id
        }));
        promises.push(this.store.query('assessments/module', {
          gradeScheduleId: schedule.id
        }));
        return Promise.all(promises).then(function (results) {
          _this.setProperties({
            associatedAssessments: results[0],
            associatedModules: results[1]
          });
          if (!Ember.isEmpty(_this.associatedAssessments) || !Ember.isEmpty(_this.associatedModules)) {
            // We need to warn the user about dependencies
            _this.setProperties({
              deleteWarningDialogOpen: true,
              entityToDelete: schedule
            });
          } else {
            _this.send('openDeleteDialog', schedule);
          }
        }).catch(function (err) {
          return _this.set('deleteErrorMessage', err);
        });
      },
      closeWarningDeleteDialog: function closeWarningDeleteDialog() {
        this.setProperties({
          deleteWarningDialogOpen: false,
          associatedAssessments: null,
          associatedModules: null
        });
      },
      continueDeleting: function continueDeleting() {
        this.send('closeWarningDeleteDialog');
        this.send('openDeleteDialog', this.entityToDelete);
      },
      openDeleteDialog: function openDeleteDialog(schedule) {
        this.setProperties({
          deleteDialogOpen: true,
          entityToDelete: schedule
        });
      },
      confirmDelete: function confirmDelete(confirmed) {
        var _this2 = this;
        if (!confirmed) {
          // reset the deletion values
          this.setProperties({
            deleteDialogOpen: false,
            entityToDelete: null,
            deleteErrorMessage: ''
          });

          // we don't want to delete anything 👋
          return;
        }
        return new Ember.RSVP.Promise(function (resolve, reject) {
          // Deletion confirmed; actually delete the record.
          _this2.set('deleteInProgress', true);
          var entity = _this2.get('entityToDelete');
          // Delete record (locally + pushed to server).
          return entity.destroyRecord().then(resolve).catch(function (err) {
            // Remove 'deleted' flag so that entity can be interacted with again.
            entity.rollbackAttributes();
            reject(err);
          });
        }).then(function () {
          // Tidy up / close dialog.
          _this2.setProperties({
            deleteDialogOpen: false,
            entityToDelete: null,
            deleteErrorMessage: ''
          });
          _this2.toaster.success("grade schedule deleted successfully.");
        }).catch(function (err) {
          if (!Ember.isEmpty(err)) {
            if (err.errors && err.errors.length > 0) {
              _this2.set('deleteErrorMessage', err.errors[0].detail);
              return;
            }
            if (err.message) {
              _this2.set('deleteErrorMessage', err.message);
              return;
            }
          }
          _this2.set('deleteErrorMessage', 'An unknown error occurred. Please try again.');
        }).then(function () {
          _this2.set('deleteInProgress', false);
        });
      }
    }
  });
  _exports.default = _default;
});