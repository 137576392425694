define("linced-admin/services/school-schema", ["exports", "linced-admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    fetch: Ember.inject.service(),
    store: Ember.inject.service(),
    schemaFetched: false,
    getPeopleSchema: function getPeopleSchema() {
      var _this = this;
      var store = this.store;
      var promise = Ember.RSVP.resolve();
      if (!this.schemaFetched) {
        promise = this.fetch.request(_environment.default.APP.apiHost + '/v4/people/schema/schools/d2729515-d1ca-436c-8e0a-11870867f7d9/groups').then(function (response) {
          _this.store.pushPayload({
            'people-groups': response.groups
          });
          _this.set('schemaFetched', true);
        });
      }
      return promise.then(function () {
        return store.peekAll('people-group').filterBy('schoolId', 'd2729515-d1ca-436c-8e0a-11870867f7d9');
      });
    }
  });
  _exports.default = _default;
});