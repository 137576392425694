define("linced-admin/components/person-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    selectedTab: 0,
    preview: false,
    prefix: '',
    groups: Ember.computed('preview', 'fieldGroups.[]', 'person.type', function () {
      if (this.preview) {
        return this.fieldGroups;
      } else {
        return this.fieldGroups.filterBy('personType', this.get('person.type'));
      }
    }),
    // TODO: Don't use observers if possible.
    // eslint-disable-next-line
    _personChangedWatcher: Ember.observer('person.id', function () {
      this.person.reInitFields();
    }),
    actions: {
      savePerson: function savePerson() {
        // Don't try saving if it's a preview, as the record will be an unsaved dummy one.
        if (!this.preview) {
          this.person.save();
        }
      }
    }
  });
  _exports.default = _default;
});