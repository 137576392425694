define("linced-admin/templates/components/person-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "n/A/RcEs",
    "block": "{\"symbols\":[\"group\",\"index\",\"field\",\"tabs\",\"group\",\"index\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"layout-column flex\"],[8],[0,\"\\n  \"],[5,\"paper-tabs\",[],[[\"@class\",\"@borderBottom\",\"@onChange\",\"@selected\"],[\"flex-none\",true,[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"selectedTab\"]]],null]],null],[22,\"selectedTab\"]]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[28,\"sort-by\",[\"sequence\",\"name\",[24,[\"groups\"]]],null]],null,{\"statements\":[[0,\"      \"],[6,[23,4,[\"tab\"]],[],[[\"@value\"],[[23,6,[]]]],{\"statements\":[[0,\"\\n        \"],[1,[23,5,[\"name\"]],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[5,6]},null],[0,\"  \"]],\"parameters\":[4]}],[0,\"\\n\\n  \"],[5,\"paper-content\",[],[[\"@class\"],[\"flex\"]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[28,\"sort-by\",[\"sequence\",\"name\",[24,[\"groups\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[11,\"class\",[29,[\"flex-grow md-padding \",[28,\"if\",[[28,\"eq\",[[23,2,[]],[24,[\"selectedTab\"]]],null],\"show\",\"hide\"],null]]]],[8],[0,\"\\n        \"],[7,\"h1\",true],[10,\"class\",\"md-display-2\"],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"layout-column\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"sort-by\",[\"sequence\",\"label\",[23,1,[\"fields\"]]],null]],null,{\"statements\":[[0,\"            \"],[5,\"dynamic-field\",[],[[\"@entity\",\"@field\",\"@prefix\"],[[22,\"person\"],[23,3,[]],[22,\"prefix\"]]]],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "linced-admin/templates/components/person-form.hbs"
    }
  });
  _exports.default = _default;
});