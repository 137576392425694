define("linced-admin/routes/people/information", ["exports", "linced-admin/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    schoolSchema: Ember.inject.service(),
    model: function model() {
      var person = this._super.apply(this, arguments);
      var schema = this.schoolSchema;
      return Ember.RSVP.hash({
        person: person,
        groups: schema.getPeopleSchema(person.get('type')) //this.get('store').findRecord('people-template', '57f46f1b-9984-4a56-82c4-a3c42b8b7c3d')
      });
    }
  });
  _exports.default = _default;
});