define("linced-admin/models/enrolments/log", ["exports", "@ember-data/model", "@linc-technologies/linced-common/enums/jurisdiction", "linced-admin/enums/enrolment-log-resource"], function (_exports, _model, _jurisdiction, _enrolmentLogResource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // createTime is when the resource was created in seconds from the epoch.
    createTime: (0, _model.attr)('number'),
    // schoolId is the unique identifier of the school.
    schoolId: (0, _model.belongsTo)('school'),
    school: Ember.computed.alias('schoolId'),
    // campusId is the unique identifier of the School's Campus.
    campusId: (0, _model.attr)('string'),
    // jurisdiction maps the audit log to a jurisdictional educational
    // authority.
    jurisdiction: (0, _model.attr)('number'),
    // resource was the resource the action has been performed on.
    // 1: LogResource_CREDENTIALS
    // 2: LogResource_IDENTIFIERS
    // 3: LogResource_STATUS
    // 4: LogResource_ENROLMENTS
    // 5: LogResource_UNENROLMENTS
    resource: (0, _model.attr)('number'),
    // action was the action performed on the resource.
    // 1: LogAction_CREATE
    // 2: LogAction_UPDATE
    // 3: LogAction_DELETE
    action: (0, _model.attr)('number'),
    // statusId may provide a link to the specific status request that was
    // sent to the third party educational provider if further request
    // introspection is required.
    statusId: (0, _model.attr)('string'),
    // requesterId is the person, or staff member who requested this data from
    // the 3rd-party provider.
    requesterId: (0, _model.belongsTo)('person'),
    requester: Ember.computed.alias('requesterId'),
    // learnerId may contain a link to the learner the action was performed on
    // behalf of.
    learnerId: (0, _model.belongsTo)('person'),
    learner: Ember.computed.alias('learnerId'),
    // resourceId may contain a link to the specific linked resource.
    resourceId: (0, _model.attr)('string'),
    actionPerformed: Ember.computed('jurisdiction', 'resource', 'action', function () {
      var verb = 'performed ';
      var resource = 'an unknown action ';
      switch (this.action) {
        case 1:
          verb = 'created ';
          break;
        case 2:
          verb = 'updated ';
          break;
        case 3:
          verb = 'deleted ';
          break;
        case 4:
          verb = 'marked ';
      }
      switch (this.jurisdiction) {
        case _jurisdiction.default.NZ_MOE:
          switch (this.resource) {
            case _enrolmentLogResource.default.IDENTIFIERS:
              resource = 'a GetCheckNSN ';
              break;
            case _enrolmentLogResource.default.ENROLMENTS:
              resource = 'an Arrivals Upload ';
              break;
            case _enrolmentLogResource.default.UNENROLMENTS:
              resource = 'a Leavers Upload ';
              break;
          }
          break;
      }
      if (this.action === 4) {
        return "".concat(verb, " ").concat(resource, " as processed");
      }
      return "".concat(verb, " ").concat(resource);
    })
  });
  _exports.default = _default;
});