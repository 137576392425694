define("linced-admin/models/goals/subcategory", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    schoolId: (0, _model.belongsTo)('school'),
    school: Ember.computed.alias('schoolId'),
    campusId: (0, _model.attr)('string'),
    // TODO: implement when we have Campuses

    isPublished: (0, _model.attr)('boolean'),
    subjectId: (0, _model.belongsTo)('goals/subject'),
    subject: Ember.computed.alias('subjectId'),
    isPLP: (0, _model.attr)('boolean'),
    categoryId: (0, _model.belongsTo)('goals/category'),
    category: Ember.computed.alias('categoryId'),
    goals: (0, _model.hasMany)('goals/goal'),
    goalSortOrder: Object.freeze(['sequence', 'label']),
    sortedGoals: Ember.computed.sort('goals', 'goalSortOrder'),
    sortedBoundGoals: Ember.computed('sortedGoals.[]', function () {
      var goals = this.sortedGoals.slice();
      goals.__category__ = this.categoryId;
      goals.__subcategory__ = this;
      return goals;
    }),
    updateTime: (0, _model.attr)('number'),
    createTime: (0, _model.attr)('number'),
    isLocked: (0, _model.attr)('boolean'),
    templateId: (0, _model.attr)('string'),
    // TODO: implement once Templates are added
    sequence: (0, _model.attr)('number'),
    label: (0, _model.attr)('string'),
    description: (0, _model.attr)('string')
  });
  _exports.default = _default;
});