define("linced-admin/components/select-dialogue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    // Passed in
    buttonLabel: '',
    templateType: '',
    templates: Ember.A(),
    usageSets: Ember.A(),
    selectedTemplates: Ember.A(),
    sortByAttr: '',
    // Dialogue attributes
    selectedUsageSetsFilter: Ember.A(),
    remainingUsageSetsFilter: Ember.computed.setDiff('usageSets', 'selectedUsageSetsFilter'),
    dialogueOpen: false,
    filteredTemplates: Ember.computed('templates.[]', 'selectedUsageSetsFilter.[]', function () {
      var _this = this;
      return this.get('templates').filter(function (template) {
        if (!Ember.isEmpty(_this.selectedUsageSetsFilter)) {
          return _this.selectedUsageSetsFilter.some(function (usageSet) {
            return template.get('usageSetIds').includes(usageSet);
          });
        }
        return true;
      });
    }),
    actions: {
      openDialog: function openDialog() {
        this.set('dialogueOpen', true);
      },
      toggleTemplate: function toggleTemplate(template, checked) {
        if (checked) {
          this.selectedTemplates.pushObject(template);
        } else {
          this.selectedTemplates.removeObject(template);
        }
      },
      addUsageSetFilter: function addUsageSetFilter(usageSet) {
        this.selectedUsageSetsFilter.pushObject(usageSet);
      },
      removeUsageSetFilter: function removeUsageSetFilter(usageSet) {
        this.selectedUsageSetsFilter.removeObject(usageSet);
      },
      toggleAllTemplates: function toggleAllTemplates(templates, checked) {
        if (checked) {
          this.set('selectedTemplates', templates.toArray());
        } else {
          this.set('selectedTemplates', Ember.A());
        }
      }
    }
  });
  _exports.default = _default;
});