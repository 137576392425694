define("linced-admin/controllers/people/schema/resequence-groups", ["exports", "@linc-technologies/linced-common/enums/person-type"], function (_exports, _personType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    personTypes: _personType.default.values,
    selectedTab: _personType.default.LEARNER,
    typeGroups: Ember.computed('model.[]', 'selectedTab', function () {
      return this.model.filterBy('personType', this.selectedTab).sortBy('sequence', 'name');
    }),
    actions: {
      // drag-drop sorting
      sortDragEnd: function sortDragEnd(_ref) {
        var sourceList = _ref.sourceList,
          sourceIndex = _ref.sourceIndex,
          targetList = _ref.targetList,
          targetIndex = _ref.targetIndex;
        if (sourceList === targetList && sourceIndex === targetIndex) {
          return;
        }
        var item = sourceList.objectAt(sourceIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, item);
        targetList.forEach(function (entity, index) {
          entity.set('sequence', index + 1);
          entity.save();
        });
      }
    }
  });
  _exports.default = _default;
});