define("linced-admin/models/cron/task", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // createTime is when the resource was created in seconds from the epoch.
    createTime: (0, _model.attr)('number'),
    // updateTime is the last time the resource was modified in seconds from
    // the epoch.
    updateTime: (0, _model.attr)('number'),
    // jurisdiction is the educational jurisdiction this specific task is
    // registered to be run for.
    jurisdiction: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    // campusId is the school campus the task relates to.
    serviceName: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    // serviceTask is the service's unique scheduled task name.
    serviceTask: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    // crontab stores the crontab format string as to when the job should be
    // run.
    crontab: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    // enabled specifies whether the scheduled task is currently configured to
    // run.
    enabled: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    // description provides a human-readable description to be displayed.
    description: (0, _model.attr)('string', {
      defaultValue: ''
    })
  });
  _exports.default = _default;
});