define("linced-admin/templates/callback", ["exports", "@linc-technologies/linced-common/templates/callback"], function (_exports, _callback) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _callback.default;
    }
  });
});