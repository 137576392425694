define("linced-admin/controllers/people/schema/template/groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    groupName: '',
    groupKey: '',
    groupDescription: '',
    groupId: '',
    groupDialogOpen: false,
    saveInProgress: false,
    deleteGroupDialogOpen: false,
    deleteInProgress: false,
    errorMessage: '',
    availableGroups: Ember.computed.setDiff('model.allGroups', 'model.groups'),
    selectedGroup: null,
    isSortable: false,
    // toggleDialog opens the group dialog.
    toggleDialog: function toggleDialog() {
      this.set('errorMessage', '');
      this.toggleProperty('groupDialogOpen');
      if (!this.groupDialogOpen) {
        this.resetForm();
      }
    },
    actions: {
      toggleGroupDialog: function toggleGroupDialog() {
        this.toggleDialog();
      },
      addExistingGroup: function addExistingGroup(group) {
        var _this = this;
        group.get('templateSetIds').pushObject(this.get('model.templateSet'));
        group.save().then(function () {
          _this.get('model.groups').update();
        });
        this.toggleDialog();
      },
      editGroup: function editGroup(id) {
        var _this2 = this;
        this.store.findRecord('people-schema/group', id).then(function (group) {
          _this2.set('groupName', group.get('name'));
          _this2.set('groupKey', group.get('key'));
          _this2.set('groupDescription', group.get('description'));
        });
        this.set('groupId', id);
        this.toggleDialog();
      },
      saveGroup: function saveGroup() {
        var _this3 = this;
        this.set('saveInProgress', true);
        this.set('errorMessage', '');
        var promise;
        var action = 'create';
        if (Ember.isEmpty(this.groupId)) {
          promise = new Ember.RSVP.Promise(function (resolve) {
            var record = _this3.store.createRecord('people-schema/group', {
              name: _this3.groupName,
              key: _this3.groupKey,
              description: _this3.groupDescription,
              sequence: _this3.get('model.groups.length') + 1,
              personType: _this3.get('model.templateSet.personType')
            });
            record.get('templateSetIds').pushObject(_this3.get('model.templateSet'));
            resolve(record);
          });
        } else {
          action = 'update';
          promise = new Ember.RSVP.Promise(function (resolve) {
            var id = _this3.groupId;
            _this3.store.findRecord('people-schema/group', id).then(function (group) {
              group.set('name', _this3.groupName);
              group.set('key', _this3.groupKey);
              group.set('description', _this3.groupDescription);
              resolve(group);
            });
          });
        }
        promise.then(function (record) {
          return record.save().then(function () {
            _this3.set('groupDialogOpen', false);
            _this3.resetForm();
          }).catch(function (err) {
            _this3.handleError(err, action);
            record.rollbackAttributes();
          }).then(function () {
            _this3.set('saveInProgress', false);
            _this3.get('model.groups').update();
          });
        });
      },
      deleteGroup: function deleteGroup(id) {
        var _this4 = this;
        this.set('errorMessage', '');
        this.store.findRecord('people-schema/group', id).then(function (group) {
          _this4.set('groupToDelete', group);
        });
        this.set('deleteGroupDialogOpen', true);
      },
      confirmDelete: function confirmDelete(confirmed) {
        var _this5 = this;
        return new Ember.RSVP.Promise(function (resolve, reject) {
          if (confirmed) {
            var group = _this5.groupToDelete;

            // Deletion confirmed; actually delete (or disconnect) the record.
            _this5.set('deleteInProgress', true);
            if (group.get('templateSetIds.length') === 1) {
              // Delete record (locally + pushed to server).
              return group.destroyRecord().then(resolve).catch(function (err) {
                // Remove 'deleted' flag so that entity can be interacted with again.
                group.rollbackAttributes();
                reject(err);
              });
            } else {
              var groupFields = _this5.get('model.allFields').filterBy('templateGroupId.id', group.id);
              if (groupFields.length) {
                reject({
                  errors: {
                    msg: 'This group cannot be removed from this Template, as it contains fields.  Please delete all fields within this group, then try again.'
                  }
                });
              } else {
                group.get('templateSetIds').removeObject(_this5.get('model.templateSet'));
                group.save().then(function () {
                  _this5.get('model.groups').update();
                  resolve();
                }).catch(function (err) {
                  group.rollbackAttributes();
                  reject(err);
                });
              }
            }
          } else {
            resolve();
          }
        }).then(function () {
          // Tidy up / close dialog.
          _this5.set('deleteGroupDialogOpen', false);
          _this5.set('groupToDelete', null);
        }).catch(function (err) {
          _this5.handleError(err, 'delete');
        }).then(function () {
          _this5.set('deleteInProgress', false);
        });
      }
    },
    /*
    init() {
      this.set('schoolId', this.get('currentUser.user.schoolId'));
    },*/
    handleError: function handleError(err) {
      if (!Ember.isEmpty(err.errors.msg)) {
        this.set('errorMessage', err.errors.msg);
      } else {
        this.set('errorMessage', err.message);
      }
    },
    setErrorMessage: function setErrorMessage(code, action) {
      var message = 'An unknown error occurred.  Please try again.';
      var codes = {
        '400': 'Sorry, your request was invalid.  Please check all details below, and try again.',
        '403': {
          create: 'Sorry, you are not authorised to create a new user.  Please check that you are logged in as the correct user, and try again.',
          update: 'Sorry, you are not authorised to edit this user.  Please check that you are logged in as the correct user, and try again.',
          delete: 'Sorry, you are not authorised to delete this user.  Please check that you are logged in as the correct user, and try again.'
        },
        '409': 'A user already exists with that username.  Please double-check the details, or find and update the existing user.'
      };
      if (!Ember.isEmpty(codes[code][action])) {
        message = codes[code][action];
      } else if (!Ember.isEmpty(codes[code])) {
        message = codes[code];
      }
      this.set('errorMessage', message);
    },
    resetForm: function resetForm() {
      this.set('groupId', '');
      this.set('groupName', '');
      this.set('groupKey', '');
      this.set('groupDescription', '');
      this.set('selectedGroup', null);
      this.set('groupSearchText', null);
    }
  });
  _exports.default = _default;
});