define("linced-admin/routes/page-templates", ["exports", "linced-admin/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    currentUser: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        pages: this.store.findAll('posts/page'),
        tagCategories: this.store.findAll('posts/tagCategory'),
        assessments: this.store.query('assessments/assessment', {
          isPublished: true
        }),
        assessmentCategories: this.store.findAll('assessments/category'),
        goalSubjects: this.store.findAll('goals/subject'),
        schoolGroups: this.store.findAll('schools/group'),
        usageSets: this.store.findAll('schools/usage-set'),
        schools: this.store.findAll('school')
      });
    },
    resetController: function resetController(controller) {
      controller.reset();
    },
    actions: {
      pageCreated: function pageCreated() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});