define("linced-admin/controllers/school-options/preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    init: function init() {
      var store = this.store;
      store.pushPayload('school', {
        school: {
          id: 'dummy',
          fieldsArray: []
        }
      });
      var dummy = store.peekRecord('school', 'dummy');
      dummy.initFields();
      this.set('school', dummy);
      this.reset();
      this._super.apply(this, arguments);
    },
    // TODO: Don't use observers if possible.
    // eslint-disable-next-line
    _optionsLoaded: Ember.observer('model.options.@each.id', function () {
      this.reset();
      var headingIds = this.headingIds;
      var groupIds = this.groupIds;
      var groups = this.groups;
      var groupHeadings = this.groupHeadings;
      var headingFields = this.headingFields;
      this.get('model.options').forEach(function (field) {
        field.get('headingId').then(function (heading) {
          var headingId = heading.get('id');
          if (!headingIds.includes(headingId)) {
            headingFields.set(headingId, Ember.A([]));
            heading.get('groupId').then(function (group) {
              var groupId = group.get('id');
              if (!groupIds.includes(groupId)) {
                groupIds.pushObject(groupId);
                groups.pushObject(group);
                groupHeadings.set(groupId, Ember.A([]));
              }
              groupHeadings.get(groupId).pushObject(heading);
            });
            headingIds.pushObject(headingId);
          }
          headingFields.get(headingId).pushObject(field);
        });
      });
    }),
    reset: function reset() {
      this.set('groupHeadings', Ember.Object.create());
      this.set('headingFields', Ember.Object.create());
      this.set('headingIds', Ember.A([]));
      this.set('groupIds', Ember.A([]));
      this.set('groups', Ember.A([]));
    }
  });
  _exports.default = _default;
});