define("linced-admin/components/confirm-delete-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /** attrs **/
    // entityType is the type of entity to be deleted.
    entityType: '',
    // entityLabel is the label of the entity being deleted to inform the user.
    entityLabel: '',
    // errorMessage is a string that will be displayed to the user if there is an
    // error on attempting deletion.
    errorMessage: '',
    // onClose provides a function that will be called based on the users decision
    // to confirm the deletion. The function should close the dialog unless an
    // error occurs performing the action.
    // Returns a boolean indicating if the deletion was confirmed.
    onDelete: function onDelete() {},
    // actions
    actions: {
      onDelete: function onDelete(confirmed) {
        this.get('onDelete')(confirmed);
      }
    }
  });
  _exports.default = _default;
});