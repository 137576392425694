define("linced-admin/templates/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "KsDKG8hQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\",true],[8],[0,\"Welcome to Hero Admin!\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h2\",true],[8],[0,\"You are logged in!\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"currentUser\",\"user\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[8],[0,\"Signed in as \"],[1,[24,[\"currentUser\",\"user\",\"username\"]],false],[0,\" (\"],[1,[24,[\"currentUser\",\"scopes\"]],false],[0,\")\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "linced-admin/templates/home.hbs"
    }
  });
  _exports.default = _default;
});