define("linced-admin/controllers/milestones/sets", ["exports", "linced-admin/config/environment", "@linc-technologies/linced-common/enums/progression-type"], function (_exports, _environment, _progressionType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Internal imports
  // External imports
  var _default = Ember.Controller.extend({
    // Services
    fetch: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    store: Ember.inject.service(),
    toaster: Ember.inject.service(),
    // Attributes
    currentSet: null,
    deleteTemplateSetDialogOpen: false,
    entityToDelete: null,
    errorMessage: '',
    saveInProgress: false,
    templateSetDialogOpen: false,
    // Usage sets
    usageSetDialogOpen: false,
    selectedUsageSets: Ember.A(),
    initialUsageSets: Ember.A(),
    remainingUsageSetsFilter: Ember.computed.setDiff('model.usageSets', 'selectedUsageSetsFilter'),
    selectedUsageSetsFilter: Ember.A(),
    filteredSets: Ember.computed('model.sets.[]', 'selectedUsageSetsFilter.[]', function () {
      var _this = this;
      return this.get('model.sets').filter(function (set) {
        if (!Ember.isEmpty(_this.selectedUsageSetsFilter)) {
          return _this.selectedUsageSetsFilter.some(function (usageSet) {
            return set.get('usageSetIds').includes(usageSet);
          });
        }
        return true;
      }).sortBy('label');
    }),
    actions: {
      addTemplateSet: function addTemplateSet() {
        this.resetForm();
        this.set('templateSetDialogOpen', true);
      },
      editTemplateSet: function editTemplateSet(templateSet) {
        this.set('templateSetId', templateSet.get('id'));
        this.set('templateSetLabel', templateSet.get('label'));
        this.set('templateSetDescription', templateSet.get('description'));
        this.set('templateSetDialogOpen', true);
      },
      closeTemplateSetDialog: function closeTemplateSetDialog() {
        this.set('templateSetDialogOpen', false);
      },
      saveTemplateSet: function saveTemplateSet() {
        var _this2 = this;
        this.set('saveInProgress', true);
        this.set('errorMessage', '');
        var promise;
        if (Ember.isEmpty(this.templateSetId)) {
          promise = new Ember.RSVP.Promise(function (resolve) {
            var record = _this2.store.createRecord('milestones/set', {
              label: _this2.templateSetLabel,
              description: _this2.templateSetDescription,
              progressionType: _progressionType.default.PROGRESSION
            });
            resolve(record);
          });
        } else {
          promise = new Ember.RSVP.Promise(function (resolve) {
            var id = _this2.templateSetId;
            _this2.store.findRecord('milestones/set', id).then(function (templateSet) {
              templateSet.set('label', _this2.templateSetLabel);
              templateSet.set('description', _this2.templateSetDescription);
              resolve(templateSet);
            });
          });
        }
        promise.then(function (record) {
          return record.save().then(function () {
            _this2.set('templateSetDialogOpen', false);
            _this2.resetForm();
            _this2.toaster.success('Template Set saved successfully.');
          }).catch(function () {
            record.rollbackAttributes();
            _this2.toaster.error('Error saving Template Set.');
          }).then(function () {
            _this2.set('saveInProgress', false);
          });
        });
      },
      deleteTemplateSet: function deleteTemplateSet(templateSet) {
        this.setProperties({
          errorMessage: '',
          templateSetLabel: templateSet.get('label'),
          templateSetId: templateSet.id,
          deleteTemplateSetDialogOpen: true
        });
      },
      confirmDelete: function confirmDelete(confirmed) {
        var _this3 = this;
        if (confirmed) {
          return new Ember.RSVP.Promise(function (resolve, reject) {
            // Deletion confirmed; actually delete the record.
            _this3.set('deleteInProgress', true);

            // Grab record from local store - no GET request, as that causes weirdness.
            // We only need the ID to issue the DELETE anyway, which can't change, so no need to use findRecord.
            var templateSet = _this3.store.peekRecord('milestones/set', _this3.templateSetId);
            _this3.set('entityToDelete', templateSet);
            // Delete record (locally + pushed to server).
            return _this3.entityToDelete.destroyRecord().then(resolve).catch(function (err) {
              // Remove 'deleted' flag so that entity can be interacted with again.
              _this3.entityToDelete.rollbackAttributes();
              reject(err);
            });
          }).then(function () {
            // Update the usage sets relationships
            return _this3.entityToDelete.get('usageSetIds').then(function (usageSetIds) {
              var promises = [];
              usageSetIds.forEach(function (usageSetId) {
                var promise = usageSetId.save();
                promises.push(promise);
              });
              return Promise.all(promises);
            }).then(function () {
              _this3.toaster.success("Template set \"".concat(_this3.entityToDelete.get('label'), "\" successfully deleted."));
              // Tidy up / close dialog.
              _this3.setProperties({
                deletePageDialogOpen: false,
                entityToDelete: null,
                deleteTemplateSetDialogOpen: false
              });
              _this3.resetForm();
            });
          }).catch(function () {
            _this3.toaster.error('Error deleting Template Set.');
          }).then(function () {
            _this3.set('deleteInProgress', false);
          });
        } else {
          // Cancel the deletion and close the dialog
          this.setProperties({
            errorMessage: '',
            templateSetLabel: '',
            templateSetId: '',
            deleteTemplateSetDialogOpen: false
          });
        }
      },
      // Apply templates
      openApplyDialog: function openApplyDialog(templateSet) {
        this.setProperties({
          applySet: templateSet,
          applyErrorMessage: '',
          applyInProgress: false,
          applyDialogOpen: true,
          selectedSchool: null
        });
      },
      closeApplyDialog: function closeApplyDialog() {
        this.set('applyDialogOpen', false);
      },
      applyTemplateSet: function applyTemplateSet() {
        var _this4 = this;
        var data = {
          schoolId: this.get('selectedSchool.id')
        };
        var options = {
          data: JSON.stringify(data)
        };
        var templateSetsUri = "".concat(_environment.default.APP.apiHost, "/milestones/").concat(_environment.default.APP.apiPath, "/templates/sets");
        var setId = this.get('applySet.id');
        this.set('applyInProgress', true);
        this.fetch.post("".concat(templateSetsUri, "/").concat(setId, "/:apply"), options).then(function () {
          var toaster = _this4.paperToaster;
          _this4.set('applyErrorMessage', '');
          _this4.set('applyInProgress', false);
          _this4.set('applyDialogOpen', false);
          var successToast = toaster.show('Template Set applied successfully.', {
            duration: 8000,
            position: 'top right',
            toastClass: 'success',
            action: {
              label: 'Undo',
              onClick: function onClick() {
                toaster.cancelToast(successToast);
                _this4.fetch.post("".concat(templateSetsUri, "/").concat(setId, "/:remove"), options).then(function () {
                  toaster.show('Template Set removed successfully.', {
                    duration: 4000,
                    position: 'top right',
                    toastClass: 'success'
                  });
                }).catch(function (err) {
                  toaster.show("Error removing Template Set: ".concat(err.errors[0].detail.message), {
                    duration: 8000,
                    position: 'top right',
                    toastClass: 'warning'
                  });
                });
              }
            }
          });
        }).catch(function (err) {
          _this4.set('applyErrorMessage', err.errors[0].detail.message);
          _this4.set('applyInProgress', false);
        });
      },
      openUsageSetDialog: function openUsageSetDialog(usageSet) {
        var _this5 = this;
        usageSet.get('usageSetIds').then(function (usageSetsArray) {
          _this5.setProperties({
            usageSetDialogOpen: true,
            currentSet: usageSet,
            selectedUsageSets: usageSetsArray.slice(),
            initialUsageSets: usageSetsArray
          });
        }).catch(function () {
          _this5.toaster.error("Error trying to edit the milestone set's usage sets");
        });
      },
      closeUsageSetDialog: function closeUsageSetDialog() {
        this.setProperties({
          usageSetDialogOpen: false,
          currentSet: null,
          selectedUsageSets: Ember.A(),
          initialUsageSets: Ember.A()
        });
      },
      toggleUsageSet: function toggleUsageSet(usageSet, checked) {
        if (checked) {
          this.selectedUsageSets.pushObject(usageSet);
        } else {
          this.selectedUsageSets.removeObject(usageSet);
        }
      },
      saveMilestoneAndUsageSet: function saveMilestoneAndUsageSet() {
        var _this6 = this;
        this.set('saveInProgress', true);
        var promises = [];
        var changedUsageSets = [];

        // Update the usage sets
        var removedUsageSets = this.initialUsageSets.filter(function (usageSet) {
          return !_this6.selectedUsageSets.includes(usageSet);
        });
        var addedUsageSets = this.selectedUsageSets.filter(function (usageSet) {
          return !_this6.initialUsageSets.includes(usageSet);
        });
        changedUsageSets = changedUsageSets.concat(removedUsageSets, addedUsageSets);
        if (!Ember.isEmpty(changedUsageSets)) {
          // Usage sets a page has been newly added to/removed from
          changedUsageSets.forEach(function (changedUsageSet) {
            promises.push(changedUsageSet.save());
          });
        }

        // Save the milestone template set
        this.currentSet.set('usageSetIds', this.selectedUsageSets);
        promises.push(this.currentSet.save());
        return Promise.all(promises).then(function () {
          // After saving the page and all involved usage sets
          _this6.toaster.success("Successfully saved the page and usage sets");
          _this6.setProperties({
            usageSetDialogOpen: false,
            currentSet: null,
            selectedUsageSets: Ember.A(),
            saveInProgress: false
          });
        }).catch(function () {
          _this6.toaster.error("Error saving the page's usage sets");
          _this6.currentSet.rollbackAttributes();
        });
      },
      addUsageSetFilter: function addUsageSetFilter(usageSet) {
        this.selectedUsageSetsFilter.pushObject(usageSet);
      },
      removeUsageSetFilter: function removeUsageSetFilter(usageSet) {
        this.selectedUsageSetsFilter.removeObject(usageSet);
      },
      toggleMilestoneSetPublished: function toggleMilestoneSetPublished(milestoneSet) {
        var _this7 = this;
        var published = milestoneSet.get('isPublished');
        milestoneSet.get('milestones').forEach(function (milestone) {
          milestone.set('isPublished', published);
          milestone.save();
        });
        milestoneSet.get('levels').forEach(function (level) {
          level.set('isPublished', published);
          level.save();
        });
        milestoneSet.get('expectations').forEach(function (expectation) {
          expectation.set('isPublished', published);
          expectation.save();
        });
        milestoneSet.get('usageSetIds').then(function (usageSetsArray) {
          _this7.setProperties({
            selectedUsageSets: Ember.A(),
            initialUsageSets: usageSetsArray,
            currentSet: milestoneSet
          });
          _this7.send('saveMilestoneAndUsageSet');
        });
      }
    },
    resetForm: function resetForm() {
      this.setProperties({
        templateSetId: null,
        templateSetLabel: '',
        templateSetDescription: ''
      });
    },
    reset: function reset() {
      this.resetForm();
      this.set('selectedUsageSetsFilter', Ember.A());
    }
  });
  _exports.default = _default;
});