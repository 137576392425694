define("linced-admin/models/school", ["exports", "@linc-technologies/linced-common/models/school", "@ember-data/model", "@linc-technologies/linced-common/enums/country-code", "@linc-technologies/linced-common/enums/feature", "@linc-technologies/linced-common/enums/jurisdiction"], function (_exports, _school, _model, _countryCode, _feature, _jurisdiction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _school.default.extend({
    optionSetIds: (0, _model.hasMany)('school-options/set', {
      inverse: null
    }),
    supportPersonIds: (0, _model.hasMany)('person'),
    countryCodeLabel: Ember.computed('countryCode', function () {
      return _countryCode.default.getLabel(this.countryCode);
    }),
    jurisdictionLabel: Ember.computed('jurisdiction', function () {
      return _jurisdiction.default.getLabel(this.jurisdiction);
    }),
    hasBilling: Ember.computed('featuresEnabled', function () {
      return this.featuresEnabled.includes(_feature.default.PUPIL_BILLING);
    }),
    hasSuite: Ember.computed('featuresEnabled', function () {
      return this.featuresEnabled.includes(_feature.default.SUITE);
    }),
    hasPremier: Ember.computed('featuresEnabled', function () {
      return this.featuresEnabled.includes(_feature.default.PREMIER);
    }),
    groups: (0, _model.hasMany)('schools/group')
  });
  _exports.default = _default;
});