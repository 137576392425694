define("linced-admin/components/process-monitor-viewer", ["exports", "@linc-technologies/linced-common/components/process-monitor-viewer"], function (_exports, _processMonitorViewer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _processMonitorViewer.default;
    }
  });
});