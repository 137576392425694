define("linced-admin/routes/milestones/set", ["exports", "linced-admin/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        set: this.store.find('milestones/set', params.set_id),
        milestones: this.store.query('milestones/milestone', {
          templateSetId: params.set_id
        }),
        levels: this.store.query('milestones/level', {
          templateSetId: params.set_id
        }),
        expectations: this.store.query('milestones/expectation', {
          templateSetId: params.set_id
        }),
        subjects: this.store.query('goals/subject', {
          trackable: true
        }),
        goalCategories: this.store.findAll('goals/category'),
        pages: this.store.findAll('posts/page')
      });
    }
  });
  _exports.default = _default;
});