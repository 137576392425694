define("linced-admin/models/assessments/module", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    createTime: (0, _model.attr)('number'),
    updateTime: (0, _model.attr)('number'),
    isPublished: (0, _model.attr)('boolean'),
    isLocked: (0, _model.attr)('boolean'),
    assessmentId: (0, _model.belongsTo)('assessments/assessment'),
    assessment: Ember.computed.alias('assessmentId'),
    sequence: (0, _model.attr)('number'),
    label: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    type: (0, _model.attr)('number'),
    key: (0, _model.attr)('string'),
    gradeScheduleId: (0, _model.belongsTo)('assessments/grade-schedule'),
    gradeSchedule: Ember.computed.alias('gradeScheduleId'),
    isModerated: (0, _model.attr)('boolean'),
    sharesEvidence: (0, _model.attr)('boolean'),
    numericStepping: (0, _model.attr)('number'),
    dueTime: (0, _model.attr)('number')
  });
  _exports.default = _default;
});