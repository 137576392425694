define("linced-admin/controllers/goals/subject", ["exports", "@linc-technologies/linced-common/enums/media-sharing-level", "ember-get-config", "ember-cli-string-helpers/utils/titleize", "@linc-technologies/linced-common/utils/tinymce"], function (_exports, _mediaSharingLevel, _emberGetConfig, _titleize, _tinymce) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // External Imports
  var _default = Ember.Controller.extend({
    fetch: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    media: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    gridView: true,
    isPLP: false,
    categoryDialogOpen: false,
    categorySaveInProgress: false,
    editingCategory: null,
    categoryLabel: '',
    categoryDescription: '',
    categoryBadgeMedia: '',
    categoryBadgeImage: '',
    subcategoryDialogOpen: false,
    subcategorySaveInProgress: false,
    editingSubcategory: null,
    subcategoryLabel: '',
    subcategoryDescription: '',
    goalDialogOpen: false,
    goalSaveInProgress: false,
    editingGoal: null,
    goalLabel: '',
    goalEducatorDescription: '',
    goalPeopleDescription: '',
    goalExemplar: '',
    entityToDelete: null,
    applyExemplarsToExistingGoals: false,
    editorOptions: Object.freeze((0, _tinymce.defaultTinyMCEConfig)()),
    uploading: false,
    actions: {
      addCategory: function addCategory() {
        this.resetCategory();
        this.set('categoryDialogOpen', true);
      },
      editCategory: function editCategory(category) {
        this.resetCategory();
        this.set('editingCategory', category);
        this.set('categoryLabel', category.get('label'));
        this.set('categoryDescription', category.get('description'));
        this.set('categoryBadgeMedia', category.get('badgeMedia'));
        this.set('categoryBadgeImage', category.get('badgeImage'));
        this.set('categoryBadgeDescription', category.get('badgeDescription'));
        this.set('categoryDialogOpen', true);
      },
      closeCategoryDialog: function closeCategoryDialog() {
        this.set('categoryDialogOpen', false);
      },
      saveCategory: function saveCategory() {
        var _this = this;
        var category = this.editingCategory;
        var toaster = this.paperToaster;
        if (Ember.isEmpty(category)) {
          category = this.store.createRecord('goals/category', {
            schoolId: this.get('currentUser.school'),
            subjectId: this.get('model.subject'),
            isPLP: this.isPLP,
            sequence: this.get('model.categories.length') + 1
          });
        }
        category.set('label', this.categoryLabel);
        category.set('description', this.categoryDescription);
        category.set('badgeMedia', this.categoryBadgeMedia);
        category.set('badgeDescription', this.categoryBadgeDescription);
        category.save().then(function () {
          _this.set('categoryDialogOpen', false);
          if (_this.isPLP) {
            // PLP goals don't have a subject, so we need to reload the categories to get new ones.
            _this.get('model.categories').update();
          }
          toaster.show("Goals category \"".concat(category.get('label'), "\" saved."), {
            duration: 4000,
            position: 'top right'
          });
        }).catch(function () {
          toaster.show("Error saving goals category.", {
            duration: 4000,
            position: 'top right'
          });
        });
      },
      addSubcategory: function addSubcategory(category) {
        this.resetSubcategory();
        this.set('subcategoryParent', category);
        this.set('subcategoryDialogOpen', true);
      },
      editSubcategory: function editSubcategory(subcategory) {
        this.resetSubcategory();
        this.set('editingSubcategory', subcategory);
        this.set('subcategoryLabel', subcategory.get('label'));
        this.set('subcategoryDescription', subcategory.get('description'));
        this.set('subcategoryDialogOpen', true);
      },
      closeSubcategoryDialog: function closeSubcategoryDialog() {
        this.set('subcategoryDialogOpen', false);
      },
      saveSubcategory: function saveSubcategory() {
        var _this2 = this;
        var subcategory = this.editingSubcategory;
        var category = this.subcategoryParent;
        var toaster = this.paperToaster;
        if (Ember.isEmpty(subcategory)) {
          subcategory = this.store.createRecord('goals/subcategory', {
            schoolId: this.get('currentUser.school'),
            subjectId: this.get('model.subject'),
            isPLP: this.isPLP,
            categoryId: category,
            sequence: category.get('subcategories.length') + 1
          });
        }
        subcategory.set('label', this.subcategoryLabel);
        subcategory.set('description', this.subcategoryDescription);
        subcategory.save().then(function () {
          _this2.set('subcategoryDialogOpen', false);
          toaster.show("Goals subcategory \"".concat(subcategory.get('label'), "\" saved."), {
            duration: 4000,
            position: 'top right'
          });
        }).catch(function () {
          toaster.show("Error saving goals subcategory.", {
            duration: 4000,
            position: 'top right'
          });
        });
      },
      addGoal: function addGoal(category, subcategory) {
        this.resetGoal();
        this.set('goalCategory', category);
        this.set('goalSubcategory', subcategory);
        this.set('goalDialogOpen', true);
      },
      editGoal: function editGoal(goal) {
        this.resetGoal();
        this.set('editingGoal', goal);
        this.set('goalLabel', goal.get('label'));
        this.set('goalEducatorDescription', goal.get('educatorDescription'));
        this.set('goalPeopleDescription', goal.get('peopleDescription'));
        this.set('goalExemplar', goal.get('exemplar'));
        this.set('goalDialogOpen', true);
      },
      closeGoalDialog: function closeGoalDialog() {
        this.set('goalDialogOpen', false);
      },
      saveGoal: function saveGoal() {
        var _this3 = this;
        var goal = this.editingGoal;
        var toaster = this.paperToaster;
        if (Ember.isEmpty(goal)) {
          var category = this.goalCategory;
          var subcategory = this.goalSubcategory;
          var sequence = (Ember.isEmpty(subcategory) ? category.get('goals.length') : subcategory.get('goals.length')) + 1;
          goal = this.store.createRecord('goals/goal', {
            schoolId: this.get('currentUser.school'),
            authorId: this.get('currentUser.person'),
            subjectId: this.get('model.subject'),
            categoryId: category,
            subcategoryId: subcategory,
            sequence: sequence
          });
        }
        goal.set('label', this.goalLabel);
        goal.set('educatorDescription', this.goalEducatorDescription);
        goal.set('peopleDescription', this.goalPeopleDescription);
        goal.set('exemplar', this.goalExemplar);
        goal.save().then(function () {
          _this3.set('goalDialogOpen', false);
          toaster.show("Goal \"".concat(goal.get('label'), "\" saved."), {
            duration: 4000,
            position: 'top right'
          });
        }).catch(function () {
          toaster.show("Error saving goal.", {
            duration: 4000,
            position: 'top right'
          });
        });
      },
      confirmDeleteEntity: function confirmDeleteEntity(type, entity) {
        this.set('entityTypeToDelete', type);
        this.set('entityToDelete', entity);
        this.set('deleteErrorMessage', '');
        this.set('deleteDialogOpen', true);
      },
      closeDeleteDialog: function closeDeleteDialog() {
        this.set('deleteDialogOpen', false);
      },
      doDeleteEntity: function doDeleteEntity() {
        var _this4 = this;
        var entity = this.entityToDelete;
        var entityType = entity.get('constructor.modelName');
        if (entity) {
          entity.destroyRecord().then(function () {
            _this4.set('deleteDialogOpen', false);
            _this4.paperToaster.show("".concat((0, _titleize.default)(_this4.entityTypeToDelete), " deleted successfully."), {
              duration: 4000,
              position: 'top right'
            });
            if (entityType === 'goals/subject') {
              // The entire subject was deleted; navigate back to the Goals admin page
              _this4.router.transitionTo('goals');
            }
          }).catch(function (err) {
            _this4.set('deleteErrorMessage', err.errors[0].detail);
          });
        }
      },
      // drag-drop sorting
      sortDragEnd: function sortDragEnd(_ref) {
        var sourceList = _ref.sourceList,
          sourceIndex = _ref.sourceIndex,
          targetList = _ref.targetList,
          targetIndex = _ref.targetIndex;
        if (sourceList === targetList && sourceIndex === targetIndex) {
          return;
        }
        var item = sourceList.objectAt(sourceIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, item);
        if (sourceList !== targetList) {
          // Moved to a new parent category/subcategory - update the relationship.
          item.setProperties({
            categoryId: targetList.get('__category__'),
            subcategoryId: targetList.get('__subcategory__')
          });
        }
        targetList.forEach(function (entity, index) {
          entity.set('sequence', index + 1);
          entity.save();
        });
      },
      setGridView: function setGridView(newState) {
        this.set('gridView', newState);
      },
      // Publish/unpublish entities
      togglePublish: function togglePublish(entity) {
        entity.toggleProperty('isPublished');
        entity.save();
      },
      // Apply templates
      openApplyDialog: function openApplyDialog(category) {
        if (Ember.isEmpty(category)) {
          this.set('applyType', 'subject');
          this.set('applyCategory', null);
        } else {
          this.set('applyType', 'category');
          this.set('applyCategory', category);
        }
        this.set('applyErrorMessage', '');
        this.set('applyInProgress', false);
        this.set('applyExemplarsToExistingGoals', false);
        this.set('applyDialogOpen', true);
      },
      closeApplyDialog: function closeApplyDialog() {
        this.set('applyDialogOpen', false);
      },
      applyTemplate: function applyTemplate() {
        var _this5 = this;
        var data = {
          schoolId: this.get('selectedSchool.id'),
          applyExemplarsToExistingGoals: this.applyExemplarsToExistingGoals
        };
        var options = {
          data: JSON.stringify(data)
        };
        var templatesBaseUri = "".concat(_emberGetConfig.default.APP.apiHost, "/goals/").concat(_emberGetConfig.default.APP.apiPath, "/templates");
        var entity = this.applyType === 'subject' ? 'subjects' : 'categories';
        var entityId = entity === 'subjects' ? this.get('model.subject.id') : this.get('applyCategory.id');
        this.set('applyInProgress', true);
        this.fetch.post("".concat(templatesBaseUri, "/").concat(entity, "/").concat(entityId, "/:apply"), options).then(function () {
          var toaster = _this5.paperToaster;
          _this5.set('applyErrorMessage', '');
          _this5.set('applyInProgress', false);
          _this5.set('applyDialogOpen', false);
          var successToast = toaster.show('Template applied successfully.', {
            duration: 8000,
            position: 'top right',
            toastClass: 'success',
            action: {
              label: 'Undo',
              onClick: function onClick() {
                toaster.cancelToast(successToast);
                _this5.fetch.post("".concat(templatesBaseUri, "/").concat(entity, "/").concat(entityId, "/:remove"), options).then(function () {
                  toaster.show('Template removed successfully.', {
                    duration: 4000,
                    position: 'top right',
                    toastClass: 'success'
                  });
                }).catch(function (err) {
                  toaster.show("Error removing template: ".concat(err.errors[0].detail), {
                    duration: 8000,
                    position: 'top right',
                    toastClass: 'warning'
                  });
                });
              }
            }
          });
        }).catch(function (err) {
          _this5.set('applyErrorMessage', err.errors[0].detail);
          _this5.set('applyInProgress', false);
        });
      },
      // Category badge image upload handling
      startUpload: function startUpload() {
        this.set('uploading', true);
      },
      handleUpload: function handleUpload(file, upload) {
        this.media.handleImageUpload(this, file, upload, _mediaSharingLevel.default.PUBLIC, '', '', 'categoryBadgeMedia', 'categoryBadgeImage');
      },
      handleUploadError: function handleUploadError() {
        this.paperToaster.show("Error uploading badge image!", {
          duration: 8000,
          position: 'top right',
          toastClass: 'warning'
        });
      }
    },
    resetCategory: function resetCategory() {
      this.set('editingCategory', null);
      this.set('categoryLabel', '');
      this.set('categoryDescription', '');
      this.set('categoryBadgeMedia', '');
      this.set('categoryBadgeImage', '');
      this.set('categoryBadgeDescription', '');
    },
    resetSubcategory: function resetSubcategory() {
      this.set('editingSubcategory', null);
      this.set('subcategoryLabel', '');
      this.set('subcategoryDescription', '');
    },
    resetGoal: function resetGoal() {
      this.set('editingGoal', null);
      this.set('goalLabel', '');
      this.set('goalEducatorDescription', '');
      this.set('goalPeopleDescription', '');
      this.set('goalExemplar', '');
    }
  });
  _exports.default = _default;
});