define("linced-admin/models/timetable/structure", ["exports", "@linc-technologies/linced-common/models/timetable/structure"], function (_exports, _structure) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _structure.default;
    }
  });
});