define("linced-admin/routes/finance/nsw-sap/transaction-upload", ["exports", "linced-admin/routes/base", "@linc-technologies/linced-common/enums/feature", "@linc-technologies/linced-common/enums/jurisdiction"], function (_exports, _base, _feature, _jurisdiction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    model: function model() {
      return this.store.query('school', {
        jurisdiction: _jurisdiction.default.AU_NSW
      }).then(function (schools) {
        return schools.filter(function (school) {
          // Filter schools to those which have an external ID (i.e. are synced
          // against DoE) and have Finance enabled.
          return !Ember.isEmpty(school.externalId) && school.featuresEnabled.includes(_feature.default.PUPIL_BILLING);
        }).sortBy('name');
      });
    },
    resetController: function resetController(controller) {
      controller.reset();
    }
  });
  _exports.default = _default;
});