define("linced-admin/models/milestones/level", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    createTime: (0, _model.attr)('number'),
    updateTime: (0, _model.attr)('number'),
    progressionType: (0, _model.attr)('number'),
    label: (0, _model.attr)('string'),
    briefLabel: (0, _model.attr)('string'),
    sequence: (0, _model.attr)('number'),
    goalSubjectTemplateId: (0, _model.belongsTo)('goals/subject'),
    goalSubjectTemplate: Ember.computed.alias('goalSubjectTemplateId'),
    goal_subject_template_id: Ember.computed.alias('goalSubjectTemplateId.id'),
    goalCategoryTemplateId: (0, _model.belongsTo)('goals/category'),
    goalCategoryTemplate: Ember.computed.alias('goalCategoryTemplateId'),
    pageTemplateId: (0, _model.belongsTo)('posts/page'),
    pageTemplate: Ember.computed.alias('pageTemplateId'),
    templateSetId: (0, _model.belongsTo)('milestones/set', {
      inverse: 'levels'
    }),
    templateSet: Ember.computed.alias('templateSetId'),
    isPublished: (0, _model.attr)('boolean')
  });
  _exports.default = _default;
});