define("linced-admin/helpers/slugify", ["exports", "@linc-technologies/linced-common/helpers/slugify"], function (_exports, _slugify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _slugify.default;
    }
  });
  Object.defineProperty(_exports, "slugify", {
    enumerable: true,
    get: function get() {
      return _slugify.slugify;
    }
  });
});