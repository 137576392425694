define("linced-admin/controllers/post-templates", ["exports", "@linc-technologies/linced-common/enums/jurisdiction", "@linc-technologies/linced-common/enums/page-type", "@linc-technologies/linced-common/utils/tinymce", "@linc-technologies/linced-common/utils/post-templates", "ember-get-config"], function (_exports, _jurisdiction, _pageType, _tinymce, _postTemplates, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    queryParams: {
      pageType: 'type',
      jurisdiction: 'jurisdiction'
    },
    ajax: Ember.inject.service(),
    toaster: Ember.inject.service(),
    PageType: _pageType.default,
    pageType: _pageType.default.STUDENT,
    Jurisdiction: _jurisdiction.default,
    jurisdiction: null,
    selectedJurisdiction: Ember.computed('jurisdiction', function () {
      if (Ember.isEmpty(this.jurisdiction)) {
        return;
      }
      return _jurisdiction.default.values.findBy('value', parseInt(this.jurisdiction, 10));
    }),
    sortOrder: Object.freeze(['sequence', 'name']),
    sortedPostTemplates: Ember.computed.sort('model.postTemplates', 'sortOrder'),
    postTemplate: null,
    postTemplateDialogOpen: false,
    saveInProgress: false,
    previewDialogOpen: false,
    editableRoot: false,
    previewEditorOptions: Ember.computed('editableRoot', function () {
      return (0, _tinymce.templatedPostTinyMCEConfig)(this.editableRoot);
    }),
    templateContent: '',
    previewContent: Ember.computed('previewDialogOpen', 'templateContent', function () {
      if (!this.previewDialogOpen) {
        // Don't bother generating preview content if we're not previewing.
        return '';
      }
      return (0, _postTemplates.processTemplate)(this.templateContent, {
        preview: true
      });
    }),
    // Bulk Apply dialog.
    bulkApplyDialogOpen: false,
    bulkApplyInProgress: false,
    bulkApplyTemplate: null,
    bulkApplyGroup: null,
    bulkApplyExclusions: null,
    bulkApplyProcessed: 0,
    remainingBulkApplySchools: Ember.computed.setDiff('bulkApplyGroup.schools', 'bulkApplyExclusions'),
    bulkApplyProgress: Ember.computed('bulkApplyGroup.schools.[]', 'bulkApplyProcessed', function () {
      if (Ember.isEmpty(this.bulkApplyGroup.schools)) {
        return 100;
      }
      if (this.bulkApplyProcessed < 1) {
        return 0;
      }
      return this.bulkApplyProcessed / this.bulkApplyGroup.schools.length * 100;
    }),
    actions: {
      noop: function noop() {},
      setJurisdictionFilter: function setJurisdictionFilter(jurisdiction) {
        if (Ember.isEmpty(jurisdiction)) {
          this.set('jurisdiction', null);
          return;
        }
        this.set('jurisdiction', jurisdiction.value);
      },
      edit: function edit(postTemplate) {
        var _this = this;
        postTemplate.reload().then(function (updated) {
          _this.setProperties({
            postTemplateDialogOpen: true,
            postTemplate: updated
          });
        });
      },
      delete: function _delete() {},
      addPostTemplate: function addPostTemplate() {
        this.resetForm();
        this.set('postTemplateDialogOpen', true);
      },
      savePostTemplate: function savePostTemplate(props) {
        var _this2 = this;
        this.set('saveInProgress', true);
        var record;
        if (Ember.isEmpty(this.postTemplate)) {
          record = this.store.createRecord('posts/postTemplate', {});
        } else {
          record = this.postTemplate;
        }
        record.setProperties(props);
        record.save().then(function () {
          _this2.send('postTemplateCreated');
          _this2.set('postTemplateDialogOpen', false);
          _this2.toaster.success('Post Template saved successfully.');
        }).catch(function () {
          _this2.toaster.error('Error saving Post Template!');
        }).finally(function () {
          _this2.set('saveInProgress', false);
        });
      },
      preview: function preview(templateContent, editableRoot) {
        this.setProperties({
          templateContent: templateContent,
          previewDialogOpen: true,
          editableRoot: editableRoot
        });
      },
      toggleBulkApplyDialog: function toggleBulkApplyDialog() {
        this.toggleProperty('bulkApplyDialogOpen');
        if (!this.bulkApplyDialogOpen) {
          this.setProperties({
            bulkApplyInProgress: false,
            bulkApplyTemplate: null,
            bulkApplyGroup: null,
            bulkApplyExclusions: Ember.A(),
            bulkApplyProcessed: 0
          });
        }
      },
      addBulkApplyExclusion: function addBulkApplyExclusion(school) {
        this.bulkApplyExclusions.pushObject(school);
      },
      removeBulkApplyExclusion: function removeBulkApplyExclusion(school) {
        this.bulkApplyExclusions.removeObject(school);
      },
      setBulkApplyGroup: function setBulkApplyGroup(group) {
        this.setProperties({
          bulkApplyGroup: group,
          bulkApplyExclusions: Ember.A()
        });
      },
      bulkApply: function bulkApply() {
        var _this3 = this;
        this.setProperties({
          bulkApplyInProgress: true,
          bulkApplyProcessed: 0
        });
        var excludedIds = this.bulkApplyExclusions.mapBy('id');
        var templateId = this.bulkApplyTemplate.id;
        this.bulkApplyGroup.schools.reduce(function (chain, school) {
          return chain.then(function () {
            if (excludedIds.includes(school.id)) {
              _this3.incrementProperty('bulkApplyProcessed');
              return Ember.RSVP.resolve();
            }
            _this3.ajax.set('headers.X-Tenant-Id', school.id);
            return _this3.ajax.post("".concat(_emberGetConfig.default.APP.apiHost, "/posts/").concat(_emberGetConfig.default.APP.apiPath, "/templates/posttemplates/").concat(templateId, "/:apply"), {
              data: JSON.stringify({
                schoolId: school.id
              })
            }).then(function (data) {
              // Set the new Post Template's sequence to 999 to put it at the
              // end of the list, since the sequence won't necessarily align
              // with the school's existing Post Templates.
              data.postTemplate.sequence = 999;
              return _this3.ajax.put("".concat(_emberGetConfig.default.APP.apiHost, "/posts/").concat(_emberGetConfig.default.APP.apiPath, "/posttemplates/").concat(data.postTemplate.id), {
                data: data
              });
            }).then(function () {
              return _this3.incrementProperty('bulkApplyProcessed');
            });
          });
        }, Ember.RSVP.resolve());
      }
    },
    resetForm: function resetForm() {
      this.setProperties({
        name: '',
        description: '',
        postTemplate: '',
        editableRoot: false
      });
    }
  });
  _exports.default = _default;
});