define("linced-admin/mixins/dynamic-fields-serializer", ["exports", "linced-admin/mixins/dynamic-field-types"], function (_exports, _dynamicFieldTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function transmogrifyFields(fields) {
    var map = Ember.A([]);
    if (!Ember.isEmpty(fields)) {
      Object.keys(fields).forEach(function (key) {
        var field = fields[key];
        var k = (0, _dynamicFieldTypes.getValueKey)(field.datatype);
        if (!field.hasOwnProperty(k)) {
          // Value is "empty" according to Golang, so we didn't receive it - set to datatype's empty value.
          field[k] = (0, _dynamicFieldTypes.getValueDefault)(field.datatype);
        }
        fields[key].key = key;
        map.pushObject(fields[key]);
      });
    }
    return map;
  }
  var _default = Ember.Mixin.create({
    _fieldsKey: 'fields',
    normalize: function normalize(modelClass, hash) {
      var fieldsKey = this._fieldsKey;
      hash.fieldsArray = transmogrifyFields(hash[fieldsKey]);
      return this._super.apply(this, arguments);
    },
    serialize: function serialize(snapshot) {
      var json = this._super(snapshot, {
        includeId: true
      });
      var raw = snapshot.record.get('fields');
      var keys = Object.keys(raw);
      var jsonFields = {};
      var keyRE = /^.+?:.+?$/;
      keys.forEach(function (key) {
        // Only try to transmogrify actual dynamic field keys
        if (keyRE.test(key)) {
          var field = raw.get(key).serialize();
          var fkey = (0, _dynamicFieldTypes.getValueKey)(field.datatype);
          jsonFields[key] = {
            datatype: field.datatype
          };
          jsonFields[key][fkey] = field[fkey];
        }
      });
      delete json.fieldsArray;
      delete json.fields;
      json[this._fieldsKey] = jsonFields;
      return json;
    }
  });
  _exports.default = _default;
});