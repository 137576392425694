define("linced-admin/models/schools/group", ["exports", "@ember-data/model", "md5"], function (_exports, _model, _md) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    createTime: (0, _model.attr)('number'),
    updateTime: (0, _model.attr)('number'),
    name: (0, _model.attr)('string'),
    label: (0, _model.attr)('string'),
    grouping: (0, _model.attr)('string'),
    schoolIds: (0, _model.hasMany)('school'),
    schools: Ember.computed.alias('schoolIds'),
    // image returns the URI to an autogenerated gravatar.
    image: Ember.computed('id', function () {
      return "https://gravatar.com/avatar/".concat((0, _md.default)(this.id), "?d=identicon&f=y");
    })
  });
  _exports.default = _default;
});