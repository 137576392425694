define("linced-admin/templates/tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "JNlU28Oz",
    "block": "{\"symbols\":[\"tabs\"],\"statements\":[[7,\"h1\",true],[8],[0,\"Tag Templates\"],[9],[0,\"\\n\\n\"],[5,\"paper-tabs\",[],[[\"@selected\",\"@borderBottom\",\"@onChange\"],[\"tags\",true,[28,\"action\",[[23,0,[]],\"noop\"],null]]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"tab\"]],[],[[\"@value\",\"@href\"],[\"pages\",[28,\"href-to\",[\"page-templates\"],null]]],{\"statements\":[[0,\"Pages\"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"tab\"]],[],[[\"@value\",\"@href\"],[\"postTemplates\",[28,\"href-to\",[\"post-templates\"],null]]],{\"statements\":[[0,\"Post Templates\"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"tab\"]],[],[[\"@value\",\"@href\"],[\"tags\",[28,\"href-to\",[\"tags\"],null]]],{\"statements\":[[0,\"Tags\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "linced-admin/templates/tags.hbs"
    }
  });
  _exports.default = _default;
});