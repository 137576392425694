define("linced-admin/models/assessments/gradeschedule-selectvalue", ["exports", "@ember-data/model", "ember-data-model-fragments"], function (_exports, _model, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberDataModelFragments.default.Fragment.extend({
    value: (0, _model.attr)(),
    label: (0, _model.attr)(),
    description: (0, _model.attr)()
  });
  _exports.default = _default;
});