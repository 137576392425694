define("linced-admin/routes/assessments/index", ["exports", "linced-admin/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    model: function model() {
      var store = this.store;
      return Ember.RSVP.hash({
        assessments: store.findAll('assessments/assessment'),
        categories: store.findAll('assessments/category'),
        gradeschedules: store.findAll('assessments/grade-schedule'),
        usageSets: store.findAll('schools/usage-set'),
        schools: this.store.findAll('school'),
        schoolGroups: this.store.findAll('schools/group')
      });
    }
  });
  _exports.default = _default;
});