define("linced-admin/templates/people/schema/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "LnJBUvj/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\",true],[8],[0,\"Templates: \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"people.schema.template.groups\",[24,[\"model\",\"id\"]]]],{\"statements\":[[1,[24,[\"model\",\"name\"]],false]],\"parameters\":[]}],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"description\"]]],null,{\"statements\":[[0,\"  \"],[7,\"p\",true],[8],[1,[24,[\"model\",\"description\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "linced-admin/templates/people/schema/template.hbs"
    }
  });
  _exports.default = _default;
});