define("linced-admin/controllers/cron/task", ["exports", "linced-admin/enums/log-level", "ember-get-config"], function (_exports, _logLevel, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Attrs
    LogLevel: _logLevel.default,
    // Services
    ajax: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    // State
    // deleteInProgress provides state for if a delete request is currently
    // in flight.
    deleteInProgress: false,
    // deleteTaskDialogOpen provides the state of the task delete dialog.
    deleteTaskDialogOpen: false,
    // taskRunInProgress provides state for if a task run request is currently in
    // flight.
    taskRunInProgress: false,
    // Methods
    /**
     * toastMessage pops a notification of success or failure.
     *
     * @param {boolean} success - Whether a success was had or not.
     * @param {string} message - The message to display
     */
    toastMessage: function toastMessage(success, message) {
      var toastClass = 'success';
      if (!success) {
        toastClass = 'warning';
      }
      this.paperToaster.show(message, {
        duration: 6000,
        position: 'top right',
        toastClass: toastClass
      });
    },
    // reset provides a call for the router to reset the controller on
    // re-entering the route.
    reset: function reset() {
      this.set('deleteInProgress', false);
      this.set('deleteTaskDialogOpen', false);
      this.set('taskRunInProgress', false);
    },
    // Actions
    actions: {
      /**
       * deleteTask deletes the provided scheduled task from the server.
       *
       * @param {Object} task - task record.
       */
      deleteTask: function deleteTask(task) {
        var _this = this;
        this.set('deleteInProgress', true);
        task.destroyRecord().then(function () {
          _this.toastMessage(true, "Deleted ".concat(task.serviceName, " ").concat(task.serviceTask));
        }).then(function () {
          _this.router.transitionTo('cron');
        }).catch(function (err) {
          _this.toastMessage(false, "Error deleting ".concat(task.serviceName, ": ").concat(task.serviceTask, ". Error: ").concat(err));
        }).finally(function () {
          _this.set('deleteInProgress', false);
          _this.set('taskDeleteDialogOpen', false);
        });
      },
      /**
       * runTask fires a request to run the task now.
       *
       * @param {Object} task - task record.
       */
      runTask: function runTask(task) {
        var _this2 = this;
        this.set('taskRunInProgress', true);
        var url = "".concat(_emberGetConfig.default.APP.apiHost, "/cron/").concat(_emberGetConfig.default.APP.apiPath, "/tasks/").concat(task.id, "/:run");
        return this.ajax.post(url).then(function () {
          _this2.toastMessage(true, "Running ".concat(task.serviceName, " ").concat(task.serviceTask, "!"));
        }).catch(function (err) {
          _this2.toastMessage(false, "Error running ".concat(task.serviceName, ": ").concat(task.serviceTask, ". Error: ").concat(err));
        }).finally(function () {
          _this2.model.logs.update();
          _this2.set('taskRunInProgress', false);
        });
      },
      /**
       * toggleEnabled flips the enabled state and instantly saves the task.
       *
       * @param {Object} task - task record.
       */
      toggleEnabled: function toggleEnabled(task) {
        var _this3 = this;
        // store the toggled state.
        var toggledEnabledState = !task.enabled;

        // build a state string to return the action performed on save.
        var state = 'disabl';
        if (toggledEnabledState) {
          state = 'enabl';
        }

        // Update state and notify of outcome.
        task.set('enabled', toggledEnabledState);
        task.save().then(function () {
          _this3.toastMessage(true, "".concat(state, "ed ").concat(task.serviceName, "'s ").concat(task.serviceTask));
        }).catch(function (err) {
          _this3.toastMessage(false, "Error ".concat(state, "ing ").concat(task.serviceName, " ").concat(task.serviceTask, ". Error: ").concat(err));
        });
      }
    }
  });
  _exports.default = _default;
});