define("linced-admin/models/posts/tag-category", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    createTime: (0, _model.attr)('number'),
    updateTime: (0, _model.attr)('number'),
    label: (0, _model.attr)('string'),
    tags: (0, _model.hasMany)('posts/tag', {
      inverse: 'categoryId'
    }),
    // Usage sets membership
    usageSetIds: (0, _model.hasMany)('schools/usage-set')
  });
  _exports.default = _default;
});