define("linced-admin/serializers/school-options/field", ["exports", "@ember-data/serializer/rest", "@linc-technologies/linced-common/enums/data-type", "@linc-technologies/linced-common/enums/input-type"], function (_exports, _rest, _dataType, _inputType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _rest.default.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      optionSetIds: {
        serialize: 'ids'
      }
    },
    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      return modelName.replace(/^school-options\/field/, 'option');
    },
    modelNameFromPayloadKey: function modelNameFromPayloadKey(key) {
      return this._super(key.replace(/^option/, 'school-options/field'));
    },
    serializeIntoHash: function serializeIntoHash(hash) {
      this._super.apply(this, arguments);
      var dataType = 0;
      switch (hash.option.inputType) {
        case _inputType.default.CHECKBOX:
        case _inputType.default.MULTI_SELECT:
        case _inputType.default.SORTABLE_LIST:
          dataType = _dataType.default.LIST;
          break;
        case _inputType.default.NUMBER:
          // Already set via the UI.
          dataType = hash.option.dataType;
          break;
        case _inputType.default.SWITCH:
          dataType = _dataType.default.BOOLEAN;
          break;
        case _inputType.default.SELECT:
          dataType = hash.option.dataType || _dataType.default.STRING;
          break;
        case _inputType.default.EDITABLE_LIST:
          dataType = _dataType.default.VALUE_LIST;
          break;
        default:
          dataType = _dataType.default.STRING;
          break;
      }
      hash.option.dataType = dataType;
    }
  });
  _exports.default = _default;
});