define("linced-admin/models/goals/subject", ["exports", "@ember-data/model", "@linc-technologies/linced-common/enums/subject-type"], function (_exports, _model, _subjectType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    schoolId: (0, _model.belongsTo)('school'),
    school: Ember.computed.alias('schoolId'),
    campusId: (0, _model.attr)('string'),
    // TODO: implement when we have Campuses

    isPublished: (0, _model.attr)('boolean'),
    categories: (0, _model.hasMany)('goals/category'),
    categorySortOrder: Object.freeze(['sequence', 'label']),
    sortedCategories: Ember.computed.sort('categories', 'categorySortOrder'),
    sequence: (0, _model.attr)('number'),
    // Usage sets membership
    usageSetIds: (0, _model.hasMany)('schools/usage-set'),
    updateTime: (0, _model.attr)('number'),
    createTime: (0, _model.attr)('number'),
    isLocked: (0, _model.attr)('boolean'),
    templateId: (0, _model.attr)('string'),
    // TODO: implement once Templates are added

    label: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    trackable: (0, _model.attr)('boolean'),
    calculateBestFits: (0, _model.attr)('boolean'),
    visibleToTeachers: (0, _model.attr)('boolean'),
    visibleToParents: (0, _model.attr)('boolean'),
    visibleToLearners: (0, _model.attr)('boolean'),
    showRadialDials: (0, _model.attr)('boolean'),
    isTopic: (0, _model.attr)('boolean'),
    subjectType: (0, _model.attr)('number'),
    subjectTypeName: Ember.computed('subjectType', function () {
      var subjectType = this.subjectType;
      // Check if subjectType is valid, otherwise default to "Not set"
      return subjectType ? _subjectType.default.getLabel(subjectType) || 'Not set' : 'Not set';
    })
  });
  _exports.default = _default;
});