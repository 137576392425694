define("linced-admin/mixins/dynamic-fields", ["exports", "@ember-data/model", "ember-data-model-fragments/attributes", "linced-admin/mixins/dynamic-field-types"], function (_exports, _model, _attributes, _dynamicFieldTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    fieldsArray: (0, _model.attr)(),
    fields: (0, _attributes.fragment)('dynamic-field/values', {
      defaultValue: {
        _initialised: false
      }
    }),
    // TODO: Don't use observers if possible.
    // eslint-disable-next-line
    _initWatcher: Ember.observer('fieldsArray.[]', function () {
      if (Ember.isArray(this.fieldsArray)) {
        this.initFields();
      }
    }),
    initFields: function initFields() {
      var _this = this;
      var fields = this.fieldsArray;
      var fieldsMap = this.fields;
      if (Ember.isArray(fields) && fields.length) {
        fields.forEach(function (field) {
          _this._addField(field);
        });
      }
      fieldsMap.set('_initialised', true);
    },
    _addField: function _addField(field) {
      var fieldsMap = this.fields;
      var fieldValue = (0, _attributes.fragment)('dynamic-field/value', {
        defaultValue: field
      });
      Ember.defineProperty(fieldsMap, field.key, undefined, fieldValue);
    },
    addField: function addField(key, datatype) {
      var valueKey = (0, _dynamicFieldTypes.getValueKey)(datatype);
      var valueDefault = (0, _dynamicFieldTypes.getValueDefault)(datatype);
      var field = {
        key: key,
        datatype: datatype
      };
      field[valueKey] = valueDefault;
      this._addField(field);
    },
    _arraysMatch: function _arraysMatch(a, b) {
      return a.length === b.length && a.every(function (el) {
        return b.includes(el);
      });
    }
  });
  _exports.default = _default;
});