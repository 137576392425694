define("linced-admin/models/group", ["exports", "@ember-data/model", "ember-data-model-fragments/attributes"], function (_exports, _model, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    createTime: (0, _model.attr)('number'),
    updateTime: (0, _model.attr)('number'),
    type: (0, _model.attr)('number'),
    autoGroupType: (0, _model.attr)('number'),
    name: (0, _model.attr)('string'),
    label: (0, _model.attr)('string'),
    year: (0, _model.attr)('number'),
    grouping: (0, _model.attr)('string'),
    team: (0, _model.attr)('string'),
    params: (0, _attributes.fragment)('autogroup-params'),
    filter: (0, _model.attr)('string'),
    // Usage sets membership
    usageSetIds: (0, _model.hasMany)('schools/usage-set')
  });
  _exports.default = _default;
});