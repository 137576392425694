define("linced-admin/routes/hero-notices/notice", ["exports", "linced-admin/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        post: this.store.findRecord('posts/globalnotice', params.notice_id),
        groups: this.store.findAll('schools/group')
      });
    }
  });
  _exports.default = _default;
});