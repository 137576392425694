define("linced-admin/models/person", ["exports", "@linc-technologies/linced-common/models/person", "@ember-data/model"], function (_exports, _person, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // External imports
  var _default = _person.default.extend({
    // Technically a belongsTo relationship, but we don't want to auto-fetch user records.
    userId: (0, _model.attr)('string')
  });
  _exports.default = _default;
});