define("linced-admin/routes/school-options/headings", ["exports", "linced-admin/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    store: Ember.inject.service(),
    model: function model(params) {
      var store = this.store;
      var group = store.findRecord('school-options/group', params.group_id);
      return Ember.RSVP.hash({
        group: group,
        headings: store.query('school-options/heading', {
          optionGroupId: params.group_id
        })
      });
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});