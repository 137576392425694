define("linced-admin/controllers/enrolment/schema/template", ["exports", "ember-get-config", "@linc-technologies/linced-common/enums/person-type", "@linc-technologies/linced-common/utils/tinymce"], function (_exports, _emberGetConfig, _personType, _tinymce) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // External Imports
  var _default = Ember.Controller.extend({
    // Services
    currentUser: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    store: Ember.inject.service(),
    PersonType: _personType.default,
    // Attrs
    editorOptions: Object.freeze((0, _tinymce.minimalTinyMCEConfig)()),
    schema: Ember.computed.alias('model.schema'),
    recordIsDirty: Ember.computed.alias('schema.currentState.isDirty'),
    // State
    deleteSectionDialogOpen: false,
    deleteInProgress: false,
    expandedSections: Ember.A(),
    enrolmentIntroductionDialogOpen: false,
    enrolmentIntroduction: '',
    isSortable: false,
    sectionTitle: '',
    sectionDescription: '',
    sectionDialogOpen: false,
    saveInProgress: false,
    filteredFieldSets: Ember.computed('model.fieldSets.[]', function () {
      return this.model.fieldSets.filter(function (set) {
        return [_personType.default.LEARNER, _personType.default.CAREGIVER, _personType.default.DOCTORS_AND_SPECIALISTS].includes(set.personType);
      });
    }),
    selectedFieldSets: Ember.A(),
    remainingFieldSets: Ember.computed.setDiff('filteredFieldSets', 'selectedFieldSets'),
    allSections: Ember.computed('schema.{sections.[],primaryContacts,emergencyContacts,medicalContacts,medicalConditions}', function () {
      var sections = this.schema.sections.slice();
      sections.pushObjects(Ember.A([this.schema.primaryContacts, this.schema.emergencyContacts, this.schema.medicalContacts, this.schema.medicalConditions]).compact());
      return sections;
    }),
    enrolmentUrl: Ember.computed('currentUser.school.jurisdictionalId', function () {
      var jurisdictionalId = this.get('currentUser.school.jurisdictionalId');
      return "".concat(_emberGetConfig.default.APP.enrolmentHost, "/apply/NZ/").concat(jurisdictionalId, "?preview=").concat(this.schema.id);
    }),
    allLearnerFields: Ember.computed('model.{allFields.[],fieldGroups.[]}', function () {
      return this.model.allFields.filter(function (field) {
        var personType = field.get('personType');
        if (Ember.isEmpty(personType)) {
          personType = field.get('templateGroupId.personType');
        }
        // Include unknowns, so that we don't completely lose them.  There shouldn't be (m)any.
        return personType === _personType.default.LEARNER || Ember.isEmpty(personType);
      });
    }),
    filteredLearnerFields: Ember.computed('allLearnerFields.[]', 'selectedFieldSets.[]', function () {
      // If no filter defined, return all learner fields.
      if (Ember.isEmpty(this.selectedFieldSets)) {
        return this.allLearnerFields;
      }

      // If filter is defined, return only learner fields from the selected template set(s).
      var setIds = this.selectedFieldSets.mapBy('id');
      return this.allLearnerFields.filter(function (field) {
        var setId = field.get('templateSetId.id');
        return setIds.includes(setId);
      });
    }),
    selectedLearnerFieldIds: Ember.computed.mapBy('schema.sections', 'fieldIds'),
    // NOTE: selectedLearnerFields may contain fields which don't match the current filter; use the full list of fields.
    selectedLearnerFields: Ember.computed('allLearnerFields.[]', 'selectedLearnerFieldIds.[]', function () {
      var fields = this.allLearnerFields;
      var selectedIds = this.get('schema.sections').reduce(function (ids, section) {
        return ids.pushObjects(section.get('fields').mapBy('fieldId'));
      }, Ember.A());
      return fields.filter(function (field) {
        return selectedIds.includes(field.get('id'));
      });
    }),
    availableLearnerFields: Ember.computed.setDiff('filteredLearnerFields', 'selectedLearnerFields'),
    allCaregiverFields: Ember.computed('model.{allFields.[],fieldGroups.[]}', function () {
      return this.model.allFields.filter(function (field) {
        var personType = field.get('personType');
        if (Ember.isEmpty(personType)) {
          personType = field.get('templateGroupId.personType');
        }
        // Include unknowns, so that we don't completely lose them.  There shouldn't be (m)any.
        return personType === _personType.default.CAREGIVER || Ember.isEmpty(personType);
      });
    }),
    filteredCaregiverFields: Ember.computed('allCaregiverFields.[]', 'selectedFieldSets.[]', function () {
      // If no filter defined, return all caregiver fields.
      if (Ember.isEmpty(this.selectedFieldSets)) {
        return this.allCaregiverFields;
      }

      // If filter is defined, return only caregiver fields from the selected template set(s).
      var setIds = this.selectedFieldSets.mapBy('id');
      return this.allCaregiverFields.filter(function (field) {
        var setId = field.get('templateSetId.id');
        return setIds.includes(setId);
      });
    }),
    // NOTE: selected*ContactFields may contain fields which don't match the current filter; use the full list of fields.
    selectedPrimaryContactFields: Ember.computed('allCaregiverFields.[]', 'schema.primaryContacts.fields.[]', function () {
      var fields = this.allCaregiverFields;
      var selectedIds = this.schema.primaryContacts.fields.mapBy('fieldId');
      return fields.filter(function (field) {
        return selectedIds.includes(field.get('id'));
      });
    }),
    availablePrimaryContactFields: Ember.computed.setDiff('filteredCaregiverFields', 'selectedPrimaryContactFields'),
    selectedEmergencyContactFields: Ember.computed('allCaregiverFields.[]', 'schema.emergencyContacts.fields.[]', function () {
      var fields = this.allCaregiverFields;
      var selectedIds = this.schema.emergencyContacts.fields.mapBy('fieldId');
      return fields.filter(function (field) {
        return selectedIds.includes(field.get('id'));
      });
    }),
    availableEmergencyContactFields: Ember.computed.setDiff('filteredCaregiverFields', 'selectedEmergencyContactFields'),
    allMedicalContactFields: Ember.computed('model.{allFields.[],fieldGroups.[]}', function () {
      return this.model.allFields.filter(function (field) {
        var personType = field.get('personType');
        if (Ember.isEmpty(personType)) {
          personType = field.get('templateGroupId.personType');
        }
        // Include unknowns, so that we don't completely lose them.  There shouldn't be (m)any.
        return personType === _personType.default.DOCTORS_AND_SPECIALISTS || Ember.isEmpty(personType);
      });
    }),
    filteredMedicalContactFields: Ember.computed('allMedicalContactFields.[]', 'selectedFieldSets.[]', function () {
      // If no filter defined, return all medical contact fields.
      if (Ember.isEmpty(this.selectedFieldSets)) {
        return this.allMedicalContactFields;
      }

      // If filter is defined, return only medical contact fields from the
      // selected template set(s).
      var setIds = this.selectedFieldSets.mapBy('id');
      return this.allMedicalContactFields.filter(function (field) {
        var setId = field.get('templateSetId.id');
        return setIds.includes(setId);
      });
    }),
    selectedMedicalContactFields: Ember.computed('allMedicalContactFields.[]', 'schema.medicalContacts.fieldIds', function () {
      var fields = this.allMedicalContactFields;
      var selectedIds = this.schema.medicalContacts.fields.mapBy('fieldId');
      return fields.filter(function (field) {
        return selectedIds.includes(field.get('id'));
      });
    }),
    availableMedicalContactFields: Ember.computed.setDiff('filteredMedicalContactFields', 'selectedMedicalContactFields'),
    actions: {
      toggleFieldSet: function toggleFieldSet(set) {
        if (this.selectedFieldSets.includes(set)) {
          this.selectedFieldSets.removeObject(set);
        } else {
          this.selectedFieldSets.pushObject(set);
        }
      },
      toggleSection: function toggleSection(section) {
        var expanded = this.expandedSections;
        if (expanded.includes(section)) {
          expanded.removeObject(section);
        } else {
          expanded.pushObject(section);
        }
      },
      // isContactSection receives an extra parameter when called by the template in order to trigger
      // the fieldIds computed property on all the sections to be calculated.  Otherwise, it's deemed unused and thus
      // won't be computed at all - resulting in all fields always being available.
      isContactSection: function isContactSection(section) {
        switch (section) {
          case this.schema.primaryContacts:
          case this.schema.emergencyContacts:
            return true;
          default:
            return false;
        }
      },
      isMedicalContactSection: function isMedicalContactSection(section) {
        switch (section) {
          case this.schema.medicalContacts:
            return true;
          default:
            return false;
        }
      },
      isConditionSection: function isConditionSection(section) {
        switch (section) {
          case this.schema.medicalConditions:
            return true;
          default:
            return false;
        }
      },
      addField: function addField(section, field) {
        var props = field.getProperties('isSiblingLinked', 'key', 'inputType', 'dataType', 'label', 'default', 'placeholder', 'htmlAttributes', 'isRequired', 'optionsFromEnum');
        // `options` is a fragment, so needs to be copied into the new field fragment.
        if (!Ember.isEmpty(field.options)) {
          props.options = field.options.copy();
        }
        // `default` is also a fragment, so needs to be copied into the new field fragment too.
        if (!Ember.isEmpty(field.default)) {
          props.default = field.default.copy();
        }
        props.fieldId = field.get('id');
        props.index = section.get('fields.length') + 1;
        section.get('fields').createFragment(props);
        this.set('selectedField', null);
      },
      removeField: function removeField(section, field) {
        section.get('fields').removeObject(field);
      },
      toggleSectionDialog: function toggleSectionDialog() {
        this.toggleProperty('sectionDialogOpen');
        if (!this.sectionDialogOpen) {
          this.resetSectionForm();
        }
      },
      editSection: function editSection(section) {
        this.set('section', section);
        this.set('sectionTitle', section.get('title'));
        this.set('sectionDescription', section.get('description'));
        this.set('sectionDialogOpen', true);
      },
      saveSection: function saveSection() {
        var section = this.section;
        if (Ember.isEmpty(section)) {
          // Creating a new section
          var _section = this.get('schema.sections').createFragment({
            title: this.sectionTitle,
            description: this.sectionDescription,
            index: this.get('schema.sections.length') + 1
          });

          // Expand the new section by default, so that fields can be added.
          this.expandedSections.pushObject(_section);
        } else {
          // Editing and existing section
          section.set('title', this.sectionTitle);
          section.set('description', this.sectionDescription);
        }
        this.set('sectionDialogOpen', false);
        this.resetSectionForm();
      },
      deleteSection: function deleteSection(section) {
        this.set('sectionToDelete', section);
        this.set('deleteSectionDialogOpen', true);
      },
      confirmDeleteSection: function confirmDeleteSection(confirmed) {
        if (confirmed) {
          this.get('schema.sections').removeObject(this.sectionToDelete);
        }

        // Tidy up / close dialog.
        this.set('deleteSectionDialogOpen', false);
        this.set('sectionToDelete', null);
      },
      saveSchema: function saveSchema() {
        var _this = this;
        var schema = this.schema;
        schema.save().then(function () {
          _this.paperToaster.show('Enrolment form template saved successfully.', {
            duration: 4000,
            position: 'top right',
            toastClass: 'success'
          });
        });
      },
      // drag-drop sorting
      sortDragEnd: function sortDragEnd(_ref) {
        var sourceList = _ref.sourceList,
          sourceIndex = _ref.sourceIndex,
          targetList = _ref.targetList,
          targetIndex = _ref.targetIndex;
        if (sourceList === targetList && sourceIndex === targetIndex) {
          return;
        }
        var item = sourceList.objectAt(sourceIndex);
        sourceList.removeAt(sourceIndex);
        if (sourceList === targetList) {
          targetList.insertAt(targetIndex, item);
        } else {
          // Fields are fragments, so if the list changes they must be copied
          // as fragments' owners can't be changed by simply moving the fragment over.
          targetList.insertAt(targetIndex, item.copy());
        }
        targetList.forEach(function (entity, index) {
          entity.set('index', index + 1);
        });
      },
      toggleEnrolmentIntroductionDialog: function toggleEnrolmentIntroductionDialog() {
        this.set('enrolmentIntroduction', this.schema.introduction);
        this.toggleProperty('enrolmentIntroductionDialogOpen');
      },
      saveEnrolmentDescription: function saveEnrolmentDescription() {
        this.toggleProperty('enrolmentIntroductionDialogOpen');
        this.set('schema.introduction', this.enrolmentIntroduction);
        this.set('enrolmentIntroduction', '');
      }
    },
    resetSectionForm: function resetSectionForm() {
      this.set('section', '');
      this.set('sectionTitle', '');
      this.set('sectionDescription', '');
    },
    reset: function reset() {
      this.setProperties({
        selectedFieldSets: Ember.A(),
        expandedSections: Ember.A()
      });
    }
  });
  _exports.default = _default;
});