define("linced-admin/controllers/enrolment/schema/templates", ["exports", "@linc-technologies/linced-common/enums/country-code", "ember-get-config"], function (_exports, _countryCode, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    toaster: Ember.inject.service(),
    router: Ember.inject.service(),
    editingTemplate: null,
    templateDialogOpen: false,
    applyTemplate: null,
    applyErrorMessage: '',
    applyInProgress: false,
    applyDialogOpen: false,
    selectedSchool: null,
    actions: {
      addTemplate: function addTemplate() {
        var template = this.store.createRecord('enrolments/schema', {
          primaryContacts: {
            count: 3,
            index: 1,
            title: 'Primary Contact(s)'
          },
          emergencyContacts: {
            count: 2,
            index: 2,
            title: 'Emergency Contact(s)',
            description: 'Who should the school contact in case of an emergency, if the Primary Contacts are unreachable?'
          },
          medicalContacts: {
            count: 2,
            index: 3,
            title: 'Medical Contact(s)'
          },
          medicalConditions: {
            count: 2,
            index: 4,
            title: 'Medical Condition(s)'
          }
        });
        this.setProperties({
          editingTemplate: template,
          templateDialogOpen: true
        });
      },
      editTemplate: function editTemplate(template) {
        this.setProperties({
          editingTemplate: template,
          templateDialogOpen: true
        });
      },
      closeTemplateDialog: function closeTemplateDialog() {
        this.editingTemplate.rollbackAttributes();
        this.set('templateDialogOpen', false);
      },
      saveTemplate: function saveTemplate() {
        var _this = this;
        var isNew = Ember.isEmpty(this.editingTemplate.id);
        this.editingTemplate.save().then(function () {
          _this.toaster.success('Enrolment form template saved successfully.');
          _this.set('templateDialogOpen', false);
          if (isNew) {
            // This is a new template - go straight to editing the form itself.
            _this.router.transitionTo('enrolment.schema.template', _this.editingTemplate.id);
          }
        }).catch(function () {
          _this.toaster.error('Error saving enrolment form template!');
        });
      },
      deleteTemplate: function deleteTemplate(template) {
        this.set('errorMessage', '');
        this.set('templateLabel', template.get('templateName'));
        this.set('templateId', template.id);
        this.set('deleteTemplateDialogOpen', true);
      },
      confirmDelete: function confirmDelete(confirmed) {
        var _this2 = this;
        var toaster = this.toaster;
        return new Ember.RSVP.Promise(function (resolve, reject) {
          if (confirmed) {
            // Deletion confirmed; actually delete the record.
            _this2.set('deleteInProgress', true);

            // Grab record from local store - no GET request, as that causes weirdness.
            // We only need the ID to issue the DELETE anyway, which can't change, so no need to use findRecord.
            var template = _this2.store.peekRecord('enrolments/schema', _this2.templateId);

            // Delete record (locally + pushed to server).
            return template.destroyRecord().then(resolve).catch(function (err) {
              // Remove 'deleted' flag so that entity can be interacted with again.
              template.rollbackAttributes();
              reject(err);
            });
          } else {
            resolve();
          }
        }).then(function () {
          // Tidy up / close dialog.
          _this2.set('deleteTemplateDialogOpen', false);
          if (confirmed) {
            toaster.success('Template deleted successfully.');
          }
        }).catch(function () {
          toaster.error('Error deleting Template.');
        }).then(function () {
          _this2.set('deleteInProgress', false);
        });
      },
      // Apply templates
      openApplyDialog: function openApplyDialog(template) {
        this.setProperties({
          applyTemplate: template,
          applyErrorMessage: '',
          applyInProgress: false,
          applyDialogOpen: true,
          selectedSchool: null
        });
      },
      closeApplyDialog: function closeApplyDialog() {
        this.set('applyDialogOpen', false);
      },
      applyTemplate: function applyTemplate() {
        var _this3 = this;
        var school = this.selectedSchool;
        if (Ember.isEmpty(school)) {
          return;
        }
        var data = {
          schoolId: school.id,
          countryCode: _countryCode.default.getLabel(school.countryCode),
          schoolCode: school.jurisdictionalId,
          schoolName: school.name,
          // TODO: We do not store an id on the enrolment schema so we can't do the
          //  blobby serving. This will work until we nuke the unauthenticated
          //  media routes.
          schoolLogoUrl: "".concat(_emberGetConfig.default.APP.apiHost, "/media/").concat(_emberGetConfig.default.APP.apiPath, "/media/").concat(school.logoMedia, "/logo.png}"),
          jurisdiction: school.jurisdiction
        };
        var options = {
          data: JSON.stringify(data)
        };
        var templatesUri = "".concat(_emberGetConfig.default.APP.apiHost, "/enrolments/").concat(_emberGetConfig.default.APP.apiPath, "/templates/schemas");
        var templateId = this.get('applyTemplate.id');
        this.set('applyInProgress', true);
        this.ajax.post("".concat(templatesUri, "/").concat(templateId, "/:apply"), options).then(function () {
          _this3.toaster.success('Template applied successfully.');
          _this3.set('applyErrorMessage', '');
          _this3.set('applyInProgress', false);
          _this3.set('applyDialogOpen', false);
        }).catch(function (err) {
          _this3.toaster.error("Error applying template! ".concat(err));
          _this3.set('applyErrorMessage', err.payload.message);
          _this3.set('applyInProgress', false);
        });
      }
    }
  });
  _exports.default = _default;
});