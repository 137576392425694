define("linced-admin/models/user", ["exports", "@ember-data/model", "md5"], function (_exports, _model, _md) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    username: (0, _model.attr)('string'),
    schoolId: (0, _model.attr)('string'),
    personId: (0, _model.attr)('string'),
    roles: (0, _model.attr)(),
    primaryRole: Ember.computed('roles', function () {
      if (Ember.isEmpty(this.roles)) {
        return 'NONE';
      }
      return this.roles[0];
    }),
    password: (0, _model.attr)('string'),
    // Should never be set, except when creating new users.
    image: Ember.computed('id', function () {
      return "https://gravatar.com/avatar/".concat((0, _md.default)(this.id), "?d=monsterid&f=y");
    }),
    bannerText: Ember.computed.alias('username')
  });
  _exports.default = _default;
});