define("linced-admin/routes/sif/zones", ["exports", "linced-admin/routes/base", "linced-admin/enums/sif-country"], function (_exports, _base, _sifCountry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    queryParams: {
      country: {
        refreshModel: true
      }
    },
    model: function model(params) {
      if (Ember.isEmpty(params.country) || Ember.isEmpty(_sifCountry.default.values.findBy('value', params.country))) {
        params.country = _sifCountry.default.AUSTRALIA;
      }
      return Ember.RSVP.hash({
        zones: this.store.findAll("sif/".concat(params.country, "/zone"))
      });
    }
  });
  _exports.default = _default;
});