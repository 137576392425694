define("linced-admin/components/dropdown-multiselect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    // sortByParam is the list item's attribute that the list is sorted by
    sortByParam: '',
    // list is the displayed array of items
    list: null,
    // selected is the array of selected items, ideally passed in
    selected: Ember.A(),
    // isToggled indicates whether the "Show all" mode is active
    isToggled: false,
    actions: {
      addItem: function addItem(item, checked) {
        if (checked) {
          this.selected.pushObject(item);
        } else {
          this.selected.removeObject(item);
        }
      },
      deselectAll: function deselectAll() {
        this.set('selected', Ember.A());
      }
    }
  });
  _exports.default = _default;
});