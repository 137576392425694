define("linced-admin/utils/strings", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.conformDate = conformDate;
  _exports.isTruthy = isTruthy;
  _exports.toCamelCase = toCamelCase;
  function toCamelCase(str) {
    if (Ember.isEmpty(str)) {
      return '';
    }
    return str.replace(/[_-]/g, ' ') // Convert underscores and dashes to spaces (word boundaries)
    .trim() // Remove any leading/trailing space caused by replacing dashes/underscores to spaces
    .replace(/[^a-z0-9 ]/gi, '') // Remove remaining non-alphanumeric characters
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
      if (/\s+/.test(match)) {
        return '';
      }
      // lowercase if first letter, otherwise uppercase.
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
  }
  function isTruthy(val) {
    switch (val.toLowerCase().trim()) {
      case 'y':
      case 'yes':
      case 'true':
      case '1':
        return true;
      default:
        return false;
    }
  }
  function conformDate(str) {
    if (Ember.isEmpty(str)) {
      return '';
    }
    var parsed = (0, _moment.default)(str, ['YYYY-MM-DD', 'DD/MM/YYYY', 'MM/DD/YYYY']);
    if (parsed.isValid()) {
      return parsed.format('YYYY-MM-DD');
    } else {
      return '';
    }
  }
});