define("linced-admin/models/goals/category", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    media: Ember.inject.service(),
    schoolId: (0, _model.belongsTo)('school'),
    school: Ember.computed.alias('schoolId'),
    campusId: (0, _model.attr)('string'),
    // TODO: implement when we have Campuses

    isPublished: (0, _model.attr)('boolean'),
    subjectId: (0, _model.belongsTo)('goals/subject'),
    subject: Ember.computed.alias('subjectId'),
    isPLP: (0, _model.attr)('boolean'),
    subcategories: (0, _model.hasMany)('goals/subcategory'),
    subcategorySortOrder: Object.freeze(['sequence', 'label']),
    sortedSubcategories: Ember.computed.sort('subcategories', 'subcategorySortOrder'),
    sortedBoundSubcategories: Ember.computed('sortedSubcategories.[]', function () {
      var subcategories = this.sortedSubcategories.slice();
      subcategories.__category__ = this;
      return subcategories;
    }),
    goals: (0, _model.hasMany)('goals/goal'),
    filteredGoals: Ember.computed.filterBy('goals', 'subcategory.id', undefined),
    goalSortOrder: Object.freeze(['sequence', 'label']),
    sortedGoals: Ember.computed.sort('filteredGoals', 'goalSortOrder'),
    sortedBoundGoals: Ember.computed('sortedGoals.[]', function () {
      var goals = this.sortedGoals.slice();
      goals.__category__ = this;
      return goals;
    }),
    updateTime: (0, _model.attr)('number'),
    createTime: (0, _model.attr)('number'),
    isLocked: (0, _model.attr)('boolean'),
    templateId: (0, _model.attr)('string'),
    // TODO: implement once Templates are added
    sequence: (0, _model.attr)('number'),
    label: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    badgeMediaId: (0, _model.attr)('string'),
    badgeMedia: Ember.computed.alias('badgeMediaId'),
    badgeImage: '',
    badgeDescription: (0, _model.attr)('string'),
    didCreate: function didCreate() {
      this.media.onResourceLoadOrUpdate(this, this.badgeMedia, 'badgeImage');
    },
    didLoad: function didLoad() {
      this.media.onResourceLoadOrUpdate(this, this.badgeMedia, 'badgeImage');
    },
    didUpdate: function didUpdate() {
      this.media.onResourceLoadOrUpdate(this, this.badgeMedia, 'badgeImage');
    },
    willDestroy: function willDestroy() {
      this.media.onDestroy(this.badgeImage);
      this._super();
    }
  });
  _exports.default = _default;
});