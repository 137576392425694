define("linced-admin/controllers/page-templates", ["exports", "ember-get-config", "@linc-technologies/linced-common/enums/page-access-level", "linced-admin/enums/page-type", "@linc-technologies/linced-common/enums/reaction-type", "@linc-technologies/linced-common/utils/tinymce"], function (_exports, _emberGetConfig, _pageAccessLevel, _pageType, _reactionType, _tinymce) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _Object$freeze;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } // External Imports
  var _default = Ember.Controller.extend({
    queryParams: ['currentFilter'],
    fetch: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    toaster: Ember.inject.service(),
    PageAccessLevel: _pageAccessLevel.default,
    PageType: _pageType.default,
    pageTypes: _pageType.default.values,
    ReactionType: _reactionType.default,
    reactionTypes: _reactionType.default.values,
    currentFilter: _pageType.default.STUDENT,
    filteredPages: Ember.computed('filteredByTypePages.[]', 'selectedUsageSetsFilter.[]', function () {
      var _this = this;
      return this.get('filteredByTypePages').filter(function (page) {
        if (!Ember.isEmpty(_this.selectedUsageSetsFilter)) {
          return _this.selectedUsageSetsFilter.some(function (usageSet) {
            return page.get('usageSetIds').includes(usageSet);
          });
        }
        return true;
      });
    }),
    filteredByTypePages: Ember.computed('model.pages.@each.type', 'currentFilter', function () {
      var _this2 = this;
      return this.get('model.pages').filter(function (page) {
        return page.type === _this2.currentFilter;
      });
    }),
    // Sorting
    sortDialogOpen: false,
    pageSort: Object.freeze(['sequence', 'label']),
    sortedPages: Ember.computed.sort('model.pages', 'pageSort'),
    sortedFilteredPages: Ember.computed.sort('filteredPages', 'pageSort'),
    currentPage: null,
    pageId: '',
    pageLabel: '',
    pageKey: '',
    pageVisibleToParents: false,
    pageSiblingLinked: false,
    pageType: null,
    type: null,
    hasPosts: false,
    tagCategories: Ember.A(),
    remainingTagCategories: Ember.computed.setDiff('model.tagCategories', 'tagCategories'),
    hasGoals: false,
    hasGoalsAsTags: false,
    goalSubjects: Ember.A(),
    remainingGoalSubjects: Ember.computed.setDiff('model.goalSubjects', 'goalSubjects'),
    hasMultipleGoalSubjects: Ember.computed('goalSubjects.length', function () {
      return this.goalSubjects.length > 1;
    }),
    sortingGoalSubjects: false,
    hasEffort: false,
    hasOTJAssessment: false,
    hasAssessments: false,
    assessments: Ember.A(),
    remainingAssessments: Ember.computed.setDiff('model.assessments', 'assessments'),
    hasMultipleAssessments: Ember.computed('hasAssessments', 'assessments.length', function () {
      return this.hasAssessments && this.assessments.length > 1;
    }),
    sortingAssessments: false,
    // Is this a special use page
    // ie. sickbay
    isSpecialUse: Ember.computed('isSickbay', function () {
      return this.isSickbay;
    }),
    specialUseLabel: Ember.computed('isSickbay', function () {
      switch (true) {
        case this.isSickbay:
          return 'Sickbay';
        default:
          return '';
      }
    }),
    // Page configuration
    icon: '',
    label: '',
    title: '',
    description: '',
    caregiverDescription: '',
    learnerDescription: '',
    postTemplate: '',
    reactionType: null,
    // Page visibility
    visibleInMenu: false,
    visibleOnContent: false,
    visibleOnStudentProfile: false,
    visibleToParents: false,
    visibleToLearners: false,
    // Page authorisation / permissions
    staffCanPublish: false,
    staffCanEdit: false,
    caregiversCanPost: false,
    learnersCanPost: false,
    canComment: false,
    // Page magic
    hasLearnerIcon: false,
    hasAutogroup: false,
    // Page Access Level
    accessLevel: _pageAccessLevel.default.STANDARD,
    hasRestrictedAccess: Ember.computed('accessLevel', function () {
      // Disable page visibility flags for caregiver or learners for non-standard pages.
      return this.accessLevel !== _pageAccessLevel.default.STANDARD;
    }),
    accessLevelNoteMap: Object.freeze((_Object$freeze = {}, _defineProperty(_Object$freeze, _pageAccessLevel.default.STANDARD, ' - only visible to staff members who have standard, sensitive, or confidential access. No access to staff members with restricted access.'), _defineProperty(_Object$freeze, _pageAccessLevel.default.SENSITIVE, ' - only visible to staff members who have sensitive or confidential access.'), _defineProperty(_Object$freeze, _pageAccessLevel.default.CONFIDENTIAL, ' - only visible to staff members who have confidential level access.'), _Object$freeze)),
    // Is this a Sickbay page
    isSickbay: false,
    pageDialogOpen: false,
    saveInProgress: false,
    deletePageDialogOpen: false,
    deleteInProgress: false,
    errorMessage: '',
    // Usage sets attributes
    usageSetDialogOpen: false,
    selectedUsageSets: Ember.A(),
    initialUsageSets: Ember.A(),
    remainingUsageSetsFilter: Ember.computed.setDiff('model.usageSets', 'selectedUsageSetsFilter'),
    selectedUsageSetsFilter: Ember.A(),
    pageProperties: Ember.A(['hasAssessments', 'hasPosts', 'hasGoals', 'hasEffort', 'icon', 'label', 'title', 'description', 'caregiverDescription', 'learnerDescription', 'postTemplate', 'reactionType', 'visibleInMenu', 'visibleOnContent', 'visibleOnStudentProfile', 'visibleToParents', 'visibleToLearners', 'staffCanPublish', 'staffCanEdit', 'caregiversCanPost', 'learnersCanPost', 'canComment', 'hasLearnerIcon', 'hasAutogroup', 'hasOTJAssessment', 'hasGoalsAsTags', 'accessLevel', 'isSickbay']),
    editorOptions: Object.freeze((0, _tinymce.defaultTinyMCEConfig)()),
    // Bulk Apply dialog.
    bulkApplyDialogOpen: false,
    bulkApplyInProgress: false,
    bulkApplyTemplate: null,
    bulkApplyGroup: null,
    bulkApplyExclusions: null,
    bulkApplyProcessed: 0,
    remainingBulkApplySchools: Ember.computed.setDiff('bulkApplyGroup.schools', 'bulkApplyExclusions'),
    bulkApplyProgress: Ember.computed('bulkApplyGroup.schools.[]', 'bulkApplyProcessed', function () {
      if (Ember.isEmpty(this.bulkApplyGroup.schools)) {
        return 100;
      }
      if (this.bulkApplyProcessed < 1) {
        return 0;
      }
      return this.bulkApplyProcessed / this.bulkApplyGroup.schools.length * 100;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.resetForm();
    },
    // toggleDialog opens the page dialog.
    toggleDialog: function toggleDialog() {
      this.set('errorMessage', '');
      this.toggleProperty('pageDialogOpen');
      if (!this.pageDialogOpen) {
        this.resetForm();
      } else if (Ember.isEmpty(this.pageId)) {
        this.set('type', _pageType.default.values.findBy('value', this.currentFilter));
      }
    },
    actions: {
      filterChanged: function filterChanged(newValue) {
        switch (newValue) {
          case 'tags':
            this.router.transitionTo('admin.tags');
            break;
          default:
            this.set('currentFilter', newValue);
            this.set('selectedUsageSetsFilter', Ember.A());
            break;
        }
      },
      togglePageDialog: function togglePageDialog() {
        this.toggleDialog();
      },
      editPage: function editPage(id) {
        var _this3 = this;
        this.store.findRecord('posts/page', id).then(function (page) {
          _this3.setProperties(page.getProperties(_this3.pageProperties));
          _this3.set('type', _this3.pageTypes.findBy('value', page.get('type')));
          _this3.set('reactionType', _this3.reactionTypes.findBy('value', page.get('reactionType')));
          _this3.set('goalSubjects', page.get('goalSubjects').slice());
          _this3.set('tagCategories', page.get('tagCategories').slice());
          _this3.set('assessments', page.get('assessments').slice());
        });
        this.set('pageId', id);
        this.toggleDialog();
      },
      savePage: function savePage() {
        var _this4 = this;
        this.set('saveInProgress', true);
        this.set('errorMessage', '');
        var promise;
        var action = 'create';
        if (Ember.isEmpty(this.pageId)) {
          promise = new Ember.RSVP.Promise(function (resolve) {
            var record = _this4.store.createRecord('posts/page', _this4.getProperties(_this4.pageProperties));
            record.setProperties({
              authorId: _this4.get('currentUser.personId'),
              type: _this4.get('type.value'),
              reactionType: _this4.get('reactionType.value'),
              goalSubjectIds: _this4.goalSubjects,
              tagCategoryIds: _this4.tagCategories,
              assessmentIds: _this4.assessments,
              sequence: 999,
              isSickbay: _this4.isSickbay
            });
            resolve(record);
          });
        } else {
          action = 'update';
          promise = new Ember.RSVP.Promise(function (resolve) {
            var id = _this4.pageId;
            _this4.store.findRecord('posts/page', id).then(function (page) {
              page.setProperties(_this4.getProperties(_this4.pageProperties));
              page.setProperties({
                type: _this4.get('type.value'),
                reactionType: _this4.get('reactionType.value'),
                goalSubjectIds: _this4.goalSubjects,
                tagCategoryIds: _this4.tagCategories,
                assessmentIds: _this4.assessments,
                isSickbay: _this4.isSickbay
              });
              resolve(page);
            });
          });
        }
        promise.then(function (record) {
          return record.save().then(function () {
            if (action === 'create') {
              // Send an event to trigger the route to refresh the model
              _this4.send('pageCreated');
            }
            _this4.set('pageDialogOpen', false);
            _this4.resetForm();
          }).catch(function (err) {
            _this4.handleError(err, action);
            record.rollbackAttributes();
          }).then(function () {
            _this4.set('saveInProgress', false);
          });
        });
      },
      deletePage: function deletePage(id) {
        var _this5 = this;
        this.set('errorMessage', '');
        this.store.findRecord('posts/page', id).then(function (page) {
          _this5.set('pageLabel', page.get('label'));
        });
        this.set('pageId', id);
        this.set('deletePageDialogOpen', true);
      },
      confirmDelete: function confirmDelete(confirmed) {
        var _this6 = this;
        if (confirmed) {
          return new Ember.RSVP.Promise(function (resolve, reject) {
            // Deletion confirmed; actually delete the record.
            _this6.set('deleteInProgress', true);

            // Grab record from local store - no GET request, as that causes weirdness.
            // We only need the ID to issue the DELETE anyway, which can't change, so no need to use findRecord.
            var page = _this6.store.peekRecord('posts/page', _this6.pageId);
            _this6.set('currentPage', page);
            // Delete record (locally + pushed to server).
            return page.destroyRecord().then(resolve).catch(function (err) {
              // Remove 'deleted' flag so that entity can be interacted with again.
              page.rollbackAttributes();
              reject(err);
            });
          }).then(function () {
            // Update the usage sets relationships
            return _this6.currentPage.get('usageSetIds').then(function (usageSetIds) {
              var promises = [];
              usageSetIds.forEach(function (usageSetId) {
                var promise = usageSetId.save();
                promises.push(promise);
              });
              return Promise.all(promises);
            }).then(function () {
              // Tidy up / close dialog.
              _this6.setProperties({
                deletePageDialogOpen: false,
                currentPage: null
              });
              _this6.resetForm();
            });
          }).catch(function (err) {
            _this6.handleError(err, 'delete');
          }).then(function () {
            _this6.set('deleteInProgress', false);
          });
        } else {
          this.setProperties({
            deletePageDialogOpen: false,
            currentPage: null
          });
        }
      },
      addGoalSubject: function addGoalSubject(subject) {
        this.goalSubjects.pushObject(subject);
      },
      removeGoalSubject: function removeGoalSubject(subject) {
        this.goalSubjects.removeObject(subject);
      },
      addTagCategory: function addTagCategory(category) {
        this.tagCategories.pushObject(category);
      },
      removeTagCategory: function removeTagCategory(category) {
        this.tagCategories.removeObject(category);
      },
      toggleHasAssessments: function toggleHasAssessments() {
        this.toggleProperty('hasAssessments');
        if (this.hasAssessments) {
          // If page has assessments, set access level to default
          this.set('accessLevel', _pageAccessLevel.default.STANDARD);
        }
      },
      addAssessment: function addAssessment(assessment) {
        this.assessments.pushObject(assessment);
      },
      removeAssessment: function removeAssessment(assessment) {
        this.assessments.removeObject(assessment);
      },
      setAccessBasedFlags: function setAccessBasedFlags(accessLevel) {
        this.set('accessLevel', accessLevel);
        if (accessLevel !== _pageAccessLevel.default.STANDARD) {
          this.setProperties({
            // If page access level is not Standard
            // Set following flags related to page visibility for caregiver or learners to false
            visibleOnStudentProfile: false,
            visibleToParents: false,
            visibleOnContent: false,
            visibleToLearners: false,
            caregiversCanPost: false,
            learnersCanPost: false
          });
        }
      },
      toggleBulkApplyDialog: function toggleBulkApplyDialog() {
        this.toggleProperty('bulkApplyDialogOpen');
        if (!this.bulkApplyDialogOpen) {
          this.setProperties({
            bulkApplyInProgress: false,
            bulkApplyTemplate: null,
            bulkApplyGroup: null,
            bulkApplyExclusions: Ember.A(),
            bulkApplyProcessed: 0
          });
        }
      },
      addBulkApplyExclusion: function addBulkApplyExclusion(school) {
        this.bulkApplyExclusions.pushObject(school);
      },
      removeBulkApplyExclusion: function removeBulkApplyExclusion(school) {
        this.bulkApplyExclusions.removeObject(school);
      },
      setBulkApplyGroup: function setBulkApplyGroup(group) {
        this.setProperties({
          bulkApplyGroup: group,
          bulkApplyExclusions: Ember.A()
        });
      },
      bulkApply: function bulkApply() {
        var _this7 = this;
        this.setProperties({
          bulkApplyInProgress: true,
          bulkApplyProcessed: 0
        });
        var excludedIds = this.bulkApplyExclusions.mapBy('id');
        var templateId = this.bulkApplyTemplate.id;
        this.bulkApplyGroup.schools.reduce(function (chain, school) {
          return chain.then(function () {
            if (excludedIds.includes(school.id)) {
              _this7.incrementProperty('bulkApplyProcessed');
              return Ember.RSVP.resolve();
            }
            _this7.fetch.set('headers.X-Tenant-Id', school.id);
            return _this7.fetch.post("".concat(_emberGetConfig.default.APP.apiHost, "/posts/").concat(_emberGetConfig.default.APP.apiPath, "/templates/pages/").concat(templateId, "/:apply"), {
              data: JSON.stringify({
                schoolId: school.id
              })
            }).then(function (data) {
              // Set the new Page's sequence to 999 to put it at the end of
              // the list, since the sequence won't necessarily align with the
              // school's existing Pages.
              data.page.sequence = 999;
              return _this7.fetch.put("".concat(_emberGetConfig.default.APP.apiHost, "/posts/").concat(_emberGetConfig.default.APP.apiPath, "/pages/").concat(data.page.id), {
                data: data
              });
            }).then(function () {
              return _this7.incrementProperty('bulkApplyProcessed');
            });
          });
        }, Ember.RSVP.resolve());
      },
      openSortDialog: function openSortDialog() {
        this.set('sortDialogOpen', true);
      },
      closeSortDialog: function closeSortDialog() {
        this.set('sortDialogOpen', false);
      },
      // drag-drop sorting
      sortDragEnd: function sortDragEnd(_ref) {
        var sourceList = _ref.sourceList,
          sourceIndex = _ref.sourceIndex,
          targetList = _ref.targetList,
          targetIndex = _ref.targetIndex;
        if (sourceList === targetList && sourceIndex === targetIndex) {
          return;
        }
        var item = sourceList.objectAt(sourceIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, item);
      },
      openUsageSetDialog: function openUsageSetDialog(id) {
        var _this8 = this;
        var page = this.store.peekRecord('posts/page', id);
        page.get('usageSetIds').then(function (usageSetsArray) {
          _this8.setProperties({
            usageSetDialogOpen: true,
            currentPage: page,
            selectedUsageSets: usageSetsArray.slice(),
            initialUsageSets: usageSetsArray
          });
        }).catch(function () {
          _this8.toaster.error("Error trying to edit the page's usage sets");
        });
      },
      closeUsageSetDialog: function closeUsageSetDialog() {
        this.currentPage.set('usageSetIds', this.initialUsageSets);
        this.setProperties({
          usageSetDialogOpen: false,
          selectedUsageSets: Ember.A(),
          initialUsageSets: Ember.A(),
          currentPage: null
        });
      },
      addUsageSet: function addUsageSet(usageSet, checked) {
        if (checked) {
          this.selectedUsageSets.pushObject(usageSet);
        } else {
          this.selectedUsageSets.removeObject(usageSet);
        }
      },
      addUsageSetFilter: function addUsageSetFilter(usageSet) {
        this.selectedUsageSetsFilter.pushObject(usageSet);
      },
      removeUsageSetFilter: function removeUsageSetFilter(usageSet) {
        this.selectedUsageSetsFilter.removeObject(usageSet);
      },
      savePageAndUsageSet: function savePageAndUsageSet() {
        var _this9 = this;
        this.set('saveInProgress', true);
        var promises = [];
        var changedUsageSets = Ember.A();

        // Update the usage sets
        var removedUsageSets = this.initialUsageSets.filter(function (usageSet) {
          return !_this9.selectedUsageSets.includes(usageSet);
        });
        var addedUsageSets = this.selectedUsageSets.filter(function (usageSet) {
          return !_this9.initialUsageSets.includes(usageSet);
        });
        changedUsageSets = changedUsageSets.concat(removedUsageSets, addedUsageSets);
        if (!Ember.isEmpty(changedUsageSets)) {
          // Usage sets a page has been newly added to/removed from
          changedUsageSets.forEach(function (changedUsageSet) {
            var promise = changedUsageSet.save();
            promises.push(promise);
          });
        }
        // Save the page template at the end - this is done so the comparison is most accurate
        // as this.initialUsageSets is a reference to usageSetIds
        this.currentPage.set('usageSetIds', this.selectedUsageSets);
        promises.push(this.currentPage.save());
        return Promise.all(promises).then(function () {
          // After saving the page and all involved usage sets
          _this9.toaster.success("Successfully saved the page and usage sets");
          _this9.currentPage.reload();
          _this9.setProperties({
            usageSetDialogOpen: false,
            currentPage: null,
            selectedUsageSets: Ember.A(),
            initialUsageSets: Ember.A(),
            saveInProgress: false
          });
        }).catch(function () {
          _this9.toaster.error("Error saving the page's usage sets");
          _this9.currentPage.rollbackAttributes();
        });
      },
      // unpublishPageTemplate will remove the page from all usage sets so it becomes unusable
      unpublishPageTemplate: function unpublishPageTemplate(page) {
        var _this10 = this;
        page.get('usageSetIds').then(function (usageSetsArray) {
          if (!Ember.isEmpty(usageSetsArray)) {
            _this10.setProperties({
              selectedUsageSets: Ember.A(),
              initialUsageSets: usageSetsArray,
              currentPage: page
            });
            _this10.send('savePageAndUsageSet');
          }
        });
      }
    },
    handleError: function handleError(err) {
      if (!Ember.isEmpty(err.errors.msg)) {
        this.set('errorMessage', err.errors.msg);
      } else {
        this.set('errorMessage', err.message);
      }
    },
    resetForm: function resetForm() {
      this.setProperties({
        pageId: '',
        type: {
          value: 1,
          label: 'Student'
        },
        hasAssessments: false,
        hasPosts: false,
        hasGoals: false,
        hasEffort: false,
        hasGoalsAsTags: false,
        hasOTJAssessment: false,
        goalSubjects: Ember.A(),
        tagCategories: Ember.A(),
        assessments: Ember.A(),
        icon: '',
        label: '',
        title: '',
        description: '',
        caregiverDescription: '',
        learnerDescription: '',
        postTemplate: '',
        reactionType: {
          value: 1,
          label: 'None'
        },
        visibleInMenu: false,
        visibleOnContent: false,
        visibleOnStudentProfile: false,
        visibleToParents: false,
        staffCanPublish: false,
        staffCanEdit: false,
        caregiversCanPost: false,
        learnersCanPost: false,
        canComment: false,
        accessLevel: _pageAccessLevel.default.values.findBy('value', _pageAccessLevel.default.STANDARD),
        bulkApplyDialogOpen: false,
        bulkApplyInProgress: false,
        bulkApplyTemplate: null,
        bulkApplyGroup: null,
        bulkApplyExclusions: Ember.A(),
        bulkApplyProcessed: 0,
        isSickbay: false,
        selectedUsageSetsFilter: Ember.A()
      });
    },
    reset: function reset() {
      this.resetForm();
    }
  });
  _exports.default = _default;
});