define("linced-admin/serializers/goals/state", ["exports", "@linc-technologies/linced-common/serializers/goals/state"], function (_exports, _state) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _state.default;
    }
  });
});