define("linced-admin/controllers/logs/enrolments", ["exports", "linced-admin/enums/enrolment-log-resource"], function (_exports, _enrolmentLogResource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Attrs
    queryParams: ['jurisdiction', 'resource', 'schoolId', 'duration'],
    // Default State
    EnrolmentLogResource: _enrolmentLogResource.default,
    jurisdiction: null,
    resource: null,
    duration: '15',
    schoolId: null,
    resultDialogOpen: false,
    result: null,
    resultLoading: false,
    // methods
    selectedSchool: Ember.computed('schoolId', function () {
      var _this = this;
      if (Ember.isEmpty(this.schoolId)) {
        return null;
      }
      var schools = this.model.schools.filter(function (school) {
        return school.id === _this.schoolId;
      });
      if (schools.length > 0) {
        return schools[0];
      }
    }),
    // durations provides the list of durations to offer in the dropdown.
    durations: Ember.A([{
      value: '15',
      label: '15 minutes'
    }, {
      value: '60',
      label: '1 hour'
    }, {
      value: '1440',
      label: '1 day'
    }, {
      value: '10080',
      label: '1 week'
    }, {
      value: '43200',
      label: '30 days'
    }, {
      value: '129600',
      label: '90 days'
    }, {
      value: '262080',
      label: '6 months'
    }, {
      value: '525600',
      label: '1 year'
    }]),
    // Actions
    actions: {
      /**
       * setSchool binds the selected school's id as a query param to filter logs.
       *
       * @param {Object} school - contains a school model.
       */
      setSchool: function setSchool(school) {
        if (Ember.isEmpty(school)) {
          this.set('schoolId', null);
          return;
        }
        this.set('schoolId', school.id);
      },
      /**
       * setDuration binds the selected duration as a query param to filter logs.
       *
       * @param {Object} duration - contains the selected duration object.
       */
      setDuration: function setDuration(duration) {
        this.set('duration', duration.value);
      },
      /**
       * showResult displays a dialog with the result associated with a log entry.
       *
       * @param {Object} logEntry - the log entry whose result should be shown.
       */
      showResult: function showResult(logEntry) {
        var _this2 = this;
        var loading = true;
        if (Ember.isEmpty(logEntry.resourceId)) {
          loading = false;
        } else {
          var resource = '';
          switch (logEntry.resource) {
            case _enrolmentLogResource.default.IDENTIFIERS:
              resource = 'identifier';
              break;
            case _enrolmentLogResource.default.ENROLMENTS:
              resource = 'enrolment';
              break;
            case _enrolmentLogResource.default.UNENROLMENTS:
              resource = 'unenrolment';
              break;
          }
          if (Ember.isEmpty(resource)) {
            loading = false;
          } else {
            this.store.findRecord("enrolments/".concat(resource), logEntry.resourceId).then(function (entity) {
              _this2.setProperties({
                result: entity,
                resultLoading: false
              });
            });
          }
        }
        this.setProperties({
          resultDialogOpen: true,
          resultLoading: loading,
          result: null
        });
      },
      closeResultDialog: function closeResultDialog() {
        this.set('resultDialogOpen', false);
      }
    }
  });
  _exports.default = _default;
});