define("linced-admin/models/media", ["exports", "@linc-technologies/linced-common/models/media", "@ember-data/model"], function (_exports, _media, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _media.default.extend({
    globalNoticeId: (0, _model.belongsTo)('posts/globalnotice')
  });
  _exports.default = _default;
});