define("linced-admin/components/chart-pie", ["exports", "@linc-technologies/linced-common/components/chart-pie"], function (_exports, _chartPie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _chartPie.default;
    }
  });
});