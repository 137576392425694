define("linced-admin/templates/school-options/preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5asfxEHF",
    "block": "{\"symbols\":[\"group\",\"card\",\"heading\",\"field\",\"title\",\"text\"],\"statements\":[[7,\"h1\",true],[8],[0,\"Option Set Preview: \"],[1,[24,[\"model\",\"optionSet\",\"name\"]],false],[9],[0,\"\\n\"],[5,\"paper-content\",[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[24,[\"groups\"]]],null,{\"statements\":[[0,\"    \"],[5,\"paper-card\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[6,[23,2,[\"title\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[6,[23,5,[\"text\"]],[],[[],[]],{\"statements\":[[0,\"\\n          \"],[6,[23,6,[\"headline\"]],[],[[],[]],{\"statements\":[[1,[23,1,[\"name\"]],false]],\"parameters\":[]}],[0,\"\\n        \"]],\"parameters\":[6]}],[0,\"\\n      \"]],\"parameters\":[5]}],[0,\"\\n      \"],[6,[23,2,[\"content\"]],[],[[\"@class\"],[\"layout-column\"]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[28,\"get\",[[24,[\"groupHeadings\"]],[23,1,[\"id\"]]],null]],null,{\"statements\":[[0,\"          \"],[7,\"h3\",true],[8],[1,[23,3,[\"name\"]],false],[9],[0,\"\\n\"],[4,\"each\",[[28,\"get\",[[24,[\"headingFields\"]],[23,3,[\"id\"]]],null]],null,{\"statements\":[[0,\"            \"],[5,\"dynamic-field\",[],[[\"@field\",\"@entity\"],[[23,4,[]],[22,\"school\"]]]],[0,\"\\n\"]],\"parameters\":[4]},null]],\"parameters\":[3]},null],[0,\"      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "linced-admin/templates/school-options/preview.hbs"
    }
  });
  _exports.default = _default;
});