define("linced-admin/routes/my-profile", ["exports", "linced-admin/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Extends From
  // Ember Imports
  var _default = _base.default.extend({
    currentUser: Ember.inject.service(),
    model: function model() {
      var personId = this.get('currentUser.person.id');
      return Ember.RSVP.hash({
        devices: this.store.query('device', {
          personId: personId
        })
      });
    }
  });
  _exports.default = _default;
});