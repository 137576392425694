define("linced-admin/models/assessments/category", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    parentCategoryId: (0, _model.belongsTo)('assessments/category', {
      inverse: 'subcategories'
    }),
    parent: Ember.computed.alias('parentCategoryId'),
    parent_id: Ember.computed.alias('parent.id'),
    subcategories: (0, _model.hasMany)('assessments/category', {
      inverse: 'parentCategoryId'
    }),
    subcategorySortOrder: Object.freeze(['sequence', 'label']),
    sortedSubcategories: Ember.computed.sort('subcategories', 'subcategorySortOrder'),
    sortedBoundSubcategories: Ember.computed('sortedSubcategories.[]', function () {
      var subcategories = this.sortedSubcategories.slice();
      subcategories.__parent__ = this;
      return subcategories;
    }),
    assessments: (0, _model.hasMany)('assessments/assessment'),
    assessmentSortOrder: Object.freeze(['sequence', 'label']),
    sortedAssessments: Ember.computed.sort('assessments', 'assessmentSortOrder'),
    sortedBoundAssessments: Ember.computed('sortedAssessments.[]', function () {
      var assessments = this.sortedAssessments.slice();
      assessments.__category__ = this;
      return assessments;
    }),
    updateTime: (0, _model.attr)('number'),
    createTime: (0, _model.attr)('number'),
    isPublished: (0, _model.attr)('boolean'),
    isLocked: (0, _model.attr)('boolean'),
    sequence: (0, _model.attr)('number'),
    label: (0, _model.attr)('string'),
    description: (0, _model.attr)('string')
  });
  _exports.default = _default;
});