define("linced-admin/templates/goals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6LjVe70U",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\",true],[8],[0,\"Goal Templates\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "linced-admin/templates/goals.hbs"
    }
  });
  _exports.default = _default;
});