define("linced-admin/routes/goals/index", ["exports", "linced-admin/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    model: function model() {
      return Ember.RSVP.hash({
        subjects: this.store.findAll('goals/subject'),
        usageSets: this.store.findAll('schools/usage-set'),
        schools: this.store.findAll('school'),
        schoolGroups: this.store.findAll('schools/group')
      });
    },
    actions: {
      didTransition: function didTransition() {
        this._super.apply(this, arguments);
        // Set activeSubject on the controller so that it can highlight the correct button.
        var subjectId = this.paramsFor('goals.subject').subject_id;
        this.controller.set('activeSubject', subjectId);
      },
      editSubject: function editSubject(subject) {
        this.controller.send('editSubject', subject);
      }
    }
  });
  _exports.default = _default;
});