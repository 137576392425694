define("linced-admin/templates/components/milestones-subject-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "wtlLCV86",
    "block": "{\"symbols\":[\"subject\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"layout-row layout-wrap layout-align-center-center md-padding\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"subjects\"]]],null,{\"statements\":[[0,\"    \"],[5,\"paper-button\",[],[[\"@raised\",\"@primary\",\"@onClick\"],[true,[28,\"eq\",[[24,[\"activeSubject\"]],[23,1,[]]],null],[28,\"action\",[[23,0,[]],[24,[\"onChange\"]],[23,1,[]]],null]]],{\"statements\":[[1,[23,1,[\"label\"]],false]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "linced-admin/templates/components/milestones-subject-picker.hbs"
    }
  });
  _exports.default = _default;
});