define("linced-admin/enums/log-level", ["exports", "@linc-technologies/linced-common/enums/base-enum"], function (_exports, _baseEnum) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _baseEnum.default.create({
    values: Object.freeze([{
      value: 0,
      label: 'Success'
    }, {
      value: 1,
      label: 'Debug'
    }, {
      value: 2,
      label: 'Info'
    }, {
      value: 3,
      label: 'Warning'
    }, {
      value: 4,
      label: 'Error'
    }])
  });
  _exports.default = _default;
});