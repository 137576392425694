define("linced-admin/mixins/age", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    dob: Ember.computed.alias('model.dob'),
    ageYears: Ember.computed('dob', function () {
      var dob = this.dob;
      if (Ember.isPresent(dob)) {
        return (0, _moment.default)().diff(dob, 'years');
      }
      return '';
    }),
    ageMonths: Ember.computed('dob', function () {
      var dob = this.dob;
      if (Ember.isPresent(dob)) {
        return (0, _moment.default)().diff(dob, 'months') % 12;
      }
      return '';
    })
  });
  _exports.default = _default;
});