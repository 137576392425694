define("linced-admin/models/posts/page", ["exports", "@ember-data/model", "@linc-technologies/linced-common/enums/page-access-level"], function (_exports, _model, _pageAccessLevel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Page meta
    createTime: (0, _model.attr)('number'),
    updateTime: (0, _model.attr)('number'),
    authorId: (0, _model.belongsTo)('person'),
    type: (0, _model.attr)('number'),
    isPublished: (0, _model.attr)('boolean'),
    hasAssessments: (0, _model.attr)('boolean'),
    hasPosts: (0, _model.attr)('boolean'),
    hasGoals: (0, _model.attr)('boolean'),
    hasEffort: (0, _model.attr)('boolean'),
    hasGoalsAsTags: (0, _model.attr)('boolean'),
    hasOTJAssessment: (0, _model.attr)('boolean'),
    // Page configuration
    icon: (0, _model.attr)('string'),
    label: (0, _model.attr)('string'),
    title: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    caregiverDescription: (0, _model.attr)('string'),
    learnerDescription: (0, _model.attr)('string'),
    reactionType: (0, _model.attr)('number'),
    sequence: (0, _model.attr)('number'),
    // Page visibility
    visibleInMenu: (0, _model.attr)('boolean'),
    visibleOnContent: (0, _model.attr)('boolean'),
    visibleOnStudentProfile: (0, _model.attr)('boolean'),
    visibleToParents: (0, _model.attr)('boolean'),
    visibleToLearners: (0, _model.attr)('boolean'),
    // Page authorisation / permissions
    staffCanPublish: (0, _model.attr)('boolean'),
    staffCanEdit: (0, _model.attr)('boolean'),
    caregiversCanPost: (0, _model.attr)('boolean'),
    learnersCanPost: (0, _model.attr)('boolean'),
    canComment: (0, _model.attr)('boolean'),
    // Page content filters
    assessmentIds: (0, _model.hasMany)('assessments/assessment'),
    assessments: Ember.computed.alias('assessmentIds'),
    goalSubjectIds: (0, _model.hasMany)('goals/subject'),
    goalSubjects: Ember.computed.alias('goalSubjectIds'),
    tagCategoryIds: (0, _model.hasMany)('posts/tagCategory'),
    tagCategories: Ember.computed.alias('tagCategoryIds'),
    // Post template
    postTemplate: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    // Page magic
    hasLearnerIcon: (0, _model.attr)('boolean'),
    hasAutogroup: (0, _model.attr)('boolean'),
    // Access level for page
    accessLevel: (0, _model.attr)('number', {
      defaultValue: _pageAccessLevel.default.STANDARD
    }),
    // Is this a Sickbay page
    isSickbay: (0, _model.attr)('boolean'),
    // Usage sets membership
    usageSetIds: (0, _model.hasMany)('schools/usage-set')
  });
  _exports.default = _default;
});