define("linced-admin/models/school-options/heading", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    key: (0, _model.attr)('string'),
    sequence: (0, _model.attr)('number'),
    groupId: (0, _model.belongsTo)('school-options/group')
  });
  _exports.default = _default;
});