define("linced-admin/components/new-connection", ["exports", "@linc-technologies/linced-common/utils/filtering", "@linc-technologies/linced-common/enums/data-type"], function (_exports, _filtering, _dataType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    toaster: Ember.inject.service(),
    // props
    heroSchools: Ember.A(),
    refresh: function refresh() {},
    // variables
    dialogueOpen: false,
    heroSchool: null,
    heroSchoolFilters: Ember.A(),
    isLoading: false,
    postcode: null,
    step: 'heroSchool',
    wondeSchool: null,
    wondeSchools: Ember.A(),
    wondeSchoolFilters: Ember.A(),
    filteredHeroSchools: Ember.computed('heroSchools.[]', 'heroSchoolFilters.@each.{field,value}', function () {
      if (Ember.isEmpty(this.heroSchoolFilters)) {
        // No filters - just return the data.
        return this.heroSchools;
      }

      // Determine datatypes for any specified filters.
      var filters = this.heroSchoolFilters.map(function (filter) {
        return {
          key: filter.field,
          dataType: _dataType.default.STRING,
          value: filter.value
        };
      });
      return this.heroSchools.filter(function (row) {
        return filters.every(function (filter) {
          // Generic filter processing.
          return (0, _filtering.filterValue)(Ember.getWithDefault(row, filter.key, ''), filter.dataType, filter.value);
        });
      });
    }),
    filteredWondeSchools: Ember.computed('wondeSchools.[]', 'wondeSchoolFilters.@each.{field,value}', function () {
      if (Ember.isEmpty(this.wondeSchoolFilters)) {
        // No filters - just return the data.
        return this.wondeSchools;
      }

      // Determine datatypes for any specified filters.
      var filters = this.wondeSchoolFilters.map(function (filter) {
        return {
          key: filter.field,
          dataType: _dataType.default.STRING,
          value: filter.value
        };
      });
      return this.wondeSchools.filter(function (row) {
        return filters.every(function (filter) {
          // Generic filter processing.
          return (0, _filtering.filterValue)(Ember.getWithDefault(row, filter.key, ''), filter.dataType, filter.value);
        });
      });
    }),
    actions: {
      createConnection: function createConnection() {
        var _this = this;
        this.store.createRecord('wonde/connection', {
          schoolId: this.heroSchool.id,
          wondeId: this.wondeSchool.id
        }).save().then(function () {
          _this.toaster.success('Connection created successfully');
        }).catch(function (err) {
          _this.toaster.error("Error creating connection: ".concat(err));
        }).finally(function () {
          _this.send('toggleDialogue');
          _this.refresh();
        });
      },
      filterHeroSchools: function filterHeroSchools(field, value) {
        var filter = this.heroSchoolFilters.findBy('field', field);
        if (Ember.isEmpty(filter)) {
          // No existing filter on this field; add it.
          this.heroSchoolFilters.pushObject({
            field: field,
            value: value
          });
          return;
        }
        if (Ember.isEmpty(value)) {
          // Filter has been cleared; remove it.
          this.heroSchoolFilters.removeObject(filter);
        } else {
          // Existing filter has been changed; update it.
          Ember.set(filter, 'value', value);
        }
      },
      filterWondeSchools: function filterWondeSchools(field, value) {
        var filter = this.wondeSchoolFilters.findBy('field', field);
        if (Ember.isEmpty(filter)) {
          // No existing filter on this field; add it.
          this.wondeSchoolFilters.pushObject({
            field: field,
            value: value
          });
          return;
        }
        if (Ember.isEmpty(value)) {
          // Filter has been cleared; remove it.
          this.wondeSchoolFilters.removeObject(filter);
        } else {
          // Existing filter has been changed; update it.
          Ember.set(filter, 'value', value);
        }
      },
      goBack: function goBack() {
        if (this.step === 'wondeSchool') {
          return this.setProperties({
            step: 'heroSchool',
            heroSchool: null
          });
        }
        this.setProperties({
          step: 'wondeSchool',
          wondeSchool: null
        });
      },
      selectHeroSchool: function selectHeroSchool(school) {
        this.setProperties({
          step: 'wondeSchool',
          postcode: school.physicalAddress ? school.physicalAddress.postcode || '' : '',
          heroSchool: school
        });
      },
      selectWondeSchool: function selectWondeSchool(school) {
        this.setProperties({
          step: 'confirmation',
          wondeSchool: school
        });
      },
      searchSchools: function searchSchools() {
        var _this2 = this;
        var params = {
          postcode: this.postcode
        };
        this.set('isLoading', true);
        this.store.query('wonde/school', params).then(function (res) {
          _this2.setProperties({
            isLoading: false,
            wondeSchools: res,
            postcode: ''
          });
        });
      },
      toggleDialogue: function toggleDialogue() {
        this.setProperties({
          step: 'heroSchool',
          dialogueOpen: !this.dialogueOpen,
          heroSchool: null,
          wondeSchool: null,
          wondeSchools: Ember.A()
        });
      }
    }
  });
  _exports.default = _default;
});