define("linced-admin/initializers/offline", ["exports", "linced-admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {
    var application = arguments[1] || arguments[0];

    // apply config to global Offline
    window.Offline.options = _environment.default.emberOffline;
    application.register('offline:main', window.Offline, {
      instantiate: false
    });
    application.inject('service:offline', 'offline', 'offline:main');
  }
  var _default = {
    name: 'offline',
    initialize: initialize
  };
  _exports.default = _default;
});