define("linced-admin/components/enrolment/form-section", ["exports", "@linc-technologies/linced-common/components/enrolment/form-section"], function (_exports, _formSection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _formSection.default;
    }
  });
});