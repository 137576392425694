define("linced-admin/models/bookings/teacher-group-mapping", ["exports", "ember-data-model-fragments/fragment", "@ember-data/model", "ember-data-model-fragments/attributes"], function (_exports, _fragment, _model, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _fragment.default.extend({
    store: Ember.inject.service(),
    // teacherIds is the list of teachers associated with the mapping.
    teacherIds: (0, _attributes.array)('string'),
    teachers: Ember.computed('teacherIds.[]', function () {
      var _this = this;
      if (Ember.isEmpty(this.teacherIds)) {
        return Ember.A();
      }
      return this.store.peekAll('person').filter(function (teacher) {
        return _this.teacherIds.includes(teacher.id);
      });
    }),
    // groupIds is the list of groups associated with the mapping.
    groupIds: (0, _attributes.array)('string'),
    groups: Ember.computed('groupIds.[]', function () {
      var _this2 = this;
      if (Ember.isEmpty(this.groupIds)) {
        return Ember.A();
      }
      return this.store.peekAll('group').filter(function (group) {
        return _this2.groupIds.includes(group.id);
      });
    }),
    teacherCount: Ember.computed.alias('teacherIds.length'),
    groupCount: Ember.computed.alias('groupIds.length'),
    // maxBookings is maximum number of bookings that can be made for this allocation.
    maxBookings: (0, _model.attr)('number')
  });
  _exports.default = _default;
});