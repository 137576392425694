define("linced-admin/transforms/array-string", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      return Ember.typeOf(serialized) === 'array' ? serialized : [];
    },
    serialize: function serialize(deserialized) {
      switch (Ember.typeOf(deserialized)) {
        case 'array':
          return deserialized;
        case 'string':
          return deserialized.split(',').map(function (item) {
            return item.trim();
          });
        default:
          return [];
      }
    }
  });
  _exports.default = _default;
});