define("linced-admin/controllers/milestones/set", ["exports", "@linc-technologies/linced-common/enums/progression-type"], function (_exports, _progressionType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    store: Ember.inject.service(),
    ProgressionType: _progressionType.default,
    isOTJ: Ember.computed('model.set.progressionType', function () {
      return this.model.set.progressionType === _progressionType.default.OTJ;
    }),
    otjPages: Ember.computed.filterBy('model.pages', 'hasOTJAssessment', true),
    selectedTab: 'expectations',
    milestoneTypes: Ember.A([{
      value: 0,
      name: 'Unspecified'
    }, {
      value: 1,
      name: 'Weeks at School'
    }, {
      value: 2,
      name: 'Months at School'
    }, {
      value: 3,
      name: 'Year Level'
    }]),
    milestoneDialogOpen: false,
    subjectCategories: Ember.computed('activeSubject.id', 'model.goalCategories.[]', function () {
      var subjectId = this.get('activeSubject.id');
      if (Ember.isEmpty(subjectId)) {
        return Ember.A();
      } else {
        return this.get('model.goalCategories').filterBy('subject.id', subjectId).sortBy('sequence', 'label');
      }
    }),
    subjectLevels: Ember.computed('isOTJ', 'activeSubject.id', 'model.levels.[]', function () {
      var subjectId = this.get('activeSubject.id');
      if (Ember.isEmpty(subjectId)) {
        return Ember.A();
      } else {
        var filter = 'goalSubjectTemplate.id';
        if (this.isOTJ) {
          filter = 'pageTemplate.id';
        }
        return this.get('model.levels').filterBy(filter, subjectId).sortBy('sequence', 'label');
      }
    }),
    subjectExpectations: Ember.computed('isOTJ', 'activeSubject.id', 'model.expectations.[]', function () {
      var filter = 'goalSubjectTemplate.id';
      if (this.isOTJ) {
        filter = 'pageTemplate.id';
      }
      return this.get('model.expectations').filterBy(filter, this.get('activeSubject.id'));
    }),
    unsetMarkers: Ember.A([{
      label: 'below'
    }, {
      label: 'at'
    }, {
      label: 'above'
    }, {
      label: 'wellAbove'
    }]),
    markers: Ember.computed('activeSubject.id', 'model.{levels.[],milestones.[],expectations.[]}', function () {
      var milestones = this.get('model.milestones');
      var markers = Ember.A();
      var unsetMarkers = this.unsetMarkers;
      var numLevels = this.get('subjectLevels.length');
      var subjectExpectations = this.subjectExpectations;
      milestones.forEach(function (milestone) {
        var milestoneMarkers = Ember.A();
        milestoneMarkers.__milestoneId__ = milestone.id;
        for (var i = -1; i < numLevels; i++) {
          var markerList = Ember.A();
          markerList.__pos__ = i;
          markerList.__milestoneId__ = milestone.id;
          milestoneMarkers.pushObject(markerList);
        }
        var expectation = subjectExpectations.findBy('milestoneTemplate.id', milestone.id);
        if (!Ember.isEmpty(expectation)) {
          unsetMarkers.forEach(function (marker) {
            var index = expectation.get("".concat(marker.label, "Index"));
            milestoneMarkers.findBy('__pos__', index).pushObject(marker);
          });
        } else {
          var _markerList = milestoneMarkers.get('firstObject');
          unsetMarkers.forEach(function (marker) {
            return _markerList.pushObject(marker);
          });
        }
        markers.pushObject(milestoneMarkers);
      });
      return markers;
    }),
    levelDialogOpen: false,
    actions: {
      addMilestone: function addMilestone() {
        this.resetMilestoneForm();
        this.set('milestoneDialogOpen', true);
      },
      editMilestone: function editMilestone(milestone) {
        this.set('milestoneId', milestone.get('id'));
        this.set('milestoneLabel', milestone.get('label'));
        this.set('milestoneType', this.milestoneTypes.findBy('value', milestone.get('type')));
        this.set('milestoneDuration', milestone.get('duration'));
        this.set('milestoneYearLevel', milestone.get('yearLevel'));
        this.set('milestoneMonth', milestone.get('month'));
        this.set('milestoneDay', milestone.get('day'));
        this.set('milestoneIsInterim', milestone.get('isInterim'));
        this.set('milestoneDialogOpen', true);
      },
      closeMilestoneDialog: function closeMilestoneDialog() {
        this.set('milestoneDialogOpen', false);
      },
      saveMilestone: function saveMilestone() {
        var _this = this;
        this.set('saveInProgress', true);
        this.set('errorMessage', '');
        var promise;
        if (Ember.isEmpty(this.milestoneId)) {
          promise = new Ember.RSVP.Promise(function (resolve) {
            var record = _this.store.createRecord('milestones/milestone', {
              label: _this.milestoneLabel,
              sequence: _this.get('model.milestones.length') + 1,
              type: _this.get('milestoneType.value'),
              duration: _this.milestoneDuration,
              yearLevel: _this.milestoneYearLevel,
              month: _this.milestoneMonth,
              day: _this.milestoneDay,
              templateSetId: _this.get('model.set'),
              isInterim: _this.milestoneIsInterim,
              progressionType: _this.model.set.progressionType
            });
            resolve(record);
          });
        } else {
          promise = new Ember.RSVP.Promise(function (resolve) {
            var id = _this.milestoneId;
            _this.store.findRecord('milestones/milestone', id).then(function (milestone) {
              milestone.set('label', _this.milestoneLabel);
              milestone.set('type', _this.get('milestoneType.value'));
              milestone.set('duration', _this.milestoneDuration);
              milestone.set('yearLevel', _this.milestoneYearLevel);
              milestone.set('month', _this.milestoneMonth);
              milestone.set('day', _this.milestoneDay);
              milestone.set('isInterim', _this.milestoneIsInterim);
              resolve(milestone);
            });
          });
        }
        promise.then(function (record) {
          var toaster = _this.paperToaster;
          return record.save().then(function () {
            _this.set('milestoneDialogOpen', false);
            _this.resetMilestoneForm();
            toaster.show('Milestone saved successfully.', {
              duration: 4000,
              position: 'top right',
              toastClass: 'success'
            });
            _this.get('model.milestones').update();
          }).catch(function () {
            record.rollbackAttributes();
            toaster.show('Error saving Milestone.', {
              duration: 4000,
              position: 'top right',
              toastClass: 'warning'
            });
          }).then(function () {
            _this.set('saveInProgress', false);
          });
        });
      },
      deleteMilestone: function deleteMilestone(milestone) {
        this.set('errorMessage', '');
        this.set('deleteLabel', milestone.get('label'));
        this.set('milestoneId', milestone.id);
        this.set('deleteType', 'Milestone');
        this.set('deleteDialogOpen', true);
      },
      addLevel: function addLevel() {
        this.resetLevelForm();
        this.set('levelDialogOpen', true);
      },
      editLevel: function editLevel(level) {
        this.set('levelId', level.get('id'));
        this.set('levelLabel', level.get('label'));
        this.set('levelBriefLabel', level.get('briefLabel'));
        this.set('levelGoalCategoryTemplate', level.get('goalCategoryTemplate'));
        this.set('levelDialogOpen', true);
      },
      closeLevelDialog: function closeLevelDialog() {
        this.set('levelDialogOpen', false);
      },
      saveLevel: function saveLevel() {
        var _this2 = this;
        this.set('levelSaveInProgress', true);
        this.set('errorMessage', '');
        var promise;
        if (Ember.isEmpty(this.levelId)) {
          promise = new Ember.RSVP.Promise(function (resolve) {
            var record = _this2.store.createRecord('milestones/level', {
              label: _this2.levelLabel,
              briefLabel: _this2.levelBriefLabel,
              sequence: _this2.get('subjectLevels.length') + 1,
              templateSetId: _this2.get('model.set'),
              progressionType: _this2.model.set.progressionType
            });
            if (_this2.isOTJ) {
              record.set('pageTemplateId', _this2.activeSubject);
            } else {
              record.set('goalSubjectTemplateId', _this2.activeSubject);
              record.set('goalCategoryTemplateId', _this2.levelGoalCategoryTemplate);
            }
            resolve(record);
          });
        } else {
          promise = new Ember.RSVP.Promise(function (resolve) {
            var id = _this2.levelId;
            _this2.store.findRecord('milestones/level', id).then(function (level) {
              level.set('label', _this2.levelLabel);
              level.set('briefLabel', _this2.levelBriefLabel);
              if (!_this2.isOTJ) {
                level.set('goalCategoryTemplateId', _this2.levelGoalCategoryTemplate);
              }
              resolve(level);
            });
          });
        }
        promise.then(function (record) {
          var toaster = _this2.paperToaster;
          return record.save().then(function () {
            _this2.set('levelDialogOpen', false);
            _this2.resetLevelForm();
            toaster.show('Level saved successfully.', {
              duration: 4000,
              position: 'top right',
              toastClass: 'success'
            });
            _this2.get('model.levels').update();
          }).catch(function () {
            record.rollbackAttributes();
            toaster.show('Error saving Level.', {
              duration: 4000,
              position: 'top right',
              toastClass: 'warning'
            });
          }).then(function () {
            _this2.set('levelSaveInProgress', false);
          });
        });
      },
      deleteLevel: function deleteLevel(level) {
        this.set('errorMessage', '');
        this.set('deleteLabel', level.get('label'));
        this.set('levelId', level.id);
        this.set('deleteType', 'Level');
        this.set('deleteDialogOpen', true);
      },
      // Generic delete handler for all entity types
      confirmDelete: function confirmDelete(confirmed) {
        var _this3 = this;
        var toaster = this.paperToaster;
        var type = this.deleteType;
        var typeLower = type.toLowerCase();
        return new Ember.RSVP.Promise(function (resolve, reject) {
          if (confirmed) {
            // Deletion confirmed; actually delete the record.
            _this3.set('deleteInProgress', true);

            // Grab record from local store - no GET request, as that causes weirdness.
            // We only need the ID to issue the DELETE anyway, which can't change, so no need to use findRecord.
            var entity = _this3.store.peekRecord("milestones/".concat(typeLower), _this3.get("".concat(typeLower, "Id")));

            // Delete record (locally + pushed to server).
            return entity.destroyRecord().then(resolve).catch(function (err) {
              // Remove 'deleted' flag so that entity can be interacted with again.
              entity.rollbackAttributes();
              reject(err);
            });
          } else {
            resolve();
          }
        }).then(function () {
          // Tidy up / close dialog.
          _this3.set('deleteDialogOpen', false);
          _this3["reset".concat(type, "Form")]();
          if (confirmed) {
            toaster.show("".concat(type, " deleted successfully."), {
              duration: 4000,
              position: 'top right',
              toastClass: 'success'
            });
          }
        }).catch(function () {
          toaster.show("Error deleting ".concat(type, "."), {
            duration: 4000,
            position: 'top right',
            toastClass: 'warning'
          });
        }).then(function () {
          _this3.set('deleteInProgress', false);
        });
      },
      // drag-drop sorting
      sortDragEnd: function sortDragEnd(_ref) {
        var sourceList = _ref.sourceList,
          sourceIndex = _ref.sourceIndex,
          targetList = _ref.targetList,
          targetIndex = _ref.targetIndex;
        if (sourceList === targetList && sourceIndex === targetIndex) {
          return;
        }
        var item = sourceList.objectAt(sourceIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, item);
        targetList.forEach(function (entity, index) {
          entity.set('sequence', index + 1);
          entity.save();
        });
      },
      // drag-drop sorting for expectations
      setExpectation: function setExpectation(_ref2) {
        var _this4 = this;
        var sourceList = _ref2.sourceList,
          sourceIndex = _ref2.sourceIndex,
          targetList = _ref2.targetList,
          targetIndex = _ref2.targetIndex;
        if (sourceList === targetList && sourceIndex === targetIndex) {
          return;
        }
        var item = sourceList.objectAt(sourceIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, item);
        var expectation = this.subjectExpectations.findBy('milestoneTemplate.id', targetList.__milestoneId__);
        if (Ember.isEmpty(expectation)) {
          expectation = this.store.createRecord('milestones/expectation', {
            milestoneTemplateId: this.get('model.milestones').findBy('id', targetList.__milestoneId__),
            templateSetId: this.get('model.set'),
            progressionType: this.model.set.progressionType,
            // Set all indices to -1 - we'll adjust them (where applicable) before saving.
            belowIndex: -1,
            atIndex: -1,
            aboveIndex: -1,
            wellAboveIndex: -1
          });
          if (this.isOTJ) {
            expectation.set('pageTemplateId', this.activeSubject);
          } else {
            expectation.set('goalSubjectTemplateId', this.activeSubject);
          }
        }

        // Set new expectation level for dragged handle
        var newPos = targetList.__pos__;
        expectation.set("".concat(item.label, "Index"), newPos);

        // If any higher levels are now below this one, bring them up
        var targets = this.unsetMarkers;
        var target = targets.indexOf(item);
        for (var i = 0; i < target; i++) {
          var key = "".concat(targets[i].label, "Index");
          if (expectation.get(key) > newPos) {
            expectation.set(key, newPos);
          }
        }
        for (var _i = target + 1; _i < targets.get('length'); _i++) {
          var _key = "".concat(targets[_i].label, "Index");
          if (expectation.get(_key) < newPos) {
            expectation.set(_key, newPos);
          }
        }

        // Save the updated expectation record
        expectation.save().then(function () {
          _this4.get('model.expectations').update();
        });
      },
      calculateLevelClass: function calculateLevelClass(index, expectation) {
        if (Ember.isEmpty(expectation)) {
          return;
        }
        if (index <= expectation.get('belowIndex')) {
          return 'well-below';
        } else if (index <= expectation.get('atIndex')) {
          return 'below';
        } else if (index <= expectation.get('aboveIndex')) {
          return 'at';
        } else if (index <= expectation.get('wellAboveIndex')) {
          return 'above';
        } else {
          return 'well-above';
        }
      }
    },
    resetMilestoneForm: function resetMilestoneForm() {
      this.set('milestoneId', null);
      this.set('milestoneLabel', '');
      this.set('milestoneType', null);
      this.set('milestoneDuration', 0);
      this.set('milestoneYearLevel', 0);
      this.set('milestoneMonth', 0);
      this.set('milestoneDay', 0);
      this.set('milestoneIsInterim', false);
    },
    resetLevelForm: function resetLevelForm() {
      this.set('levelId', null);
      this.set('levelLabel', '');
      this.set('levelBriefLabel', '');
      this.set('levelGoalCategoryTemplate', null);
    }
  });
  _exports.default = _default;
});