define("linced-admin/models/goals/badge-verification", ["exports", "ember-data-model-fragments", "@ember-data/model"], function (_exports, _emberDataModelFragments, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberDataModelFragments.default.Fragment.extend({
    // The type of verification method.
    // Supported values for single assertion verification are HostedBadge and SignedBadge
    // (aliases in context are available: hosted and signed).
    // "hosted"
    type: (0, _model.attr)('string')
  });
  _exports.default = _default;
});