define("linced-admin/models/enrolments/credential", ["exports", "@ember-data/model", "ember-data-model-fragments/attributes", "@linc-technologies/linced-common/enums/jurisdiction", "linced-admin/enums/enrolment-nz-service"], function (_exports, _model, _attributes, _jurisdiction, _enrolmentNzService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Standard Library Imports
  // External Imports
  var _default = _model.default.extend({
    // createTime is when the resource was created in seconds from the epoch.
    createTime: (0, _model.attr)('number'),
    // updateTime is the last time the resource was modified in seconds from
    // the epoch.
    updateTime: (0, _model.attr)('number'),
    // schoolId is the unique identifier of the school.
    schoolId: (0, _model.belongsTo)('school'),
    school: Ember.computed.alias('schoolId'),
    // campusId is the unique identifier of the School's Campus.
    campusId: (0, _model.attr)('string'),
    // enabled provides a switch to disable the use of the stored credentials.
    enabled: (0, _model.attr)('boolean'),
    // jurisdiction maps the credential to a jurisdictional educational
    // authority.
    jurisdiction: (0, _model.attr)('number'),
    // nzMoe provides the container to authenticate and authorize to New Zealand
    // jurisdictional services.
    nzMoe: (0, _attributes.fragment)('enrolments/credential-nz'),
    /**
     * setIntegrationPassword configures a jurisidictional credential for a
     * school.
     *
     * @param {integer} type - the type of password being set.
     * @param {string}  password - The password to be set.
     * @param {integer} [jurisdiction] - The school's jurisdiction.
     */
    setIntegrationPassword: function setIntegrationPassword(type, password, jurisdiction) {
      if (Ember.isEmpty(jurisdiction)) {
        jurisdiction = this.jurisdiction;
      }
      switch (jurisdiction) {
        case _jurisdiction.default.NZ_MOE:
          this.setNzMoePassword(type, password);
          break;
      }
    },
    /**
     * setNzMoePassword configures integration passwords for New Zealand
     * jurisdiction.
     *
     * @param {integer} type - the type of password being set.
     * @param {string}  password - The password to be set.
     */
    setNzMoePassword: function setNzMoePassword(type, password) {
      var nzMoe = {
        enrolId: this.get('school.jurisdictionalId'),
        password: password,
        service: 0
      };
      switch (type) {
        case _enrolmentNzService.default.DEX:
          nzMoe.service = _enrolmentNzService.default.DEX;
          this.set('nzMoe', nzMoe);
      }
    }
  });
  _exports.default = _default;
});