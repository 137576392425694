define("linced-admin/routes/schools", ["exports", "linced-admin/routes/base", "@linc-technologies/linced-common/enums/country-code", "ember-get-config"], function (_exports, _base, _countryCode, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  var _default = _base.default.extend({
    model: function model() {
      var store = this.store;
      return Ember.RSVP.hash({
        schools: store.findAll('school'),
        credentials: store.findAll('enrolments/credential'),
        optionSets: store.findAll('school-options/set'),
        peopleSchemaTemplates: store.findAll('people-schema/set'),
        // We only want the published page templates
        pageTemplates: store.query('posts/page', {
          isPublished: true
        }),
        // We only want the published goal subject templates
        goalSubjects: store.query('goals/subject', {
          isPublished: true
        }),
        milestoneSets: store.query('milestones/set', {
          isPublished: true
        }),
        autogroups: store.findAll('group'),
        enrolmentSchemaTemplates: store.findAll('enrolments/schema'),
        groups: store.findAll('schools/group'),
        sifZones: _emberGetConfig.default.APP.sifEnabled ? Ember.RSVP.hash(_defineProperty({}, _countryCode.default.AU, store.findAll('sif/au/zone'))) : [],
        usageSets: store.findAll('schools/usage-set')
      });
    }
  });
  _exports.default = _default;
});