define("linced-admin/components/grade-schedule-picker", ["exports", "@linc-technologies/linced-common/helpers/enum-option", "@linc-technologies/linced-common/enums/grading-method"], function (_exports, _enumOption, _gradingMethod) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['flex', 'layout-row'],
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    GradingMethod: _gradingMethod.default,
    dialogOpen: false,
    editingSchedule: null,
    label: '',
    description: '',
    isShared: true,
    awardMethod: null,
    // awardMethod = GradeMethod.NUMERIC
    gradeFrom: 0,
    gradeTo: 0,
    gradeStep: 0,
    // awardMethod = GradeMethod.SELECT || GradeMethod.MULTIPLE_SELECT
    selectOptions: Ember.A([{}]),
    saveInProgress: false,
    errorMessage: '',
    // TODO: Don't use observers if possible.
    // eslint-disable-next-line
    _startEditing: Ember.observer('dialogOpen', function () {
      var schedule = this.editingSchedule;
      if (this.dialogOpen && schedule) {
        // Dialog has been triggered with a schedule to edit; reset and populate the form.
        var awardMethod = schedule.get('awardMethod');
        this.resetForm();
        this.set('editingSchedule', schedule);
        this.set('label', schedule.get('label'));
        this.set('description', schedule.get('description'));
        this.set('isShared', schedule.get('isShared'));
        this.set('awardMethod', (0, _enumOption.enumOption)([_gradingMethod.default, awardMethod]));
        switch (awardMethod) {
          case _gradingMethod.default.NUMERIC:
            this.set('gradeFrom', schedule.get('numeric.range.start'));
            this.set('gradeTo', schedule.get('numeric.range.end'));
            this.set('gradeStep', schedule.get('numeric.defaultNumericStepping'));
            break;
          case _gradingMethod.default.SELECT:
          case _gradingMethod.default.MULTIPLE_SELECT:
          case _gradingMethod.default.ICON_SELECT:
            this.set('selectOptions', schedule.get('select.values'));
            break;
        }
      }
    }),
    onChange: function onChange() {},
    actions: {
      openDialog: function openDialog() {
        this.resetForm();
        this.set('dialogOpen', true);
      },
      closeDialog: function closeDialog() {
        this.set('dialogOpen', false);
      },
      save: function save() {
        var _this = this;
        var schedule = this.editingSchedule;
        var store = this.store;
        var isNew = false;
        this.set('saveInProgress', true);

        // Create a new Grade Schedule if needed
        if (Ember.isEmpty(schedule)) {
          isNew = true;
          schedule = store.createRecord('assessments/grade-schedule', {});
        }

        // Set properties from form
        var data = this.getProperties('label', 'description', 'isShared');
        var awardMethod = this.get('awardMethod.value');
        switch (awardMethod) {
          case _gradingMethod.default.NUMERIC:
            Ember.assign(data, {
              awardMethod: awardMethod,
              numeric: {
                range: {
                  start: this.gradeFrom,
                  end: this.gradeTo
                },
                defaultNumericStepping: this.gradeStep
              }
            });
            break;
          case _gradingMethod.default.SELECT:
          case _gradingMethod.default.MULTIPLE_SELECT:
          case _gradingMethod.default.ICON_SELECT:
            Ember.assign(data, {
              awardMethod: awardMethod,
              select: {
                values: this.selectOptions
              }
            });
            break;
        }
        schedule.setProperties(data);
        schedule.save().then(function () {
          _this.set('dialogOpen', false);
          if (isNew && _this.onChange) {
            // Select the newly-created schedule.
            _this.onChange(schedule);
          }
        }).catch(function (err) {
          _this.set('errorMessage', err);
        }).then(function () {
          _this.set('saveInProgress', false);
        });
      },
      moveOption: function moveOption(_ref) {
        var sourceList = _ref.sourceList,
          sourceIndex = _ref.sourceIndex,
          targetList = _ref.targetList,
          targetIndex = _ref.targetIndex;
        if (sourceList === targetList && sourceIndex === targetIndex) {
          return;
        }
        var draggedItem = sourceList.objectAt(sourceIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, draggedItem);
      },
      addOption: function addOption() {
        this.selectOptions.pushObject({});
      },
      removeOption: function removeOption(option) {
        this.selectOptions.removeObject(option);
      }
    },
    resetForm: function resetForm() {
      this.set('errorMessage', '');
      this.set('label', '');
      this.set('description', '');
      this.set('isShared', true);
      this.set('editingSchedule', null);
      this.set('awardMethod', (0, _enumOption.enumOption)([_gradingMethod.default, _gradingMethod.default.NUMERIC]));
      this.set('gradeFrom', 0);
      this.set('gradeTo', 100);
      this.set('gradeStep', 1);
      this.set('selectOptions', Ember.A([{}]));
    }
  });
  _exports.default = _default;
});