define("linced-admin/models/post", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    author: (0, _model.attr)('string'),
    students: (0, _model.attr)(),
    schoolId: (0, _model.attr)('string'),
    postType: (0, _model.attr)('string'),
    body: (0, _model.attr)('string'),
    attachments: (0, _model.attr)(),
    dateCreated: (0, _model.attr)(),
    dateUpdated: (0, _model.attr)()
  });
  _exports.default = _default;
});