define("linced-admin/routes/goals/plp", ["exports", "linced-admin/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    model: function model() {
      var store = this.store;
      return Ember.RSVP.hash({
        categories: store.query('goals/category', {
          isPLP: true
        }),
        subcategories: store.query('goals/subcategory', {
          isPLP: true
        }),
        schools: store.findAll('school')
      });
    }
  });
  _exports.default = _default;
});