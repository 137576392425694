define("linced-admin/routes/logs/enrolments", ["exports", "linced-admin/routes/base", "moment"], function (_exports, _base, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    queryParams: {
      jurisdiction: {
        refreshModel: true
      },
      resource: {
        refreshModel: true
      },
      schoolId: {
        refreshModel: true
      },
      duration: {
        refreshModel: true
      }
    },
    model: function model(params) {
      // Swap duration out for a startTime timestamp for the request.
      var duration = params.duration || 15;
      params.startTime = (0, _moment.default)().subtract(duration, 'minutes').unix();
      delete params.duration;
      var model = {
        schools: this.store.findAll('school'),
        logs: this.store.query('enrolments/log', params)
      };
      return Ember.RSVP.hash(model);
    },
    afterModel: function afterModel(model) {
      // TODO: grab the people referenced in the logs.
      var promises = {
        logs: model.logs,
        schools: model.schools
      };

      // pry out and returned linked resources.
      return Ember.RSVP.hash(promises);
    }
  });
  _exports.default = _default;
});