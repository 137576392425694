define("linced-admin/controllers/tags/category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    store: Ember.inject.service(),
    tagId: '',
    label: '',
    errorMessage: '',
    tagDialogOpen: false,
    isSortable: false,
    // toggleDialog opens the tag dialog.
    toggleDialog: function toggleDialog() {
      this.set('errorMessage', '');
      this.toggleProperty('tagDialogOpen');
      if (!this.tagDialogOpen) {
        this.resetForm();
      }
    },
    actions: {
      toggleTagDialog: function toggleTagDialog() {
        this.toggleDialog();
      },
      editTag: function editTag(id) {
        var _this = this;
        this.store.findRecord('posts/tag', id).then(function (tag) {
          _this.set('label', tag.get('label'));
        });
        this.set('tagId', id);
        this.toggleDialog();
      },
      saveTag: function saveTag(createAnother) {
        var _this2 = this;
        this.set('saveInProgress', true);
        this.set('errorMessage', '');
        var promise;
        var action = 'create';
        if (Ember.isEmpty(this.tagId)) {
          promise = new Ember.RSVP.Promise(function (resolve) {
            var record = _this2.store.createRecord('posts/tag', {
              label: _this2.label,
              categoryId: _this2.get('model.category'),
              schoolId: _this2.get('currentUser.school'),
              sequence: _this2.get('model.tags.length') + 1
            });
            resolve(record);
          });
        } else {
          action = 'update';
          promise = new Ember.RSVP.Promise(function (resolve) {
            var id = _this2.tagId;
            _this2.store.findRecord('posts/tag', id).then(function (tag) {
              tag.set('label', _this2.label);
              resolve(tag);
            });
          });
        }
        promise.then(function (record) {
          return record.save().then(function () {
            if (action === 'create') {
              // Send an event to trigger the route to refresh the model
              _this2.send('tagCreated');
            }
            _this2.paperToaster.show("Tag saved successfully.", {
              duration: 4000,
              position: 'top right',
              toastClass: 'success'
            });
            _this2.resetForm();
            if (createAnother) {
              document.querySelector('.new-tag-dialog .new-tag-label > input').focus();
            } else {
              _this2.set('tagDialogOpen', false);
            }
          }).catch(function (err) {
            _this2.handleError(err, action);
            record.rollbackAttributes();
          }).then(function () {
            _this2.set('saveInProgress', false);
          });
        });
      },
      deleteTag: function deleteTag(id) {
        var _this3 = this;
        this.set('errorMessage', '');
        this.store.findRecord('posts/tag', id).then(function (tag) {
          _this3.set('label', tag.get('label'));
        });
        this.set('tagId', id);
        this.set('deleteTagDialogOpen', true);
      },
      confirmDelete: function confirmDelete(confirmed) {
        var _this4 = this;
        return new Ember.RSVP.Promise(function (resolve, reject) {
          if (confirmed) {
            // Deletion confirmed; actually delete the record.
            _this4.set('deleteInProgress', true);

            // Grab record from local store - no GET request, as that causes weirdness.
            // We only need the ID to issue the DELETE anyway, which can't change, so no need to use findRecord.
            var field = _this4.store.peekRecord('posts/tag', _this4.tagId);

            // Delete record (locally + pushed to server).
            return field.destroyRecord().then(resolve).catch(function (err) {
              // Remove 'deleted' flag so that entity can be interacted with again.
              field.rollbackAttributes();
              reject(err);
            });
          } else {
            resolve();
          }
        }).then(function () {
          // Tidy up / close dialog.
          _this4.set('deleteTagDialogOpen', false);
          _this4.resetForm();
        }).catch(function (err) {
          _this4.handleError(err, 'delete');
        }).then(function () {
          _this4.set('deleteInProgress', false);
        });
      },
      // drag-drop sorting
      sortDragEnd: function sortDragEnd(_ref) {
        var sourceList = _ref.sourceList,
          sourceIndex = _ref.sourceIndex,
          targetList = _ref.targetList,
          targetIndex = _ref.targetIndex;
        if (sourceList === targetList && sourceIndex === targetIndex) {
          return;
        }
        var item = sourceList.objectAt(sourceIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, item);
        targetList.forEach(function (entity, index) {
          entity.set('sequence', index + 1);
          entity.save();
        });
      }
    },
    handleError: function handleError(err) {
      if (!Ember.isEmpty(err.errors.msg)) {
        this.set('errorMessage', err.errors.msg);
      } else {
        this.set('errorMessage', err.message);
      }
    },
    resetForm: function resetForm() {
      this.set('tagId', '');
      this.set('label', '');
    }
  });
  _exports.default = _default;
});