define("linced-admin/models/dynamic-field/value", ["exports", "@ember-data/model", "ember-data-model-fragments", "@linc-technologies/linced-common/enums/data-type", "ember-data-model-fragments/attributes"], function (_exports, _model, _emberDataModelFragments, _dataType, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function getValueKey(dataType) {
    var key = '';
    switch (dataType) {
      case _dataType.default.STRING:
      case _dataType.default.HTML:
        key = 'valueString';
        break;
      case _dataType.default.LIST:
        key = 'valueList';
        break;
      case _dataType.default.INTEGER:
        key = 'valueInt';
        break;
      case _dataType.default.FLOAT:
        key = 'valueFloat';
        break;
      case _dataType.default.BOOLEAN:
        key = 'valueBool';
        break;
      case _dataType.default.VALUE_LIST:
        key = 'valueValueList';
    }
    return key;
  }
  var _default = _emberDataModelFragments.default.Fragment.extend({
    key: (0, _model.attr)('string'),
    datatype: (0, _model.attr)('number'),
    value: Ember.computed('datatype', 'valueString', 'valueList', 'valueInt', 'valueFloat', 'valueBool', 'valueValueList', {
      get: function get() {
        var key = getValueKey(this.datatype);
        if (key.length) {
          return this.get(key);
        }
        return null;
      },
      set: function set(_, value) {
        var key = getValueKey(this.datatype);
        if (key.length) {
          this.set(key, value);
          return value;
        }
        return null;
      }
    }),
    valueString: (0, _model.attr)('string'),
    valueList: (0, _attributes.array)('string'),
    valueInt: (0, _model.attr)('number'),
    valueFloat: (0, _model.attr)('number'),
    valueBool: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    valueValueList: (0, _attributes.fragmentArray)('people-schema/field-option'),
    // requiredRole specifies the Field Access role required to view this field,
    // if applicable.  If empty, anyone with access to the entity can see it.
    requiredRole: (0, _model.attr)('string'),
    // isReadOnly specifies whether this field is read-only.
    isReadOnly: (0, _model.attr)('boolean', {
      defaultValue: false
    })
  });
  _exports.default = _default;
});