define("linced-admin/serializers/auth/base", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _rest.default.extend({
    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      return this._super(modelName).replace(/^auth\//, '');
    },
    modelNameFromPayloadKey: function modelNameFromPayloadKey(key) {
      return this._super('auth/' + key);
    }
  });
  _exports.default = _default;
});