define("linced-admin/controllers/finance/nsw-sap/transaction-upload", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    toaster: Ember.inject.service(),
    selectedSchool: null,
    transactionId: null,
    forceSend: false,
    sendInProgress: false,
    actions: {
      send: function send() {
        var _this = this;
        this.set('sendInProgress', true);
        var options = {
          data: JSON.stringify({
            schoolId: this.selectedSchool.id,
            transactionId: parseInt(this.transactionId, 10),
            forceSend: this.forceSend
          })
        };
        var url = "".concat(_emberGetConfig.default.APP.apiHost, "/finance/").concat(_emberGetConfig.default.APP.apiPath, "/nsw/sap/:sendtransaction");
        return this.ajax.post(url, options).then(function () {
          _this.reset();
          _this.toaster.success('Transaction sent to SAP.');
        }).catch(function (resp) {
          if (Ember.isEmpty(resp) || Ember.isEmpty(resp.message)) {
            _this.toaster.error('Failed to send transaction to SAP.');
          } else {
            _this.toaster.error("Failed to send transaction to SAP: ".concat(resp.message));
          }
        }).finally(function () {
          _this.set('sendInProgress', false);
        });
      }
    },
    reset: function reset() {
      this.setProperties({
        selectedSchool: null,
        transactionId: null,
        forceSend: false,
        sendInProgress: false
      });
    }
  });
  _exports.default = _default;
});