define("linced-admin/components/dynamic-field-default", ["exports", "linced-admin/components/dynamic-field"], function (_exports, _dynamicField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _dynamicField.default.extend({
    layoutName: 'components/dynamic-field',
    store: Ember.inject.service(),
    // Override the field's `isRequired` property so that the default is never required.
    fieldIsRequired: false,
    // TODO: Don't use observers if possible.
    // eslint-disable-next-line
    dataTypeChanged: Ember.observer('field.dataType', function () {
      this.set('isInitialised', false);
      this.getEntityField();
      this.hasFieldMeta();
    }),
    getEntityField: function getEntityField() {
      var field = this.get('field.default');
      var dataType = this.get('field.dataType');
      if (Ember.isEmpty(field)) {
        // Create a new fieldValue if we don't have one yet.
        field = this.createEntityField();
      } else if (field.datatype !== dataType) {
        // If the datatype has changed, update the existing default value's datatype.
        this.get('field.default').set('datatype', dataType);
      }
      return field;
    },
    createEntityField: function createEntityField() {
      var field = {
        datatype: this.get('field.dataType')
      };
      this.field.set('default', this.store.createFragment('dynamic-field/value', field));
      return this.get('field.default');
    },
    hasFieldMeta: function hasFieldMeta() {
      this.initValue();
    }
  });
  _exports.default = _default;
});