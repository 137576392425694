define("linced-admin/adapters/assessments/category", ["exports", "linced-admin/adapters/assessments/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    modelNamePlural: 'categories'
  });
  _exports.default = _default;
});