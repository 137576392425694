define("linced-admin/services/roles", ["exports", "@linc-technologies/linced-common/enums/roles/linc-staff-access"], function (_exports, _lincStaffAccess) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // External imports
  var _default = Ember.Service.extend({
    currentUser: Ember.inject.service(),
    // has determines whether the current user has the specified role.
    has: function has(role) {
      if (Ember.isEmpty(this.currentUser.person.roles)) {
        return false;
      }
      return this.currentUser.person.roles.includes(role);
    },
    // Admin site roles to access certain sections of the menu
    hasAPIActivationAccess: Ember.computed('currentUser.person.roles.[]', function () {
      return this.has(_lincStaffAccess.default.API_ACTIVATION);
    }),
    // hasFullRole allows the person to access all sections of the menu
    hasFullAccessRole: Ember.computed('currentUser.person.roles.[]', function () {
      return this.has(_lincStaffAccess.default.FULL_ACCESS);
    }),
    // hasManageRole allows the person to access the Hero Management section of the menu
    hasManageRole: Ember.computed('currentUser.person.roles.[]', function () {
      return this.has(_lincStaffAccess.default.HERO_MANAGEMENT);
    }),
    // hasCommunicationRole allows the person to access the Hero Communication section of the menu
    hasCommunicationRole: Ember.computed('currentUser.person.roles.[]', function () {
      return this.has(_lincStaffAccess.default.HERO_COMMUNICATION);
    }),
    // hasSHManageRole allows the person to access the SuperHero Management section of the menu
    hasSHManageRole: Ember.computed('currentUser.person.roles.[]', function () {
      return this.has(_lincStaffAccess.default.SUPERHERO_MANAGEMENT);
    })
  });
  _exports.default = _default;
});