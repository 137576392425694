define("linced-admin/controllers/wonde/connections", ["exports", "@linc-technologies/linced-common/enums/connection-status", "@linc-technologies/linced-common/enums/wonde-sis-type"], function (_exports, _connectionStatus, _wondeSisType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // External Imports
  var _default = Ember.Controller.extend({
    // Services
    toaster: Ember.inject.service(),
    // variables
    selectedStatus: 0,
    searchQuery: '',
    connectionDialogueOpen: false,
    //attrs
    ConnectionStatus: _connectionStatus.default,
    WondeSisType: _wondeSisType.default,
    // computed properties
    connectionSchoolIds: Ember.computed.mapBy('model.connections', 'schoolId'),
    unlinkedSchools: Ember.computed.filter('model.schools.@each{id}', function (school) {
      return !this.connectionSchoolIds.includes(school.id);
    }),
    wondeSchools: Ember.computed('model.schools.[]', 'model.connections.@each{schoolId,data}', function () {
      var connectionMap = new Map(this.model.connections.map(function (connection) {
        return [connection.schoolId, connection];
      }));
      return this.model.schools.map(function (school) {
        var match = connectionMap.get(school.id);
        if (match) {
          return {
            connection: match,
            school: school
          };
        }
      }).filter(function (item) {
        return !!item;
      });
    }),
    displaySchools: Ember.computed('wondeSchools.[]', 'selectedStatus', 'searchQuery', function () {
      var _this = this;
      var filteredSchools = this.wondeSchools;
      if (this.selectedStatus !== _connectionStatus.default.UNSPECIFIED) {
        filteredSchools = this.wondeSchools.filter(function (school) {
          return school.connection.status === _this.selectedStatus;
        });
      }
      if (this.searchQuery !== '') {
        var _filter = this.searchQuery.toLowerCase();
        filteredSchools = filteredSchools.filter(function (obj) {
          var school = obj.school;
          var connection = obj.connection;
          if (school.get('name').toLowerCase().indexOf(_filter) !== -1) {
            // School name matches filter.
            return true;
          }
          if (school.jurisdictionalId.toLowerCase().indexOf(_filter) !== -1) {
            // Jurisdictional ID matches filter.
            return true;
          }
          if (connection.wondeId.toLowerCase().indexOf(_filter) !== -1) {
            // Wonde ID matches filter.
            return true;
          }
          if (connection.displayStatus.toLowerCase().indexOf(_filter) !== -1) {
            // Status matches filter.
            return true;
          }
          if (school.id === _filter) {
            // School ID (UUID) matches filter completely (partial matches are ignored to avoid weird results).
            return true;
          }

          // None of the things we check against matched.
          return false;
        });
      }
      return filteredSchools.map(function (school) {
        return school;
      });
    }),
    actions: {
      refresh: function refresh() {
        this.model.update();
      },
      setSelectedStatus: function setSelectedStatus(status) {
        this.set('selectedStatus', status.value);
      },
      // updateSIS performs a save on update when the record's SIS is changed.
      updateSIS: function updateSIS(hash, selectedSIS) {
        var _this2 = this;
        var connection = hash.connection,
          school = hash.school;
        connection.set('sis', selectedSIS.value);
        connection.save().then(function () {
          _this2.toaster.success("Successfully updated ".concat(school.name, "'s SIS to ").concat(selectedSIS.label));
        }).catch(function () {
          _this2.toaster.error("Error attempting to update ".concat(school.name, "'s SIS, please try again"));
        });
      }
    }
  });
  _exports.default = _default;
});