define("linced-admin/routes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    beforeModel: function beforeModel() {
      if (this.session.get('isAuthenticated')) {
        this.transitionTo('home');
      } else {
        this.transitionTo('login');
      }
    }
  });
  _exports.default = _default;
});