define("linced-admin/models/school-options/group", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    key: (0, _model.attr)('string'),
    icon: (0, _model.attr)('string'),
    sequence: (0, _model.attr)('number'),
    heroOnly: (0, _model.attr)('boolean'),
    headings: (0, _model.hasMany)('school-options/heading', {
      async: true
    })
  });
  _exports.default = _default;
});