define("linced-admin/components/sif-school", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['flex-50'],
    // Props
    // school provides the school record from SIF to be displayed.
    school: null,
    // onAccept is the action which will be triggered when the button within the
    // displayed school is clicked.  Defaults to a noop to prevent errors.
    onAccept: function onAccept() {},
    actions: {
      // accept triggers the provided onAccept function/action when the button is
      // clicked by the user.
      accept: function accept() {
        this.onAccept(this.school);
      }
    }
  });
  _exports.default = _default;
});