define("linced-admin/controllers/cron/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    paperToaster: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    /**
     * toastMessage pops a notification of success or failure.
     *
     * @param {boolean} success - Whether a success was had or not.
     * @param {string} message - The message to display
     */
    toastMessage: function toastMessage(success, message) {
      var toastClass = 'success';
      if (!success) {
        toastClass = 'warning';
      }
      this.paperToaster.show(message, {
        duration: 6000,
        position: 'top right',
        toastClass: toastClass
      });
    },
    actions: {
      /**
       * toggleEnabled flips the enabled state and instantly saves the task.
       *
       * @param {Object} task - task record.
       */
      toggleEnabled: function toggleEnabled(task) {
        var _this = this;
        // store the toggled state.
        var toggledEnabledState = !task.enabled;

        // build a state string to return the action performed on save.
        var state = 'disabl';
        if (toggledEnabledState) {
          state = 'enabl';
        }

        // Update state and notify of outcome.
        task.set('enabled', toggledEnabledState);
        task.save().then(function () {
          _this.toastMessage(true, "".concat(state, "ed ").concat(task.serviceName, "'s ").concat(task.serviceTask));
        }).catch(function (err) {
          _this.toastMessage(false, "Error ".concat(state, "ing ").concat(task.serviceName, " ").concat(task.serviceTask, ". Error: ").concat(err));
        });
      },
      /**
       * viewTask transitions to the individual task to display the task logs and
       * perform specific actions on the task itself.
       *
       * @param {Object} task - task record.
       */
      viewTask: function viewTask(task) {
        this.router.transitionTo('cron.task', task.id);
      }
    }
  });
  _exports.default = _default;
});