define("linced-admin/controllers/usage-sets/usage-set", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    autogroupsGroupings: Ember.computed('model.groupTemplateIds.@each.grouping', function () {
      return this.model.groupTemplateIds.sortBy('grouping').mapBy('grouping').uniq();
    }),
    assessmentCategories: Ember.computed('model.assessmentTemplateIds.@each.grouping', function () {
      return this.model.assessmentTemplateIds.sortBy('category').mapBy('category').uniq();
    })
  });
  _exports.default = _default;
});