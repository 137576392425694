define("linced-admin/routes/finance/schema-update", ["exports", "linced-admin/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    model: function model() {
      return Ember.RSVP.hash({
        schools: this.store.findAll('school')
      });
    },
    resetController: function resetController(controller) {
      controller.reset();
    }
  });
  _exports.default = _default;
});