define("linced-admin/controllers/usage-sets/index", ["exports", "@linc-technologies/linced-common/enums/jurisdiction"], function (_exports, _jurisdiction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; } // External imports
  var _default = Ember.Controller.extend({
    Jurisdiction: _jurisdiction.default,
    queryParams: ['jurisdictionsFilter'],
    // Services
    store: Ember.inject.service(),
    toaster: Ember.inject.service(),
    // Attributes
    currentSet: null,
    // Creation and updating attributes
    name: '',
    description: '',
    selectedJurisdictions: Ember.A(),
    // Filters
    selectedJurisdictionsFilter: Ember.A(),
    remainingJurisdictionsFilter: Ember.computed.setDiff('Jurisdiction.values', 'selectedJurisdictionsFilter'),
    // jurisdictionsFilter shadows the selectedJurisdictionsFilter to strip the numeric values
    // and use the computed property as a query parameter
    jurisdictionsFilter: Ember.computed('selectedJurisdictionsFilter.[]', {
      set: function set() {
        if (Ember.isEmpty(this.get('selectedJurisdictionsFilter'))) {
          return [];
        }
        return this.get('selectedJurisdictionsFilter').map(function (jurisdiction) {
          return jurisdiction.value;
        });
      },
      get: function get() {
        if (Ember.isEmpty(this.get('selectedJurisdictionsFilter'))) {
          return [];
        }
        return this.get('selectedJurisdictionsFilter').map(function (jurisdiction) {
          return jurisdiction.value;
        });
      }
    }),
    // Additional dialogs controls
    usageSetDialogOpen: false,
    saveInProgress: false,
    deleteUsageSetDialogOpen: false,
    deleteInProgress: false,
    // Computed
    // jurisdictionsToAdd shadows the selectedJurisdictions to only store the numeric values
    // of the enum, so we can send the array to the db
    jurisdictionsToAdd: Ember.computed('selectedJurisdictions.[]', function () {
      if (Ember.isEmpty(this.selectedJurisdictions)) {
        return [];
      }
      return this.selectedJurisdictions.map(function (jurisdiction) {
        return jurisdiction.value;
      });
    }),
    // remainingJurisdictions is used to display available jurisdictions in the chips
    // input upon editing/creating a usage set
    remainingJurisdictions: Ember.computed.setDiff('Jurisdiction.values', 'selectedJurisdictions'),
    // Functions
    resetUsageSet: function resetUsageSet() {
      this.setProperties({
        currentSet: null,
        name: '',
        description: '',
        selectedJurisdictions: Ember.A()
      });
    },
    // resetForm is used to reset dialogs to the initial state
    resetForm: function resetForm() {
      this.setProperties({
        usageSetDialogOpen: false,
        saveInProgress: false,
        deleteUsageSetDialogOpen: false,
        deleteInProgress: false,
        // Filters
        selectedJurisdictionsFilter: Ember.A()
      });
    },
    actions: {
      // openDialog opens the creation/editing of a usage set dialog.
      openDialog: function openDialog() {
        this.setProperties({
          usageSetDialogOpen: true
        });
      },
      // closeDialog closes the creation/editing of a usage set dialog.
      closeDialog: function closeDialog() {
        this.setProperties({
          usageSetDialogOpen: false
        });
        this.resetUsageSet();
      },
      // editSet pre-populates the necessary usage set attributes
      // and opens up the edit dialog
      editSet: function editSet(id) {
        var _this = this;
        this.store.findRecord('schools/usage-set', id).then(function (set) {
          _this.setProperties({
            name: set.name,
            description: set.description,
            selectedJurisdictions: set.displayedJurisdictions
          });
          _this.set('currentSet', set);
          _this.send('openDialog');
        }).catch(function () {
          _this.toaster.error("Error editing the usage set");
        });
      },
      addJurisdiction: function addJurisdiction(jurisdiction) {
        this.selectedJurisdictions.pushObject(jurisdiction);
      },
      removeJurisdiction: function removeJurisdiction(jurisdiction) {
        this.selectedJurisdictions.removeObject(jurisdiction);
      },
      addJurisdictionFilter: function addJurisdictionFilter(jurisdiction) {
        this.selectedJurisdictionsFilter.pushObject(jurisdiction);
      },
      removeJurisdictionFilter: function removeJurisdictionFilter(jurisdiction) {
        this.selectedJurisdictionsFilter.removeObject(jurisdiction);
      },
      // saveUsageSet saves a usage set and related subcomponents
      // when creating or editing usage sets
      saveUsageSet: function saveUsageSet() {
        var _this2 = this;
        this.set('saveInProgress', true);
        var usageSet = this.currentSet;
        if (Ember.isEmpty(usageSet)) {
          // Creating a new usage set
          usageSet = this.store.createRecord('schools/usage-set', {
            name: this.get('name'),
            description: this.get('description'),
            jurisdictions: this.get('jurisdictionsToAdd')
          });
        } else {
          // Updating the usage set
          usageSet.setProperties({
            name: this.get('name'),
            description: this.get('description'),
            jurisdictions: this.get('jurisdictionsToAdd')
          });
        }
        usageSet.save().then(function () {
          _this2.toaster.success("Successfully saved the usage set");
          _this2.resetForm();
          _this2.resetUsageSet();
        }).catch(function () {
          _this2.toaster.error("Error saving the usage set");
          usageSet.rollbackAttributes();
        }).then(function () {
          _this2.set('saveInProgress', false);
        });
      },
      // deleteSet pre-populates attributes and opens up the deletion dialog
      deleteSet: function deleteSet(id) {
        var _this3 = this;
        this.store.findRecord('schools/usage-set', id).then(function (set) {
          _this3.setProperties({
            currentSet: set,
            deleteUsageSetDialogOpen: true
          });
        }).catch(function () {
          _this3.toaster.error("Error trying to delete the usage set");
        });
      },
      confirmDelete: function confirmDelete(confirmed) {
        var _this4 = this;
        if (confirmed) {
          this.set('deleteInProgress', true);
          this.currentSet.destroyRecord().then(function () {
            // Get all related subcomponents in parallel
            return Promise.all([_this4.currentSet.get('pageIds'), _this4.currentSet.get('goalSubjectIds'), _this4.currentSet.get('milestoneSetIds'), _this4.currentSet.get('groupTemplateIds'), _this4.currentSet.get('assessmentTemplateIds'), _this4.currentSet.get('tagCategoryTemplatesIds')]).then(function (_ref) {
              var _ref2 = _slicedToArray(_ref, 6),
                pageIds = _ref2[0],
                goalSubjectIds = _ref2[1],
                milestoneSetIds = _ref2[2],
                groupTemplateIds = _ref2[3],
                assessmentTemplateIds = _ref2[4],
                tagCategoryTemplatesIds = _ref2[5];
              var promises = [];

              // Iterate over each sets of subcomponents and save the related records
              [pageIds, goalSubjectIds, milestoneSetIds, groupTemplateIds, assessmentTemplateIds, tagCategoryTemplatesIds].forEach(function (idSet) {
                idSet.forEach(function (id) {
                  var promise = id.save();
                  promises.push(promise);
                });
              });

              // Wait for all saves to complete
              return Promise.all(promises);
            }).then(function () {
              _this4.setProperties({
                currentSet: null,
                deleteUsageSetDialogOpen: false,
                deleteInProgress: false
              });
            });
          }).catch(function () {
            _this4.currentSet.rollbackAttributes();
            _this4.toaster.error("Error trying to delete the usage set");
            _this4.set('deleteInProgress', false);
          });
        } else {
          this.setProperties({
            currentSet: null,
            deleteUsageSetDialogOpen: false,
            deleteInProgress: false
          });
        }
      }
    }
  });
  _exports.default = _default;
});