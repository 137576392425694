define("linced-admin/templates/components/learner/id-search-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "cEUM/pIl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"layout-column layout-gt-xs-row flex\"],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"id-search-field-wrap layout-row flex\"],[8],[0,\"\\n    \"],[5,\"paper-input\",[],[[\"@class\",\"@isTouched\",\"@label\",\"@onChange\",\"@type\",\"@value\"],[\"flex\",true,[24,[\"field\",\"label\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"entityField\",\"value\"]]],null]],null],[22,\"inputType\"],[24,[\"entityField\",\"value\"]]]]],[0,\"\\n\"],[4,\"if\",[[24,[\"isSearching\"]]],null,{\"statements\":[[0,\"      \"],[5,\"paper-progress-circular\",[],[[\"@accent\",\"@diameter\"],[true,18]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "linced-admin/templates/components/learner/id-search-field.hbs"
    }
  });
  _exports.default = _default;
});