define("linced-admin/controllers/school-options/sets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    currentUser: Ember.inject.service('current-user'),
    store: Ember.inject.service('store'),
    paperToaster: Ember.inject.service(),
    setName: '',
    setDescription: '',
    setId: '',
    setDialogOpen: false,
    saveInProgress: false,
    deleteSetDialogOpen: false,
    deleteInProgress: false,
    errorMessage: '',
    // toggleDialog opens the set dialog.
    toggleDialog: function toggleDialog() {
      this.set('errorMessage', '');
      this.toggleProperty('setDialogOpen');
      if (!this.setDialogOpen) {
        this.resetForm();
      }
    },
    actions: {
      toggleSetDialog: function toggleSetDialog() {
        this.toggleDialog();
      },
      editSet: function editSet(id) {
        var _this = this;
        this.store.findRecord('schoolOptions/set', id).then(function (set) {
          _this.set('setName', set.get('name'));
          _this.set('setDescription', set.get('description'));
        });
        this.set('setId', id);
        this.toggleDialog();
      },
      saveSet: function saveSet() {
        var _this2 = this;
        this.set('saveInProgress', true);
        this.set('errorMessage', '');
        var promise;
        var action = 'create';
        if (Ember.isEmpty(this.setId)) {
          promise = new Ember.RSVP.Promise(function (resolve) {
            var record = _this2.store.createRecord('schoolOptions/set', {
              name: _this2.setName,
              description: _this2.setDescription
            });
            resolve(record);
          });
        } else {
          action = 'update';
          promise = new Ember.RSVP.Promise(function (resolve) {
            var id = _this2.setId;
            _this2.store.findRecord('schoolOptions/set', id).then(function (set) {
              set.set('name', _this2.setName);
              set.set('description', _this2.setDescription);
              resolve(set);
            });
          });
        }
        promise.then(function (record) {
          return record.save().then(function () {
            _this2.set('setDialogOpen', false);
            _this2.resetForm();
          }).catch(function (err) {
            _this2.handleError(err, action);
            record.rollbackAttributes();
          }).then(function () {
            _this2.set('saveInProgress', false);
            if (action == 'create') {
              _this2.send('refreshModel');
            }
          });
        });
      },
      deleteSet: function deleteSet(id) {
        var _this3 = this;
        this.set('errorMessage', '');
        this.store.findRecord('schoolOptions/set', id).then(function (set) {
          _this3.set('setName', set.get('name'));
        });
        this.set('setId', id);
        this.set('deleteSetDialogOpen', true);
      },
      confirmDelete: function confirmDelete(confirmed) {
        var _this4 = this;
        return new Ember.RSVP.Promise(function (resolve, reject) {
          if (confirmed) {
            // Deletion confirmed; actually delete the record.
            _this4.set('deleteInProgress', true);

            // Grab record from local store - no GET request, as that causes weirdness.
            // We only need the ID to issue the DELETE anyway, which can't change, so no need to use findRecord.
            var set = _this4.store.peekRecord('schoolOptions/set', _this4.setId);

            // Delete record (locally + pushed to server).
            return set.destroyRecord().then(resolve).catch(function (err) {
              // Remove 'deleted' flag so that entity can be interacted with again.
              set.rollbackAttributes();
              reject(err);
            });
          } else {
            resolve();
          }
        }).then(function () {
          // Tidy up / close dialog.
          _this4.set('deleteSetDialogOpen', false);
          _this4.set('setId', '');
          _this4.set('setName', '');
        }).catch(function (err) {
          _this4.handleError(err, 'delete');
        }).then(function () {
          _this4.set('deleteInProgress', false);
        });
      }
    },
    /*
    init() {
      this.set('schoolId', this.get('currentUser.user.schoolId'));
    },*/
    handleError: function handleError(err) {
      if (!Ember.isEmpty(err.errors.msg)) {
        this.set('errorMessage', err.errors.msg);
      } else {
        this.set('errorMessage', err.message);
      }
    },
    setErrorMessage: function setErrorMessage(code, action) {
      var message = 'An unknown error occurred.  Please try again.';
      var codes = {
        '400': 'Sorry, your request was invalid.  Please check all details below, and try again.',
        '403': {
          create: 'Sorry, you are not authorised to create a new user.  Please check that you are logged in as the correct user, and try again.',
          update: 'Sorry, you are not authorised to edit this user.  Please check that you are logged in as the correct user, and try again.',
          delete: 'Sorry, you are not authorised to delete this user.  Please check that you are logged in as the correct user, and try again.'
        },
        '409': 'A user already exists with that username.  Please double-check the details, or find and update the existing user.'
      };
      if (!Ember.isEmpty(codes[code][action])) {
        message = codes[code][action];
      } else if (!Ember.isEmpty(codes[code])) {
        message = codes[code];
      }
      this.set('errorMessage', message);
    },
    resetForm: function resetForm() {
      this.set('setId', '');
      this.set('setName', '');
      this.set('setDescription', '');
    }
  });
  _exports.default = _default;
});