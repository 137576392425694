define("linced-admin/services/print", ["exports", "@linc-technologies/linced-common/services/print"], function (_exports, _print) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _print.default;
    }
  });
});