define("linced-admin/serializers/milestones/milestone", ["exports", "linced-admin/serializers/milestones/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    normalize: function normalize(modelClass, payload) {
      if (Ember.isEmpty(payload.yearLevel)) {
        // Year 0 is a valid choice for year level milestones, but Go omits it because 0 is "empty".
        payload.yearLevel = 0;
      }
      return this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});