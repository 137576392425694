define("linced-admin/router", ["exports", "linced-admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('login');
    this.route('home');
    this.route('users');
    this.route('my-profile');
    this.route('callback');
    this.route('login-failed');
    this.route('logged-out');
    this.route('people', function () {
      this.route('schema', function () {
        this.route('templates');
        this.route('template', {
          path: '/templates/:set_id'
        }, function () {
          this.route('groups');
          this.route('group', {
            path: '/groups/:group_id'
          }, function () {});
          this.route('view');
        });
        this.route('apply-templates');
        this.route('resequence-groups');
      });
      this.route('information', {
        path: '/:person_id/information'
      });
    });
    this.route('schools');
    this.route('school-options', function () {
      this.route('groups');
      this.route('headings', {
        path: '/groups/:group_id'
      });
      this.route('fields', {
        path: '/groups/:group_id/headings/:heading_id'
      });
      this.route('sets');
      this.route('preview', {
        path: '/sets/:set_id/preview'
      });
    });
    this.route('page-templates');
    this.route('goals', function () {
      this.route('subject', {
        path: '/subjects/:subject_id'
      });
      this.route('plp');
    });
    this.route('milestones', function () {
      this.route('sets');
      this.route('set', {
        path: '/sets/:set_id'
      });
    });
    this.route('autogroups');
    this.route('tags', function () {
      this.route('category', {
        path: '/categories/:category_id'
      });
    });
    this.route('assessments', function () {
      this.route('assessment', {
        path: '/:assessment_id'
      });
      this.route('grade-schedules');
    });
    this.route('enrolment', function () {
      this.route('schema', function () {
        this.route('templates');
        this.route('template', {
          path: '/template/:schema_id'
        });
      });
    });
    this.route('auth', function () {
      this.route('clients');
      this.route('fusionauth');
    });
    this.route('logs', function () {
      this.route('enrolments');
    });
    this.route('cron', function () {
      this.route('index', {
        path: '/tasks'
      });
      this.route('task', {
        path: '/tasks/:task_id'
      });
    });
    this.route('contacts', function () {
      this.route('search');
    });
    this.route('school-groups');
    this.route('hero-notices', function () {
      this.route('notice', {
        path: '/:notice_id'
      });
    });
    this.route('sif', function () {
      this.route('zones');
    });
    this.route('wonde', function () {
      this.route('connections');
    });
    this.route('finance', function () {
      this.route('schema-update');
      this.route('nsw-sap', function () {
        this.route('transaction-upload');
      });
    });
    this.route('post-templates');
    this.route('usage-sets', function () {
      this.route('usage-set', {
        path: '/:set_id'
      });
    });

    // Default Route - Do not put routes beneath this one
    this.route('not-found', {
      path: '/*path'
    });
  });
  var _default = Router;
  _exports.default = _default;
});