define("linced-admin/routes/post-templates", ["exports", "linced-admin/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    queryParams: {
      pageType: {
        refreshModel: true,
        as: 'type'
      },
      jurisdiction: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var store = this.store;
      var postTemplateParams = {
        type: params.pageType
      };
      if (!Ember.isEmpty(params.jurisdiction)) {
        postTemplateParams.jurisdiction = params.jurisdiction;
      }
      return Ember.RSVP.hash({
        fieldTemplateSets: store.findAll('people-schema/set'),
        fieldGroups: store.findAll('people-schema/group'),
        fields: store.findAll('people-schema/field'),
        postTemplates: store.query('posts/postTemplate', postTemplateParams),
        schoolGroups: this.store.findAll('schools/group'),
        schools: this.store.findAll('school')
      });
    },
    actions: {
      postTemplateCreated: function postTemplateCreated() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});