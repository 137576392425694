define("linced-admin/app", ["exports", "@linc-technologies/linced-common/models/custom-inflector-rules", "ember-get-config", "ember-load-initializers", "linced-admin/resolver"], function (_exports, _customInflectorRules, _emberGetConfig, _emberLoadInitializers, _resolver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Global Imports

  // External Imports

  // Internal Imports

  /*global newrelic*/
  try {
    newrelic.addRelease('linced-admin', _emberGetConfig.default.version);
  } catch (e) {
    // eslint-disable-next-line
    console.log("Could not configure new relic release (".concat(JSON.stringify(_emberGetConfig.default.version), "})"), e);
  }
  var App = Ember.Application.extend({
    modulePrefix: _emberGetConfig.default.modulePrefix,
    podModulePrefix: _emberGetConfig.default.podModulePrefix,
    Resolver: _resolver.default
  });
  (0, _emberLoadInitializers.default)(App, _emberGetConfig.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});