define("linced-admin/enums/auth-sync-status", ["exports", "@linc-technologies/linced-common/enums/base-enum"], function (_exports, _baseEnum) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _baseEnum.default.create({
    values: Object.freeze([{
      value: 1,
      label: 'Processing'
    }, {
      value: 2,
      label: 'Ready'
    }, {
      value: 3,
      label: 'Failed'
    }])
  });
  _exports.default = _default;
});