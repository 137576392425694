define("linced-admin/models/address", ["exports", "ember-data-model-fragments", "@ember-data/model"], function (_exports, _emberDataModelFragments, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberDataModelFragments.default.Fragment.extend({
    number: (0, _model.attr)('string'),
    street: (0, _model.attr)('string'),
    locality: (0, _model.attr)('string'),
    district: (0, _model.attr)('string'),
    region: (0, _model.attr)('string'),
    postcode: (0, _model.attr)('string')
  });
  _exports.default = _default;
});