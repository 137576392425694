define("linced-admin/enums/auth-sync-type", ["exports", "@linc-technologies/linced-common/enums/base-enum"], function (_exports, _baseEnum) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _baseEnum.default.create({
    values: Object.freeze([{
      value: 'user',
      label: 'User'
    }, {
      value: 'client',
      label: 'Client'
    }])
  });
  _exports.default = _default;
});