define("linced-admin/adapters/cron/process-monitor", ["exports", "linced-admin/adapters/cron/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    modelNamePlural: 'processMonitors'
  });
  _exports.default = _default;
});