define("linced-admin/models/assessments/gradeschedule-select", ["exports", "ember-data-model-fragments", "ember-data-model-fragments/attributes"], function (_exports, _emberDataModelFragments, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberDataModelFragments.default.Fragment.extend({
    values: (0, _attributes.fragmentArray)('assessments/gradeschedule-selectvalue')
  });
  _exports.default = _default;
});