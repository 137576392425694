define("linced-admin/models/people-schema/set", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    key: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    personType: (0, _model.attr)('number'),
    groups: (0, _model.hasMany)('people-schema/group', {
      inverse: 'templateSetIds'
    }),
    sequence: (0, _model.attr)('number', {
      defaultValue: 9999
    })
  });
  _exports.default = _default;
});