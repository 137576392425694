define("linced-admin/templates/components/profile-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6YZC+1Bd",
    "block": "{\"symbols\":[\"card\",\"title\",\"text\",\"&default\"],\"statements\":[[5,\"paper-card\",[],[[\"@class\"],[\"profile-banner-card\"]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"title\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,2,[\"text\"]],[],[[\"@class\"],[\"flex flex-order-2\"]],{\"statements\":[[0,\"\\n      \"],[6,[23,3,[\"headline\"]],[],[[],[]],{\"statements\":[[1,[24,[\"user\",\"fullName\"]],false]],\"parameters\":[]}],[0,\"\\n      \"],[6,[23,3,[\"subhead\"]],[],[[],[]],{\"statements\":[[1,[24,[\"user\",\"bannerText\"]],false]],\"parameters\":[]}],[0,\"\\n      \"],[14,4],[0,\"\\n    \"]],\"parameters\":[3]}],[0,\"\\n    \"],[6,[23,2,[\"media\"]],[],[[\"@class\",\"@size\",\"@src\",\"@alt\",\"@title\"],[\"flex-order-1\",\"lg\",[24,[\"user\",\"image\"]],[24,[\"user\",\"fullName\"]],[24,[\"user\",\"fullName\"]]]]],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "linced-admin/templates/components/profile-banner.hbs"
    }
  });
  _exports.default = _default;
});