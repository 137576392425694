define("linced-admin/routes/milestones/set", ["exports", "linced-admin/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        set: this.store.find('milestones/set', params.set_id),
        allSets: this.store.findAll('milestones/set'),
        milestoneSets: this.store.query('milestones/milestone-set', {
          include: 'milestones'
        }),
        milestones: this.store.query('milestones/milestone', {
          templateSetId: params.set_id
        }),
        levels: this.store.query('milestones/level', {
          templateSetId: params.set_id
        }),
        expectations: this.store.query('milestones/expectation', {
          templateSetId: params.set_id
        }),
        subjects: this.store.query('goals/subject', {
          trackable: true
        }),
        goalCategories: this.store.findAll('goals/category'),
        pages: this.store.findAll('posts/page'),
        usageSets: this.store.findAll('schools/usage-set')
      });
    },
    setupController: function setupController(controller, model) {
      var milestoneSets = model.milestoneSets.toArray().sortBy('label');
      milestoneSets.unshiftObject({
        label: 'Default Milestones',
        isDefault: true
      });
      model.milestoneSets = milestoneSets;
      this._super.apply(this, arguments);
      var selectedMilestoneSet = model.set.milestoneSetTemplate;
      if (Ember.isEmpty(model.set.milestone_set_template_id)) {
        selectedMilestoneSet = milestoneSets.findBy('isDefault');
      }
      controller.setProperties({
        selectedUsageSetsFilter: model.set.usageSetIds.toArray(),
        includeSubjectsWithLevels: true,
        selectedMilestoneSet: selectedMilestoneSet
      });
    }
  });
  _exports.default = _default;
});