define("linced-admin/templates/hero-notices/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "mfJ3JdBo",
    "block": "{\"symbols\":[],\"statements\":[[5,\"publishable-post-list\",[],[[\"@editPost\",\"@homePage\",\"@newPost\",\"@posts\"],[[28,\"action\",[[23,0,[]],\"editPost\"],null],true,[28,\"action\",[[23,0,[]],\"newPost\"],null],[24,[\"model\",\"posts\"]]]]],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"layout-row layout-align-center-center\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[20,[24,[\"model\",\"posts\",\"length\"]]],null]],null,{\"statements\":[[0,\"    \"],[5,\"paper-button\",[],[[\"@raised\",\"@onClick\"],[true,[28,\"action\",[[23,0,[]],\"nextPage\"],null]]],{\"statements\":[[0,\"Older posts\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"gt\",[[24,[\"offset\"]],0],null]],null,{\"statements\":[[0,\"    \"],[5,\"paper-button\",[],[[\"@raised\",\"@onClick\"],[true,[28,\"action\",[[23,0,[]],\"prevPage\"],null]]],{\"statements\":[[0,\"Newer posts\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[5,\"new-post\",[],[[\"@dialogOpen\",\"@finishEditing\",\"@post\",\"@postCreated\",\"@postType\",\"@schoolGroups\"],[[22,\"editingPost\"],[28,\"action\",[[23,0,[]],\"closeEditPost\"],null],[22,\"postBeingEdited\"],[28,\"action\",[[23,0,[]],\"refresh\"],null],[24,[\"PostTypes\",\"GLOBAL_NOTICE\"]],[24,[\"model\",\"groups\"]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "linced-admin/templates/hero-notices/index.hbs"
    }
  });
  _exports.default = _default;
});