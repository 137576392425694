define("linced-admin/adapters/timetable/base", ["exports", "linced-admin/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    namespace: 'timetable/v4',
    pathForType: function pathForType() {
      return this._super.apply(this, arguments).replace('timetable/', '').toLowerCase();
    }
  });
  _exports.default = _default;
});