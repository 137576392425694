define("linced-admin/routes/wonde/connections", ["exports", "linced-admin/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    model: function model() {
      var _this = this;
      return Ember.RSVP.hash({
        connections: this.store.findAll("wonde/connection"),
        schools: this.store.findAll('school'),
        update: function update() {
          return _this.refresh();
        }
      });
    }
  });
  _exports.default = _default;
});