define("linced-admin/components/oauth-client", ["exports", "@linc-technologies/linced-common/enums/media-type", "@linc-technologies/linced-common/enums/media-sharing-level", "ember-get-config", "@linc-technologies/linced-common/utils/uppy"], function (_exports, _mediaType, _mediaSharingLevel, _emberGetConfig, _uppy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attrs
    clientProperties: Object.freeze(['id', 'allowedAudiences', 'allowedRegions', 'allowedTenantAccess', 'grantTypes', 'responseTypes', 'scopes', 'public', 'disabled', 'name', 'secret', 'redirectUris', 'owner', 'policyUri', 'termsOfServiceUri', 'clientUri', 'logoUri', 'contacts', 'published']),
    heroAllowedAudiences: Object.freeze([{
      description: 'Development APIs',
      value: 'https://dev-api.linc-ed.com'
    }, {
      description: 'Staging APIs',
      value: 'https://staging-api.linc-ed.com'
    }, {
      description: 'Production APIs',
      value: 'https://api4.linc-ed.com'
    }, {
      description: 'Gatekeeper',
      value: 'https://go.linc-ed.com'
    }]),
    heroAllowedGrantTypes: Object.freeze([{
      description: 'Authorization Code',
      value: 'authorize_code'
    }, {
      description: 'Client Credentials',
      value: 'client_credentials'
    }, {
      description: 'Implicit',
      value: 'implicit'
    }]),
    heroAllowedResponseTypes: Object.freeze([{
      description: 'Authorization Code',
      value: 'code'
    }, {
      description: 'Access Token',
      value: 'token'
    }]),
    // Services
    currentUser: Ember.inject.service(),
    media: Ember.inject.service(),
    store: Ember.inject.service(),
    toaster: Ember.inject.service(),
    // Props
    // client provides the OAuth 2.0 client to be editing.
    client: null,
    // heroAllowedScopes provides all the allowed region scopes that can be
    // assigned to an OAuth Client.
    heroAllowedRegions: null,
    // heroAllowedScopes provides all the scopes that can be assigned to an OAuth
    // Client.
    heroAllowedScopes: null,
    // heroTenants provides all the schools we currently have on file, to enable
    // a client access.
    heroTenants: null,
    // newClient provides a boolean to enable creating a brand-new OAuth client.
    newClient: false,
    // onCreate provides the callback function to return the newly created
    // client to show the returned client ID and Secret.
    // @param {DS.Model<module:linced-admin/models/client} - an oauth2 client.
    onCreate: function onCreate() {},
    // State
    // clientEditOpen provides the state of the OAuth client edit dialog.
    clientEditOpen: false,
    // clientDeleteOpen provides the state of the OAuth client delete dialog.
    clientDeleteOpen: false,
    // remainingAllowedAudiences calculates the difference between the currently
    // selected audiences and the allowed Audiences to return the remaining items
    // that can be added to the client.
    remainingAllowedAudiences: Ember.computed.setDiff('heroAllowedAudiences', 'selectedAudiences'),
    // remainingAllowedRegions calculates the difference between the currently
    // selected regions and the allowed Regions to return the remaining items
    // that can be added to the client.
    remainingAllowedRegions: Ember.computed.setDiff('heroAllowedRegions', 'selectedRegions'),
    // remainingAllowedScopes calculates the difference between the currently
    // selected scopes and the allowed Scopes to return the remaining items that
    // can be added to the client.
    remainingAllowedScopes: Ember.computed.setDiff('heroAllowedScopes', 'selectedScopes'),
    // remainingAllowedTenants calculates the difference between the currently
    // selected tenants and the allowed Tenants to return the remaining items
    // that can be added to the client.
    remainingAllowedTenants: Ember.computed.setDiff('heroTenants', 'selectedTenants'),
    // remainingAllowedGrantTypes calculates the difference between the currently
    // selected granttypes and the allowed GrantTypes to return the remaining
    // items that can be added to the client.
    remainingAllowedGrantTypes: Ember.computed.setDiff('heroAllowedGrantTypes', 'selectedGrantTypes'),
    // remainingAllowedResponseTypes calculates the difference between the
    // currently selected responsetypes and the allowed ResponseTypes to return
    // the remaining items that can be added to the client.
    remainingAllowedResponseTypes: Ember.computed.setDiff('heroAllowedResponseTypes', 'selectedResponseTypes'),
    // saveInProgress provides the current state of when a record save is in
    // progress.
    saveInProgress: false,
    // uploading provides the current state of uploading the OAuth Client's logo.
    uploading: false,
    /* Client properties State */
    // id contains the provided client's id field, to enable operations to happen
    // on the copied attribute, rather than the datastore object itself in order
    // to prevent data munging.
    id: '',
    // allowedAudiences contains the provided client's allowedAudiences field, to
    // enable operations to happen on the copied attribute, rather than the
    // datastore object itself in order to prevent data munging.
    allowedAudiences: null,
    // allowedRegions contains the provided client's allowedRegions field, to
    // enable operations to happen on the copied attribute, rather than the
    // datastore object itself in order to prevent data munging.
    allowedRegions: null,
    // allowedTenantAccess contains the provided client's allowedTenantAccess
    // field, to enable operations to happen on the copied attribute, rather than
    // the datastore object itself in order to prevent data munging.
    allowedTenantAccess: null,
    // grantTypes contains the provided client's grantTypes field, to enable
    // operations to happen on the copied attribute, rather than the datastore
    // object itself in order to prevent data munging.
    grantTypes: null,
    // responseTypes contains the provided client's responseTypes field, to
    // enable operations to happen on the copied attribute, rather than the
    // datastore object itself in order to prevent data munging.
    responseTypes: null,
    // scopes contains the provided client's scopes field, to enable operations
    // to happen on the copied attribute, rather than the datastore object itself
    // in order to prevent data munging.
    scopes: null,
    // public contains the provided client's public field, to enable operations
    // to happen on the copied attribute, rather than the datastore object itself
    // in order to prevent data munging.
    public: false,
    // disabled contains the provided client's disabled field, to enable
    // operations to happen on the copied attribute, rather than the datastore
    // object itself in order to prevent data munging.
    disabled: false,
    // name contains the provided client's name field, to enable operations to
    // happen on the copied attribute, rather than the datastore object itself in
    // order to prevent data munging.
    name: '',
    // secret contains the provided client's secret field, to enable operations
    // to happen on the copied attribute, rather than the datastore object itself
    // in order to prevent data munging.
    secret: '',
    // redirectUris contains the provided client's redirectUris field, to enable
    // operations to happen on the copied attribute, rather than the datastore
    // object itself in order to prevent data munging.
    redirectUris: null,
    // owner contains the provided client's owner field, to enable operations to
    // happen on the copied attribute, rather than the datastore object itself in
    // order to prevent data munging.
    owner: '',
    // policyUri contains the provided client's policyUri field, to enable
    // operations to happen on the copied attribute, rather than the datastore
    // object itself in order to prevent data munging.
    policyUri: '',
    // termsOfServiceUri contains the provided client's termsOfServiceUri field,
    // to enable operations to happen on the copied attribute, rather than the
    // datastore object itself in order to prevent data munging.
    termsOfServiceUri: '',
    // clientUri contains the provided client's clientUri field, to enable
    // operations to happen on the copied attribute, rather than the datastore
    // object itself in order to prevent data munging.
    clientUri: '',
    // logoUri contains the provided client's logoUri field, to enable operations
    // to happen on the copied attribute, rather than the datastore object itself
    // in order to prevent data munging.
    logoUri: '',
    // contacts contains the provided client's contacts field, to enable
    // operations to happen on the copied attribute, rather than the datastore
    // object itself in order to prevent data munging.
    contacts: null,
    // published contains the provided client's published field, to enable
    // operations to happen on the copied attribute, rather than the datastore
    // object itself in order to prevent data munging.
    published: false,
    // Methods
    /**
     * selectedAudiences provides a computed property to return the mapped
     * audience objects that are currently in use by the OAuth client to enable
     * the pretty chips component to render.
     */
    selectedAudiences: Ember.computed('allowedAudiences.[]', 'heroAllowedAudiences.[]', function () {
      var _this = this;
      if (Ember.isEmpty(this.allowedAudiences)) {
        return [];
      }
      return this.allowedAudiences.map(function (aud) {
        return _this.heroAllowedAudiences.findBy('value', aud);
      });
    }),
    // selectedGrantTypes provides a computed property to return the mapped oauth
    // grant types that are currently in use by the OAuth client to enable the
    // pretty chips component to render.
    selectedGrantTypes: Ember.computed('grantTypes.[]', 'heroAllowedGrantTypes.[]', function () {
      var _this2 = this;
      if (Ember.isEmpty(this.grantTypes)) {
        return [];
      }
      return this.grantTypes.map(function (grantType) {
        return _this2.heroAllowedGrantTypes.findBy('value', grantType);
      });
    }),
    /**
     * selectedResponseTypes provides a computed property to return the mapped
     * oauth response types that are currently in use by the OAuth client to
     * enable the pretty chips component to render.
     */
    selectedResponseTypes: Ember.computed('responseTypes.[]', 'heroAllowedResponseTypes.[]', function () {
      var _this3 = this;
      if (Ember.isEmpty(this.responseTypes)) {
        return [];
      }
      return this.responseTypes.map(function (responseType) {
        return _this3.heroAllowedResponseTypes.findBy('value', responseType);
      });
    }),
    /**
     * selectedRegions provides a computed property to return the mapped regions
     * that are currently in use by the OAuth client to enable the pretty chips
     * component to render.
     */
    selectedRegions: Ember.computed('allowedRegions.[]', 'heroAllowedRegions.[]', function () {
      var _this4 = this;
      if (Ember.isEmpty(this.allowedRegions)) {
        return [];
      }
      return this.allowedRegions.map(function (region) {
        return _this4.heroAllowedRegions.findBy('enum', region);
      });
    }),
    /**
     * selectedScopes provides a computed property to return the mapped scopes
     * that are currently in use by the OAuth client to enable the pretty chips
     * component to render.
     */
    selectedScopes: Ember.computed('scopes.[]', 'heroAllowedScopes.[]', function () {
      var _this5 = this;
      if (Ember.isEmpty(this.scopes)) {
        return [];
      }
      return this.scopes.map(function (scope) {
        return _this5.heroAllowedScopes.findBy('value', scope);
      });
    }),
    /**
     * selectedTenants provides a computed property to return the mapped schools
     * that are currently in use by the OAuth client to enable the pretty contact
     * chips component to render.
     */
    selectedTenants: Ember.computed('allowedTenantAccess.[]', 'schools.[]', function () {
      var _this6 = this;
      if (Ember.isEmpty(this.allowedTenantAccess)) {
        return [];
      }
      return this.allowedTenantAccess.map(function (tid) {
        return _this6.heroTenants.findBy('id', tid);
      });
    }),
    /**
     * isRegionScope checks to see if a provided scope is a jurisdiction based
     * scope to make it easy to determine if the entered scope's enum also needs
     * to be added to allowed regions.
     *
     * @param {string} scope
     * @returns {boolean}
     */
    isRegionScope: function isRegionScope(scope) {
      return scope.value.startsWith('urn:hero:jurisdictions');
    },
    /**
     * resetForm resets the required state properties to defaults.
     */
    resetForm: function resetForm() {
      this.set('id', '');
      this.set('allowedAudiences', []);
      this.set('allowedRegions', []);
      this.set('allowedTenantAccess', []);
      this.set('grantTypes', []);
      this.set('responseTypes', []);
      this.set('scopes', []);
      this.set('public', false);
      this.set('disabled', false);
      this.set('name', '');
      this.set('secret', '');
      this.set('redirectUris', []);
      this.set('owner', '');
      this.set('policyUri', '');
      this.set('termsOfServiceUri', '');
      this.set('clientUri', '');
      this.set('logoUri', '');
      this.set('contacts', []);
      this.set('published', false);

      // State reset
      this.set('uploading', false);
    },
    /**
     * toggleEdit toggles the edit client dialog open, resets the form and
     * creates a deep copy of the provided client into the components client
     * state variables, ready for editing.
     */
    toggleEdit: function toggleEdit() {
      var client = this.client;
      this.toggleProperty('clientEditOpen');
      if (!this.clientEditOpen) {
        this.resetForm();
        return;
      }
      if (!Ember.isEmpty(client)) {
        this.setProperties(client.getProperties(this.clientProperties));

        // deep copy arrays
        this.set('allowedAudiences', client.allowedAudiences.slice());
        this.set('allowedRegions', client.allowedRegions.slice());
        this.set('allowedTenantAccess', client.allowedTenantAccess.slice());
        this.set('grantTypes', client.grantTypes.slice());
        this.set('responseTypes', client.responseTypes.slice());
      }
    },
    /**
     * createClient creates a new OAuth client on the server.
     *
     * @param {Object} props - contains the client properties that are wanting to
     *     be pushed into the client on create.
     */
    createClient: function createClient(props) {
      var _this7 = this;
      var newClient = this.store.createRecord('client', Ember.getProperties(this, props));
      newClient.save().then(function (client) {
        _this7.onCreate(client);
        _this7.toaster.success('OAuth Client created successfully!');
        _this7.toggleEdit();
      }).catch(function (err) {
        _this7.toaster.error("Error creating new OAuth Client! ".concat(err));
      }).finally(function () {
        _this7.set('saveInProgress', false);
      });
    },
    /**
     * updateClient updates the current OAuth client on the server.
     *
     * @param {Object} props - contains the client properties that are wanting to
     *     be pushed into the client on update.
     */
    updateClient: function updateClient(props) {
      var _this8 = this;
      this.store.findRecord('client', this.id).then(function (client) {
        client.setProperties(Ember.getProperties(_this8, props));
        return client.save();
      }).then(function () {
        _this8.toaster.success('OAuth Client saved successfully!');
        _this8.toggleEdit();
      }).catch(function (err) {
        _this8.toaster.error("Error saving OAuth Client! ".concat(err));
      }).finally(function () {
        _this8.set('saveInProgress', false);
      });
    },
    /**
     * deleteClient deletes the current OAuth client from the server.
     */
    deleteClient: function deleteClient() {
      var _this9 = this;
      this.set('deleteInProgress', true);
      if (!Ember.isEmpty(this.onDelete)) {
        this.onDelete(this.client);
        this.set('deleteInProgress', false);
        this.set('clientDeleteOpen', false);
        return;
      }
      this.store.findRecord('client', this.client.id, {
        backgroundReload: false
      }).then(function (client) {
        client.destroyRecord();
      }).then(function () {
        _this9.toaster.success('Successfully deleted OAuth Client!');
      }).catch(function (err) {
        _this9.toaster.error("Error deleting OAuth Client! ".concat(err));
      }).finally(function () {
        _this9.set('deleteInProgress', false);
        _this9.set('clientDeleteOpen', false);
      });
    },
    // Actions
    actions: {
      noOp: function noOp() {},
      // addAudience adds a audience to the locally copied client's audiences.
      addAudience: function addAudience(audience) {
        this.allowedAudiences.pushObject(audience.value);
      },
      // removeAudience removes a audience from the locally copied client's
      // audiences.
      removeAudience: function removeAudience(audience) {
        this.allowedAudiences.removeObject(audience.value);
      },
      // addContact adds a contact to the locally copied client's contacts.
      addContact: function addContact(contact) {
        this.contacts.pushObject(contact);
      },
      // removeContact removes a contact from the locally copied client's
      // contacts.
      removeContact: function removeContact(contact) {
        this.contacts.removeObject(contact);
      },
      // addGrantType adds a grant type to the locally copied client's grant
      // types.
      addGrantType: function addGrantType(grantType) {
        this.grantTypes.pushObject(grantType.value);
      },
      // removeGrantType removes a grant type from the locally copied client's
      // grant types.
      removeGrantType: function removeGrantType(grantType) {
        this.grantTypes.removeObject(grantType.value);
      },
      // addRedirectUri adds a redirect uri to the locally copied client's
      // redirect uris.
      addRedirectUri: function addRedirectUri(redirectUri) {
        this.redirectUris.pushObject(redirectUri);
      },
      // removeRedirectUri removes a redirect uri from the locally copied client's
      // redirect uris.
      removeRedirectUri: function removeRedirectUri(redirectUri) {
        this.redirectUris.removeObject(redirectUri);
      },
      // addResponseType adds a response type to the locally copied client's
      // response types.
      addResponseType: function addResponseType(responseType) {
        this.responseTypes.pushObject(responseType.value);
      },
      // removeResponseType removes a response type from the locally copied
      // client's response types.
      removeResponseType: function removeResponseType(responseType) {
        this.responseTypes.removeObject(responseType.value);
      },
      // addScope adds a scope to the locally copied client's scopes.
      addScope: function addScope(scope) {
        if (this.isRegionScope(scope)) {
          this.allowedRegions.pushObject(scope.enum);
        }
        this.scopes.pushObject(scope.value);
      },
      // removeScope removes a scope from the locally copied client's scopes.
      removeScope: function removeScope(scope) {
        if (this.isRegionScope(scope)) {
          this.allowedRegions.removeObject(scope.enum);
        }
        this.scopes.removeObject(scope.value);
      },
      // addTenant adds a tenant to the locally copied client's tenants.
      addTenant: function addTenant(tenant) {
        this.allowedTenantAccess.pushObject(tenant.id);
      },
      // removeTenant removes a tenant from the locally copied client's tenants.
      removeTenant: function removeTenant(tenant) {
        this.allowedTenantAccess.removeObject(tenant.id);
      },
      // toggleClientEdit toggles the client edit dialog on or off.
      toggleClientEdit: function toggleClientEdit() {
        this.toggleEdit();
      },
      // toggleClientDelete toggles the client delete dialog on or off.
      toggleClientDelete: function toggleClientDelete() {
        this.toggleProperty('clientDeleteOpen');
        if (!this.clientDeleteOpen) {
          this.resetForm();
        }
      },
      // onSave performs either a save, or update based on whether the provided
      // newClient property is set to true or false.
      onSave: function onSave() {
        var properties = this.clientProperties;
        this.set('saveInProgress', true);
        if (this.newClient) {
          this.createClient(properties);
        } else {
          this.updateClient(properties);
        }
      },
      // onDelete performs a client deletion.
      onDelete: function onDelete() {
        this.deleteClient();
      },
      /**
       * onUploadStart provides the callback for when a profile picture has
       * started being uploaded.
       */
      onUploadStart: function onUploadStart() {
        this.set('uploading', true);
      },
      /**
       * onUpload provides the callback for handling what to do after a profile
       * picture has been uploaded.
       *
       * @param file
       * @param upload
       */
      onUpload: function onUpload(file, upload) {
        var _this10 = this;
        var _getKeyAndSourceFromU = (0, _uppy.getKeyAndSourceFromUpload)(upload),
          key = _getKeyAndSourceFromU.key,
          mediaSource = _getKeyAndSourceFromU.mediaSource;
        var currentUri = this.logoUri;
        this.set('logoUri', '');
        this.store.createRecord('media', {
          schoolId: this.get('currentUser.school'),
          personId: this.get('currentUser.person'),
          sharedWith: _mediaSharingLevel.default.PUBLIC,
          mediaType: _mediaType.default.IMAGE,
          mediaSource: mediaSource,
          mimeType: file.type,
          fileName: file.name,
          storageKey: key,
          fileSize: file.size
        }).save().then(function (media) {
          // TODO: We (HERO STAFF) are the only ones that care about this.
          //  We do not store an id on the client model so we can't do the
          //  blobby serving. This will work until we nuke the unauthenticated media routes.
          _this10.set('logoUri', "".concat(_emberGetConfig.default.APP.apiHost, "/media/").concat(_emberGetConfig.default.APP.apiPath, "/media/").concat(media.id, "/").concat(encodeURIComponent(media.fileName)));
          _this10.toaster.success('Uploaded OAuth Client Logo!');
        }).catch(function () {
          // Revert unsaved changes, i.e. the profileMediaId
          _this10.set('logoUri', currentUri);
          _this10.toaster.error('Error saving OAuth Client Logo!');
        }).finally(function () {
          _this10.set('uploading', false);
        });
      },
      /**
       * onUploadError provides the callback to deal with upload errors.
       */
      onUploadError: function onUploadError() {
        this.toaster.error('Error saving OAuth Client Logo!');
      }
    },
    // Life Cycle hooks
    didReceiveAttrs: function didReceiveAttrs() {
      if (Ember.isEmpty(this.client)) {
        // If no client is presented, set everything to expected default values.
        this.resetForm();
      }
    }
  });
  _exports.default = _default;
});