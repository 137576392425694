define("linced-admin/controllers/school-options/headings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    currentUser: Ember.inject.service('current-user'),
    store: Ember.inject.service('store'),
    paperToaster: Ember.inject.service(),
    headingName: '',
    headingKey: '',
    headingSequence: 0,
    headingId: '',
    headingDialogOpen: false,
    saveInProgress: false,
    deleteHeadingDialogOpen: false,
    deleteInProgress: false,
    errorMessage: '',
    // toggleDialog opens the heading dialog.
    toggleDialog: function toggleDialog() {
      this.set('errorMessage', '');
      this.toggleProperty('headingDialogOpen');
      if (!this.headingDialogOpen) {
        this.resetForm();
      }
    },
    actions: {
      toggleHeadingDialog: function toggleHeadingDialog() {
        this.toggleDialog();
      },
      editHeading: function editHeading(id) {
        var _this = this;
        this.store.findRecord('schoolOptions/heading', id).then(function (heading) {
          _this.set('headingName', heading.get('name'));
          _this.set('headingKey', heading.get('key'));
          _this.set('headingSequence', heading.get('sequence'));
        });
        this.set('headingId', id);
        this.toggleDialog();
      },
      saveHeading: function saveHeading() {
        var _this2 = this;
        this.set('saveInProgress', true);
        this.set('errorMessage', '');
        var promise;
        var action = 'create';
        if (Ember.isEmpty(this.headingId)) {
          promise = new Ember.RSVP.Promise(function (resolve) {
            var record = _this2.store.createRecord('schoolOptions/heading', {
              name: _this2.headingName,
              key: _this2.headingKey,
              sequence: _this2.headingSequence,
              groupId: _this2.get('model.group')
            });
            resolve(record);
          });
        } else {
          action = 'update';
          promise = new Ember.RSVP.Promise(function (resolve) {
            var id = _this2.headingId;
            _this2.store.findRecord('schoolOptions/heading', id).then(function (heading) {
              heading.set('name', _this2.headingName);
              heading.set('key', _this2.headingKey);
              heading.set('sequence', _this2.headingSequence);
              resolve(heading);
            });
          });
        }
        promise.then(function (record) {
          return record.save().then(function () {
            _this2.set('headingDialogOpen', false);
            _this2.resetForm();
          }).catch(function (err) {
            _this2.handleError(err, action);
            record.rollbackAttributes();
          }).then(function () {
            _this2.set('saveInProgress', false);
            if (action == 'create') {
              _this2.send('refreshModel');
            }
          });
        });
      },
      deleteHeading: function deleteHeading(id) {
        var _this3 = this;
        this.set('errorMessage', '');
        this.store.findRecord('schoolOptions/heading', id).then(function (heading) {
          _this3.set('headingName', heading.get('name'));
        });
        this.set('headingId', id);
        this.set('deleteHeadingDialogOpen', true);
      },
      confirmDelete: function confirmDelete(confirmed) {
        var _this4 = this;
        return new Ember.RSVP.Promise(function (resolve, reject) {
          if (confirmed) {
            // Deletion confirmed; actually delete the record.
            _this4.set('deleteInProgress', true);

            // Grab record from local store - no GET request, as that causes weirdness.
            // We only need the ID to issue the DELETE anyway, which can't change, so no need to use findRecord.
            var heading = _this4.store.peekRecord('schoolOptions/heading', _this4.headingId);

            // Delete record (locally + pushed to server).
            return heading.destroyRecord().then(resolve).catch(function (err) {
              // Remove 'deleted' flag so that entity can be interacted with again.
              heading.rollbackAttributes();
              reject(err);
            });
          } else {
            resolve();
          }
        }).then(function () {
          // Tidy up / close dialog.
          _this4.set('deleteHeadingDialogOpen', false);
          _this4.set('headingId', '');
          _this4.set('headingName', '');
        }).catch(function (err) {
          _this4.handleError(err, 'delete');
        }).then(function () {
          _this4.set('deleteInProgress', false);
        });
      }
    },
    /*
    init() {
      this.set('schoolId', this.get('currentUser.user.schoolId'));
    },*/
    handleError: function handleError(err) {
      if (!Ember.isEmpty(err.errors.msg)) {
        this.set('errorMessage', err.errors.msg);
      } else {
        this.set('errorMessage', err.message);
      }
    },
    setErrorMessage: function setErrorMessage(code, action) {
      var message = 'An unknown error occurred.  Please try again.';
      var codes = {
        '400': 'Sorry, your request was invalid.  Please check all details below, and try again.',
        '403': {
          create: 'Sorry, you are not authorised to create a new user.  Please check that you are logged in as the correct user, and try again.',
          update: 'Sorry, you are not authorised to edit this user.  Please check that you are logged in as the correct user, and try again.',
          delete: 'Sorry, you are not authorised to delete this user.  Please check that you are logged in as the correct user, and try again.'
        },
        '409': 'A user already exists with that username.  Please double-check the details, or find and update the existing user.'
      };
      if (!Ember.isEmpty(codes[code][action])) {
        message = codes[code][action];
      } else if (!Ember.isEmpty(codes[code])) {
        message = codes[code];
      }
      this.set('errorMessage', message);
    },
    resetForm: function resetForm() {
      this.set('headingId', '');
      this.set('headingName', '');
      this.set('headingKey', '');
      this.set('headingSequence', '');
    }
  });
  _exports.default = _default;
});