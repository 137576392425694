define("linced-admin/controllers/people/schema/template/group", ["exports", "@linc-technologies/linced-common/enums/data-type", "@linc-technologies/linced-common/enums/input-type", "@linc-technologies/linced-common/enums/person-type", "@linc-technologies/linced-common/enums/roles/field-access"], function (_exports, _dataType, _inputType, _personType, _fieldAccess) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    store: Ember.inject.service(),
    InputType: _inputType.default,
    FieldAccessRoles: _fieldAccess.default,
    DataType: _dataType.default,
    // inputTypes is a limited list - there are some types used for School Options which we don't want here.
    inputTypes: Ember.A([{
      value: 1,
      label: 'Text'
    }, {
      value: 2,
      label: 'Textarea'
    }, {
      value: 3,
      label: 'Radio boxes'
    }, {
      value: 4,
      label: 'Checkbox(es)'
    }, {
      value: 5,
      label: 'Date'
    }, {
      value: 6,
      label: 'Email address'
    }, {
      value: 7,
      label: 'Number'
    }, {
      value: 8,
      label: 'URL'
    }, {
      value: 9,
      label: 'Select'
    }, {
      value: 10,
      label: 'Multiple select (chips)'
    }, {
      value: 11,
      label: 'Switch'
    }, {
      value: 14,
      label: 'Jurisdictional ID'
    }]),
    numericTypes: Ember.A([{
      value: 3,
      label: 'Integer (whole numbers)'
    }, {
      value: 4,
      label: 'Float (decimal numbers)'
    }]),
    selectDataTypes: Ember.A([{
      value: 1,
      label: 'String'
    }, {
      value: 3,
      label: 'Integer (whole numbers)'
    }]),
    /*{{!-- enum InputType {
      InputType_Unspecified = 0;
      InputType_Text = 1;
        InputType_Date = 5;
        InputType_Email = 6;
        InputType_Number = 7;
        InputType_Url = 8;
      InputType_Textfield = 2;
      InputType_Radio = 3;
      InputType_Checkbox = 4;
      InputType_Select = 9;
    }
    // DataType is based on basic Go types
    enum DataType {
      DataType_Unspecified = 0;
      DataType_String = 1;
      // List is provided for things like tags, where they can be dynamically added, reordered and deleted.
      DataType_List = 2;
      DataType_Int = 3;
      DataType_Float = 4;
      DataType_Bool = 5;
    } --}}*/

    fieldId: '',
    fieldLabel: '',
    numericType: null,
    selectDataType: null,
    // TODO: Don't use observers if possible.
    // eslint-disable-next-line
    _updateFieldType: Ember.observer('fieldType.value', 'selectDataType', function () {
      var type = this.get('fieldType.value');
      if (!Ember.isEmpty(type) && !Ember.isEmpty(this.field)) {
        this.field.set('inputType', type);
        if (type !== _inputType.default.NUMBER) {
          var dataType = 0;
          switch (type) {
            case _inputType.default.CHECKBOX:
            case _inputType.default.MULTI_SELECT:
              dataType = _dataType.default.LIST;
              break;
            case _inputType.default.SWITCH:
              dataType = _dataType.default.BOOLEAN;
              break;
            case _inputType.default.SELECT:
              dataType = this.selectDataType || _dataType.default.STRING;
              break;
            default:
              dataType = _dataType.default.STRING;
              break;
          }
          this.field.set('dataType', dataType);
        }
      }
      if (type === _inputType.default.NUMBER) {
        if (!Ember.isEmpty(this.field)) {
          switch (this.field.dataType) {
            case _dataType.default.INTEGER:
            case _dataType.default.FLOAT:
              this.set('numericType', this.getNumericType(this.field.dataType));
              break;
            default:
              this.set('numericType', this.getNumericType(_dataType.default.INTEGER));
              break;
          }
        } else {
          this.set('numericType', this.getNumericType(_dataType.default.INTEGER));
        }
      }
    }),
    // TODO: Don't use observers if possible.
    // eslint-disable-next-line
    _updateNumericType: Ember.observer('fieldType.value', 'numericType.value', function () {
      if (this.get('fieldType.value') === _inputType.default.NUMBER) {
        var type = this.get('numericType.value');
        if (!Ember.isEmpty(type) && !Ember.isEmpty(this.field)) {
          this.field.set('dataType', type);
        }
      }
    }),
    fieldTypeHasOptions: Ember.computed('fieldType', function () {
      var fieldType = this.get('fieldType.value');
      switch (fieldType) {
        case 3:
        case 4:
        case 9:
        case 10:
          return true;
      }
      return false;
    }),
    newOption: null,
    fieldDialogOpen: false,
    saveInProgress: false,
    deleteFieldDialogOpen: false,
    deleteInProgress: false,
    errorMessage: '',
    isSortable: false,
    init: function init() {
      this._super.apply(this, arguments);
      this.resetForm();
    },
    filteredRoleOptions: Ember.computed('model.group.personType', function () {
      switch (this.model.group.personType) {
        case _personType.default.LEARNER:
          return [_fieldAccess.default.STUDENT_MEDICAL, _fieldAccess.default.STUDENT_SENSITIVE];
        case _personType.default.STAFF:
          return [_fieldAccess.default.STAFF_MEDICAL, _fieldAccess.default.STAFF_SENSITIVE];
        case _personType.default.CAREGIVER:
          return [_fieldAccess.default.CAREGIVER_MEDICAL, _fieldAccess.default.CAREGIVER_SENSITIVE];
      }
      return [];
    }),
    // toggleDialog opens the field dialog.
    toggleDialog: function toggleDialog() {
      this.set('errorMessage', '');
      this.toggleProperty('fieldDialogOpen');
      if (!this.fieldDialogOpen) {
        this.field.rollbackAttributes();
        this.resetForm();
      }
    },
    actions: {
      toggleFieldDialog: function toggleFieldDialog() {
        this.toggleDialog();
      },
      editField: function editField(id) {
        var _this = this;
        this.store.findRecord('people-schema/field', id).then(function (field) {
          var props = {
            field: field
          };
          var inputType = field.get('inputType');
          props.fieldType = _this.getInputType(inputType);
          if (inputType === _inputType.default.NUMBER) {
            props.numericType = _this.getNumericType(field.get('dataType'));
          }
          if (inputType === _inputType.default.SELECT) {
            props.selectDataType = _this.selectDataTypes.findBy('value', field.get('dataType'));
          }
          _this.setProperties(props);
        });
        this.set('fieldId', id);
        this.toggleDialog();
      },
      saveField: function saveField() {
        var _this2 = this;
        this.set('saveInProgress', true);
        this.set('errorMessage', '');
        var action = 'create';
        if (Ember.isEmpty(this.fieldId)) {
          this.field.set('templateGroupId', this.get('model.group'));
          this.field.set('templateSetId', this.get('model.templateSet'));
          this.field.set('sequence', this.get('model.fields.length') + 1);
        } else {
          action = 'update';
        }
        switch (this.get('fieldType.value')) {
          case _inputType.default.NUMBER:
            this.field.set('dataType', this.get('numericType.value'));
            break;
          case _inputType.default.SELECT:
            this.field.set('dataType', this.selectDataType.value);
            break;
        }
        return this.field.save().then(function () {
          _this2.set('fieldDialogOpen', false);
          _this2.resetForm();
        }).catch(function (err) {
          _this2.handleError(err, action);
          _this2.field.rollbackAttributes();
        }).then(function () {
          _this2.set('saveInProgress', false);
          _this2.get('model.fields').update();
        });
      },
      deleteField: function deleteField(id) {
        var _this3 = this;
        this.set('errorMessage', '');
        this.store.findRecord('people-schema/field', id).then(function (field) {
          _this3.set('fieldLabel', field.get('label'));
        });
        this.set('fieldId', id);
        this.set('deleteFieldDialogOpen', true);
      },
      confirmDelete: function confirmDelete(confirmed) {
        var _this4 = this;
        return new Ember.RSVP.Promise(function (resolve, reject) {
          if (confirmed) {
            // Deletion confirmed; actually delete the record.
            _this4.set('deleteInProgress', true);

            // Grab record from local store - no GET request, as that causes weirdness.
            // We only need the ID to issue the DELETE anyway, which can't change, so no need to use findRecord.
            var field = _this4.store.peekRecord('people-schema/field', _this4.fieldId);

            // Delete record (locally + pushed to server).
            return field.destroyRecord().then(resolve).catch(function (err) {
              // Remove 'deleted' flag so that entity can be interacted with again.
              field.rollbackAttributes();
              reject(err);
            });
          } else {
            resolve();
          }
        }).then(function () {
          // Tidy up / close dialog.
          _this4.set('deleteFieldDialogOpen', false);
          _this4.resetForm();
        }).catch(function (err) {
          _this4.handleError(err, 'delete');
        }).then(function () {
          _this4.set('deleteInProgress', false);
        });
      },
      addOption: function addOption() {
        if (Ember.isEmpty(this.get('field.options'))) {
          this.field.set('options', Ember.A());
        }
        if (this.selectDataType.value === _dataType.default.INTEGER) {
          this.field.options.pushObject({
            valueInt: parseInt(this.newOptionValue, 10),
            label: this.newOptionLabel
          });
        } else {
          this.field.options.pushObject({
            value: this.newOptionValue,
            label: this.newOptionLabel
          });
        }
        this.set('newOptionValue', '');
        this.set('newOptionLabel', '');
      },
      removeOption: function removeOption(option) {
        this.field.options.removeObject(option);
      },
      maybeMutBoth: function maybeMutBoth(a, b, context, newValue) {
        if (context.get(a) === context.get(b)) {
          context.set(b, newValue);
        }
        context.set(a, newValue);
      },
      // drag-drop sorting
      sortDragEnd: function sortDragEnd(_ref) {
        var sourceList = _ref.sourceList,
          sourceIndex = _ref.sourceIndex,
          targetList = _ref.targetList,
          targetIndex = _ref.targetIndex;
        if (sourceList === targetList && sourceIndex === targetIndex) {
          return;
        }
        var item = sourceList.objectAt(sourceIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, item);
        targetList.forEach(function (entity, index) {
          entity.set('sequence', index + 1);
          entity.save();
        });
      },
      optionSortDragEnd: function optionSortDragEnd(_ref2) {
        var sourceList = _ref2.sourceList,
          sourceIndex = _ref2.sourceIndex,
          targetList = _ref2.targetList,
          targetIndex = _ref2.targetIndex;
        if (sourceList === targetList && sourceIndex === targetIndex) {
          return;
        }
        var item = sourceList.objectAt(sourceIndex);
        var replacementItem = {
          label: item.label,
          value: item.value
        };
        sourceList.removeObject(item);
        targetList.insertAt(targetIndex, replacementItem);
      }
    },
    /*
    init() {
      this.set('schoolId', this.get('currentUser.user.schoolId'));
    },*/
    getInputType: function getInputType(inputType) {
      var types = this.inputTypes;
      return types.findBy('value', inputType);
    },
    getNumericType: function getNumericType(dataType) {
      var types = this.numericTypes;
      return types.findBy('value', dataType);
    },
    handleError: function handleError(err) {
      if (!Ember.isEmpty(err.errors.msg)) {
        this.set('errorMessage', err.errors.msg);
      } else {
        this.set('errorMessage', err.message);
      }
    },
    setErrorMessage: function setErrorMessage(code, action) {
      var message = 'An unknown error occurred.  Please try again.';
      var codes = {
        '400': 'Sorry, your request was invalid.  Please check all details below, and try again.',
        '403': {
          create: 'Sorry, you are not authorised to create a new user.  Please check that you are logged in as the correct user, and try again.',
          update: 'Sorry, you are not authorised to edit this user.  Please check that you are logged in as the correct user, and try again.',
          delete: 'Sorry, you are not authorised to delete this user.  Please check that you are logged in as the correct user, and try again.'
        },
        '409': 'A user already exists with that username.  Please double-check the details, or find and update the existing user.'
      };
      if (!Ember.isEmpty(codes[code][action])) {
        message = codes[code][action];
      } else if (!Ember.isEmpty(codes[code])) {
        message = codes[code];
      }
      this.set('errorMessage', message);
    },
    resetForm: function resetForm() {
      var field = this.field;
      if (field && field.get('currentState.isDirty')) {
        field.rollbackAttributes();
      }
      this.set('field', this.store.createRecord('people-schema/field', {}));
      this.set('fieldId', '');
      this.set('fieldType', this.get('inputTypes.firstObject'));
      this.set('numericType', this.get('numericTypes.firstObject'));
      this.set('selectDataType', this.get('selectDataTypes.firstObject'));
      this.set('newOptionLabel', '');
      this.set('newOptionValue', '');
    }
  });
  _exports.default = _default;
});