define("linced-admin/routes/school-options/fields", ["exports", "linced-admin/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    store: Ember.inject.service(),
    model: function model(params) {
      var store = this.store;
      var group = store.findRecord('school-options/group', params.group_id);
      var heading = store.findRecord('school-options/heading', params.heading_id);
      return Ember.RSVP.hash({
        group: group,
        heading: heading,
        fields: store.query('school-options/field', {
          optionHeadingId: params.heading_id
        }),
        sets: store.findAll('school-options/set')
      });
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});