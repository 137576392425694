define("linced-admin/controllers/my-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service()
  });
  _exports.default = _default;
});