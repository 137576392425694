define("linced-admin/services/hero-api-bookings-allocations", ["exports", "@linc-technologies/linced-common/services/hero-api-bookings-allocations"], function (_exports, _heroApiBookingsAllocations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _heroApiBookingsAllocations.default;
    }
  });
});