define("linced-admin/controllers/hero-notices/notice", ["exports", "@linc-technologies/linced-common/mixins/edit-post", "@linc-technologies/linced-common/enums/post-type"], function (_exports, _editPost, _postType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend(_editPost.default, {
    router: Ember.inject.service(),
    PostTypes: _postType.default,
    actions: {
      goToNotice: function goToNotice(post) {
        this.router.transitionTo(post.get('url'));
      }
    }
  });
  _exports.default = _default;
});