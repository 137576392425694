define("linced-admin/models/goals/badge-badge", ["exports", "ember-data-model-fragments", "@ember-data/model", "ember-data-model-fragments/attributes"], function (_exports, _emberDataModelFragments, _model, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberDataModelFragments.default.Fragment.extend({
    // Unique IRI for the Badge
    badgeId: (0, _model.attr)('string'),
    // valid JSON-LD representation of the BadgeClass type.
    // In most cases, this will simply be the string BadgeClass.
    // "BadgeClass"
    type: (0, _model.attr)('string'),
    // The name of the achievement.
    name: (0, _model.attr)('string'),
    // A short description of the achievement.
    description: (0, _model.attr)('string'),
    // IRI of an image representing the achievement. May be a Data URI, or URI
    // where the image may be found.
    image: (0, _model.attr)('string'),
    //TODO: this is going to need to be addressed since we will have auth on our image serve routes which will stop third parties from requesting images.
    // Embedded criteria document describing how to earn the achievement.
    criteria: (0, _attributes.fragment)('goals/badge-criteria'),
    // Document describing the individual, entity, or organization that issued
    // the badge.
    issuer: (0, _attributes.fragment)('goals/badge-profile')
  });
  _exports.default = _default;
});