define("linced-admin/initializers/oauth2", ["exports", "@linc-technologies/linced-common/initializers/oauth2"], function (_exports, _oauth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _oauth.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _oauth.initialize;
    }
  });
});