define("linced-admin/serializers/milestones/milestone-set", ["exports", "linced-admin/serializers/milestones/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      return this._super(modelName).replace('milestoneSet', 'milestoneset');
    },
    modelNameFromPayloadKey: function modelNameFromPayloadKey(key) {
      return this._super(key.replace('milestoneset', 'milestoneSet'));
    },
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload) {
      if (!Ember.isArray(payload.milestoneSetTemplates)) {
        // If there are no Milestone Set Templates, Go may omit the empty key.
        payload.milestoneSetTemplates = [];
      }
      return this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});