define("linced-admin/components/single-chip", ["exports", "ember-paper/components/paper-chips"], function (_exports, _paperChips) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _paperChips.default.extend({
    hasValue: Ember.computed('content.length', function () {
      return Ember.isPresent(this.content);
    })
  });
  _exports.default = _default;
});