define("linced-admin/templates/access-denied", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "QbVZbgMa",
    "block": "{\"symbols\":[],\"statements\":[[5,\"log-card\",[],[[\"@icon\",\"@title\",\"@warn\"],[\"warning-2\",\"Access denied\",true]],{\"statements\":[[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    You do not have access to the requested page.\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    Please contact Customer Support if you need to make any changes to the page\\n    you were trying to access.\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "linced-admin/templates/access-denied.hbs"
    }
  });
  _exports.default = _default;
});