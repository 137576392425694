define("linced-admin/controllers/application", ["exports", "@linc-technologies/linced-common/enums/jurisdiction", "@linc-technologies/linced-common/utils/oauth2", "@linc-technologies/linced-common/enums/scopes", "ember-get-config", "linced-admin/enums/enrolment-log-resource"], function (_exports, _jurisdiction, _oauth, _scopes, _emberGetConfig, _enrolmentLogResource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // External Imports
  // Internal Imports
  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service('current-user'),
    offline: Ember.inject.service(),
    permissions: Ember.inject.service(),
    pwa: Ember.inject.service(),
    roles: Ember.inject.service(),
    session: Ember.inject.service('session'),
    EnrolmentLogResource: _enrolmentLogResource.default,
    Jurisdiction: _jurisdiction.default,
    sifEnabled: _emberGetConfig.default.APP.sifEnabled,
    expandedMenuItem: Ember.computed('currentRouteName', function () {
      return this.currentRouteName.split('.')[0];
    }),
    isSuperAdmin: Ember.computed('currentUser.scopes.[]', function () {
      return this.permissions.meet(_scopes.default.LINCED_ADMIN);
    }),
    showManagementMenu: Ember.computed.or('isSuperAdmin', 'roles.hasFullAccessRole', 'roles.hasManageRole'),
    showCommunicationMenu: Ember.computed.or('isSuperAdmin', 'roles.hasFullAccessRole', 'roles.hasCommunicationRole'),
    showSuperMenu: Ember.computed.or('roles.hasAPIActivationAccess', 'isSuperAdmin'),
    actions: {
      invalidateSession: function invalidateSession() {
        this.session.invalidate();
      },
      transitionToLoginRoute: function transitionToLoginRoute() {
        this.signIn();
      },
      toggleExpandedMenuItem: function toggleExpandedMenuItem(value, ev) {
        if (this.expandedMenuItem === value) {
          value = null;
        }
        this.set('expandedMenuItem', value);
        ev.stopPropagation();
      }
    },
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      var checkOffline = window.localStorage ? !window.localStorage.getItem('skipCheckOffline') : true;
      if (checkOffline) {
        window.setInterval(function () {
          return _this.offline.check();
        }, 5000);
        this.offline.offline.on('up', function () {
          return _this.pwa.setOnline(true);
        });
        this.offline.offline.on('down', function () {
          return _this.pwa.setOnline(false);
        });
      }
    },
    // signIn is provided by linc-ed common and sends a user to the sign in page.
    signIn: _oauth.signIn
  });
  _exports.default = _default;
});