define("linced-admin/routes/tags/category", ["exports", "linced-admin/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        category: this.store.findRecord('posts/tagCategory', params.category_id),
        tags: this.store.query('posts/tag', {
          categoryId: params.category_id
        })
      });
    },
    actions: {
      tagCreated: function tagCreated() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});