define("linced-admin/components/change-profile-image", ["exports", "@linc-technologies/linced-common/components/change-profile-image"], function (_exports, _changeProfileImage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _changeProfileImage.default;
    }
  });
});