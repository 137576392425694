define("linced-admin/controllers/school-options/fields", ["exports", "@linc-technologies/linced-common/enums/data-type", "@linc-technologies/linced-common/enums/input-type", "@linc-technologies/linced-common/utils/tinymce"], function (_exports, _dataType, _inputType, _tinymce) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    DataType: _dataType.default,
    InputType: _inputType.default,
    numericTypes: Ember.A([{
      value: 3,
      label: 'Integer (whole numbers)'
    }, {
      value: 4,
      label: 'Float (decimal numbers)'
    }]),
    selectDataTypes: Ember.A([{
      value: 1,
      label: 'String'
    }, {
      value: 3,
      label: 'Integer (whole numbers)'
    }]),
    editorOptions: Object.freeze((0, _tinymce.minimalTinyMCEConfig)()),
    field: null,
    fieldId: '',
    fieldLabel: '',
    fieldKey: '',
    remainingFieldSets: Ember.computed('model.sets.@each.id', 'field.optionSetIds.[]', function () {
      var _this = this;
      return this.get('model.sets').filter(function (source) {
        return !_this.get('field.optionSetIds').any(function (set) {
          return source.id === set.id;
        });
      });
    }),
    fieldType: null,
    numericType: null,
    selectDataType: null,
    // TODO: Don't use observers if possible.
    // eslint-disable-next-line
    _updateFieldType: Ember.observer('fieldType.value', 'selectDataType', function () {
      var type = this.get('fieldType.value');
      if (!Ember.isEmpty(type) && !Ember.isEmpty(this.field)) {
        this.field.set('inputType', type);
        if (type !== _inputType.default.NUMBER) {
          var dataType = 0;
          switch (type) {
            case _inputType.default.CHECKBOX:
            case _inputType.default.MULTI_SELECT:
              dataType = _dataType.default.LIST;
              break;
            case _inputType.default.SWITCH:
              dataType = _dataType.default.BOOLEAN;
              break;
            case _inputType.default.SELECT:
              dataType = this.get('selectDataType.value') || _dataType.default.STRING;
              break;
            default:
              dataType = _dataType.default.STRING;
              break;
          }
          this.field.set('dataType', dataType);
        }
      }
      if (type === _inputType.default.NUMBER) {
        if (!Ember.isEmpty(this.field)) {
          switch (this.field.dataType) {
            case _dataType.default.INTEGER:
            case _dataType.default.FLOAT:
              this.set('numericType', this.getNumericType(this.field.dataType));
              break;
            default:
              this.set('numericType', this.getNumericType(_dataType.default.INTEGER));
              break;
          }
        } else {
          this.set('numericType', this.getNumericType(_dataType.default.INTEGER));
        }
      }
    }),
    // TODO: Don't use observers if possible.
    // eslint-disable-next-line
    _updateNumericType: Ember.observer('fieldType.value', 'numericType.value', function () {
      if (this.get('fieldType.value') === _inputType.default.NUMBER) {
        var type = this.get('numericType.value');
        if (!Ember.isEmpty(type) && !Ember.isEmpty(this.field)) {
          this.field.set('dataType', type);
        }
      }
    }),
    fieldTypeHasOptions: Ember.computed('fieldType.value', function () {
      var fieldType = this.get('fieldType.value');
      switch (fieldType) {
        case _inputType.default.RADIO:
        case _inputType.default.CHECKBOX:
        case _inputType.default.SELECT:
        case _inputType.default.MULTI_SELECT:
        case _inputType.default.SORTABLE_LIST:
          return true;
      }
      return false;
    }),
    fieldTypeHasStaticContent: Ember.computed('fieldType.value', function () {
      var fieldType = this.get('fieldType.value');
      switch (fieldType) {
        case _inputType.default.TITLE:
        case _inputType.default.DESCRIPTION:
          return true;
      }
      return false;
    }),
    newOption: null,
    isSortable: false,
    sortedFields: Ember.computed('model.fields.[]', function () {
      return this.model.fields.sortBy('sequence', 'label');
    }),
    fieldDialogOpen: false,
    saveInProgress: false,
    deleteFieldDialogOpen: false,
    deleteInProgress: false,
    errorMessage: '',
    // toggleDialog opens the field dialog.
    toggleDialog: function toggleDialog() {
      this.set('errorMessage', '');
      this.toggleProperty('fieldDialogOpen');
      if (!this.fieldDialogOpen) {
        this.field.rollbackAttributes();
        this.resetForm();
      }
    },
    actions: {
      toggleFieldDialog: function toggleFieldDialog() {
        this.toggleDialog();
      },
      editField: function editField(id) {
        var _this2 = this;
        this.store.findRecord('schoolOptions/field', id).then(function (field) {
          var props = {
            field: field
          };
          var inputType = field.get('inputType');
          props.fieldType = _this2.getInputType(inputType);
          switch (inputType) {
            case _inputType.default.NUMBER:
              props.numericType = _this2.getNumericType(field.get('dataType'));
              break;
            case _inputType.default.SELECT:
              props.selectDataType = _this2.selectDataTypes.findBy('value', field.get('dataType'));
              break;
            default:
              // Reset selectDataType to STRING to prevent issues when going from
              // a numeric select to a non-select with options (i.e. radio
              // buttons or checkboxes), which can (currently) only by stringy.
              props.selectDataType = _this2.selectDataTypes.findBy('value', _dataType.default.STRING);
              break;
          }
          _this2.setProperties(props);
        });
        this.set('fieldId', id);
        this.toggleDialog();
      },
      saveField: function saveField() {
        var _this3 = this;
        this.set('saveInProgress', true);
        this.set('errorMessage', '');
        var action = 'create';
        if (Ember.isEmpty(this.fieldId)) {
          this.field.set('headingId', this.get('model.heading'));
        } else {
          action = 'update';
        }
        switch (this.get('fieldType.value')) {
          case _inputType.default.NUMBER:
            this.field.set('dataType', this.get('numericType.value'));
            break;
          case _inputType.default.SELECT:
            this.field.set('dataType', this.get('selectDataType.value'));
            break;
        }
        return this.field.save().then(function () {
          _this3.set('fieldDialogOpen', false);
          _this3.resetForm();
        }).catch(function (err) {
          _this3.handleError(err, action);
        }).then(function () {
          _this3.set('saveInProgress', false);
          if (action === 'create') {
            _this3.send('refreshModel');
          }
        });
      },
      deleteField: function deleteField(id) {
        var _this4 = this;
        this.set('errorMessage', '');
        this.store.findRecord('schoolOptions/field', id).then(function (field) {
          _this4.set('fieldLabel', field.get('label'));
        });
        this.set('fieldId', id);
        this.set('deleteFieldDialogOpen', true);
      },
      confirmDelete: function confirmDelete(confirmed) {
        var _this5 = this;
        return new Ember.RSVP.Promise(function (resolve, reject) {
          if (confirmed) {
            // Deletion confirmed; actually delete the record.
            _this5.set('deleteInProgress', true);

            // Grab record from local store - no GET request, as that causes weirdness.
            // We only need the ID to issue the DELETE anyway, which can't change, so no need to use findRecord.
            var field = _this5.store.peekRecord('schoolOptions/field', _this5.fieldId);

            // Delete record (locally + pushed to server).
            return field.destroyRecord().then(resolve).catch(function (err) {
              // Remove 'deleted' flag so that entity can be interacted with again.
              field.rollbackAttributes();
              reject(err);
            });
          } else {
            resolve();
          }
        }).then(function () {
          // Tidy up / close dialog.
          _this5.set('deleteFieldDialogOpen', false);
          _this5.resetForm();
        }).catch(function (err) {
          _this5.handleError(err, 'delete');
        }).then(function () {
          _this5.set('deleteInProgress', false);
        });
      },
      // Field Options
      addOption: function addOption() {
        if (Ember.isEmpty(this.get('field.options'))) {
          this.field.set('options', Ember.A());
        }
        if (this.selectDataType.value === _dataType.default.INTEGER) {
          this.field.options.pushObject({
            valueInt: parseInt(this.get('newOption.value'), 10),
            label: this.get('newOption.label')
          });
        } else {
          this.field.options.pushObject({
            value: this.get('newOption.value'),
            label: this.get('newOption.label')
          });
        }
        this.set('newOption', {
          value: '',
          label: ''
        });
      },
      removeOption: function removeOption(option) {
        this.field.options.removeObject(option);
      },
      maybeMutBoth: function maybeMutBoth(a, b, context, newValue) {
        if (Ember.get(context, a) === Ember.get(context, b)) {
          Ember.set(context, b, newValue);
        }
        Ember.set(context, a, newValue);
      },
      // Option Sets
      removeSet: function removeSet(item) {
        this.get('field.optionSetIds').removeObject(item);
      },
      addSet: function addSet(item) {
        this.get('field.optionSetIds').pushObject(item);
      },
      // drag-drop sorting
      sortDragEnd: function sortDragEnd(_ref) {
        var sourceList = _ref.sourceList,
          sourceIndex = _ref.sourceIndex,
          targetList = _ref.targetList,
          targetIndex = _ref.targetIndex;
        if (sourceList === targetList && sourceIndex === targetIndex) {
          return;
        }
        var item = sourceList.objectAt(sourceIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, item);
        targetList.forEach(function (entity, index) {
          entity.set('sequence', index + 1);
          entity.save();
        });
      },
      optionSortDragEnd: function optionSortDragEnd(_ref2) {
        var sourceList = _ref2.sourceList,
          sourceIndex = _ref2.sourceIndex,
          targetList = _ref2.targetList,
          targetIndex = _ref2.targetIndex;
        if (sourceList === targetList && sourceIndex === targetIndex) {
          return;
        }
        var item = sourceList.objectAt(sourceIndex);
        var replacementItem = {
          label: item.label,
          value: item.value,
          valueInt: item.valueInt
        };
        sourceList.removeObject(item);
        targetList.insertAt(targetIndex, replacementItem);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.resetForm();
    },
    getInputType: function getInputType(inputType) {
      var types = this.InputType.values;
      return types.findBy('value', inputType);
    },
    getNumericType: function getNumericType(dataType) {
      var types = this.numericTypes;
      return types.findBy('value', dataType);
    },
    handleError: function handleError(err) {
      if (!Ember.isEmpty(err.errors.msg)) {
        this.set('errorMessage', err.errors.msg);
      } else {
        this.set('errorMessage', err.message);
      }
    },
    setErrorMessage: function setErrorMessage(code, action) {
      var message = 'An unknown error occurred.  Please try again.';
      var codes = {
        '400': 'Sorry, your request was invalid.  Please check all details below, and try again.',
        '403': {
          create: 'Sorry, you are not authorised to create a new user.  Please check that you are logged in as the correct user, and try again.',
          update: 'Sorry, you are not authorised to edit this user.  Please check that you are logged in as the correct user, and try again.',
          delete: 'Sorry, you are not authorised to delete this user.  Please check that you are logged in as the correct user, and try again.'
        },
        '409': 'A user already exists with that username.  Please double-check the details, or find and update the existing user.'
      };
      if (!Ember.isEmpty(codes[code][action])) {
        message = codes[code][action];
      } else if (!Ember.isEmpty(codes[code])) {
        message = codes[code];
      }
      this.set('errorMessage', message);
    },
    resetForm: function resetForm() {
      var field = this.field;
      if (field && field.get('currentState.isDirty')) {
        field.rollbackAttributes();
      }
      this.set('field', this.store.createRecord('schoolOptions/field', {}));
      this.set('fieldId', '');
      this.set('fieldType', {
        value: 1,
        label: 'Text'
      });
      this.set('newOption', {
        value: '',
        label: ''
      });
      this.set('selectDataType', this.selectDataTypes.firstObject);
    }
  });
  _exports.default = _default;
});