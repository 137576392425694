define("linced-admin/models/enrolments/schema", ["exports", "@ember-data/model", "ember-data-model-fragments/attributes"], function (_exports, _model, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Model Attributes
    // createTime is when the resource was created in seconds from the epoch.
    createTime: (0, _model.attr)('number'),
    // updateTime is the last time the resource was modified in seconds from
    // the epoch.
    updateTime: (0, _model.attr)('number'),
    // templateName is the name of the template.
    templateName: (0, _model.attr)('string'),
    // enrolmentYear is the year for which the enrolment intake is for.
    enrolmentYear: (0, _model.attr)('number'),
    // enrolmentsOpen provides a switch for schools that prefer to be reactive.
    enrolmentsOpen: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    // dateEnrolmentsOpen contains a 'yyyy-mm-dd' date string as to when
    // enrolments will open.
    dateEnrolmentsOpens: (0, _model.attr)('date'),
    // dateEnrolmentsClose contains a 'yyyy-mm-dd' date string as to when
    // enrolments will close.
    dateEnrolmentsClose: (0, _model.attr)('date'),
    // introduction provides an overall welcome to the school's enrolment page.
    introduction: (0, _model.attr)('string'),
    // sections contain a conceptual whole of data needed to enrol a student.
    sections: (0, _attributes.fragmentArray)('enrolments/section'),
    // primaryContacts contains schema metadata relating to primary contacts.
    primaryContacts: (0, _attributes.fragment)('enrolments/section'),
    // emergencyContacts contains schema metadata relating to secondary contacts.
    emergencyContacts: (0, _attributes.fragment)('enrolments/section'),
    // medicalContacts contains schema metadata relating to secondary contacts.
    medicalContacts: (0, _attributes.fragment)('enrolments/section'),
    // medicalConditions contains schema metadata relating to medical conditions.
    medicalConditions: (0, _attributes.fragment)('enrolments/section')
  });
  _exports.default = _default;
});