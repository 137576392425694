define("linced-admin/models/people/post-count-page", ["exports", "linced-admin/models/people/post-count-base"], function (_exports, _postCountBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _postCountBase.default.extend({
    page: Ember.computed('itemId', function () {
      return this.store.peekRecord('posts/page', this.itemId);
    })
  });
  _exports.default = _default;
});