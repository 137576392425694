define("linced-admin/models/cron/log", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // createTime is when the resource was created in seconds from the epoch.
    createTime: (0, _model.attr)('number'),
    // startTime is the time the scheduled task begun in milliseconds from the
    // epoch - as recorded by the sending service for a more accurate time
    // estimate.
    startTime: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    // finishTime is the time the scheduled task finished processing in
    // milliseconds from the epoch - as recorded by the sending service for a
    // more accurate time estimate.
    finishTime: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    // taskId references the specific task this log is being created for.
    taskId: (0, _model.belongsTo)('cron/task'),
    task: Ember.computed.alias('taskId'),
    // succeeded provides a simple switch of whether the task overall task ran
    // successfully.
    succeeded: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    // level provides the logging level of any returned messages from the
    // processing of the scheduled task.
    level: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    // message may contain a message returned from the service processing the
    // scheduled run task.
    message: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    // processing provides a boolean for tracking whether a job is in progress,
    // this will generally only be true if cron has only just sent an event,
    // waiting on the service to return a completion log. This enables tracking
    // of dead jobs.
    processing: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    // Methods
    /**
     * took returns the amount of time it took the service to process the task in
     * milliseconds.
     */
    took: Ember.computed('startTime', 'finishTime', function () {
      return this.finishTime - this.startTime;
    }),
    /**
     * tookToString returns the time a task took in a human understandable string.
     */
    tookToString: Ember.computed('took', function () {
      if (this.processing) {
        return 'currently processing...';
      }
      var taken = this.took;
      switch (true) {
        case taken / 1000 >= 1:
          return "".concat(taken / 1000, " s");
        default:
          return "".concat(taken, " ms");
      }
    })
  });
  _exports.default = _default;
});