define("linced-admin/templates/people/information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Cm9+kIYd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\",true],[8],[0,\"Person information\"],[9],[0,\"\\n\"],[5,\"person-form\",[],[[\"@fieldGroups\",\"@person\"],[[24,[\"model\",\"groups\"]],[24,[\"model\",\"person\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "linced-admin/templates/people/information.hbs"
    }
  });
  _exports.default = _default;
});