define("linced-admin/components/entity-publisher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    tagName: '',
    iconSize: 24,
    displayWarning: false,
    dialogOpen: false,
    warningMessage: '',
    entity: null,
    // Functions
    afterToggle: function afterToggle() {},
    actions: {
      decideAction: function decideAction() {
        if (this.displayWarning && this.entity.isPublished) {
          this.set('dialogOpen', true);
          return;
        }
        this.send('togglePublish', this.entity);
      },
      // Publish/unpublish entities
      togglePublish: function togglePublish(entity) {
        var _this = this;
        entity.toggleProperty('isPublished');
        entity.save().then(function (entity) {
          _this.afterToggle(entity);
          if (_this.displayWarning) {
            _this.set('dialogOpen', false);
          }
        });
      }
    }
  });
  _exports.default = _default;
});