define("linced-admin/routes/people/schema/template/view", ["exports", "linced-admin/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    model: function model() {
      var templateSet = this.modelFor('people.schema.template');
      return Ember.RSVP.hash({
        templateSet: templateSet,
        groups: this.store.query('peopleSchema/group', {
          setId: templateSet.id
        }),
        fields: this.store.query('peopleSchema/field', {
          setId: templateSet.id
        })
      });
    }
  });
  _exports.default = _default;
});