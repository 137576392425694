define("linced-admin/helpers/md5", ["exports", "ember-md5/helpers/md5"], function (_exports, _md) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _md.default;
    }
  });
});