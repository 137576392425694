define("linced-admin/controllers/auth/fusionauth", ["exports", "linced-admin/enums/auth-sync-status", "linced-admin/enums/auth-sync-type"], function (_exports, _authSyncStatus, _authSyncType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Attrs
    AuthSyncType: _authSyncType.default,
    // Services
    currentUser: Ember.inject.service(),
    toaster: Ember.inject.service(),
    // State
    syncing: false,
    syncComplete: false,
    syncErrors: null,
    syncStatus: false,
    syncType: '',
    pollTimer: false,
    count: 0,
    created: 0,
    updated: 0,
    failed: 0,
    createdPercent: Ember.computed('count', 'created', function () {
      return this.created / this.count * 100;
    }),
    updatedPercent: Ember.computed('count', 'updated', function () {
      return this.updated / this.count * 100;
    }),
    failedPercent: Ember.computed('count', 'failed', function () {
      return this.failed / this.count * 100;
    }),
    // reset provides a method to reset the controller to an expected state when
    // wanting to perform another sync.
    reset: function reset() {
      this.setProperties({
        count: 0,
        created: 0,
        updated: 0,
        failed: 0,
        syncing: false,
        syncComplete: false,
        syncErrors: null,
        syncType: ''
      });
    },
    // Methods
    updateStatus: function updateStatus() {
      var _this = this;
      if (Ember.isEmpty(this.syncStatus)) {
        return;
      }
      this.syncStatus.reload().then(function (status) {
        _this.setProperties({
          count: status.count,
          created: status.created,
          updated: status.updated,
          failed: status.failed,
          syncErrors: status.syncErrors
        });
        switch (status.status) {
          case _authSyncStatus.default.PROCESSING:
            // Still processing - queue another update.
            _this.set('pollTimer', Ember.run.debounce(_this, 'updateStatus', 30000, false));
            break;
          case _authSyncStatus.default.READY:
            // Finished processing - perform clean up.
            Ember.run.cancel(_this.pollTimer);
            _this.set('syncComplete', true);
            break;
          case _authSyncStatus.default.FAILED:
            // OhNoes... 💩 hit the fan...
            Ember.run.cancel(_this.pollTimer);
            _this.set('syncComplete', true);

            // ensure we defend against the base case - nothing processed
            if (!_this.created && !_this.updated && !_this.failed) {
              _this.set('failed', status.count);
            }
            break;
        }
      });
    },
    // Actions
    actions: {
      syncFusionAuth: function syncFusionAuth(type) {
        var _this2 = this;
        this.reset();
        this.setProperties({
          syncing: true,
          syncType: type
        });
        if (!Ember.isEmpty(this.pollTimer)) {
          // Cancel any pending status poll.
          Ember.run.cancel(this.pollTimer);
        }
        var newStatusRecord = {
          requesterId: this.get('currentUser.person'),
          type: type
        };
        this.store.createRecord('auth/status', newStatusRecord).save().then(function (syncStatus) {
          _this2.toaster.success('FusionAuth Sync Started!');
          var pollTimer = Ember.run.debounce(_this2, 'updateStatus', 10000, false);
          _this2.setProperties({
            syncStatus: syncStatus,
            pollTimer: pollTimer
          });
        }).catch(function () {
          _this2.toaster.error('FusionAuth Sync Failed!');
          _this2.set('syncing', false);
          Ember.run.cancel(_this2.pollTimer);
        });
      },
      closeSyncingDialog: function closeSyncingDialog() {
        this.set('syncing', false);
      }
    }
  });
  _exports.default = _default;
});