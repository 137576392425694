define("linced-admin/controllers/autogroups", ["exports", "@linc-technologies/linced-common/enums/group-auto-groups", "ember-get-config"], function (_exports, _groupAutoGroups, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // External imports
  var _default = Ember.Controller.extend({
    fetch: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    toaster: Ember.inject.service(),
    // Attrs
    AutoGroupTypes: _groupAutoGroups.AutoGroupTypes,
    JurisdictionalParams: _groupAutoGroups.JurisdictionalParams,
    CalculatedParams: _groupAutoGroups.CalculatedParams,
    AttendanceParams: _groupAutoGroups.AttendanceParams,
    // Props
    group: null,
    // State
    groupDialogOpen: false,
    saveInProgress: false,
    deleteGroupDialogOpen: false,
    deleteInProgress: false,
    errorMessage: '',
    applyDialogOpen: false,
    applyStep: 0,
    applyTemplates: Ember.A(),
    applySchools: Ember.A(),
    applyConfirmed: false,
    applyInProgress: false,
    selectedSchoolGroup: null,
    // Usage sets attributes
    usageSetDialogOpen: false,
    selectedUsageSets: Ember.A(),
    initialUsageSets: Ember.A(),
    remainingUsageSetsFilter: Ember.computed.setDiff('model.usageSets', 'selectedUsageSetsFilter'),
    selectedUsageSetsFilter: Ember.A(),
    filteredGroups: Ember.computed('model.groups.[]', 'selectedUsageSetsFilter.[]', function () {
      var _this = this;
      return this.get('model.groups').filter(function (group) {
        if (!Ember.isEmpty(_this.selectedUsageSetsFilter)) {
          return _this.selectedUsageSetsFilter.some(function (usageSet) {
            return group.get('usageSetIds').includes(usageSet);
          });
        }
        return true;
      }).sortBy('name');
    }),
    groupings: Ember.computed('filteredGroups.@each.grouping', function () {
      return this.filteredGroups.sortBy('grouping').mapBy('grouping').uniq();
    }),
    actions: {
      openApplyDialog: function openApplyDialog() {
        this.setProperties({
          applyTemplates: Ember.A(),
          applySchools: Ember.A(),
          applyStep: 0,
          applyConfirmed: false,
          applyInProgress: false,
          applyDialogOpen: true,
          selectedSchoolGroup: null
        });
      },
      toggleApplyTemplate: function toggleApplyTemplate(id) {
        if (this.applyTemplates.includes(id)) {
          this.applyTemplates.removeObject(id);
        } else {
          this.applyTemplates.pushObject(id);
        }
      },
      toggleApplySchool: function toggleApplySchool(id) {
        if (this.applySchools.includes(id)) {
          this.applySchools.removeObject(id);
        } else {
          this.applySchools.pushObject(id);
        }
      },
      selectSchoolGroup: function selectSchoolGroup() {
        this.applySchools.pushObjects(this.selectedSchoolGroup.schools.mapBy('id'));
        this.set('applySchools', this.applySchools.uniq());
      },
      deselectSchoolGroup: function deselectSchoolGroup() {
        this.applySchools.removeObjects(this.selectedSchoolGroup.schools.mapBy('id'));
      },
      applySelected: function applySelected() {
        var _this2 = this;
        this.setProperties({
          applyConfirmed: true,
          applyInProgress: true
        });
        var chain = this.applyTemplates.reduce(function (templatePromise, templateId) {
          return templatePromise.then(function () {
            var applyUri = "".concat(_emberGetConfig.default.APP.apiHost, "/groups/").concat(_emberGetConfig.default.APP.apiPath, "/templates/groups/").concat(templateId, "/:apply");
            return _this2.applySchools.reduce(function (schoolPromise, schoolId) {
              var options = {
                data: JSON.stringify({
                  schoolId: schoolId
                })
              };
              return schoolPromise.then(function () {
                return _this2.fetch.post(applyUri, options);
              });
            }, Ember.RSVP.resolve());
          });
        }, Ember.RSVP.resolve());
        chain.then(function () {
          _this2.set('applyInProgress', false);
        });
      },
      closeApplyDialog: function closeApplyDialog() {
        this.set('applyDialogOpen', false);
      },
      toggleGroupDialog: function toggleGroupDialog() {
        this.set('errorMessage', '');
        this.toggleProperty('groupDialogOpen');
        if (!this.groupDialogOpen) {
          this.resetForm();
        }
      },
      newGroup: function newGroup() {
        this.set('group', this.store.createRecord('group', {
          type: 6 // GroupType.AUTO
        }));
        this.group.set('params', this.store.createFragment('autogroup-params'));
        this.set('groupDialogOpen', true);
      },
      editGroup: function editGroup(group) {
        var _this3 = this;
        this.store.findRecord('group', group.id).then(function (record) {
          _this3.set('group', record);
          _this3.set('groupDialogOpen', true);
        });
      },
      setAutoGroupType: function setAutoGroupType(type) {
        this.group.set('autoGroupType', type.value);
        // Reset params
        this.group.set('params', this.store.createFragment('autogroup-params'));
        if (type.value === 2) {
          // Jurisdictional - set jurisdiction to NZ | TODO: change this when we expand to other jurisdictions
          this.group.set('params.jurisdiction', 'NZ');
        }
      },
      setTypeParam: function setTypeParam(type) {
        this.group.set('params.type', type.value);
      },
      saveGroup: function saveGroup() {
        var _this4 = this;
        this.set('saveInProgress', true);
        this.set('errorMessage', '');
        var action = 'create';
        if (!this.group.get('isNew')) {
          action = 'update';
        }
        this.group.save().then(function () {
          _this4.set('groupDialogOpen', false);
          _this4.resetForm();
        }).catch(function (err) {
          _this4.handleError(err, action);
        }).then(function () {
          _this4.set('saveInProgress', false);
        });
      },
      deleteGroup: function deleteGroup(group) {
        var _this5 = this;
        this.set('errorMessage', '');
        this.store.findRecord('group', group.id).then(function (record) {
          _this5.set('group', record);
        });
        this.set('deleteGroupDialogOpen', true);
      },
      confirmDelete: function confirmDelete(confirmed) {
        var _this6 = this;
        return new Ember.RSVP.Promise(function (resolve, reject) {
          if (confirmed) {
            // Deletion confirmed; actually delete the record.
            _this6.set('deleteInProgress', true);
            _this6.group.destroyRecord().then(resolve).catch(function (err) {
              // Remove 'deleted' flag so that entity can be interacted with again.
              _this6.group.rollbackAttributes();
              reject(err);
            });
          } else {
            resolve();
          }
        }).then(function () {
          // Update the usage sets relationships
          return _this6.group.get('usageSetIds').then(function (usageSetIds) {
            var promises = [];
            usageSetIds.forEach(function (usageSetId) {
              promises.push(usageSetId.save());
            });
            return Promise.all(promises);
          }).then(function () {
            // Tidy up / close dialog.
            _this6.setProperties({
              deleteGroupDialogOpen: false,
              currentPage: null
            });
            _this6.resetForm();
          });
        }).catch(function (err) {
          _this6.handleError(err, 'delete');
        }).then(function () {
          _this6.set('deleteInProgress', false);
        });
      },
      openUsageSetDialog: function openUsageSetDialog(group) {
        var _this7 = this;
        group.get('usageSetIds').then(function (usageSetsArray) {
          _this7.setProperties({
            usageSetDialogOpen: true,
            group: group,
            selectedUsageSets: usageSetsArray.slice(),
            initialUsageSets: usageSetsArray
          });
        }).catch(function () {
          _this7.toaster.error("Error trying to edit the autogroup's usage sets");
        });
      },
      closeUsageSetDialog: function closeUsageSetDialog() {
        this.group.set('usageSetIds', this.initialUsageSets);
        this.setProperties({
          usageSetDialogOpen: false,
          selectedUsageSets: Ember.A(),
          initialUsageSets: Ember.A(),
          group: null
        });
      },
      addUsageSet: function addUsageSet(usageSet, checked) {
        if (checked) {
          this.selectedUsageSets.pushObject(usageSet);
        } else {
          this.selectedUsageSets.removeObject(usageSet);
        }
      },
      saveGroupAndUsageSet: function saveGroupAndUsageSet() {
        var _this8 = this;
        this.set('saveInProgress', true);
        var promises = [];
        var changedUsageSets = Ember.A();

        // Update the usage sets
        var removedUsageSets = this.initialUsageSets.filter(function (usageSet) {
          return !_this8.selectedUsageSets.includes(usageSet);
        });
        var addedUsageSets = this.selectedUsageSets.filter(function (usageSet) {
          return !_this8.initialUsageSets.includes(usageSet);
        });
        changedUsageSets = changedUsageSets.concat(removedUsageSets, addedUsageSets);
        if (!Ember.isEmpty(changedUsageSets)) {
          // Usage sets a page has been newly added to/removed from
          changedUsageSets.forEach(function (changedUsageSet) {
            promises.push(changedUsageSet.save());
          });
        }
        // Save the page template at the end - this is done so the comparison is most accurate
        // as this.initialUsageSets is a reference to usageSetIds
        this.group.set('usageSetIds', this.selectedUsageSets);
        promises.push(this.group.save());
        return Promise.all(promises).then(function () {
          // After saving the autogroup and all involved usage sets
          _this8.toaster.success("Successfully saved the autogroup and usage sets");
          _this8.group.reload();
          _this8.setProperties({
            usageSetDialogOpen: false,
            group: null,
            selectedUsageSets: Ember.A(),
            initialUsageSets: Ember.A(),
            saveInProgress: false
          });
        }).catch(function () {
          _this8.toaster.error("Error saving the autogroup's usage sets");
          _this8.group.rollbackAttributes();
        });
      },
      addUsageSetFilter: function addUsageSetFilter(usageSet) {
        this.selectedUsageSetsFilter.pushObject(usageSet);
      },
      removeUsageSetFilter: function removeUsageSetFilter(usageSet) {
        this.selectedUsageSetsFilter.removeObject(usageSet);
      }
    },
    handleError: function handleError(err) {
      if (!Ember.isEmpty(err.errors.msg)) {
        this.set('errorMessage', err.errors.msg);
      } else {
        this.set('errorMessage', err.message);
      }
    },
    resetForm: function resetForm() {
      if (this.group.isNew) {
        this.group.deleteRecord();
      }
      this.set('group', null);
    },
    reset: function reset() {
      this.setProperties({
        selectedUsageSetsFilter: Ember.A()
      });
    }
  });
  _exports.default = _default;
});