define("linced-admin/templates/hero-notices/notice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "IBj45SJ/",
    "block": "{\"symbols\":[],\"statements\":[[5,\"single-post\",[],[[\"@editPost\",\"@post\"],[[28,\"action\",[[23,0,[]],\"editPost\"],null],[24,[\"model\",\"post\"]]]]],[0,\"\\n\\n\"],[5,\"new-post\",[],[[\"@dialogOpen\",\"@finishEditing\",\"@post\",\"@postCreated\",\"@postType\",\"@schoolGroups\"],[[22,\"editingPost\"],[28,\"action\",[[23,0,[]],\"closeEditPost\"],null],[22,\"postBeingEdited\"],[28,\"action\",[[23,0,[]],\"goToNotice\"],null],[24,[\"PostTypes\",\"GLOBAL_NOTICE\"]],[24,[\"model\",\"groups\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "linced-admin/templates/hero-notices/notice.hbs"
    }
  });
  _exports.default = _default;
});