define("linced-admin/enums/enrolment-log-resource", ["exports", "linced-admin/enums/base-enum"], function (_exports, _baseEnum) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _baseEnum.default.create({
    values: Object.freeze([{
      value: 1,
      label: 'credentials'
    }, {
      value: 2,
      label: 'identifiers'
    }, {
      value: 3,
      label: 'status'
    }, {
      value: 4,
      label: 'enrolments'
    }, {
      value: 5,
      label: 'unenrolments'
    }])
  });
  _exports.default = _default;
});