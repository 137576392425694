define("linced-admin/models/auth/status-sync-error", ["exports", "@ember-data/model", "ember-data-model-fragments"], function (_exports, _model, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberDataModelFragments.default.Fragment.extend({
    // entityId provides the type's main entity id, that is, personId for a
    // 'user' or a 'clientId' for a client.
    entityId: (0, _model.attr)('string'),
    // username provides the registered email address for a user.
    username: (0, _model.attr)('string'),
    // name provides a human understandable name of the entity.
    name: (0, _model.attr)('string'),
    // errorDescription provides details about the error that has occured.
    errorDescription: (0, _model.attr)('string')
  });
  _exports.default = _default;
});