define("linced-admin/controllers/finance/schema-update", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    fetch: Ember.inject.service(),
    selectedSchools: null,
    mode: 'all',
    bulkUpdateInProgress: false,
    dialogOpen: false,
    schoolsToUpdate: 0,
    schoolsUpdated: 0,
    schoolsFailed: 0,
    schoolsProcessed: Ember.computed('schoolsUpdated', 'schoolsFailed', function () {
      return this.schoolsUpdated + this.schoolsFailed;
    }),
    progress: Ember.computed('schoolsProcessed', 'schoolsToUpdate', function () {
      if (this.schoolsToUpdate <= 0) {
        return 0;
      }
      return this.schoolsProcessed / this.schoolsToUpdate * 100;
    }),
    bulkUpdate: function bulkUpdate(schoolIds) {
      var _this = this;
      var url = "".concat(_emberGetConfig.default.APP.apiHost, "/finance/v4/schools/:bulkupdateschema");
      var data = {};
      if (!Ember.isEmpty(schoolIds)) {
        data.schoolIds = schoolIds.toArray();
      }
      return this.fetch.post(url, {
        dataType: 'json',
        data: JSON.stringify(data)
      }).then(function () {
        _this.incrementProperty('schoolsUpdated', schoolIds.length);
      }).catch(function () {
        _this.incrementProperty('schoolsFailed', schoolIds.length);
      });
    },
    actions: {
      toggleSchool: function toggleSchool(school) {
        if (Ember.isEmpty(this.selectedSchools)) {
          this.set('selectedSchools', []);
        }
        if (this.selectedSchools.includes(school.id)) {
          this.selectedSchools.removeObject(school.id);
        } else {
          this.selectedSchools.pushObject(school.id);
        }
      },
      bulkUpdate: function bulkUpdate() {
        var _this2 = this;
        var CHUNK_SIZE = 25;

        // Determine schools to be updated.
        var schoolIds = this.selectedSchools;
        if (this.mode === 'all') {
          schoolIds = this.model.schools.mapBy('id').compact();
        }
        this.setProperties({
          bulkUpdateInProgress: true,
          dialogOpen: true,
          schoolsToUpdate: schoolIds.length,
          schoolsUpdated: 0,
          schoolsFailed: 0
        });

        // Break up schools into chunks.
        var chunks = [];
        for (var i = 0; i < schoolIds.length; i += CHUNK_SIZE) {
          var slice = schoolIds.slice(i, i + CHUNK_SIZE);
          chunks.push(slice);
        }
        chunks.reduce(function (chain, chunk) {
          return chain.then(function () {
            return _this2.bulkUpdate(chunk);
          });
        }, Ember.RSVP.resolve()).finally(function () {
          _this2.set('bulkUpdateInProgress', false);
        });
      }
    },
    reset: function reset() {
      this.setProperties({
        selectedSchools: null,
        mode: 'all',
        bulkUpdateInProgress: false
      });
    }
  });
  _exports.default = _default;
});