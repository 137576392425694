define("linced-admin/adapters/enrolments/credential", ["exports", "linced-admin/adapters/enrolments/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    modelNamePlural: 'credentials'
  });
  _exports.default = _default;
});