define("linced-admin/models/people/learner", ["exports", "@ember-data/model", "ember-data-model-fragments", "ember-data-model-fragments/attributes"], function (_exports, _model, _emberDataModelFragments, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberDataModelFragments.default.Fragment.extend({
    contacts: (0, _model.attr)(),
    enrolmentId: (0, _model.attr)('string'),
    enrolments: (0, _attributes.array)(),
    firstSchoolingDate: (0, _model.attr)(),
    homeroomId: (0, _model.attr)('string'),
    schoolCampusId: (0, _model.attr)('string'),
    schoolId: (0, _model.attr)('string')
  });
  _exports.default = _default;
});