define("linced-admin/controllers/sif/zones", ["exports", "@linc-technologies/linced-common/enums/data-type", "linced-admin/enums/sif-country", "linced-admin/utils/filtering"], function (_exports, _dataType, _sifCountry, _filtering) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    queryParams: ['country'],
    country: _sifCountry.default.AUSTRALIA,
    SifCountry: _sifCountry.default,
    // Attrs
    // filterVal provides the params entered into the zone filter box.
    filterVal: '',
    // Services
    store: Ember.inject.service(),
    // State
    newZone: null,
    zoneNewOpen: false,
    // Methods
    /**
     * filteredZones filters zones based on the user's filter.
     */
    filteredZones: Ember.computed('model.zones.[]', 'filterVal', function () {
      var _this = this;
      if (Ember.isEmpty(this.filterVal)) {
        return this.model.zones;
      }
      return this.model.zones.filter(function (zone) {
        return (0, _filtering.filterValue)(zone.label, _dataType.default.STRING, _this.filterVal);
      });
    }),
    // Actions
    actions: {
      changeCountry: function changeCountry(country) {
        this.set('country', country.value);
      },
      onCreate: function onCreate(zone) {
        this.set('newZone', zone);
        this.set('zoneNewOpen', false);
      }
    }
  });
  _exports.default = _default;
});