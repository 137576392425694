define("linced-admin/components/learner/id-search-field", ["exports", "linced-admin/components/dynamic-field"], function (_exports, _dynamicField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _dynamicField.default.extend({
    classNames: ['id-search-field']
  });
  _exports.default = _default;
});