define("linced-admin/models/milestones/milestone", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    createTime: (0, _model.attr)('number'),
    updateTime: (0, _model.attr)('number'),
    label: (0, _model.attr)('string'),
    sequence: (0, _model.attr)('number'),
    type: (0, _model.attr)('number'),
    duration: (0, _model.attr)('number'),
    yearLevel: (0, _model.attr)('number'),
    month: (0, _model.attr)('number'),
    day: (0, _model.attr)('number'),
    isInterim: (0, _model.attr)('boolean'),
    templateSetId: (0, _model.belongsTo)('milestones/set', {
      inverse: 'milestones'
    }),
    templateSet: Ember.computed.alias('templateSetId'),
    progressionType: (0, _model.attr)('number'),
    isPublished: (0, _model.attr)('boolean'),
    milestoneSetTemplateId: (0, _model.belongsTo)('milestones/milestone-set', {
      inverse: 'milestones'
    }),
    milestoneSetTemplate: Ember.computed.alias('milestoneSetTemplateId')
  });
  _exports.default = _default;
});