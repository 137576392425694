define("linced-admin/mixins/dynamic-field-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getValueDefault = getValueDefault;
  _exports.getValueKey = getValueKey;
  function getValueKey(datatype) {
    var key = '';
    switch (datatype) {
      case 1:
        key = 'valueString';
        break;
      case 2:
        key = 'valueList';
        break;
      case 3:
        key = 'valueInt';
        break;
      case 4:
        key = 'valueFloat';
        break;
      case 5:
        key = 'valueBool';
        break;
    }
    return key;
  }
  function getValueDefault(datatype) {
    var defaultValue = '';
    switch (datatype) {
      case 1:
        defaultValue = '';
        break;
      case 2:
        defaultValue = [];
        break;
      case 3:
      case 4:
        defaultValue = 0;
        break;
      case 5:
        defaultValue = false;
        break;
    }
    return defaultValue;
  }
});