define("linced-admin/services/ajax", ["exports", "@linc-technologies/linced-common/services/ajax", "ember-ajax/errors"], function (_exports, _ajax, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _ajax.default.extend({
    session: Ember.inject.service(),
    namespace: '/api',
    request: function request(url, options) {
      var _this = this;
      this.authorize();
      return this._super(url, options).catch(function (error) {
        if (error instanceof _errors.UnauthorizedError) {
          if (_this.get('session.isAuthenticated')) {
            _this.session.invalidate();
          }
        } else {
          throw error;
        }
      });
    }
  });
  _exports.default = _default;
});