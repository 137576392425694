define("linced-admin/mixins/edit-assessment", ["exports", "@linc-technologies/linced-common/enums/assessment-type", "@linc-technologies/linced-common/enums/assessment-display"], function (_exports, _assessmentType, _assessmentDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    editingAssessment: null,
    assessmentLabel: '',
    assessmentDescription: '',
    assessmentCategory: null,
    assessmentType: null,
    assessmentIsModerated: false,
    assessmentGradeSchedule: null,
    isVisibleToLearners: false,
    showHistoryToLearners: false,
    canLearnersEdit: false,
    assessmentDisplay: null,
    noGradesColumnsToShowCaregiversStudents: 4,
    noGradesColumnsToShowStaff: 8,
    showToCaregiversOnlyIfContent: false,
    actions: {
      toggleAssessmentDialog: function toggleAssessmentDialog() {
        this.toggleProperty('assessmentDialogOpen');
      },
      editAssessment: function editAssessment(assessment) {
        this.resetAssessmentForm();
        this.set('editingAssessment', assessment);
        this.set('assessmentLabel', assessment.get('label'));
        this.set('assessmentDescription', assessment.get('description'));
        this.set('assessmentType', _assessmentType.default.get('values').findBy('value', assessment.get('type')));
        this.set('assessmentDisplay', assessment.get('assessmentDisplay'));
        this.set('assessmentIsModerated', !!assessment.get('isModerated'));
        this.set('assessmentGradeSchedule', assessment.get('gradeSchedule'));
        this.set('assessmentDialogOpen', true);
        this.set('isVisibleToLearners', assessment.get('isVisibleToLearners'));
        if (!Ember.isEmpty(assessment.get('noGradesColumnsToShowCaregiversStudents'))) {
          this.set('noGradesColumnsToShowCaregiversStudents', assessment.get('noGradesColumnsToShowCaregiversStudents'));
        }
        if (!Ember.isEmpty(assessment.get('noGradesColumnsToShowStaff'))) {
          this.set('noGradesColumnsToShowStaff', assessment.get('noGradesColumnsToShowStaff'));
        }
        if (!Ember.isEmpty(assessment.get('showToCaregiversOnlyIfContent'))) {
          this.set('showToCaregiversOnlyIfContent', assessment.get('showToCaregiversOnlyIfContent'));
        }
        this.set('showHistoryToLearners', assessment.get('showHistoryToLearners'));
        this.set('canLearnersEdit', assessment.get('canLearnersEdit'));
        if (Ember.isEmpty(assessment.get('assessmentDisplay'))) {
          this.set('assessmentDisplay', _assessmentDisplay.default.TERMS);
        }
      },
      saveAssessment: function saveAssessment() {
        var _this = this;
        var assessment = this.editingAssessment;
        var store = this.store;
        this.set('assessmentSaveInProgress', true);

        // Create a new Assessment if needed
        if (Ember.isEmpty(assessment)) {
          assessment = store.createRecord('assessments/assessment', {
            categoryId: this.assessmentCategory,
            // Add to the end by default
            sequence: this.get('assessmentCategory.assessments.length')
          });
        }

        // Set properties from form
        assessment.setProperties({
          label: this.assessmentLabel,
          description: this.assessmentDescription,
          type: this.get('assessmentType.value'),
          assessmentDisplay: this.assessmentDisplay,
          isModerated: this.assessmentIsModerated,
          gradeScheduleId: this.assessmentGradeSchedule,
          isVisibleToLearners: this.isVisibleToLearners,
          showHistoryToLearners: this.showHistoryToLearners,
          canLearnersEdit: this.canLearnersEdit,
          showToCaregiversOnlyIfContent: this.showToCaregiversOnlyIfContent,
          noGradesColumnsToShowCaregiversStudents: this.noGradesColumnsToShowCaregiversStudents,
          noGradesColumnsToShowStaff: this.noGradesColumnsToShowStaff
        });
        assessment.save().then(function () {
          _this.set('assessmentDialogOpen', false);
        }).catch(function (err) {
          _this.set('assessmentErrorMessage', err);
        }).then(function () {
          _this.set('assessmentSaveInProgress', false);
        });
      }
    },
    resetAssessmentForm: function resetAssessmentForm() {
      this.set('assessmentErrorMessage', '');
      this.set('assessmentLabel', '');
      this.set('assessmentDescription', '');
      this.set('assessmentCategory', null);
      this.set('assessmentType', null);
      this.set('assessmentIsModerated', false);
      this.set('assessmentGradeSchedule', null);
      this.set('editingAssessment', null);
      this.set('isVisibleToLearners', false);
      this.set('showHistoryToLearners', false);
      this.set('canLearnersEdit', false);
      this.set('showToCaregiversOnlyIfContent', false);
      this.set('noGradesColumnsToShowCaregiversStudents', 4);
      this.set('noGradesColumnsToShowStaff', 8);
    }
  });
  _exports.default = _default;
});