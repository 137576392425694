define("linced-admin/models/posts/globalnotice", ["exports", "@linc-technologies/linced-common/models/posts/globalnotice", "@ember-data/model"], function (_exports, _globalnotice, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _globalnotice.default.extend({
    groupIds: (0, _model.hasMany)('schools/group'),
    url: Ember.computed('id', function () {
      return "/hero-notices/".concat(this.id);
    })
  });
  _exports.default = _default;
});