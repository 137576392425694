define("linced-admin/adapters/school", ["exports", "linced-admin/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    namespace: 'schools/v4',
    modelNamePlural: 'schools'
  });
  _exports.default = _default;
});