define("linced-admin/controllers/tags/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    ajax: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    store: Ember.inject.service(),
    toaster: Ember.inject.service(),
    // Attributes
    categoryId: '',
    label: '',
    errorMessage: '',
    categoryDialogOpen: false,
    currentStep: 0,
    saveInProgress: false,
    // Applying template attributes
    applyConfirmed: false,
    applyDialogOpen: false,
    applyErrors: null,
    applyInProgress: false,
    selectedTemplatesToApply: Ember.A(),
    selectedSchoolsToApply: Ember.A(),
    selectedGroupFilter: null,
    // Usage sets
    usageSetDialogOpen: false,
    currentCategory: null,
    selectedUsageSets: Ember.A(),
    initialUsageSets: Ember.A(),
    remainingUsageSetsFilter: Ember.computed.setDiff('model.usageSets', 'selectedUsageSetsFilter'),
    selectedUsageSetsFilter: Ember.A(),
    filteredCategories: Ember.computed('model.tagCategories.@each.usageSetIds', 'selectedUsageSetsFilter.[]', function () {
      var selectedUsageSets = this.selectedUsageSetsFilter;
      return this.get('model.tagCategories').filter(function (tagCategory) {
        if (!Ember.isEmpty(selectedUsageSets)) {
          return selectedUsageSets.some(function (usageSet) {
            return tagCategory.get('usageSetIds').includes(usageSet);
          });
        }
        return true;
      });
    }),
    // toggleDialog opens the category dialog
    toggleDialog: function toggleDialog() {
      this.set('errorMessage', '');
      this.toggleProperty('categoryDialogOpen');
      if (!this.categoryDialogOpen) {
        this.resetForm();
      }
    },
    updateUsageSets: function updateUsageSets(tagCategory) {
      return tagCategory.get('usageSetIds').then(function (usageSetIds) {
        if (!Ember.isEmpty(usageSetIds)) {
          var promises = [];
          usageSetIds.forEach(function (usageSetId) {
            promises.push(usageSetId.save());
          });
          return Promise.all(promises);
        }
      });
    },
    updatePageTemplates: function updatePageTemplates(tagCategory) {
      return this.store.query('posts/page', {
        tagCategoryIds: JSON.stringify([tagCategory.id])
      }).then(function (pageTemplates) {
        if (!Ember.isEmpty(pageTemplates)) {
          var promises = [];
          pageTemplates.forEach(function (pageTemplate) {
            promises.push(pageTemplate.save());
          });
          return Promise.all(promises);
        }
      });
    },
    actions: {
      toggleCategoryDialog: function toggleCategoryDialog() {
        this.toggleDialog();
      },
      editCategory: function editCategory(id) {
        var _this = this;
        this.store.findRecord('posts/tagCategory', id).then(function (category) {
          _this.set('label', category.get('label'));
        });
        this.set('categoryId', id);
        this.toggleDialog();
      },
      saveCategory: function saveCategory() {
        var _this2 = this;
        this.set('saveInProgress', true);
        this.set('errorMessage', '');
        var promise;
        var action = 'create';
        if (Ember.isEmpty(this.categoryId)) {
          promise = new Ember.RSVP.Promise(function (resolve) {
            var record = _this2.store.createRecord('posts/tagCategory', {
              label: _this2.label
            });
            resolve(record);
          });
        } else {
          action = 'update';
          promise = new Ember.RSVP.Promise(function (resolve) {
            var id = _this2.categoryId;
            _this2.store.findRecord('posts/tagCategory', id).then(function (category) {
              category.set('label', _this2.label);
              resolve(category);
            });
          });
        }
        promise.then(function (record) {
          return record.save().then(function () {
            if (action === 'create') {
              // Send an event to trigger the route to refresh the model
              _this2.send('categoryCreated');
            }
            _this2.set('categoryDialogOpen', false);
            _this2.resetForm();
          }).catch(function (err) {
            _this2.handleError(err, action);
            record.rollbackAttributes();
          }).then(function () {
            _this2.set('saveInProgress', false);
          });
        });
      },
      deleteCategory: function deleteCategory(id) {
        var _this3 = this;
        this.set('errorMessage', '');
        this.store.findRecord('posts/tagCategory', id).then(function (category) {
          _this3.set('label', category.get('label'));
        });
        this.set('categoryId', id);
        this.set('deleteCategoryDialogOpen', true);
      },
      confirmDelete: function confirmDelete(confirmed) {
        var _this4 = this;
        if (!confirmed) {
          // Tidy up / close dialog.
          this.setProperties({
            deleteCategoryDialogOpen: false,
            errorMessage: ''
          });
          this.resetForm();
          return;
        }
        return new Ember.RSVP.Promise(function (resolve, reject) {
          // Deletion confirmed; actually delete the record.
          _this4.set('deleteInProgress', true);

          // Grab record from local store - no GET request, as that causes weirdness.
          // We only need the ID to issue the DELETE anyway, which can't change, so no need to use findRecord.
          var category = _this4.store.peekRecord('posts/tagCategory', _this4.categoryId);

          // Delete record (locally + pushed to server).
          return category.destroyRecord().then(function () {
            var relationshipPromises = [];
            // Update the usage sets relationships
            relationshipPromises.push(_this4.updateUsageSets(category));
            // Update the page template's relationship
            relationshipPromises.push(_this4.updatePageTemplates(category));
            return Promise.all(relationshipPromises);
          }).then(function () {
            _this4.toaster.success("The tag category has been deleted successfully.");
            // Tidy up / close dialog.
            _this4.set('deleteCategoryDialogOpen', false);
            _this4.resetForm();
          }).then(resolve).catch(function (err) {
            _this4.handleError(err, 'delete');
            // Remove 'deleted' flag so that entity can be interacted with again.
            category.rollbackAttributes();
            reject(err);
          }).then(function () {
            _this4.set('deleteInProgress', false);
          });
        });
      },
      openUsageSetDialog: function openUsageSetDialog(category) {
        var _this5 = this;
        category.get('usageSetIds').then(function (usageSetsArray) {
          _this5.setProperties({
            usageSetDialogOpen: true,
            currentCategory: category,
            selectedUsageSets: usageSetsArray.slice(),
            initialUsageSets: usageSetsArray
          });
        }).catch(function () {
          _this5.toaster.error("Error trying to edit the tag category's usage sets");
        });
      },
      toggleUsageSet: function toggleUsageSet(usageSet, checked) {
        if (checked) {
          this.selectedUsageSets.pushObject(usageSet);
        } else {
          this.selectedUsageSets.removeObject(usageSet);
        }
      },
      saveCategoryAndUsageSet: function saveCategoryAndUsageSet() {
        var _this6 = this;
        this.set('saveInProgress', true);
        var promises = [];
        var changedUsageSets = Ember.A();

        // Update the usage sets
        var removedUsageSets = this.initialUsageSets.filter(function (usageSet) {
          return !_this6.selectedUsageSets.includes(usageSet);
        });
        var addedUsageSets = this.selectedUsageSets.filter(function (usageSet) {
          return !_this6.initialUsageSets.includes(usageSet);
        });
        changedUsageSets = changedUsageSets.concat(removedUsageSets, addedUsageSets);
        if (!Ember.isEmpty(changedUsageSets)) {
          // Usage sets a page has been newly added to/removed from
          changedUsageSets.forEach(function (changedUsageSet) {
            promises.push(changedUsageSet.save());
          });
        }
        // Save the page template at the end - this is done so the comparison is most accurate
        // as this.initialUsageSets is a reference to usageSetIds
        this.currentCategory.set('usageSetIds', this.selectedUsageSets);
        promises.push(this.currentCategory.save());
        return Promise.all(promises).then(function () {
          // After saving the page and all involved usage sets
          _this6.toaster.success("Successfully saved the tag category and usage sets");
          _this6.currentCategory.reload();
          _this6.setProperties({
            usageSetDialogOpen: false,
            currentCategory: null,
            selectedUsageSets: Ember.A(),
            initialUsageSets: Ember.A(),
            saveInProgress: false
          });
        }).catch(function () {
          _this6.toaster.error("Error saving the tag category's usage sets");
          _this6.currentCategory.rollbackAttributes();
        });
      },
      addUsageSetFilter: function addUsageSetFilter(usageSet) {
        this.selectedUsageSetsFilter.pushObject(usageSet);
      },
      removeUsageSetFilter: function removeUsageSetFilter(usageSet) {
        this.selectedUsageSetsFilter.removeObject(usageSet);
      }
    },
    handleError: function handleError(err) {
      if (!Ember.isEmpty(err.errors.msg)) {
        this.set('errorMessage', err.errors.msg);
      } else {
        this.set('errorMessage', err.message);
      }
    },
    resetForm: function resetForm() {
      this.set('categoryId', '');
      this.set('label', '');
    }
  });
  _exports.default = _default;
});