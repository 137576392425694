define("linced-admin/components/assessment-edit-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      selectAssessmentGradeSchedule: function selectAssessmentGradeSchedule(schedule) {
        this.set('assessmentGradeSchedule', schedule);
      },
      toggleIsVisibleToLearners: function toggleIsVisibleToLearners(isVisibleToLearners) {
        this.set('isVisibleToLearners', isVisibleToLearners);
        if (!isVisibleToLearners) {
          this.set('showHistoryToLearners', false);
          this.set('canLearnersEdit', false);
        }
      }
    }
  });
  _exports.default = _default;
});