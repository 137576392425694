define("linced-admin/models/assessments/assessment", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    updateTime: (0, _model.attr)('number'),
    createTime: (0, _model.attr)('number'),
    isPublished: (0, _model.attr)('boolean'),
    isLocked: (0, _model.attr)('boolean'),
    categoryId: (0, _model.belongsTo)('assessments/category'),
    category: Ember.computed.alias('categoryId'),
    category_id: Ember.computed.alias('category.id'),
    sequence: (0, _model.attr)('number'),
    // Usage sets membership
    usageSetIds: (0, _model.hasMany)('schools/usage-set'),
    modules: (0, _model.hasMany)('assessments/module'),
    label: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    type: (0, _model.attr)('number'),
    assessmentDisplay: (0, _model.attr)('number'),
    key: (0, _model.attr)('string'),
    gradeScheduleId: (0, _model.belongsTo)('assessments/grade-schedule'),
    gradeSchedule: Ember.computed.alias('gradeScheduleId'),
    isModerated: (0, _model.attr)('boolean'),
    numericStepping: (0, _model.attr)('number'),
    dueTime: (0, _model.attr)('number'),
    isVisibleToLearners: (0, _model.attr)('boolean'),
    canLearnersEdit: (0, _model.attr)('boolean'),
    showHistoryToLearners: (0, _model.attr)('boolean'),
    noGradesColumnsToShowCaregiversStudents: (0, _model.attr)('number'),
    noGradesColumnsToShowStaff: (0, _model.attr)('number'),
    showToCaregiversOnlyIfContent: (0, _model.attr)('boolean')
  });
  _exports.default = _default;
});