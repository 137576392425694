define("linced-admin/controllers/hero-notices/index", ["exports", "@linc-technologies/linced-common/mixins/edit-post", "@linc-technologies/linced-common/enums/post-type"], function (_exports, _editPost, _postType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend(_editPost.default, {
    queryParams: ['offset'],
    offset: 0,
    PostTypes: _postType.default,
    actions: {
      refresh: function refresh() {
        this.model.posts.update();
      },
      nextPage: function nextPage() {
        this.incrementProperty('offset', 20);
      },
      prevPage: function prevPage() {
        this.decrementProperty('offset', 20);
      }
    }
  });
  _exports.default = _default;
});