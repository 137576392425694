define("linced-admin/controllers/assessments/index", ["exports", "linced-admin/mixins/edit-assessment", "@linc-technologies/linced-common/enums/assessment-type", "@linc-technologies/linced-common/enums/assessment-display"], function (_exports, _editAssessment, _assessmentType, _assessmentDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Internal imports
  // External imports
  var _default = Ember.Controller.extend(_editAssessment.default, {
    AssessmentType: _assessmentType.default,
    AssessmentDisplay: _assessmentDisplay.default,
    // Services
    currentUser: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    toaster: Ember.inject.service(),
    // Attributes
    editingCategory: null,
    categoryLabel: '',
    categoryDescription: '',
    parentCategory: null,
    // Deletion attributes
    deleteDialogOpen: false,
    deleteErrorMessage: '',
    deleteInProgress: false,
    deleteLabel: '',
    entityToDelete: null,
    // Usage sets
    usageSetDialogOpen: false,
    currentAssessment: null,
    selectedUsageSets: Ember.A(),
    initialUsageSets: Ember.A(),
    saveInProgress: false,
    remainingUsageSetsFilter: Ember.computed.setDiff('model.usageSets', 'selectedUsageSetsFilter'),
    selectedUsageSetsFilter: Ember.A(),
    // Computed
    shownCategories: Ember.computed('model.categories.[]', 'selectedUsageSetsFilter.[]', function () {
      if (!Ember.isEmpty(this.selectedUsageSetsFilter)) {
        // Hide all categories when we're filtering by usage set
        // as assessments without the categories will be displayed instead
        return [];
      }
      return this.get('model.categories').filter(function (assessmentCategory) {
        return Ember.isEmpty(assessmentCategory.get('parent_id'));
      });
    }),
    filteredAssessments: Ember.computed('model.assessments.@each.{category,usageSetIds}', 'selectedUsageSetsFilter.[]', function () {
      var selectedUsageSets = this.selectedUsageSetsFilter;
      return this.get('model.assessments').filter(function (assessment) {
        if (!Ember.isEmpty(selectedUsageSets)) {
          return selectedUsageSets.some(function (usageSet) {
            return assessment.get('usageSetIds').includes(usageSet);
          });
        }
        // Reject duplicates that have categories
        return Ember.isEmpty(assessment.get('category.id'));
      });
    }),
    // availableSchedules is an array of schedules which are available to the current user.
    availableSchedules: Ember.computed.filter('model.gradeschedules', function (schedule) {
      var personId = this.get('currentUser.personId');
      return schedule.get('isShared') || schedule.get('authorId') === personId;
    }),
    updateUsageSets: function updateUsageSets(assessment) {
      return assessment.get('usageSetIds').then(function (usageSetIds) {
        if (!Ember.isEmpty(usageSetIds)) {
          var promises = [];
          usageSetIds.forEach(function (usageSetId) {
            promises.push(usageSetId.save());
          });
          return Promise.all(promises);
        }
      });
    },
    updatePageTemplates: function updatePageTemplates(assessment) {
      return this.store.query('posts/page', {
        assessmentIds: JSON.stringify([assessment.id])
      }).then(function (pageTemplates) {
        if (!Ember.isEmpty(pageTemplates)) {
          var promises = [];
          pageTemplates.forEach(function (pageTemplate) {
            promises.push(pageTemplate.save());
          });
          return Promise.all(promises);
        }
      });
    },
    actions: {
      // Category management actions.
      saveCategory: function saveCategory() {
        var _this = this;
        var category = this.editingCategory;
        var store = this.store;
        this.set('categorySaveInProgress', true);

        // Create a new Category if needed
        if (Ember.isEmpty(category)) {
          category = store.createRecord('assessments/category', {
            schoolId: this.get('currentUser.school'),
            parentCategoryId: this.parentCategory
          });
        }

        // Set properties from form
        category.setProperties({
          label: this.categoryLabel,
          description: this.categoryDescription
        });
        category.save().then(function () {
          _this.set('categoryDialogOpen', false);
        }).catch(function (err) {
          _this.set('categoryErrorMessage', err);
        }).then(function () {
          _this.set('categorySaveInProgress', false);
        });
      },
      toggleCategoryDialog: function toggleCategoryDialog() {
        this.toggleProperty('categoryDialogOpen');
      },
      newCategory: function newCategory(parent) {
        this.resetCategoryForm();
        this.set('parentCategory', parent);
        this.set('categoryDialogOpen', true);
      },
      editCategory: function editCategory(category) {
        this.resetCategoryForm();
        this.set('editingCategory', category);
        this.set('categoryLabel', category.get('label'));
        this.set('categoryDescription', category.get('description'));
        this.set('categoryDialogOpen', true);
      },
      // Assessment management actions.
      newAssessment: function newAssessment(category) {
        this.resetAssessmentForm();
        this.set('assessmentCategory', category);
        this.set('assessmentDialogOpen', true);
      },
      openAssessment: function openAssessment(assessment) {
        this.router.transitionTo('assessments.assessment', assessment.get('id'));
      },
      // Drag-and-drop sorting actions.
      moveAssessment: function moveAssessment(_ref) {
        var sourceList = _ref.sourceList,
          sourceIndex = _ref.sourceIndex,
          targetList = _ref.targetList,
          targetIndex = _ref.targetIndex;
        if (sourceList === targetList && sourceIndex === targetIndex) {
          // No change in list or position.
          return;
        }
        var item = sourceList.objectAt(sourceIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, item);
        if (sourceList !== targetList) {
          // Moved to a new category - update the relationship.
          item.set('categoryId', targetList.get('__category__'));
          item.save();
        }

        // Update target category's assessment sequences.
        targetList.forEach(function (item, index) {
          item.set('sequence', index);
          if (item.get('hasDirtyAttributes')) {
            item.save();
          }
        });
      },
      moveCategory: function moveCategory(_ref2) {
        var sourceList = _ref2.sourceList,
          sourceIndex = _ref2.sourceIndex,
          targetList = _ref2.targetList,
          targetIndex = _ref2.targetIndex;
        if (sourceList === targetList && sourceIndex === targetIndex) {
          // No change in list or position.
          return;
        }
        var item = sourceList.objectAt(sourceIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, item);
        if (sourceList !== targetList) {
          // Moved to a new parent category - update the relationship.
          item.set('parentCategoryId', targetList.get('__parent__'));
          item.save();
        }

        // Update target category's subcategory sequences.
        targetList.forEach(function (item, index) {
          item.set('sequence', index);
          if (item.get('hasDirtyAttributes')) {
            item.save();
          }
        });
      },
      closeDeleteDialog: function closeDeleteDialog() {
        this.setProperties({
          deleteDialogOpen: false,
          entityToDelete: null,
          deleteLabel: '',
          deleteErrorMessage: ''
        });
      },
      deleteCategory: function deleteCategory(category) {
        this.resetCategoryForm();
        this.setProperties({
          deleteDialogOpen: true,
          entityToDelete: category,
          deleteLabel: 'Assessment Category'
        });
      },
      deleteAssessment: function deleteAssessment(assessment) {
        this.setProperties({
          deleteDialogOpen: true,
          entityToDelete: assessment,
          deleteLabel: 'Assessment'
        });
      },
      confirmDelete: function confirmDelete(confirmed) {
        var _this2 = this;
        if (!confirmed) {
          // Not confirmed the deletion
          this.setProperties({
            deleteDialogOpen: false,
            entityToDelete: null,
            deleteErrorMessage: '',
            deleteLabel: ''
          });
          return;
        }

        // Deletion confirmed; actually delete the record.
        this.set('deleteInProgress', true);
        var assessment = this.get('entityToDelete');
        // Delete record (locally + pushed to server).
        return assessment.destroyRecord()
        // Chain the promises, so that the deletion as a whole does not happen if
        // something goes wrong in the chain
        .then(function () {
          if (_this2.deleteLabel === 'Assessment') {
            var relationshipPromises = [];
            // Update the usage sets relationships
            relationshipPromises.push(_this2.updateUsageSets(_this2.entityToDelete));
            // Update the page template's relationship
            relationshipPromises.push(_this2.updatePageTemplates(_this2.entityToDelete));
            return Promise.all(relationshipPromises);
          }
        }).then(function () {
          _this2.toaster.success("".concat(_this2.deleteLabel, " deleted successfully."));
          // Tidy up / close dialog.
          _this2.setProperties({
            deleteDialogOpen: false,
            entityToDelete: null,
            deleteErrorMessage: '',
            deleteLabel: ''
          });
        }).catch(function (err) {
          if (!Ember.isEmpty(err)) {
            if (err.errors && err.errors.length > 0) {
              _this2.set('deleteErrorMessage', err.errors[0].detail);
              return;
            }
            if (err.message) {
              _this2.set('deleteErrorMessage', err.message);
              return;
            }
          }
          _this2.set('deleteErrorMessage', 'An unknown error occurred. Please try again.');
          // Remove 'deleted' flag so that entity can be interacted with again.
          _this2.entityToDelete.rollbackAttributes();
        }).finally(function () {
          _this2.set('deleteInProgress', false);
        });
      },
      openUsageSetDialog: function openUsageSetDialog(assessment) {
        var _this3 = this;
        assessment.get('usageSetIds').then(function (usageSetsArray) {
          _this3.setProperties({
            usageSetDialogOpen: true,
            currentAssessment: assessment,
            selectedUsageSets: usageSetsArray.slice(),
            initialUsageSets: usageSetsArray
          });
        }).catch(function () {
          _this3.toaster.error("Error trying to edit the assessment's usage sets");
        });
      },
      toggleUsageSet: function toggleUsageSet(usageSet, checked) {
        if (checked) {
          this.selectedUsageSets.pushObject(usageSet);
        } else {
          this.selectedUsageSets.removeObject(usageSet);
        }
      },
      saveAssessmentAndUsageSet: function saveAssessmentAndUsageSet() {
        var _this4 = this;
        this.set('saveInProgress', true);
        var promises = [];
        var changedUsageSets = Ember.A();

        // Update the usage sets
        var removedUsageSets = this.initialUsageSets.filter(function (usageSet) {
          return !_this4.selectedUsageSets.includes(usageSet);
        });
        var addedUsageSets = this.selectedUsageSets.filter(function (usageSet) {
          return !_this4.initialUsageSets.includes(usageSet);
        });
        changedUsageSets = changedUsageSets.concat(removedUsageSets, addedUsageSets);
        if (!Ember.isEmpty(changedUsageSets)) {
          // Usage sets a page has been newly added to/removed from
          changedUsageSets.forEach(function (changedUsageSet) {
            promises.push(changedUsageSet.save());
          });
        }
        // Save the page template at the end - this is done so the comparison is most accurate
        // as this.initialUsageSets is a reference to usageSetIds
        this.currentAssessment.set('usageSetIds', this.selectedUsageSets);
        promises.push(this.currentAssessment.save());
        return Promise.all(promises).then(function () {
          // After saving the page and all involved usage sets
          _this4.toaster.success("Successfully saved the assessment and usage sets");
          _this4.currentAssessment.reload();
          _this4.setProperties({
            usageSetDialogOpen: false,
            currentAssessment: null,
            selectedUsageSets: Ember.A(),
            initialUsageSets: Ember.A(),
            saveInProgress: false
          });
        }).catch(function () {
          _this4.toaster.error("Error saving the assessment's usage sets");
          _this4.currentAssessment.rollbackAttributes();
        });
      },
      addUsageSetFilter: function addUsageSetFilter(usageSet) {
        this.selectedUsageSetsFilter.pushObject(usageSet);
      },
      removeUsageSetFilter: function removeUsageSetFilter(usageSet) {
        this.selectedUsageSetsFilter.removeObject(usageSet);
      },
      // unpublishAssessment will remove the assessment templates from all usage sets so it becomes unusable
      unpublishAssessment: function unpublishAssessment(assessment) {
        var _this5 = this;
        assessment.get('usageSetIds').then(function (usageSetsArray) {
          if (!Ember.isEmpty(usageSetsArray)) {
            _this5.setProperties({
              selectedUsageSets: Ember.A(),
              initialUsageSets: usageSetsArray,
              currentAssessment: assessment
            });
            _this5.send('saveAssessmentAndUsageSet');
          }
        });
      }
    },
    resetCategoryForm: function resetCategoryForm() {
      this.set('categoryErrorMessage', '');
      this.set('categoryLabel', '');
      this.set('categoryDescription', '');
      this.set('parentCategory', null);
      this.set('editingCategory', null);
    }
  });
  _exports.default = _default;
});