define("linced-admin/serializers/posts/page-set", ["exports", "@linc-technologies/linced-common/serializers/posts/page-set"], function (_exports, _pageSet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _pageSet.default;
    }
  });
});