define("linced-admin/adapters/timetable/period", ["exports", "linced-admin/adapters/timetable/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    modelNamePlural: 'periods',
    shouldReloadRecord: function shouldReloadRecord() {
      return false; // Period records are immutable, so there's never any point reloading if we already have it.
    },
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    }
  });
  _exports.default = _default;
});