define("linced-admin/routes/schools", ["exports", "linced-admin/routes/base", "@linc-technologies/linced-common/enums/country-code", "ember-get-config"], function (_exports, _base, _countryCode, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  var _default = _base.default.extend({
    model: function model() {
      var store = this.store;
      return Ember.RSVP.hash({
        schools: store.findAll('school'),
        credentials: store.findAll('enrolments/credential'),
        optionSets: store.findAll('school-options/set'),
        peopleSchemaTemplates: store.findAll('people-schema/set'),
        // We only want the published page templates
        pageTemplates: store.query('posts/page', {
          isPublished: true
        }),
        // We only want the published goal subject templates
        goalSubjects: store.query('goals/subject', {
          isPublished: true
        }),
        milestoneSets: store.query('milestones/set', {
          isPublished: true
        }),
        autogroups: store.findAll('group'),
        enrolmentSchemaTemplates: store.findAll('enrolments/schema'),
        groups: store.findAll('schools/group'),
        sifZones: _emberGetConfig.default.APP.sifEnabled ? Ember.RSVP.hash(_defineProperty({}, _countryCode.default.AU, store.findAll('sif/au/zone'))) : [],
        usageSets: store.findAll('schools/usage-set')
      });
    }
  });
  _exports.default = _default;
});