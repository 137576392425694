define("linced-admin/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin", "linced-admin/config/environment"], function (_exports, _applicationRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    currentUser: Ember.inject.service(),
    permissions: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.router.on('routeDidChange', function (transition) {
        transition.to.find(function (route) {
          // Whenever we transition between routes (including manual URL changes),
          // check the permissions to access the route
          _this.checkRouteAccess(route);
        });
      });
    },
    sessionAuthenticated: function sessionAuthenticated() {
      this._super.apply(this, arguments);

      // Trigger a model reload, which in turn triggers beforeModel()
      // which loads the current user and redirects them if necessary.
      return this.refresh();
    },
    // Override mixin's sessionInvalidated to prevent automatic redirect to homepage
    sessionInvalidated: function sessionInvalidated() {},
    _loadCurrentUser: function _loadCurrentUser() {
      var _this2 = this;
      return this.currentUser.autoload().catch(function () {
        return _this2.session.invalidate();
      });
    },
    _preloadData: function _preloadData() {
      // Preload any useful data here.
    },
    model: function model() {
      var _this3 = this;
      if (this.session.isAuthenticated) {
        return this.store.find('person', this.get('currentUser.personId')).then(function (person) {
          person.initFields();
          _this3.currentUser.setPerson(person);
        });
      }
    },
    beforeModel: function beforeModel() {
      var _this4 = this;
      this._super.apply(this, arguments);
      if (this.session.isAuthenticated) {
        return this._loadCurrentUser().then(function () {
          if (_this4.shouldRedirectToOtherApp()) {
            _this4.autoRedirectToOtherApp();
          }
        });
      }
    },
    shouldRedirectToOtherApp: function shouldRedirectToOtherApp() {
      return !this.permissions.meet('urn:linced:meta:manager');
    },
    autoRedirectToOtherApp: function autoRedirectToOtherApp() {
      // Note: When redirecting to another app, clear the session locally (but don't invalidate it,
      // as we don't want to actually log the user out - just forget them from this app) so that
      // they don't get caught in a redirect loop if they log out and log back in as somebody who
      // actually has access to this app.
      // Redirect to /login in order to ensure that the target app triggers the auth service to
      // store a session ID for the correct client ID, in order to prevent logout issues.
      if (this.permissions.meet('urn:linced:organisation:role:staff')) {
        this.clearSession();
        window.location.href = _environment.default.APP.schoolHost + '/login';
      } else if (this.permissions.meet('urn:linced:organisation:role:caregiver')) {
        this.clearSession();
        window.location.href = _environment.default.APP.caregiverHost + '/login';
      } else if (this.permissions.meet('urn:linced:organisation:role:learner')) {
        this.clearSession();
        window.location.href = _environment.default.APP.learnerHost + '/login';
      }
    },
    clearSession: function clearSession() {
      var sessionStore = this.get('session.store');
      if (!Ember.isEmpty(sessionStore)) {
        sessionStore.clear();
      }
    },
    checkRouteAccess: function checkRouteAccess(route) {
      var appController = this.controllerFor('application');
      // If a route is nested, check the highest level route,
      // so we don't have to specify all routes' names
      var routeName = route.name.split('.').firstObject;
      if (routeName === 'auth') {
        // The permissions for these subroutes are more
        // specific, use them instead of the parent route
        routeName = route.name;
      }
      switch (routeName) {
        // Universal routes
        case 'application':
        case 'callback':
        case 'home':
        case 'index':
        case 'logged-out':
        case 'login':
        case 'login-failed':
        case 'my-profile':
          // Allow access to these routes for any user
          // who has access to the app, and let other code handle them
          break;
        // Hero Communication section
        case 'hero-notices':
        case 'school-groups':
          if (!appController.get('showCommunicationMenu')) {
            // No permissions to access the "Hero Communication" section
            this.router.replaceWith('access-denied');
          }
          break;
        // SuperHero Management section
        case 'sif':
        case 'cron':
        case 'finance':
        case 'auth.clients':
          if (!appController.get('showSuperMenu')) {
            // No permissions to access the "SuperHero Management"
            // and "Auth - OAuth Clients" section and menu items
            this.router.replaceWith('access-denied');
          }
          break;
        case 'auth.fusionauth':
          if (!appController.get('isSuperAdmin')) {
            // No permissions to access the "FusionAuth" section under
            // "SuperHero Management"
            this.router.replaceWith('access-denied');
          }
          break;
        default:
          // Check for at least access to the Hero Management section
          // which has the most routes
          if (!appController.get('showManagementMenu')) {
            // No permissions to access the "Hero Management" section
            this.router.replaceWith('access-denied');
          }
          break;
      }
    }
  });
  _exports.default = _default;
});