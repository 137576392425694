define("linced-admin/models/assessments/gradeschedule-numeric", ["exports", "@ember-data/model", "ember-data-model-fragments", "ember-data-model-fragments/attributes"], function (_exports, _model, _emberDataModelFragments, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberDataModelFragments.default.Fragment.extend({
    range: (0, _attributes.fragment)('assessments/gradeschedule-range'),
    defaultNumericStepping: (0, _model.attr)('number', {
      defaultValue: 1
    })
  });
  _exports.default = _default;
});