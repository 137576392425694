define("linced-admin/serializers/person", ["exports", "@ember-data/serializer/rest", "linced-admin/mixins/dynamic-fields-serializer"], function (_exports, _rest, _dynamicFieldsSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _rest.default.extend(_dynamicFieldsSerializer.default, {
    _payloadKeySingular: 'person',
    _payloadKeyPlural: 'people'
  });
  _exports.default = _default;
});