define("linced-admin/components/publishable-post-list", ["exports", "@linc-technologies/linced-common/components/post-list", "@linc-technologies/linced-common/enums/post-status"], function (_exports, _postList, _postStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _postList.default.extend({
    PostStatus: _postStatus.default
  });
  _exports.default = _default;
});