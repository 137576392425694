define("linced-admin/components/grade-history-table", ["exports", "@linc-technologies/linced-common/components/grade-history-table"], function (_exports, _gradeHistoryTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _gradeHistoryTable.default;
    }
  });
});