define("linced-admin/controllers/people/schema/templates", ["exports", "@linc-technologies/linced-common/enums/person-type"], function (_exports, _personType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    PersonType: _personType.default,
    templateName: '',
    templateKey: '',
    templateDescription: '',
    templateId: '',
    templatePersonType: 0,
    templateSequence: 9999,
    templateDialogOpen: false,
    saveInProgress: false,
    deleteTemplateDialogOpen: false,
    deleteInProgress: false,
    errorMessage: '',
    personTypes: _personType.default.values,
    selectedTab: _personType.default.LEARNER,
    // toggleDialog opens the template dialog.
    toggleDialog: function toggleDialog() {
      this.set('errorMessage', '');
      this.toggleProperty('templateDialogOpen');
      if (!this.templateDialogOpen) {
        this.resetForm();
      }
    },
    actions: {
      toggleTemplateDialog: function toggleTemplateDialog() {
        this.toggleDialog();
      },
      editTemplate: function editTemplate(id) {
        var _this = this;
        this.store.findRecord('people-schema/set', id).then(function (template) {
          var personType = _this.personTypes.findBy('value', template.get('personType'));
          _this.set('templateName', template.get('name'));
          _this.set('templateKey', template.get('key'));
          _this.set('templateDescription', template.get('description'));
          _this.set('templatePersonType', personType);
          _this.set('templateSequence', template.get('sequence'));
        });
        this.set('templateId', id);
        this.toggleDialog();
      },
      saveTemplate: function saveTemplate() {
        var _this2 = this;
        this.set('saveInProgress', true);
        this.set('errorMessage', '');
        var promise;
        var action = 'create';
        if (Ember.isEmpty(this.templateId)) {
          promise = new Ember.RSVP.Promise(function (resolve) {
            var record = _this2.store.createRecord('people-schema/set', {
              name: _this2.templateName,
              key: _this2.templateKey,
              description: _this2.templateDescription,
              personType: _this2.get('templatePersonType.value'),
              sequence: _this2.templateSequence
            });
            resolve(record);
          });
        } else {
          action = 'update';
          promise = new Ember.RSVP.Promise(function (resolve) {
            var id = _this2.templateId;
            _this2.store.findRecord('people-schema/set', id).then(function (template) {
              template.set('name', _this2.templateName);
              template.set('key', _this2.templateKey);
              template.set('description', _this2.templateDescription);
              template.set('personType', _this2.get('templatePersonType.value'));
              resolve(template);
            });
          });
        }
        promise.then(function (record) {
          return record.save().then(function () {
            _this2.set('templateDialogOpen', false);
            _this2.resetForm();
          }).catch(function (err) {
            _this2.handleError(err, action);
            record.rollbackAttributes();
          }).then(function () {
            _this2.set('saveInProgress', false);
            _this2.get('model.templates').update();
          });
        });
      },
      deleteTemplate: function deleteTemplate(id) {
        var _this3 = this;
        this.set('errorMessage', '');
        this.store.findRecord('people-schema/set', id).then(function (template) {
          _this3.set('templateName', template.get('name'));
        });
        this.set('templateId', id);
        this.set('deleteTemplateDialogOpen', true);
      },
      confirmDelete: function confirmDelete(confirmed) {
        var _this4 = this;
        return new Ember.RSVP.Promise(function (resolve, reject) {
          if (confirmed) {
            // Deletion confirmed; actually delete the record.
            _this4.set('deleteInProgress', true);

            // Grab record from local store - no GET request, as that causes weirdness.
            // We only need the ID to issue the DELETE anyway, which can't change, so no need to use findRecord.
            var template = _this4.store.peekRecord('people-schema/set', _this4.templateId);

            // Delete record (locally + pushed to server).
            return template.destroyRecord().then(resolve).catch(function (err) {
              // Remove 'deleted' flag so that entity can be interacted with again.
              template.rollbackAttributes();
              reject(err);
            });
          } else {
            resolve();
          }
        }).then(function () {
          // Tidy up / close dialog.
          _this4.set('deleteTemplateDialogOpen', false);
          _this4.set('templateId', '');
          _this4.set('templateKey', '');
          _this4.set('templateName', '');
          _this4.set('templatePersonType', 0);
          _this4.set('templateSequence', _this4.get('model.groups.length') + 1);
        }).catch(function (err) {
          _this4.handleError(err, 'delete');
        }).then(function () {
          _this4.set('deleteInProgress', false);
        });
      },
      // drag-drop sorting
      sortDragEnd: function sortDragEnd(_ref) {
        var sourceList = _ref.sourceList,
          sourceIndex = _ref.sourceIndex,
          targetList = _ref.targetList,
          targetIndex = _ref.targetIndex;
        if (sourceList === targetList && sourceIndex === targetIndex) {
          return;
        }
        var item = sourceList.objectAt(sourceIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, item);
        targetList.forEach(function (entity, index) {
          entity.set('sequence', index + 1);
          entity.save();
        });
      }
    },
    /*
    init() {
      this.set('schoolId', this.get('currentUser.user.schoolId'));
    },*/
    handleError: function handleError(err) {
      if (!Ember.isEmpty(err.errors.msg)) {
        this.set('errorMessage', err.errors.msg);
      } else {
        this.set('errorMessage', err.message);
      }
    },
    setErrorMessage: function setErrorMessage(code, action) {
      var message = 'An unknown error occurred.  Please try again.';
      var codes = {
        '400': 'Sorry, your request was invalid.  Please check all details below, and try again.',
        '403': {
          create: 'Sorry, you are not authorised to create a new user.  Please check that you are logged in as the correct user, and try again.',
          update: 'Sorry, you are not authorised to edit this user.  Please check that you are logged in as the correct user, and try again.',
          delete: 'Sorry, you are not authorised to delete this user.  Please check that you are logged in as the correct user, and try again.'
        },
        '409': 'A user already exists with that username.  Please double-check the details, or find and update the existing user.'
      };
      if (!Ember.isEmpty(codes[code][action])) {
        message = codes[code][action];
      } else if (!Ember.isEmpty(codes[code])) {
        message = codes[code];
      }
      this.set('errorMessage', message);
    },
    resetForm: function resetForm() {
      this.set('templateId', '');
      this.set('templateName', '');
      this.set('templateKey', '');
      this.set('templateDescription', '');
      this.set('templatePersonType', 0);
      this.set('templateSequence', this.get('model.groups.length') + 1);
    }
  });
  _exports.default = _default;
});