define("linced-admin/serializers/posts/post-template", ["exports", "linced-admin/serializers/posts/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    normalize: function normalize(model, hash, prop) {
      var defaults = {
        editableRoot: false,
        isLocked: false,
        visibleToParents: false,
        visibleToLearners: false,
        jurisdictions: []
      };
      Ember.assign(defaults, hash);
      hash = defaults;
      return this._super(model, hash, prop);
    }
  });
  _exports.default = _default;
});