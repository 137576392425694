define("linced-admin/serializers/school-options/group", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _rest.default.extend({
    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      return modelName.replace(/^school-options\/group/, 'optionGroup');
    },
    modelNameFromPayloadKey: function modelNameFromPayloadKey(key) {
      return this._super(key.replace(/^optionGroup/, 'school-options/group'));
    }
  });
  _exports.default = _default;
});