define("linced-admin/routes/usage-sets/index", ["exports", "linced-admin/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Extending from
  // External imports
  var _default = _base.default.extend({
    queryParams: {
      jurisdictions: {
        refreshModel: true,
        as: 'jurisdictionsFilter'
      }
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        usageSets: this.store.query('schools/usage-set', params)
      });
    }
  });
  _exports.default = _default;
});