define("linced-admin/routes/usage-sets/usage-set", ["exports", "linced-admin/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    model: function model(params) {
      return this.store.findRecord('schools/usage-set', params.set_id);
    }
  });
  _exports.default = _default;
});