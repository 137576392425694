define("linced-admin/adapters/sif/au/zone", ["exports", "linced-admin/adapters/sif/au/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    modelNamePlural: 'zones'
  });
  _exports.default = _default;
});