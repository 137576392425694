define("linced-admin/models/posts/post-template", ["exports", "@linc-technologies/linced-common/models/posts/post-template", "ember-data-model-fragments/attributes"], function (_exports, _postTemplate, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _postTemplate.default.extend({
    // jurisdictions is a list of Jurisdictions which this template applies to.
    jurisdictions: (0, _attributes.array)('number')
  });
  _exports.default = _default;
});