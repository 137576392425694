define("linced-admin/components/sif-zone", ["exports", "@linc-technologies/linced-common/enums/jurisdiction", "linced-admin/enums/sif-authentication-method", "linced-admin/enums/sif-country", "linced-admin/enums/sif-encoding", "linced-admin/enums/sif-matrix-param-location"], function (_exports, _jurisdiction, _sifAuthenticationMethod, _sifCountry, _sifEncoding, _sifMatrixParamLocation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attrs
    zoneProperties: Object.freeze(['id', 'name', 'jurisdiction', 'baseUrl', 'applicationKey', 'password', 'sessionToken', 'authenticationMethod', 'zoneIdPattern', 'oauthScopes', 'oauthTokenUrl', 'encoding', 'matrixParamLocation']),
    Jurisdiction: _jurisdiction.default,
    SifAuthenticationMethod: _sifAuthenticationMethod.default,
    SifCountry: _sifCountry.default,
    SifEncoding: _sifEncoding.default,
    SifMatrixParamLocation: _sifMatrixParamLocation.default,
    // Services
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    toaster: Ember.inject.service(),
    // Props
    // country provides the country to work within.
    country: _sifCountry.default.AUSTRALIA,
    // entityName provides the full name of the zone entity for this country.
    entityName: Ember.computed('country', function () {
      return "sif/".concat(this.country, "/zone");
    }),
    // zone provides the SIF Zone to be editing.
    zone: null,
    // newZone provides a boolean to enable creating a brand new SIF Zone.
    newZone: false,
    // onCreate provides the callback function to return the newly created
    // Zone to show the returned client ID and Secret.
    //
    // @param {DS.Model<module:linced-admin/models/sif/zone} - the SIF zone.
    onCreate: function onCreate() {},
    // State
    // zoneEditOpen provides the state of the Zone edit dialog.
    zoneEditOpen: false,
    // zoneDeleteOpen provides the state of the Zone delete dialog.
    zoneDeleteOpen: false,
    // saveInProgress provides the current state of when a record save is in
    // progress.
    saveInProgress: false,
    /* Zone properties State */
    // id contains the provided zone's id field, to enable operations to happen
    // on the copied attribute, rather than the datastore object itself in order
    // to prevent data munging.
    id: '',
    // name contains the provided zone's name field, to enable operations to
    // happen on the copied attribute, rather than the datastore object itself in
    // order to prevent data munging.
    name: '',
    // jurisdiction contains the provided zone's jurisdiction field, to enable
    // operations to happen on the copied attribute, rather than the datastore
    // object itself in order to prevent data munging.
    jurisdiction: null,
    // baseUrl contains the provided zone's baseUrl field, to enable
    // operations to happen on the copied attribute, rather than the datastore
    // object itself in order to prevent data munging.
    baseUrl: '',
    // applicationKey contains the provided zone's applicationKey field, to
    // enable operations to happen on the copied attribute, rather than the
    // datastore object itself in order to prevent data munging.
    applicationKey: '',
    // password contains the provided client's password field, to enable
    // operations to happen on the copied attribute, rather than the datastore
    // object itself in order to prevent data munging.
    password: '',
    // sessionToken contains the provided client's sessionToken field,
    // to enable operations to happen on the copied attribute, rather than the
    // datastore object itself in order to prevent data munging.
    sessionToken: '',
    // authenticationMethod contains the provided client's authenticationMethod
    // field, to enable operations to happen on the copied attribute, rather than
    // the datastore object itself in order to prevent data munging.
    authenticationMethod: _sifAuthenticationMethod.default.BASIC,
    // zoneIdPattern contains a pattern for appending a Zone ID to requests.
    zoneIdPattern: '',
    // oauthScopes contains a comma-separated list of scopes to request when
    // using oauth2 client credentials authentication.
    oauthScopes: '',
    // oauthTokenUrl contains the oauth2 token endpoint URL, for use with
    // oauth2 client credentials authentication.
    oauthTokenUrl: '',
    // encoding denotes the encoding method which this Zone uses.
    encoding: _sifEncoding.default.XML,
    // matrixParamLocation specifies where matrix parameters should be added to
    // HTTP requests.
    matrixParamLocation: _sifMatrixParamLocation.default.URL,
    // Methods
    /**
     * resetForm resets the required state properties to defaults.
     */
    resetForm: function resetForm() {
      this.setProperties({
        id: '',
        name: '',
        jurisdiction: null,
        baseUrl: '',
        applicationKey: '',
        password: '',
        sessionToken: '',
        authenticationMethod: _sifAuthenticationMethod.default.BASIC,
        zoneIdPattern: '',
        oauthScopes: '',
        oauthTokenUrl: '',
        encoding: _sifEncoding.default.XML,
        matrixParamLocation: _sifMatrixParamLocation.default.URL,
        // State reset
        uploading: false
      });
    },
    /**
     * toggleEdit toggles the edit zone dialog open, resets the form and
     * creates a deep copy of the provided zone into the component's zone
     * state variables, ready for editing.
     */
    toggleEdit: function toggleEdit() {
      var zone = this.zone;
      this.toggleProperty('zoneEditOpen');
      if (!this.zoneEditOpen) {
        this.resetForm();
        return;
      }
      if (!Ember.isEmpty(zone)) {
        this.setProperties(zone.getProperties(this.zoneProperties));
      }
    },
    /**
     * createZone creates a new SIF Zone on the server.
     *
     * @param {Object} props - contains the zone properties that are wanting to
     *     be pushed into the zone on create.
     */
    createZone: function createZone(props) {
      var _this = this;
      var newClient = this.store.createRecord(this.entityName, Ember.getProperties(this, props));
      newClient.save().then(function (zone) {
        _this.onCreate(zone);
        _this.toaster.success('SIF Zone created successfully!');
        _this.toggleEdit();
      }).catch(function (err) {
        _this.toaster.error("Error creating new SIF Zone! ".concat(err));
      }).finally(function () {
        _this.set('saveInProgress', false);
      });
    },
    /**
     * updateZone updates the current SIF Zone on the server.
     *
     * @param {Object} props - contains the zone properties that are wanting to
     *     be pushed into the zone on update.
     */
    updateZone: function updateZone(props) {
      var _this2 = this;
      this.store.findRecord(this.entityName, this.id).then(function (zone) {
        zone.setProperties(Ember.getProperties(_this2, props));
        return zone.save();
      }).then(function () {
        _this2.toaster.success("SIF Zone saved successfully!");
        _this2.toggleEdit();
      }).catch(function (err) {
        _this2.toaster.error("Error saving SIF Zone! ".concat(err));
      }).finally(function () {
        _this2.set('saveInProgress', false);
      });
    },
    /**
     * deleteZone deletes the current SIF Zone from the server.
     */
    deleteZone: function deleteZone() {
      var _this3 = this;
      this.set('deleteInProgress', true);
      if (!Ember.isEmpty(this.onDelete)) {
        this.onDelete(this.zone);
        this.set('deleteInProgress', false);
        this.set('zoneDeleteOpen', false);
        return;
      }
      this.store.findRecord(this.entityName, this.zone.id, {
        backgroundReload: false
      }).then(function (zone) {
        zone.destroyRecord();
      }).then(function () {
        _this3.toaster.success("SIF Zone deleted successfully!");
      }).catch(function () {
        _this3.toaster.error("Error deleting SIF Zone!");
      }).finally(function () {
        _this3.set('deleteInProgress', false);
        _this3.set('zoneDeleteOpen', false);
      });
    },
    // Actions
    actions: {
      noOp: function noOp() {},
      changeJurisdiction: function changeJurisdiction(jurisdiction) {
        this.set('jurisdiction', jurisdiction.value);
      },
      changeAuthMethod: function changeAuthMethod(method) {
        this.set('authenticationMethod', method.value);
      },
      changeEncoding: function changeEncoding(encoding) {
        this.set('encoding', encoding.value);
      },
      changeMatrixParamLocation: function changeMatrixParamLocation(location) {
        this.set('matrixParamLocation', location.value);
      },
      // onSave performs either a save, or update based on whether the provided
      // newZone property is set to true or false.
      onSave: function onSave() {
        var properties = this.zoneProperties;
        this.set('saveInProgress', true);
        if (this.newZone) {
          this.createZone(properties);
        } else {
          this.updateZone(properties);
        }
      },
      // onDelete performs a zone deletion.
      onDelete: function onDelete() {
        this.deleteZone();
      },
      // toggleZoneEdit toggles the zone edit dialog on or off.
      toggleZoneEdit: function toggleZoneEdit() {
        this.toggleEdit();
      },
      // toggleZoneDelete toggles the zone delete dialog on or off.
      toggleZoneDelete: function toggleZoneDelete() {
        this.toggleProperty('zoneDeleteOpen');
        if (!this.zoneDeleteOpen) {
          this.resetForm();
        }
      }
    },
    // Life Cycle hooks
    didReceiveAttrs: function didReceiveAttrs() {
      if (Ember.isEmpty(this.zone)) {
        // If no zone is presented, set everything to expected default values.
        this.resetForm();
      }
    }
  });
  _exports.default = _default;
});