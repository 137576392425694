define("linced-admin/serializers/posts/base", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _rest.default.extend({
    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      var model = this._super(modelName).replace(/^posts\//, '');
      // Page templates just have a payload key of "template"
      if (model === 'page') {
        return 'template';
      }
      return model + 'Template';
    },
    modelNameFromPayloadKey: function modelNameFromPayloadKey(key) {
      // Page templates just have a payload key of "template"
      if (key === 'template') {
        key = 'page';
      } else if (key === 'templates') {
        key = 'page';
      }
      return this._super('posts/' + key.replace(/Template/i, ''));
    }
  });
  _exports.default = _default;
});