define("linced-admin/controllers/school-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    groupDialogOpen: false,
    group: null,
    actions: {
      toggleGroupDialog: function toggleGroupDialog() {
        this.toggleProperty('groupDialogOpen');
      },
      newGroup: function newGroup() {
        this.set('group', this.store.createRecord('schools/group'));
        this.set('groupDialogOpen', true);
      },
      editGroup: function editGroup(group) {
        var _this = this;
        this.store.findRecord('schools/group', group.id).then(function (record) {
          _this.set('group', record);
          _this.set('groupDialogOpen', true);
        });
      }
    }
  });
  _exports.default = _default;
});