define("linced-admin/components/top-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    currentUser: Ember.inject.service('current-user'),
    store: Ember.inject.service(),
    peopleSelectorOpen: false,
    peopleSelectorClass: '',
    groupSelectorOpen: false,
    groupSelectorClass: '',
    students: Ember.A(),
    groups: Ember.A(),
    actions: {
      invalidateSession: function invalidateSession() {
        this.session.invalidate();
      },
      togglePeopleSelector: function togglePeopleSelector() {
        var newClass = '';
        this.set('groupSelectorOpen', false);
        this.set('groupSelectorClass', '');
        this.toggleProperty('peopleSelectorOpen');
        newClass = this.peopleSelectorOpen ? 'active' : '';
        this.set('peopleSelectorClass', newClass);
      },
      toggleGroupSelector: function toggleGroupSelector() {
        var newClass = '';
        this.set('peopleSelectorOpen', false);
        this.set('peopleSelectorClass', '');
        this.toggleProperty('groupSelectorOpen');
        newClass = this.groupSelectorOpen ? 'active' : '';
        this.set('groupSelectorClass', newClass);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.set('students', this.store.peekAll('student'));
      this.set('groups', this.store.peekAll('group'));
    }
  });
  _exports.default = _default;
});