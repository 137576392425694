define("linced-admin/helpers/permissions-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.extend({
    currentUser: Ember.inject.service(),
    permissions: Ember.inject.service(),
    // TODO: Don't use observers if possible.
    // eslint-disable-next-line
    onPermissionChange: Ember.observer('currentUser.user.permission', function () {
      // Recompute helper when the user's permission value changes.
      // This also catches the value being initially set, which can happen after this helper is first called.
      this.recompute();
    }),
    compute: function compute() {
      // Need to get the current user's permission value in order for the observer above to kick in.
      // See https://github.com/emberjs/ember.js/issues/10821 for details.
      this.get('currentUser.user.permission');
      return false;
    }
  });
  _exports.default = _default;
});