define("linced-admin/routes/hero-notices/index", ["exports", "linced-admin/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    queryParams: {
      offset: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var offset = 0;
      if (!Ember.isEmpty(params.offset)) {
        var temp = Number(params.offset);
        if (!Number.isNaN(temp) && temp > 0) {
          offset = temp;
        }
      }
      return Ember.RSVP.hash({
        posts: this.store.query('posts/globalnotice', {
          perPage: 20,
          offset: offset
        }),
        groups: this.store.findAll('schools/group')
      });
    }
  });
  _exports.default = _default;
});