define("linced-admin/serializers/milestones/base", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _rest.default.extend({
    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      var model = this._super(modelName).replace(/^milestones\//, '');
      if (model === 'set') {
        return 'templateSet';
      }
      return model + 'Template';
    },
    modelNameFromPayloadKey: function modelNameFromPayloadKey(key) {
      return this._super('milestones/' + key.replace(/Template/i, ''));
    }
  });
  _exports.default = _default;
});