define("linced-admin/serializers/school", ["exports", "@ember-data/serializer/rest", "@linc-technologies/linced-common/serializers/school"], function (_exports, _rest, _school) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _school.default.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      optionSetIds: {
        serialize: 'ids'
      }
    }
  });
  _exports.default = _default;
});