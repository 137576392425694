define("linced-admin/controllers/assessments/assessment", ["exports", "linced-admin/mixins/edit-assessment", "@linc-technologies/linced-common/enums/assessment-type"], function (_exports, _editAssessment, _assessmentType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Internal imports
  // External Imports
  var _default = Ember.Controller.extend(_editAssessment.default, {
    AssessmentType: _assessmentType.default,
    // Services
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    toaster: Ember.inject.service(),
    assessment: Ember.computed.alias('model.assessment'),
    modules: Ember.computed.alias('model.modules'),
    editingModule: null,
    moduleLabel: '',
    moduleDescription: '',
    moduleIsModerated: false,
    moduleSharesEvidence: false,
    selectedGradeSchedule: null,
    deleteDialogOpen: false,
    entityToDelete: null,
    deleteErrorMessage: '',
    // availableSchedules is an array of schedules which are available to the current user.
    availableSchedules: Ember.computed.filter('model.gradeschedules', function (schedule) {
      var personId = this.get('currentUser.personId');
      var selectedId = this.get('selectedGradeSchedule.id');
      return schedule.get('isShared') || schedule.get('authorId') === personId || schedule.get('id') === selectedId;
    }).property('model.gradeschedules.[]', 'editingModule.id'),
    actions: {
      selectGradeSchedule: function selectGradeSchedule(schedule) {
        this.set('selectedGradeSchedule', schedule);
      },
      saveModule: function saveModule() {
        var _this = this;
        var module = this.editingModule;
        var store = this.store;
        var isNew = false;
        this.set('moduleSaveInProgress', true);

        // Create a new Module if needed
        if (Ember.isEmpty(module)) {
          isNew = true;
          module = store.createRecord('assessments/module', {
            schoolId: this.get('currentUser.school'),
            assessmentId: this.assessment,
            authorId: this.get('currentUser.person'),
            // Add to the end by default
            sequence: this.get('assessment.modules.length')
          });
        }

        // Set properties from form
        module.setProperties({
          label: this.moduleLabel,
          description: this.moduleDescription,
          isModerated: this.moduleIsModerated,
          sharesEvidence: this.moduleSharesEvidence,
          gradeScheduleId: this.selectedGradeSchedule
        });
        module.save().then(function () {
          _this.set('moduleDialogOpen', false);
          if (isNew) {
            // Reload the list of modules so that the new one appears.
            _this.modules.update();
          }
        }).catch(function (err) {
          _this.set('moduleErrorMessage', err);
        }).then(function () {
          _this.set('moduleSaveInProgress', false);
        });
      },
      toggleModuleDialog: function toggleModuleDialog() {
        this.toggleProperty('moduleDialogOpen');
      },
      newModule: function newModule() {
        this.resetModuleForm();
        this.set('moduleDialogOpen', true);
      },
      editModule: function editModule(module) {
        this.resetModuleForm();
        this.set('editingModule', module);
        this.set('moduleLabel', module.get('label'));
        this.set('moduleDescription', module.get('description'));
        this.set('moduleIsModerated', module.get('isModerated'));
        this.set('moduleSharesEvidence', module.get('sharesEvidence'));
        this.set('selectedGradeSchedule', module.get('gradeSchedule'));
        this.set('moduleDialogOpen', true);
      },
      moveModule: function moveModule(_ref) {
        var sourceList = _ref.sourceList,
          sourceIndex = _ref.sourceIndex,
          targetList = _ref.targetList,
          targetIndex = _ref.targetIndex;
        if (sourceList === targetList && sourceIndex === targetIndex) {
          // No change in list or position.
          return;
        }
        var item = sourceList.objectAt(sourceIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, item);

        // Update assessment's module sequences.
        targetList.forEach(function (item, index) {
          item.set('sequence', index);
          if (item.get('hasDirtyAttributes')) {
            item.save();
          }
        });
      },
      deleteModule: function deleteModule(module) {
        this.setProperties({
          deleteDialogOpen: true,
          entityToDelete: module
        });
      },
      confirmDelete: function confirmDelete(confirmed) {
        var _this2 = this;
        if (!confirmed) {
          // reset the deletion values
          this.setProperties({
            deleteDialogOpen: false,
            entityToDelete: null,
            deleteErrorMessage: ''
          });

          // we don't want to delete anything 👋
          return;
        }
        return new Ember.RSVP.Promise(function (resolve, reject) {
          // Deletion confirmed; actually delete the record.
          _this2.set('deleteInProgress', true);
          var entity = _this2.get('entityToDelete');
          // Delete record (locally + pushed to server).
          return entity.destroyRecord().then(resolve).catch(function (err) {
            // Remove 'deleted' flag so that entity can be interacted with again.
            entity.rollbackAttributes();
            reject(err);
          });
        }).then(function () {
          // Tidy up / close dialog.
          _this2.setProperties({
            deleteDialogOpen: false,
            entityToDelete: null,
            deleteErrorMessage: ''
          });
          _this2.toaster.success("Assessment module deleted successfully.");
        }).catch(function (err) {
          if (!Ember.isEmpty(err)) {
            if (err.errors && err.errors.length > 0) {
              _this2.set('deleteErrorMessage', err.errors[0].detail);
              return;
            }
            if (err.message) {
              _this2.set('deleteErrorMessage', err.message);
              return;
            }
          }
          _this2.set('deleteErrorMessage', 'An unknown error occurred. Please try again.');
        }).then(function () {
          _this2.set('deleteInProgress', false);
        });
      }
    },
    resetModuleForm: function resetModuleForm() {
      this.set('moduleErrorMessage', '');
      this.set('moduleLabel', '');
      this.set('moduleDescription', '');
      this.set('moduleIsModerated', this.get('assessment.isModerated'));
      this.set('moduleSharesEvidence', this.get('assessment.sharesEvidence'));
      this.set('selectedGradeSchedule', this.get('assessment.gradeSchedule'));
      this.set('editingModule', null);
    }
  });
  _exports.default = _default;
});