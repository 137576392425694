define("linced-admin/enums/enrolment-nz-service", ["exports", "linced-admin/enums/base-enum"], function (_exports, _baseEnum) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _baseEnum.default.create({
    values: Object.freeze([{
      value: 1,
      label: 'DEX'
    }])
  });
  _exports.default = _default;
});