define("linced-admin/models/schools/usage-set", ["exports", "@ember-data/model", "ember-data-model-fragments/attributes", "@linc-technologies/linced-common/enums/jurisdiction"], function (_exports, _model, _attributes, _jurisdiction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Extends from
  // Ember imports
  // External imports
  var _default = _model.default.extend({
    createTime: (0, _model.attr)('number'),
    updateTime: (0, _model.attr)('number'),
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    jurisdictions: (0, _attributes.array)('number'),
    pageIds: (0, _model.hasMany)('posts/page'),
    goalSubjectIds: (0, _model.hasMany)('goals/subject'),
    milestoneSetIds: (0, _model.hasMany)('milestones/set'),
    groupTemplateIds: (0, _model.hasMany)('group'),
    assessmentTemplateIds: (0, _model.hasMany)('assessments/assessment'),
    tagCategoryTemplatesIds: (0, _model.hasMany)('posts/tag-category'),
    // displayedJurisdictions converts numeric values into Jurisdiction enum objects
    // for editing purposes
    displayedJurisdictions: Ember.computed('jurisdictions.[]', function () {
      if (Ember.isEmpty(this.jurisdictions)) {
        return [];
      }
      var enumObjectsArray = this.jurisdictions.map(function (value) {
        return Object.values(_jurisdiction.default.values).find(function (obj) {
          return obj.value === value;
        });
      });
      return enumObjectsArray;
    })
  });
  _exports.default = _default;
});