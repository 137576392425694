define("linced-admin/components/sync-progress-manager", ["exports", "ember-get-config", "@linc-technologies/linced-common/enums/connection-status"], function (_exports, _emberGetConfig, _connectionStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    ConnectionStatus: _connectionStatus.default,
    // services
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    toaster: Ember.inject.service(),
    // params
    schoolId: '',
    lastSyncTime: 'last sync',
    status: null,
    // variables
    dialogueOpen: false,
    openLatestSync: false,
    fullSync: false,
    processingSync: false,
    performSync: function performSync() {
      var _this = this;
      this.set('processingSync', true);
      var url = "".concat(_emberGetConfig.default.APP.apiHost, "/providers/wonde/").concat(_emberGetConfig.default.APP.apiPath, "/sync:start");
      var params = {
        schoolId: this.schoolId,
        fullSync: this.fullSync
      };
      this.ajax.post(url, {
        data: JSON.stringify(params)
      }).then(function () {
        setTimeout(function () {
          _this.toaster.success("".concat(_this.fullSync ? 'Full' : 'Partial', " sync started"));
          _this.setProperties({
            dialogueOpen: true,
            openLatestSync: true,
            fullSync: false,
            processingSync: false
          });
        }, 1000);
      }).catch(function () {
        _this.toaster.error("Error creating new ".concat(_this.fullSync ? 'full' : 'partial', " sync: Please wait for all current syncs to resolve before starting a new one"));
        _this.setProperties({
          fullSync: false,
          processingSync: false
        });
      });
    },
    actions: {
      triggerPartialSync: function triggerPartialSync() {
        this.set('fullSync', false);
        this.performSync();
      },
      triggerFullSync: function triggerFullSync() {
        this.set('fullSync', true);
        this.performSync();
      },
      toggleDialogue: function toggleDialogue() {
        this.set('dialogueOpen', !this.dialogueOpen);
        if (this.openLatestSync) {
          this.set('openLatestSync', false);
        }
      }
    }
  });
  _exports.default = _default;
});