define("linced-admin/templates/components/publishable-post-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ZLAmKOEW",
    "block": "{\"symbols\":[\"post\"],\"statements\":[[4,\"if\",[[24,[\"newPost\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"dummy-new-post\"],[11,\"onClick\",[28,\"action\",[[23,0,[]],[24,[\"newPost\"]]],null]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"new-post-dialog\"],[8],[0,\"Share something...\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"post-container\",[28,\"if\",[[24,[\"homePage\"]],\" home-page\"],null]]]],[8],[0,\"\\n  \"],[5,\"memory-scroll\",[],[[\"@key\"],[\"post-list\"]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[28,\"sort-by\",[\"date:desc\",[24,[\"filteredPosts\"]]],null]],null,{\"statements\":[[0,\"      \"],[5,\"single-post\",[],[[\"@avatar\",\"@class\",\"@disableActions\",\"@editPost\",\"@isRead\",\"@post\",\"@prerenderComments\",\"@setFilterTag\",\"@studentId\",\"@trackReadState\"],[[22,\"avatar\"],[28,\"if\",[[28,\"eq\",[[23,1,[\"status\"]],[24,[\"PostStatus\",\"PUBLISHED\"]]],null],\"post-published\",\"post-draft\"],null],[22,\"disableActions\"],[22,\"editPost\"],[28,\"contains\",[[23,1,[\"id\"]],[24,[\"readPostIds\"]]],null],[23,1,[]],[22,\"prerenderComments\"],[28,\"action\",[[23,0,[]],\"setFilterTag\"],null],[24,[\"student\",\"id\"]],[22,\"trackReadState\"]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "linced-admin/templates/components/publishable-post-list.hbs"
    }
  });
  _exports.default = _default;
});