define("linced-admin/serializers/posts/page", ["exports", "linced-admin/serializers/posts/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Extends from
  // Ember imports
  var _default = _base.default.extend({
    normalize: function normalize(model, hash, prop) {
      var defaults = {
        assessmentIds: [],
        goalSubjectIds: [],
        tagCategoryIds: []
      };
      Ember.assign(defaults, hash);
      hash = defaults;
      return this._super(model, hash, prop);
    }
  });
  _exports.default = _default;
});