define("linced-admin/models/enrolments/credential-nz", ["exports", "@ember-data/model", "ember-data-model-fragments"], function (_exports, _model, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberDataModelFragments.default.Fragment.extend({
    // service specifies the New Zealand service these credentials are for.
    service: (0, _model.attr)('number'),
    // enrolId is the unique school identifier provided from ENROL in order
    // to authenticate the school to the ENROL API.
    enrolId: (0, _model.attr)('number'),
    // password is the password provided from ENROL in order to authenticate the school to the ENROL API
    password: (0, _model.attr)('string')
  });
  _exports.default = _default;
});