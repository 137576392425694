define("linced-admin/models/posts/tag", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    createTime: (0, _model.attr)('number'),
    updateTime: (0, _model.attr)('number'),
    label: (0, _model.attr)('string'),
    sequence: (0, _model.attr)('number'),
    categoryId: (0, _model.belongsTo)('posts/tagCategory', {
      inverse: 'tags'
    }),
    category: Ember.computed.alias('categoryId')
  });
  _exports.default = _default;
});