define("linced-admin/enums/page-type", ["exports", "@linc-technologies/linced-common/enums/base-enum"], function (_exports, _baseEnum) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _baseEnum.default.create({
    values: Object.freeze([{
      value: 1,
      label: 'Student'
    }, {
      value: 2,
      label: 'Staff'
    }, {
      value: 3,
      label: 'School-wide (internal)'
    }, {
      value: 4,
      label: 'School-wide (public)'
    }])
  });
  _exports.default = _default;
});