define("linced-admin/serializers/schools/group", ["exports", "linced-admin/serializers/schools/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      // ListGroups returns an empty object if there are no results.
      // Ember Data doesn't like that - inject an empty array to appease it.
      switch (requestType) {
        case 'query':
        case 'findAll':
          if (!payload.hasOwnProperty('groups')) {
            payload.groups = [];
          }
          break;
      }
      return this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});