define("linced-admin/controllers/auth/clients", ["exports", "@linc-technologies/linced-common/enums/data-type", "@linc-technologies/linced-common/enums/jurisdiction", "linced-admin/utils/filtering"], function (_exports, _dataType, _jurisdiction, _filtering) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // availableJurisdictionScopes
  var availableJurisdictionScopes = [{
    service: 'jurisdictions',
    resource: 'New Zealand',
    permission: '(Ministry of Education)',
    enum: _jurisdiction.default.NZ_MOE.toString(),
    value: 'urn:hero:jurisdictions:nz:moe'
  }, {
    service: 'jurisdictions',
    resource: 'International',
    permission: '(Not Applicable)',
    enum: _jurisdiction.default.INDEPENDENT_SCHOOL.toString(),
    value: 'urn:hero:jurisdictions:intl:na'
  }];

  // availableScopes specify all the scopes we have within our system that can be
  // applied to a OAuth Client.
  var availableScopes = [].concat(availableJurisdictionScopes, [
  // Meta Scopes
  {
    service: 'meta',
    resource: 'Hero Admin',
    permission: '*',
    value: 'urn:linced:meta:admin'
  }, {
    service: 'meta',
    resource: 'Hero Manager',
    permission: '*',
    value: 'urn:linced:meta:manager'
  }, {
    service: 'meta',
    resource: 'services',
    permission: '*',
    value: 'urn:linced:meta:service'
  },
  // App scopes
  {
    service: 'Hero',
    resource: 'Admin App',
    permission: '',
    value: 'linced.admin'
  }, {
    service: 'Hero',
    resource: 'Teacher App',
    permission: '',
    value: 'linced.app'
  }, {
    service: 'Hero',
    resource: 'Caregiver App',
    permission: '',
    value: 'linced.parents'
  }, {
    service: 'Hero',
    resource: 'Student App',
    permission: '',
    value: 'linced.learners'
  }, {
    service: 'Hero',
    resource: 'Gatekeeper',
    permission: '',
    value: 'hero.gatekeeper'
  },
  // LINC-ED First-Party Role Based Scopes
  {
    service: 'LINC-ED',
    resource: 'Tenant Admin',
    permission: 'write/update/read',
    value: 'urn:linced:organisation:role:admin'
  }, {
    service: 'LINC-ED',
    resource: 'Tenant Staff',
    permission: 'write/update/read',
    value: 'urn:linced:organisation:role:staff'
  }, {
    service: 'LINC-ED',
    resource: 'Tenant Caregiver',
    permission: 'write/update/read',
    value: 'urn:linced:organisation:role:caregiver'
  }, {
    service: 'LINC-ED',
    resource: 'Tenant Learner',
    permission: 'write/update/read',
    value: 'urn:linced:organisation:role:learner'
  },
  // API Consumer Scopes
  // Attendance Service
  {
    service: 'attendance',
    resource: 'records',
    permission: 'write/update/read',
    value: 'urn:hero:attendance:records'
  }, {
    service: 'attendance',
    resource: 'records',
    permission: 'read',
    value: 'urn:hero:attendance:records:read'
  }, {
    service: 'attendance',
    resource: 'records',
    permission: 'delete',
    value: 'urn:hero:attendance:records:delete'
  }, {
    service: 'attendance',
    resource: 'audits',
    permission: 'read',
    value: 'urn:hero:attendance:audits:read'
  }, {
    service: 'attendance',
    resource: 'registers',
    permission: 'write/update/read',
    value: 'urn:hero:attendance:registers'
  }, {
    service: 'attendance',
    resource: 'registers',
    permission: 'read',
    value: 'urn:hero:attendance:registers:read'
  }, {
    service: 'attendance',
    resource: 'registers',
    permission: 'delete',
    value: 'urn:hero:attendance:registers:delete'
  },
  // Auth Service
  {
    service: 'auth',
    resource: 'clients',
    permission: 'write/update/read',
    value: 'urn:hero:auth:clients'
  }, {
    service: 'auth',
    resource: 'clients',
    permission: 'read',
    value: 'urn:hero:auth:clients:read'
  }, {
    service: 'auth',
    resource: 'clients',
    permission: 'delete',
    value: 'urn:hero:auth:clients:delete'
  }, {
    service: 'auth',
    resource: 'users',
    permission: 'write/update/read',
    value: 'urn:hero:auth:users'
  }, {
    service: 'auth',
    resource: 'users',
    permission: 'read',
    value: 'urn:hero:auth:users:read'
  }, {
    service: 'auth',
    resource: 'users',
    permission: 'delete',
    value: 'urn:hero:auth:users:delete'
  },
  // Dates Service
  {
    service: 'dates',
    resource: 'dates',
    permission: 'write/update/read',
    value: 'urn:hero:dates:dates'
  }, {
    service: 'dates',
    resource: 'dates',
    permission: 'read',
    value: 'urn:hero:dates:dates:read'
  }, {
    service: 'dates',
    resource: 'dates',
    permission: 'delete',
    value: 'urn:hero:dates:dates:delete'
  },
  // Enrolments Service
  {
    service: 'enrolments',
    resource: 'applications',
    permission: 'write/update/read',
    value: 'urn:hero:enrolments:applications'
  }, {
    service: 'enrolments',
    resource: 'applications',
    permission: 'read',
    value: 'urn:hero:enrolments:applications:read'
  }, {
    service: 'enrolments',
    resource: 'applications',
    permission: 'delete',
    value: 'urn:hero:enrolments:applications:delete'
  },
  // Finance Service
  {
    service: 'finance',
    resource: 'transact',
    permission: 'write/update/read',
    value: 'urn:hero:finance:transact'
  }, {
    service: 'finance',
    resource: 'schools',
    permission: 'write/update/read',
    value: 'urn:hero:finance:schools'
  }, {
    service: 'finance',
    resource: 'ledgers',
    permission: 'write/update/read',
    value: 'urn:hero:finance:ledgers'
  }, {
    service: 'finance',
    resource: 'ledgers',
    permission: 'read',
    value: 'urn:hero:finance:ledgers:read'
  }, {
    service: 'finance',
    resource: 'ledgers',
    permission: 'delete',
    value: 'urn:hero:finance:ledgers:delete'
  }, {
    service: 'finance',
    resource: 'ledger items',
    permission: 'write/update/read',
    value: 'urn:hero:finance:ledgeritems'
  }, {
    service: 'finance',
    resource: 'ledger items',
    permission: 'read',
    value: 'urn:hero:finance:ledgeritems:read'
  }, {
    service: 'finance',
    resource: 'ledger items',
    permission: 'delete',
    value: 'urn:hero:finance:ledgeritems:delete'
  }, {
    service: 'finance',
    resource: 'line items',
    permission: 'write/update/read',
    value: 'urn:hero:finance:lineitems'
  }, {
    service: 'finance',
    resource: 'line items',
    permission: 'read',
    value: 'urn:hero:finance:lineitems:read'
  }, {
    service: 'finance',
    resource: 'line items',
    permission: 'delete',
    value: 'urn:hero:finance:lineitems:delete'
  }, {
    service: 'finance',
    resource: 'access permissions',
    permission: 'write/update/read',
    value: 'urn:hero:finance:accesspermissions'
  }, {
    service: 'finance',
    resource: 'access permissions',
    permission: 'read',
    value: 'urn:hero:finance:accesspermissions:read'
  }, {
    service: 'finance',
    resource: 'access permissions',
    permission: 'delete',
    value: 'urn:hero:finance:accesspermissions:delete'
  }, {
    service: 'finance',
    resource: 'accounts',
    permission: 'write/update/read',
    value: 'urn:hero:finance:accounts'
  }, {
    service: 'finance',
    resource: 'accounts',
    permission: 'read',
    value: 'urn:hero:finance:accounts:read'
  }, {
    service: 'finance',
    resource: 'accounts',
    permission: 'delete',
    value: 'urn:hero:finance:accounts:delete'
  }, {
    service: 'finance',
    resource: 'transactions',
    permission: 'write/update/read',
    value: 'urn:hero:finance:transactions'
  }, {
    service: 'finance',
    resource: 'transactions',
    permission: 'read',
    value: 'urn:hero:finance:transactions:read'
  }, {
    service: 'finance',
    resource: 'transactions',
    permission: 'delete',
    value: 'urn:hero:finance:transactions:delete'
  }, {
    service: 'finance',
    resource: 'stripe connections',
    permission: 'write/update/read',
    value: 'urn:hero:finance:stripeconnections'
  }, {
    service: 'finance',
    resource: 'stripe connections',
    permission: 'read',
    value: 'urn:hero:finance:stripeconnections:read'
  }, {
    service: 'finance',
    resource: 'stripe connections',
    permission: 'delete',
    value: 'urn:hero:finance:stripeconnections:delete'
  }, {
    service: 'finance',
    resource: 'stripe transactions',
    permission: 'write/update/read',
    value: 'urn:hero:finance:stripetransactions'
  }, {
    service: 'finance',
    resource: 'stripe transactions',
    permission: 'read',
    value: 'urn:hero:finance:stripetransactions:read'
  }, {
    service: 'finance',
    resource: 'stripe transactions',
    permission: 'delete',
    value: 'urn:hero:finance:stripetransactions:delete'
  },
  // Groups Service
  {
    service: 'groups',
    resource: 'groups',
    permission: 'write/update/read',
    value: 'urn:hero:groups:groups'
  }, {
    service: 'groups',
    resource: 'groups',
    permission: 'read',
    value: 'urn:hero:groups:groups:read'
  }, {
    service: 'groups',
    resource: 'groups',
    permission: 'delete',
    value: 'urn:hero:groups:groups:delete'
  },
  // People Service
  {
    service: 'people',
    resource: 'enrolments',
    permission: 'write/update/read',
    value: 'urn:hero:people:enrolments'
  }, {
    service: 'people',
    resource: 'enrolments',
    permission: 'read',
    value: 'urn:hero:people:enrolments:read'
  }, {
    service: 'people',
    resource: 'enrolments',
    permission: 'delete',
    value: 'urn:hero:people:enrolments:delete'
  }, {
    service: 'people',
    resource: 'people',
    permission: 'write/update/read',
    value: 'urn:hero:people:people'
  }, {
    service: 'people',
    resource: 'people',
    permission: 'read',
    value: 'urn:hero:people:people:read'
  }, {
    service: 'people',
    resource: 'people',
    permission: 'delete',
    value: 'urn:hero:people:people:delete'
  }, {
    service: 'people',
    resource: 'access requests',
    permission: 'write/update/read',
    value: 'urn:hero:people:requests'
  }, {
    service: 'people',
    resource: 'access requests',
    permission: 'read',
    value: 'urn:hero:people:requests:read'
  }, {
    service: 'people',
    resource: 'access requests',
    permission: 'delete',
    value: 'urn:hero:people:requests:delete'
  },
  // Schools Service
  {
    service: 'schools',
    resource: 'schools',
    permission: 'write/update/read',
    value: 'urn:hero:schools:schools'
  }, {
    service: 'schools',
    resource: 'schools',
    permission: 'read',
    value: 'urn:hero:schools:schools:read'
  }, {
    service: 'schools',
    resource: 'schools',
    permission: 'delete',
    value: 'urn:hero:schools:schools:delete'
  },
  // Timetable Service
  {
    service: 'timetable',
    resource: 'periods',
    permission: 'write/update/read',
    value: 'urn:hero:timetable:periods'
  }, {
    service: 'timetable',
    resource: 'periods',
    permission: 'read',
    value: 'urn:hero:timetable:periods:read'
  }, {
    service: 'timetable',
    resource: 'structures',
    permission: 'write/update/read',
    value: 'urn:hero:timetable:structures'
  }, {
    service: 'timetable',
    resource: 'structures',
    permission: 'read',
    value: 'urn:hero:timetable:structures:read'
  }, {
    service: 'timetable',
    resource: 'structures',
    permission: 'archive',
    value: 'urn:hero:timetable:structures:archive'
  }]);
  var _default = Ember.Controller.extend({
    // Attrs
    // filterVal provides the params entered into the oauth client filter box.
    filterVal: '',
    availableJurisdictions: availableJurisdictionScopes,
    availableScopes: availableScopes,
    // Services
    store: Ember.inject.service(),
    // State
    newClient: null,
    clientNewOpen: false,
    clientNewConfirmOpen: false,
    // Methods
    /**
     * filteredGradeSchedules filters oauth clients based on the user's filter.
     */
    filteredClients: Ember.computed('model.clients.[]', 'filterVal', function () {
      var _this = this;
      if (Ember.isEmpty(this.filterVal)) {
        return this.model.clients;
      }
      return this.model.clients.filter(function (client) {
        return (0, _filtering.filterValue)(client.name, _dataType.default.STRING, _this.filterVal);
      });
    }),
    // Actions
    actions: {
      onCreate: function onCreate(client) {
        this.set('newClient', client);
        this.set('clientNewOpen', false);
        this.set('clientNewConfirmOpen', true);
      },
      closeNewClientConfirm: function closeNewClientConfirm() {
        this.set('newClient', null);
        this.set('clientNewConfirmOpen', false);
      }
    }
  });
  _exports.default = _default;
});