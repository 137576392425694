define("linced-admin/components/apply-template", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    ajax: Ember.inject.service(),
    // Attributes
    // Passed in
    customMessage: '',
    schoolGroups: Ember.A(),
    schools: Ember.A(),
    serviceName: '',
    sortByAttr: '',
    templateEndpoint: '',
    templates: Ember.A(),
    templateType: '',
    usageSets: Ember.A(),
    // Dialogue attributes
    applyConfirmed: false,
    applyDialogOpen: false,
    applyErrors: null,
    applyInProgress: false,
    currentStep: 0,
    selectedGroupFilter: null,
    selectedSchoolsToApply: Ember.A(),
    selectedTemplatesToApply: Ember.A(),
    selectedUsageSetsFilter: Ember.A(),
    // Computed
    remainingUsageSetsFilter: Ember.computed.setDiff('usageSets', 'selectedUsageSetsFilter'),
    filteredSchools: Ember.computed('schools.[]', 'selectedGroupFilter', function () {
      var schools = this.schools;
      if (!Ember.isEmpty(this.selectedGroupFilter)) {
        schools = this.selectedGroupFilter.get('schools');
      }
      return schools;
    }),
    filteredTemplates: Ember.computed('templates.[]', 'selectedUsageSetsFilter.[]', function () {
      var _this = this;
      return this.templates.filter(function (template) {
        if (!Ember.isEmpty(_this.selectedUsageSetsFilter)) {
          return _this.selectedUsageSetsFilter.some(function (usageSet) {
            return template.get('usageSetIds').includes(usageSet);
          });
        }
        return true;
      });
    }),
    actions: {
      openApplyDialog: function openApplyDialog() {
        this.setProperties({
          applyDialogOpen: true,
          currentStep: 0,
          selectedSchoolsToApply: Ember.A(),
          selectedTemplatesToApply: Ember.A(),
          selectedUsageSetsFilter: Ember.A()
        });
      },
      closeApplyDialog: function closeApplyDialog() {
        this.setProperties({
          applyConfirmed: false,
          applyDialogOpen: false,
          applyErrors: null,
          selectedSchoolsToApply: Ember.A(),
          selectedTemplatesToApply: Ember.A(),
          selectedUsageSetsFilter: Ember.A()
        });
      },
      toggleApplyTemplate: function toggleApplyTemplate(tagCategory, checked) {
        if (checked) {
          this.selectedTemplatesToApply.pushObject(tagCategory);
        } else {
          this.selectedTemplatesToApply.removeObject(tagCategory);
        }
      },
      addUsageSetFilter: function addUsageSetFilter(usageSet) {
        this.selectedUsageSetsFilter.pushObject(usageSet);
      },
      removeUsageSetFilter: function removeUsageSetFilter(usageSet) {
        this.selectedUsageSetsFilter.removeObject(usageSet);
      },
      toggleApplySchool: function toggleApplySchool(school, checked) {
        if (checked) {
          this.selectedSchoolsToApply.pushObject(school);
        } else {
          this.selectedSchoolsToApply.removeObject(school);
        }
      },
      toggleAllSchools: function toggleAllSchools(schools, checked) {
        if (checked) {
          this.set('selectedSchoolsToApply', schools.toArray());
        } else {
          this.set('selectedSchoolsToApply', Ember.A());
        }
      },
      toggleAllTemplates: function toggleAllTemplates(templates, checked) {
        if (checked) {
          this.set('selectedTemplatesToApply', templates.toArray());
        } else {
          this.set('selectedTemplatesToApply', Ember.A());
        }
      },
      applySelected: function applySelected() {
        var _this2 = this;
        this.setProperties({
          applyConfirmed: true,
          applyInProgress: true
        });
        var errors = {};
        var chain = this.selectedTemplatesToApply.reduce(function (templatePromise, template) {
          return templatePromise.then(function () {
            var applyUri = "".concat(_emberGetConfig.default.APP.apiHost, "/").concat(_this2.serviceName, "/").concat(_emberGetConfig.default.APP.apiPath, "/templates/").concat(_this2.templateEndpoint, "/").concat(template.id, "/:apply");
            return _this2.selectedSchoolsToApply.reduce(function (schoolPromise, school) {
              var options = {
                data: JSON.stringify({
                  schoolId: school.id
                })
              };
              return schoolPromise.then(function () {
                return _this2.ajax.post(applyUri, options).catch(function (error) {
                  // Store all occurred errors per school
                  if (!errors[school.name]) {
                    errors[school.name] = [];
                  }
                  errors[school.name].push("Error applying ".concat(template.label, " - ").concat(error.message));
                });
              });
            }, Ember.RSVP.resolve());
          });
        }, Ember.RSVP.resolve());
        return chain.then(function () {
          _this2.set('applyInProgress', false);
          // A normal !isEmpty won't work with an object
          if (Object.keys(errors).length > 0) {
            _this2.set('applyErrors', errors);
          }
        });
      }
    }
  });
  _exports.default = _default;
});