define("linced-admin/routes/cron/task", ["exports", "linced-admin/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    // Methods
    model: function model(params) {
      return Ember.RSVP.hash({
        task: this.store.findRecord('cron/task', params.task_id),
        logs: this.store.query('cron/log', {
          taskId: params.task_id
        })
      });
    },
    /**
     * resetController calls the reset handler on the controller.
     *
     * @param controller
     */
    resetController: function resetController(controller) {
      controller.reset();
    }
  });
  _exports.default = _default;
});