define("linced-admin/routes/tags/index", ["exports", "linced-admin/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Extends from
  // External imports
  var _default = _base.default.extend({
    model: function model() {
      return Ember.RSVP.hash({
        schoolGroups: this.store.findAll('schools/group'),
        schools: this.store.findAll('school'),
        tagCategories: this.store.findAll('posts/tagCategory'),
        usageSets: this.store.findAll('schools/usage-set')
      });
    },
    actions: {
      categoryCreated: function categoryCreated() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});