define("linced-admin/adapters/auth/status", ["exports", "linced-admin/adapters/auth/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    modelNamePlural: 'status',
    pathForType: function pathForType() {
      return this._super.apply(this, arguments).replace('statuses', 'status').toLowerCase();
    }
  });
  _exports.default = _default;
});