define("linced-admin/models/people-schema/field", ["exports", "@ember-data/model", "ember-data-model-fragments/attributes"], function (_exports, _model, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    personType: (0, _model.attr)('number'),
    sequence: (0, _model.attr)('number'),
    isLocked: (0, _model.attr)('boolean'),
    isVisibleToParents: (0, _model.attr)('boolean'),
    isSiblingLinked: (0, _model.attr)('boolean'),
    key: (0, _model.attr)('string'),
    inputType: (0, _model.attr)('number'),
    dataType: (0, _model.attr)('number'),
    label: (0, _model.attr)('string'),
    default: (0, _attributes.fragment)('dynamic-field/value'),
    placeholder: (0, _model.attr)('string'),
    isRequired: (0, _model.attr)('boolean'),
    options: (0, _attributes.fragmentArray)('people-schema/field-option'),
    optionsFromEnum: (0, _model.attr)('boolean'),
    htmlAttributes: (0, _model.attr)(),
    templateGroupId: (0, _model.belongsTo)('people-schema/group', {
      inverse: 'fields'
    }),
    templateSetId: (0, _model.belongsTo)('people-schema/set', {
      inverse: null
    }),
    // requiredRole specifies the Field Access role required to view this field,
    // if applicable.  If empty, anyone with access to the entity can see it.
    requiredRole: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    // isReadOnly specifies whether this field is read-only.
    isReadOnly: (0, _model.attr)('boolean', {
      defaultValue: false
    })
  });
  _exports.default = _default;
});