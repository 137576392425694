define("linced-admin/controllers/contacts/search", ["exports", "@linc-technologies/linced-common/enums/person-type", "ember-get-config"], function (_exports, _personType, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    fetch: Ember.inject.service(),
    store: Ember.inject.service(),
    toaster: Ember.inject.service(),
    PersonType: _personType.default,
    emailAddress: '',
    searching: false,
    hasSearched: false,
    result: null,
    resultEmail: '',
    schools: Ember.computed('result.{type,caregiver.schools.[]}', 'model.schools.@each.{id,name}', function () {
      if (Ember.isEmpty(this.result)) {
        return [];
      }
      var schoolIds = [];
      switch (this.result.type) {
        case _personType.default.LEARNER:
          schoolIds = this.result.getWithDefault('learner.enrolments', []).mapBy('schoolId');
          break;
        case _personType.default.CAREGIVER:
          schoolIds = this.result.getWithDefault('caregiver.schools', []);
          break;
        case _personType.default.STAFF:
          if (!Ember.isEmpty(this.result.get('staff.schoolId'))) {
            schoolIds.pushObject(this.result.get('staff.schoolId'));
          }
      }
      if (Ember.isEmpty(schoolIds)) {
        return [];
      }
      return this.model.schools.filter(function (school) {
        return schoolIds.includes(school.id);
      });
    }),
    schoolNames: Ember.computed.mapBy('schools', 'name'),
    dialogOpen: false,
    availableSchools: Ember.computed.setDiff('model.schools', 'schools'),
    selectedSchool: null,
    saveInProgress: false,
    studentNames: null,
    actions: {
      search: function search() {
        var _this = this;
        this.setProperties({
          searching: true,
          hasSearched: true,
          result: null,
          selectedSchool: null,
          studentNames: []
        });

        // Search users first, as they're indexed by username/email address.
        this.store.query('user', {
          username: this.emailAddress
        }).then(function (results) {
          if (Ember.isEmpty(results) || Ember.isEmpty(results.firstObject)) {
            // No matching users; search for a userless caregiver.
            var query = encodeURIComponent(_this.emailAddress);
            var url = "".concat(_emberGetConfig.default.APP.apiHost, "/people/v4/caregivers/:search?query=").concat(query);
            return _this.fetch.request(url).then(function (results) {
              if (!!results && !!results.caregivers && results.caregivers.length) {
                var result = results.caregivers.firstObject;
                return _this.store.findRecord('person', result.caregiverId);
              }
            });
          }
          return _this.store.findRecord('person', results.firstObject.personId);
        }).then(function (person) {
          _this.setProperties({
            resultEmail: _this.emailAddress,
            result: person
          });
          if (!Ember.isEmpty(person) && person.type === _personType.default.CAREGIVER) {
            var learnerIds = person.getWithDefault('caregiver.learnerIds', []);
            if (learnerIds.length >= 1) {
              var people = JSON.stringify(learnerIds.toArray());

              // Deliberately not returning this promise.
              // Student names will appear when it resolves.
              _this.store.query('person', {
                people: people
              }).then(function (results) {
                results.forEach(function (person) {
                  return person.initFields();
                });
                _this.set('studentNames', results.mapBy('fullName'));
              });
            }
          }
        }).finally(function () {
          _this.set('searching', false);
        });
      },
      resultField: function resultField(key) {
        return this.get("result.fields.".concat(key, ".value"));
      },
      toggleDialog: function toggleDialog() {
        this.toggleProperty('dialogOpen');
        this.set('selectedSchool', null);
      },
      addToSchool: function addToSchool() {
        var _this2 = this;
        if (Ember.isEmpty(this.selectedSchool) || Ember.isEmpty(this.result) || this.result.type !== _personType.default.CAREGIVER) {
          // Not a valid state to be adding a caregiver to a school.  Abort.
          return;
        }
        this.set('saveInProgress', true);
        var url = "".concat(_emberGetConfig.default.APP.apiHost, "/people/v4/caregivers/").concat(this.result.id, "/:enableTenantAccess");
        var data = {
          personId: this.result.id,
          schoolId: this.selectedSchool.id
        };
        var options = {
          data: JSON.stringify(data)
        };
        this.fetch.post(url, options).then(function () {
          return _this2.result.reload();
        }).then(function () {
          _this2.toaster.success('Connected successfully!');
          _this2.set('dialogOpen', false);
        }).catch(function () {
          _this2.toaster.error('Failed to connect!');
        }).finally(function () {
          _this2.set('saveInProgress', false);
        });
      }
    }
  });
  _exports.default = _default;
});