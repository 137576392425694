define("linced-admin/models/autogroup-params", ["exports", "ember-data-model-fragments", "@ember-data/model"], function (_exports, _emberDataModelFragments, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberDataModelFragments.default.Fragment.extend({
    team: (0, _model.attr)('string'),
    threshold: (0, _model.attr)('string'),
    jurisdiction: (0, _model.attr)('string'),
    rangeStart: (0, _model.attr)('string'),
    rangeEnd: (0, _model.attr)('string'),
    type: (0, _model.attr)('string'),
    field: (0, _model.attr)('string'),
    value: (0, _model.attr)('string')
  });
  _exports.default = _default;
});