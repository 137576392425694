define("linced-admin/models/school-options/field", ["exports", "@ember-data/model", "ember-data-model-fragments/attributes", "@linc-technologies/linced-common/enums/data-type", "@linc-technologies/linced-common/enums/input-type"], function (_exports, _model, _attributes, _dataType, _inputType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    key: (0, _model.attr)('string'),
    label: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    headingId: (0, _model.belongsTo)('school-options/heading'),
    inputType: (0, _model.attr)('number'),
    dataType: (0, _model.attr)('number'),
    default: (0, _attributes.fragment)('dynamic-field/value'),
    placeholder: (0, _model.attr)('string'),
    options: (0, _attributes.fragmentArray)('dynamic-field/value-list-item'),
    htmlAttributes: (0, _model.attr)(),
    sequence: (0, _model.attr)('number'),
    optionSetIds: (0, _model.hasMany)('school-options/set', {
      inverse: null
    }),
    staticContent: (0, _model.attr)('string'),
    // Set datatype when input type changes
    // TODO: Don't use observers if possible.
    // eslint-disable-next-line
    _setDataType: Ember.observer('inputType', function () {
      var dataType;
      switch (this.inputType) {
        case _inputType.default.CHECKBOX: // Checkbox(es)
        case _inputType.default.MULTI_SELECT:
          // MultiSelect (Chips)
          dataType = _dataType.default.LIST; // List
          break;
        case _inputType.default.NUMBER:
          // Number
          dataType = _dataType.default.INTEGER; // Int (need to allow this to be set to 4 - Float)
          break;
        case _inputType.default.SWITCH:
          // Switch
          dataType = _dataType.default.BOOLEAN; // Bool
          break;
        case _inputType.default.EDITABLE_LIST:
          // Editable List
          dataType = _dataType.default.VALUE_LIST; // ValueList
          break;
        case _inputType.default.WYSIWYG_EDITOR:
          // WYSIWYG Editor
          dataType = _dataType.default.HTML; // HTML
          break;
        default:
          dataType = _dataType.default.STRING; // String
          break;
      }
      this.set('dataType', dataType);
    }),
    // Update default value fragment's datatype when data type is changed
    // TODO: Don't use observers if possible.
    // eslint-disable-next-line
    _updateDefaultDatatype: Ember.observer('dataType', function () {
      if (!Ember.isEmpty(this.default)) {
        this.default.set('datatype', this.dataType);
      }
    })
  });
  _exports.default = _default;
});