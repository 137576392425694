define("linced-admin/controllers/goals/plp", ["exports", "linced-admin/controllers/goals/subject"], function (_exports, _subject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _subject.default.extend({
    isPLP: true
  });
  _exports.default = _default;
});