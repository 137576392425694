define("linced-admin/controllers/schools", ["exports", "linced-admin/enums/enrolment-nz-service", "ember-get-config", "@linc-technologies/linced-common/enums/country-code", "@linc-technologies/linced-common/enums/feature", "@linc-technologies/linced-common/enums/jurisdiction", "@linc-technologies/linced-common/enums/media-sharing-level"], function (_exports, _enrolmentNzService, _emberGetConfig, _countryCode, _feature, _jurisdiction, _mediaSharingLevel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Internal Imports

  // External Imports

  var FEATURE_PACKS = [_feature.default.PAGES_POWER_UP, _feature.default.GOALS_POWER_UP, _feature.default.REPORTING_POWER_UP, _feature.default.MILESTONES_POWER_UP, _feature.default.COMMUNICATIONS_KIT, _feature.default.ADMINISTRATION_KIT];
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    media: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    Feature: _feature.default,
    Jurisdiction: _jurisdiction.default,
    FEATURE_PACKS: FEATURE_PACKS,
    school: null,
    schoolLogoMedia: '',
    schoolJurisdiction: '',
    schoolJurisdictionalId: '',
    // schoolExternalId is an external ID for the school, such as a SIF RefID.
    schoolExternalId: '',
    // schooljurisdictionalCredential provides a single credential for now, as we
    // only support DEX currently.
    schooljurisdictionalCredential: null,
    // schooljurisdictionalIntegrationPassword provides the password for
    // integration with educational jurisdiction services.
    schooljurisdictionalIntegrationPassword: '',
    schoolCountry: _countryCode.default.values.findBy('value', 169),
    // Default to NZ
    schoolName: '',
    schoolDomainName: '',
    schoolEmailAddress: '',
    schoolPhysicalAddress: null,
    schoolPostalAddress: null,
    schoolId: '',
    schoolGroups: Ember.A(),
    schoolLogo: '',
    remainingOptionSets: Ember.computed.setDiff('model.optionSets', 'school.optionSetIds'),
    remainingWizardOptionSets: Ember.computed.setDiff('model.optionSets', 'selectedOptionSets'),
    studentTemplates: Ember.computed('model.peopleSchemaTemplates.[]', function () {
      return this.get('model.peopleSchemaTemplates').filterBy('personType', 1);
    }),
    staffTemplates: Ember.computed('model.templates.[]', function () {
      return this.get('model.peopleSchemaTemplates').filterBy('personType', 2);
    }),
    caregiverTemplates: Ember.computed('model.templates.[]', function () {
      return this.get('model.peopleSchemaTemplates').filterBy('personType', 3);
    }),
    doctorsAndSpecialistsTemplates: Ember.computed('model.templates.[]', function () {
      return this.get('model.peopleSchemaTemplates').filterBy('personType', 4);
    }),
    selectedFieldTemplates: Ember.A(),
    selectedPageTemplates: Ember.A(),
    selectedOptionSets: Ember.A(),
    selectedGoalSubjects: Ember.A(),
    selectedMilestoneSet: null,
    selectedAutogroups: Ember.A(),
    selectedEnrolmentSchema: null,
    selectedGroups: Ember.A(),
    remainingGroups: Ember.computed.setDiff('model.groups', 'selectedGroups'),
    wizardOpen: false,
    wizardWorking: false,
    wizardProgress: Ember.A(),
    schoolDialogOpen: false,
    saveInProgress: false,
    errorMessage: '',
    // Filtering of subcomponents by usage sets
    selectedUsageSetsFilter: Ember.A(),
    remainingUsageSetsFilter: Ember.computed.setDiff('model.usageSets', 'selectedUsageSetsFilter'),
    filteredPageTemplates: Ember.computed('model.pageTemplates.[]', 'selectedUsageSetsFilter.[]', function () {
      var _this = this;
      return this.get('model.pageTemplates').filter(function (page) {
        if (!Ember.isEmpty(_this.selectedUsageSetsFilter)) {
          return _this.selectedUsageSetsFilter.some(function (usageSet) {
            return page.get('usageSetIds').includes(usageSet);
          });
        }
        return true;
      }).sortBy('label');
    }),
    filteredGoalSubjects: Ember.computed('model.goalSubjects.[]', 'selectedUsageSetsFilter.[]', function () {
      var _this2 = this;
      return this.get('model.goalSubjects').filter(function (goalSubject) {
        if (!Ember.isEmpty(_this2.selectedUsageSetsFilter)) {
          return _this2.selectedUsageSetsFilter.some(function (usageSet) {
            return goalSubject.get('usageSetIds').includes(usageSet);
          });
        }
        return true;
      }).sortBy('label');
    }),
    filteredMilestoneSets: Ember.computed('model.milestoneSets.[]', 'selectedUsageSetsFilter.[]', function () {
      var _this3 = this;
      return this.get('model.milestoneSets').filter(function (milestoneSets) {
        if (!Ember.isEmpty(_this3.selectedUsageSetsFilter)) {
          return _this3.selectedUsageSetsFilter.some(function (usageSet) {
            return milestoneSets.get('usageSetIds').includes(usageSet);
          });
        }
        return true;
      }).sortBy('label');
    }),
    filteredAutogroups: Ember.computed('model.autogroups.[]', 'selectedUsageSetsFilter.[]', function () {
      var _this4 = this;
      return this.get('model.autogroups').filter(function (group) {
        if (!Ember.isEmpty(_this4.selectedUsageSetsFilter)) {
          return _this4.selectedUsageSetsFilter.some(function (usageSet) {
            return group.get('usageSetIds').includes(usageSet);
          });
        }
        return true;
      }).sortBy('name');
    }),
    autogroupGroupings: Ember.computed('filteredAutogroups.@each.grouping', function () {
      return this.filteredAutogroups.sortBy('grouping').mapBy('grouping').uniq();
    }),
    selectedAutogroupGroupings: Ember.computed('selectedAutogroups.@each.grouping', function () {
      return this.selectedAutogroups.sortBy('grouping').mapBy('grouping').uniq();
    }),
    // sifZones determines the applicable SIF Zone(s) for the selected country and
    // jurisdiction, if one exists.
    sifZones: Ember.computed('schoolCountry', 'schoolJurisdiction', 'model.sifZones', function () {
      if (Ember.isEmpty(this.schoolCountry) || Ember.isEmpty(this.schoolJurisdiction)) {
        return null;
      }
      var zones = this.model.sifZones;
      if (!zones.hasOwnProperty(this.schoolCountry.value)) {
        // No SIF zones for the selected country.
        return null;
      }
      return zones[this.schoolCountry.value].filterBy('jurisdiction', this.schoolJurisdiction.value);
    }),
    // selectedSifZone is the SIF zone selected by the user.
    selectedSifZone: null,
    // sifZone is the SIF zone to be searched.
    sifZone: Ember.computed.or('selectedSifZone', 'sifZones.firstObject'),
    // sifResults holds the search results from a SIF school search.
    sifResults: null,
    // sifError contains an error message if a SIF school search failed.
    sifError: null,
    // sifResultsLoading flags whether a SIF school search is in progress.
    sifResultsLoading: false,
    // sifResultsDialogOpen flags whether the SIF school search results dialog
    // should be open/visible.
    sifResultsDialogOpen: false,
    filterValue: '',
    filteredSchools: Ember.computed('filterValue', 'model.schools.@each.{isNew,name,jurisdictionalId,id}', 'selectedGroup', function () {
      var schools = this.get('model.schools').filterBy('isNew', false);
      var group = this.selectedGroup;
      if (!Ember.isEmpty(group)) {
        schools = group.schools.filterBy('isNew', false);
      }
      var filterValue = this.filterValue.toLowerCase();
      if (!filterValue.length) {
        return schools;
      } else {
        return schools.filter(function (school) {
          if (school.get('name').toLowerCase().indexOf(filterValue) !== -1) {
            // School name matches filter.
            return true;
          }
          if (school.jurisdictionalId.toLowerCase().indexOf(filterValue) !== -1) {
            // Jurisdictional ID matches filter.
            return true;
          }
          if (school.id === filterValue) {
            // School ID (UUID) matches filter completely (partial matches are ignored to avoid weird results).
            return true;
          }

          // None of the things we check against matched.
          return false;
        });
      }
    }),
    selectedSchools: Ember.A(),
    allSelected: Ember.computed('filteredSchools.@each.id', 'selectedSchools.@each.id', function () {
      var _this5 = this;
      return this.filteredSchools.every(function (school) {
        return _this5.selectedSchools.includes(school);
      });
    }),
    group: null,
    groupDialogOpen: false,
    groupings: Ember.computed('model.groups.@each.{isNew,grouping,name}', function () {
      var _this6 = this;
      var groupings = this.model.groups.uniqBy('grouping').sortBy('grouping').mapBy('grouping');
      return groupings.map(function (grouping) {
        return {
          groupName: grouping,
          options: _this6.model.groups.filterBy('grouping', grouping).sortBy('name')
        };
      });
    }),
    selectedGroup: null,
    features: _feature.default.values,
    countries: _countryCode.default.values,
    jurisdictions: _jurisdiction.default.values,
    /**
     * persistCredential saves the provided credential to the backend.
     *
     * @param {Object} credential - A credential object.
     * @param {integer} service - The integration service the password is being stored for.
     * @param {string} password - The integration service password.
     * @returns {Promise}
     */
    persistCredential: function persistCredential(credential, service, password) {
      var _this7 = this;
      credential.setIntegrationPassword(service, password);
      return credential.save().then(function () {
        _this7.paperToaster.show('School integration password saved', {
          duration: 4000,
          position: 'top right',
          toastClass: 'success'
        });
      }).catch(function (err) {
        _this7.paperToaster.show("Error saving school integration password: ".concat(err), {
          duration: 4000,
          position: 'top right',
          toastClass: 'warning'
        });
      });
    },
    /**
     * saveIntegrationCredentials creates or updates currently stored integration
     * credentials.
     *
     * @returns {Promise}
     */
    saveIntegrationCredentials: function saveIntegrationCredentials() {
      var _this8 = this;
      var password = this.schooljurisdictionalIntegrationPassword;
      if (Ember.isEmpty(password)) {
        // return early..
        return Promise.resolve();
      }
      if (!Ember.isEmpty(this.schooljurisdictionalCredential)) {
        // Perform update to existing credential.
        return this.store.findRecord('enrolments/credential', this.schooljurisdictionalCredential.id).then(function (credential) {
          switch (_this8.schoolJurisdiction.value) {
            case _jurisdiction.default.NZ_MOE:
              return _this8.persistCredential(credential, _enrolmentNzService.default.DEX, password);
            default:
              return Promise.resolve();
          }
        });
      }

      // Create new Credentials..
      var credential = this.store.createRecord('enrolments/credential', {
        schoolId: this.school,
        enabled: true,
        jurisdiction: this.schoolJurisdiction.value
      });

      // Push in the required jurisidictional credential and service payload.
      switch (this.schoolJurisdiction.value) {
        case _jurisdiction.default.NZ_MOE:
          // Bind in NZ Credential fragment.
          return this.persistCredential(credential, _enrolmentNzService.default.DEX, password);
      }
      return Promise.resolve();
    },
    // toggleDialog opens the school dialog.
    toggleDialog: function toggleDialog() {
      this.set('errorMessage', '');
      this.toggleProperty('schoolDialogOpen');
      if (!this.schoolDialogOpen) {
        this.resetForm();
      }
    },
    actions: {
      toggleSchoolDialog: function toggleSchoolDialog() {
        this.toggleDialog();
      },
      editSchool: function editSchool(id) {
        var _this9 = this;
        Ember.RSVP.hash({
          school: this.store.findRecord('school', id),
          groups: this.store.query('schools/group', {
            schoolId: id
          })
        }).then(function (_ref) {
          var school = _ref.school,
            groups = _ref.groups;
          _this9.setProperties({
            school: school,
            schoolName: school.get('name'),
            schoolLogoMedia: school.get('logoMedia'),
            schoolLogo: school.get('logo'),
            schoolJurisdiction: _this9.jurisdictions.findBy('value', school.get('jurisdiction')),
            schoolJurisdictionalId: school.get('jurisdictionalId'),
            schoolExternalId: school.get('externalId'),
            schoolCountry: _this9.countries.findBy('value', school.get('countryCode')),
            schoolDomainName: school.get('domain'),
            schoolEmailAddress: school.get('emailAddress'),
            // Addresses are fragments, so will need to be copied - but for now,
            // they're not editable via this UI, so we just leave them null and
            // the save process won't update them (unless a SIF search has set
            // them).
            schoolPostalAddress: null,
            schoolPhysicalAddress: null,
            schoolGroups: groups,
            selectedSifZone: null
          });

          // Find integration password entries.
          // Since we only have NZ Moe at the moment...
          // TODO: make smarter once we have more than one jurisdictional integration to worry about e.g. Te Rito?
          var credentials = _this9.model.credentials.filterBy('school.id', id);
          if (credentials.length) {
            var credential = credentials[0];
            if (credential.jurisdiction === _jurisdiction.default.NZ_MOE) {
              _this9.set('schooljurisdictionalCredential', credential);
            }
          } else {
            _this9.set('schooljurisdictionalCredential', null);
          }
        });
        this.set('schoolId', id);
        this.toggleDialog();
      },
      saveSchool: function saveSchool() {
        var _this10 = this;
        this.set('saveInProgress', true);
        this.set('errorMessage', '');
        var promise;
        var action = 'create';
        if (Ember.isEmpty(this.schoolId)) {
          promise = new Ember.RSVP.Promise(function (resolve) {
            var school = _this10.school;
            school.setProperties({
              name: _this10.schoolName,
              logoMedia: _this10.schoolLogoMedia,
              jurisdiction: _this10.get('schoolJurisdiction.value'),
              jurisdictionalId: _this10.schoolJurisdictionalId,
              externalId: _this10.schoolExternalId,
              countryCode: _this10.get('schoolCountry.value'),
              domain: _this10.schoolDomainName,
              emailAddress: _this10.schoolEmailAddress
            });
            if (!Ember.isEmpty(_this10.schoolPostalAddress)) {
              school.set('postalAddress', _this10.schoolPostalAddress);
            }
            if (!Ember.isEmpty(_this10.schoolPhysicalAddress)) {
              school.set('physicalAddress', _this10.schoolPhysicalAddress);
            }
            resolve(school);
          });
        } else {
          action = 'update';
          promise = new Ember.RSVP.Promise(function (resolve) {
            var id = _this10.schoolId;
            _this10.store.findRecord('school', id).then(function (school) {
              school.setProperties({
                name: _this10.schoolName,
                logoMedia: _this10.schoolLogoMedia,
                countryCode: _this10.get('schoolCountry.value'),
                jurisdictionalId: _this10.schoolJurisdictionalId,
                externalId: _this10.schoolExternalId,
                domain: _this10.schoolDomainName,
                emailAddress: _this10.schoolEmailAddress
              });
              if (Ember.isEmpty(school.jurisdiction)) {
                school.set('jurisdiction', _this10.get('schoolJurisdiction.value'));
              }
              if (!Ember.isEmpty(_this10.schoolPostalAddress)) {
                school.set('postalAddress', _this10.schoolPostalAddress);
              }
              if (!Ember.isEmpty(_this10.schoolPhysicalAddress)) {
                school.set('physicalAddress', _this10.schoolPhysicalAddress);
              }
              resolve(school);
            });
          });
        }
        var toaster = this.paperToaster;
        promise.then(function (record) {
          return record.save().then(function () {
            _this10.set('schoolDialogOpen', false);
            toaster.show('School saved', {
              duration: 4000,
              position: 'top right',
              toastClass: 'success'
            });
          }).catch(function (err) {
            _this10.handleError(err, action);
            record.rollbackAttributes();
          }).then(function () {
            _this10.set('saveInProgress', false);
          });
        }).then(function () {
          return _this10.saveIntegrationCredentials();
        }).then(function () {
          return _this10.resetForm();
        });
      },
      // Option Sets
      removeOptionSet: function removeOptionSet(item) {
        this.get('school.optionSetIds').removeObject(item);
      },
      addOptionSet: function addOptionSet(item) {
        this.get('school.optionSetIds').pushObject(item);
      },
      // New School wizard
      openWizard: function openWizard() {
        this.resetForm();
        this.setProperties({
          selectedFieldTemplates: Ember.A(),
          selectedPageTemplates: Ember.A(),
          selectedOptionSets: Ember.A(),
          selectedGoalSubjects: Ember.A(),
          selectedMilestoneSet: null,
          selectedAutogroups: Ember.A(),
          selectedGroups: Ember.A(),
          wizardConfirmed: false,
          wizardWorking: false,
          wizardProgress: Ember.A(),
          currentStep: 0
        });
        this.set('wizardOpen', true);
      },
      closeWizard: function closeWizard() {
        this.set('wizardOpen', false);
      },
      toggleWizardTemplate: function toggleWizardTemplate(selected, template) {
        if (selected.includes(template)) {
          selected.removeObject(template);
        } else {
          selected.pushObject(template);
        }
      },
      toggleWizardTemplateAll: function toggleWizardTemplateAll(selected, templates, filters) {
        if (!Ember.isEmpty(filters)) {
          // Select and de-select only the filtered items
          templates.forEach(function (template) {
            // If the template is not already in the selected array, add it
            if (!selected.includes(template)) {
              selected.addObject(template);
              return;
            }
            // If the template is already in the selected array, remove it
            selected.removeObject(template);
          });
          return;
        }
        // No filters are selected, deselect and select all items in the list
        if (selected.length >= templates.length) {
          selected.clear();
          return;
        }
        selected.setObjects(templates.toArray());
      },
      removeWizardOptionSet: function removeWizardOptionSet(item) {
        this.selectedOptionSets.removeObject(item);
      },
      addWizardOptionSet: function addWizardOptionSet(item) {
        this.selectedOptionSets.pushObject(item);
      },
      removeWizardGroup: function removeWizardGroup(item) {
        this.selectedGroups.removeObject(item);
      },
      addWizardGroup: function addWizardGroup(item) {
        this.selectedGroups.pushObject(item);
      },
      // searchSif performs a search against the selected Jurisdiction's SIF Zone
      // in order to update a school's details and connect the Hero record to the
      // SIF record via the school.externalId property.
      searchSif: function searchSif() {
        var _this11 = this;
        this.setProperties({
          sifResults: [],
          sifError: null,
          sifResultsLoading: true,
          sifResultsDialogOpen: true
        });
        var url = "".concat(_emberGetConfig.default.APP.apiHost, "/providers/au/sif/v4/schools/:search");
        var params = {
          zoneId: this.sifZone.id,
          stateProvinceId: this.schoolJurisdictionalId
        };
        this.ajax.post(url, {
          dataType: 'json',
          data: JSON.stringify(params)
        }).then(function (response) {
          _this11.set('sifResults', response.schools || []);
        }).catch(function () {
          _this11.set('sifError', 'SIF search failed.  Please try again.');
        }).finally(function () {
          _this11.set('sifResultsLoading', false);
        });
      },
      // toggleSifResultsDialog toggles visibility of the SIF school search
      // results dialog.
      toggleSifResultsDialog: function toggleSifResultsDialog() {
        this.toggleProperty('sifResultsDialogOpen');
      },
      // acceptSifResult accepts a SIF school search result, setting the
      // externalId as appropriate.
      acceptSifResult: function acceptSifResult(result) {
        this.setProperties({
          schoolExternalId: Ember.get(result, 'externalId'),
          sifResultsDialogOpen: false
        });
      },
      createSchool: function createSchool() {
        var _this12 = this;
        this.set('wizardConfirmed', true);
        this.set('wizardWorking', true);
        var progress = this.wizardProgress;
        var ajax = this.ajax;
        var jurisdiction = this.getWithDefault('schoolJurisdiction.value', _jurisdiction.default.NZ_MOE);
        progress.pushObject(Ember.Object.create({
          id: 'school',
          label: 'Creating school',
          done: false
        }));
        var school = this.store.createRecord('school', {
          name: this.schoolName,
          jurisdiction: jurisdiction,
          jurisdictionalId: this.schoolJurisdictionalId,
          externalId: this.schoolExternalId,
          domain: this.schoolDomainName,
          emailAddress: this.schoolEmailAddress,
          optionSetIds: this.selectedOptionSets,
          countryCode: this.get('schoolCountry.value')
        });
        school.save().then(function (school) {
          // Mark school creation as complete.
          progress.findBy('id', 'school').set('done', true);

          // Continue on to apply templates.  Keep going if any individual requests fail.
          progress.pushObject(Ember.Object.create({
            id: 'timetable',
            label: 'Applying timetable structure',
            done: false
          }));
          progress.pushObject(Ember.Object.create({
            id: 'fields',
            label: 'Applying fields',
            done: false
          }));
          progress.pushObject(Ember.Object.create({
            id: 'enrolmentSchema',
            label: 'Applying Enrolment Form',
            done: false
          }));
          progress.pushObject(Ember.Object.create({
            id: 'pages',
            label: 'Applying Pages',
            done: false
          }));
          progress.pushObject(Ember.Object.create({
            id: 'goals',
            label: 'Applying Goal Subjects',
            done: false
          }));
          progress.pushObject(Ember.Object.create({
            id: 'milestones',
            label: 'Applying Milestone Sets',
            done: false
          }));
          progress.pushObject(Ember.Object.create({
            id: 'autogroups',
            label: 'Applying Autogroups',
            done: false
          }));
          progress.pushObject(Ember.Object.create({
            id: 'groups',
            label: 'Adding to Groups',
            done: false
          }));
          Ember.RSVP.allSettled([
          // Stage 1: Do stuff which doesn't depend on other steps.

          // Add school to any selected Groups
          new Ember.RSVP.Promise(function (resolve) {
            if (Ember.isEmpty(_this12.selectedGroups)) {
              progress.findBy('id', 'groups').set('done', true);
              return resolve();
            }
            _this12.selectedGroups.reduce(function (chain, group) {
              return chain.then(function () {
                return group.reload().then(function (g) {
                  g.schoolIds.pushObject(school);
                  return g.save();
                });
              });
            }, Ember.RSVP.resolve()).catch(function () {
              progress.findBy('id', 'groups').set('error', 'Failed!');
            }).finally(function () {
              progress.findBy('id', 'groups').set('done', true);
              return resolve();
            });
          }),
          // Apply default timetable structure
          // TODO: Implement timetable structure templates, for different jurisdictions / school types
          new Ember.RSVP.Promise(function (resolve) {
            var periodPromises = [];
            var days = [];
            ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'].forEach(function (day, dayIdx) {
              days[dayIdx] = {
                name: day,
                isoDayNumber: dayIdx + 1,
                periodIds: []
              };
              periodPromises.push(_this12.store.createRecord('timetable/period', {
                schoolId: school,
                shortName: 'AM',
                longName: 'Morning',
                duration: 210,
                startTime: '09:00'
              }).save().then(function (am) {
                days[dayIdx].periodIds[0] = am.id;
              }));
              periodPromises.push(_this12.store.createRecord('timetable/period', {
                schoolId: school,
                shortName: 'PM',
                longName: 'Afternoon',
                duration: 90,
                startTime: '13:30'
              }).save().then(function (pm) {
                days[dayIdx].periodIds[1] = pm.id;
              }));
            });
            resolve(Ember.RSVP.all(periodPromises).then(function () {
              var structure = _this12.store.createRecord('timetable/structure', {
                schoolId: school,
                name: 'AM/PM',
                effectiveFrom: '2000-01-01',
                days: days
              });
              return structure.save();
            }).then(function () {
              progress.findBy('id', 'timetable').set('done', true);
            }, function () {
              progress.findBy('id', 'timetable').set('error', 'Failed!');
            }));
          }),
          // Apply field sets
          new Ember.RSVP.Promise(function (resolve) {
            resolve(ajax.post(_emberGetConfig.default.APP.apiHost + '/people-schema/v4/templates/sets:apply', {
              dataType: 'json',
              data: JSON.stringify({
                schoolId: school.get('id'),
                setIds: _this12.selectedFieldTemplates.mapBy('id')
              })
            }).then(function () {
              progress.findBy('id', 'fields').set('done', true);
            }, function () {
              progress.findBy('id', 'fields').set('error', 'Failed!');
            }));
          }),
          // Apply Goal Subject templates
          new Ember.RSVP.Promise(function (resolve) {
            var numApplied = 0;
            var numFailed = 0;
            var promiseArray = [];
            var data = {
              schoolId: school.get('id')
            };
            var options = {
              data: JSON.stringify(data)
            };
            var templatesUri = "".concat(_emberGetConfig.default.APP.apiHost, "/goals/").concat(_emberGetConfig.default.APP.apiPath, "/templates/subjects");
            var templates = _this12.selectedGoalSubjects;
            var numTemplates = templates.get('length');
            templates.forEach(function (template) {
              promiseArray.push(ajax.post("".concat(templatesUri, "/").concat(template.id, "/:apply"), options).then(function () {
                numApplied++;
              }, function () {
                numFailed++;
              }));
            });
            resolve(Ember.RSVP.allSettled(promiseArray).then(function () {
              if (numApplied === numTemplates) {
                progress.findBy('id', 'goals').set('done', true);
              } else if (numApplied === 0) {
                progress.findBy('id', 'goals').set('error', 'Failed!');
              } else {
                progress.findBy('id', 'goals').set('error', "".concat(numApplied, " out of ").concat(numTemplates, " applied (").concat(numFailed, " failed)"));
              }
            }));
          }),
          // Apply Autogroup templates
          new Ember.RSVP.Promise(function (resolve) {
            var numApplied = 0;
            var numFailed = 0;
            var promiseArray = [];
            var data = {
              schoolId: school.get('id')
            };
            var options = {
              data: JSON.stringify(data)
            };
            var templatesUri = "".concat(_emberGetConfig.default.APP.apiHost, "/groups/").concat(_emberGetConfig.default.APP.apiPath, "/templates/groups");
            var templates = _this12.selectedAutogroups;
            var numTemplates = templates.get('length');
            templates.forEach(function (template) {
              promiseArray.push(ajax.post("".concat(templatesUri, "/").concat(template.id, "/:apply"), options).then(function () {
                numApplied++;
              }, function () {
                numFailed++;
              }));
            });
            resolve(Ember.RSVP.allSettled(promiseArray).then(function () {
              if (numApplied === numTemplates) {
                progress.findBy('id', 'autogroups').set('done', true);
              } else if (numApplied === 0) {
                progress.findBy('id', 'autogroups').set('error', 'Failed!');
              } else {
                progress.findBy('id', 'autogroups').set('error', "".concat(numApplied, " out of ").concat(numTemplates, " applied (").concat(numFailed, " failed)"));
              }
            }));
          })]).then(function () {
            // Stage 2 - apply templates which depend on Stage 1 templates
            return Ember.RSVP.allSettled([
            // Apply a Milestone set
            new Ember.RSVP.Promise(function (resolve) {
              var numApplied = 0;
              var numFailed = 0;
              var promiseArray = [];
              var data = {
                schoolId: school.get('id')
              };
              var options = {
                data: JSON.stringify(data)
              };
              var templatesUri = "".concat(_emberGetConfig.default.APP.apiHost, "/milestones/").concat(_emberGetConfig.default.APP.apiPath, "/templates/sets");
              var template = _this12.selectedMilestoneSet;
              var numTemplates = 1;
              promiseArray.push(ajax.post("".concat(templatesUri, "/").concat(template.id, "/:apply"), options).then(function () {
                numApplied++;
              }).catch(function () {
                numFailed++;
              }));
              resolve(Ember.RSVP.allSettled(promiseArray).then(function () {
                switch (numApplied) {
                  case 1:
                    {
                      progress.findBy('id', 'milestones').set('done', true);
                      break;
                    }
                  case 0:
                    {
                      progress.findBy('id', 'milestones').set('error', 'Failed!');
                      break;
                    }
                  default:
                    {
                      progress.findBy('id', 'milestones').set('error', "".concat(numApplied, " out of ").concat(numTemplates, " applied (").concat(numFailed, " failed)"));
                    }
                }
              }));
            }),
            // Apply Enrolment Form
            new Ember.RSVP.Promise(function (resolve) {
              if (Ember.isEmpty(_this12.selectedEnrolmentSchema)) {
                // No template selected; mark as done and move on.
                progress.findBy('id', 'enrolmentSchema').set('done', true);
                return resolve();
              }
              var data = {
                schoolId: school.id,
                countryCode: _countryCode.default.getLabel(school.countryCode),
                schoolCode: school.jurisdictionalId,
                schoolName: school.name,
                // TODO: We do not store an id on the enrolment schema so we can't do the
                //  blobby serving. This will work until we nuke the unauthenticated
                //  media routes.
                schoolLogoUrl: "".concat(_emberGetConfig.default.APP.apiHost, "/media/").concat(_emberGetConfig.default.APP.apiPath, "/media/").concat(school.logoMedia, "/logo.png}"),
                jurisdiction: jurisdiction
              };
              var options = {
                data: JSON.stringify(data)
              };
              var templatesUri = "".concat(_emberGetConfig.default.APP.apiHost, "/enrolments/").concat(_emberGetConfig.default.APP.apiPath, "/templates/schemas");
              var templateId = _this12.get('selectedEnrolmentSchema.id');
              var enrolmentSchemaProgress = progress.findBy('id', 'enrolmentSchema');
              resolve(ajax.post("".concat(templatesUri, "/").concat(templateId, "/:apply"), options).then(function () {
                enrolmentSchemaProgress.set('done', true);
              }, function () {
                enrolmentSchemaProgress.set('error', 'Failed!');
              }));
            })]);
          }).then(function () {
            // Stage 3 - apply templates which depend on Stage 2 templates
            return Ember.RSVP.allSettled([
            // Apply Page templates
            new Ember.RSVP.Promise(function (resolve) {
              var numApplied = 0;
              var numFailed = 0;
              var promiseArray = [];
              var data = {
                schoolId: school.get('id')
              };
              var options = {
                data: JSON.stringify(data)
              };
              var templatesUri = "".concat(_emberGetConfig.default.APP.apiHost, "/posts/").concat(_emberGetConfig.default.APP.apiPath, "/templates/pages");
              var templates = _this12.selectedPageTemplates;
              var numTemplates = templates.get('length');
              templates.forEach(function (template) {
                promiseArray.push(ajax.post("".concat(templatesUri, "/").concat(template.id, "/:apply"), options).then(function () {
                  numApplied++;
                }, function () {
                  numFailed++;
                }));
              });
              resolve(Ember.RSVP.allSettled(promiseArray).then(function () {
                if (numApplied === numTemplates) {
                  progress.findBy('id', 'pages').set('done', true);
                } else if (numApplied === 0) {
                  progress.findBy('id', 'pages').set('error', 'Failed!');
                } else {
                  progress.findBy('id', 'pages').set('error', "".concat(numApplied, " out of ").concat(numTemplates, " applied (").concat(numFailed, " failed)"));
                }
              }));
            })]);
          }).then(function () {
            _this12.set('wizardWorking', false);
          });
        }, function () {
          // School creation failed; we can't apply templates to a school which doesn't exist!
          progress.get('lastObject').set('error', 'Failed!');
        });
      },
      // Drag-drop logo image uploading
      startUpload: function startUpload() {
        this.set('uploading', true);
      },
      handleUpload: function handleUpload(file, upload) {
        this.media.handleImageUpload(this, file, upload, _mediaSharingLevel.default.PUBLIC, '', '', 'schoolLogoMedia', 'schoolLogo');
      },
      handleUploadError: function handleUploadError() {
        this.paperToaster.show("Error uploading logo image!", {
          duration: 8000,
          position: 'top right',
          toastClass: 'warning'
        });
      },
      toggleFeatureAvailable: function toggleFeatureAvailable(feature, checked) {
        if (checked) {
          this.school.featuresAvailable.pushObject(feature);
        } else {
          this.school.featuresAvailable.removeObject(feature);
        }
      },
      toggleFeatureEnabled: function toggleFeatureEnabled(feature, checked) {
        if (checked) {
          this.school.featuresEnabled.pushObject(feature);
        } else {
          this.school.featuresEnabled.removeObject(feature);
        }
      },
      copyToClipboard: function copyToClipboard(content) {
        var _this13 = this;
        navigator.clipboard.writeText(content).then(function () {
          _this13.paperToaster.show("School UUID copied to clipboard", {
            duration: 4000,
            position: 'top right',
            toastClass: 'success'
          });
        });
        return false;
      },
      toggleSchool: function toggleSchool(school, checked) {
        if (checked) {
          this.selectedSchools.pushObject(school);
        } else {
          this.selectedSchools.removeObject(school);
        }
      },
      toggleAll: function toggleAll() {
        if (this.allSelected) {
          this.selectedSchools.clear();
        } else {
          this.selectedSchools.pushObjects(this.filteredSchools);
        }
      },
      toggleGroupDialog: function toggleGroupDialog() {
        if (this.groupDialogOpen) {
          // Closing dialog - reset selection.
          this.selectedSchools.clear();
        } else {
          // Opening dialog - create a new group.
          this.set('group', this.store.createRecord('schools/group'));
        }
        this.toggleProperty('groupDialogOpen');
      },
      addUsageSetFilter: function addUsageSetFilter(usageSet) {
        this.selectedUsageSetsFilter.pushObject(usageSet);
      },
      removeUsageSetFilter: function removeUsageSetFilter(usageSet) {
        this.selectedUsageSetsFilter.removeObject(usageSet);
      },
      deselectMilestoneSet: function deselectMilestoneSet() {
        this.set('selectedMilestoneSet', null);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.resetForm();
    },
    handleError: function handleError(err) {
      if (!Ember.isEmpty(err.errors.msg)) {
        this.set('errorMessage', err.errors.msg);
      } else {
        this.set('errorMessage', err.message);
      }
    },
    resetForm: function resetForm() {
      var school = this.school;
      if (school) {
        school.rollbackAttributes();
      }
      this.setProperties({
        uploading: false,
        school: this.store.createRecord('school', {}),
        schoolId: '',
        schoolJurisdiction: '',
        schoolJurisdictionalId: '',
        schoolExternalId: '',
        schooljurisdictionalCredential: null,
        schooljurisdictionalIntegrationPassword: '',
        schoolLogoMedia: '',
        schoolLogo: '',
        schoolName: '',
        schoolDomainName: '',
        schoolEmailAddress: '',
        schoolPhysicalAddress: null,
        schoolPostalAddress: null,
        schoolCountry: _countryCode.default.values.findBy('value', 169),
        // Default to NZ
        schoolGroups: Ember.A(),
        selectedSifZone: null
      });
    }
  });
  _exports.default = _default;
});