define("linced-admin/models/assessments/grade-schedule", ["exports", "@ember-data/model", "ember-data-model-fragments/attributes"], function (_exports, _model, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    updateTime: (0, _model.attr)('number'),
    createTime: (0, _model.attr)('number'),
    isPublished: (0, _model.attr)('boolean'),
    isLocked: (0, _model.attr)('boolean'),
    isCustom: (0, _model.attr)('boolean'),
    isShared: (0, _model.attr)('boolean'),
    label: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    awardMethod: (0, _model.attr)('number'),
    select: (0, _attributes.fragment)('assessments/gradeschedule-select'),
    numeric: (0, _attributes.fragment)('assessments/gradeschedule-numeric')
  });
  _exports.default = _default;
});