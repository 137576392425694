define("linced-admin/models/auth/status", ["exports", "@ember-data/model", "ember-data-model-fragments/attributes"], function (_exports, _model, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // createTime is when the resource was created in seconds from the epoch.
    createTime: (0, _model.attr)('number'),
    // updateTime is the last time the resource was modified in seconds from
    // the epoch.
    updateTime: (0, _model.attr)('number'),
    // requesterId is the personId of the user who created the status resource.
    requesterId: (0, _model.belongsTo)('person'),
    // status contains the current in flight status of the request.
    status: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    // State
    // count is the number of records to be processed.
    count: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    // created is the number of records of count that have been created.
    created: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    // updated is the number of records of count that have been updated.
    updated: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    // failed is the number of records of count that have failed.
    failed: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    // type is the status type - either 'user' or 'client'.
    type: (0, _model.attr)('string', {
      defaultValue: 'user'
    }),
    // syncErrors provides a list of any sync errors encountered.
    syncErrors: (0, _attributes.fragmentArray)('auth/status-sync-error')
  });
  _exports.default = _default;
});