define("linced-admin/controllers/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    currentUser: Ember.inject.service('current-user'),
    store: Ember.inject.service('store'),
    userId: '',
    username: '',
    password: '',
    schoolId: null,
    userDialogOpen: false,
    saveInProgress: false,
    deleteUserDialogOpen: false,
    deleteInProgress: false,
    errorMessage: '',
    // toggleDialog opens the user dialog.
    toggleDialog: function toggleDialog() {
      this.set('errorMessage', '');
      this.toggleProperty('userDialogOpen');
      if (!this.userDialogOpen) {
        this.resetForm();
      }
    },
    actions: {
      toggleUserDialog: function toggleUserDialog() {
        this.toggleDialog();
      },
      editUser: function editUser(id) {
        var _this = this;
        this.store.findRecord('user', id).then(function (person) {
          _this.set('username', person.get('username'));
          _this.set('schoolId', person.get('schoolId'));
        });
        this.set('userId', id);
        this.toggleDialog();
      },
      saveUser: function saveUser() {
        var _this2 = this;
        this.set('saveInProgress', true);
        this.set('errorMessage', '');
        var promise;
        var action = 'create';
        if (Ember.isEmpty(this.userId)) {
          promise = new Ember.RSVP.Promise(function (resolve) {
            var record = _this2.store.createRecord('user', {
              username: _this2.username,
              password: _this2.password,
              schoolId: _this2.schoolId,
              permission: 'USER'
            });
            resolve(record);
          });
        } else {
          action = 'update';
          promise = new Ember.RSVP.Promise(function (resolve) {
            var id = _this2.userId;
            _this2.store.findRecord('user', id).then(function (person) {
              person.set('username', _this2.username);
              person.set('password', _this2.password);
              person.set('schoolId', _this2.schoolId);
              resolve(person);
            });
          });
        }
        promise.then(function (record) {
          return record.save().then(function () {
            _this2.set('userDialogOpen', false);
            _this2.resetForm();
          }).catch(function (err) {
            _this2.handleError(err, action);
            record.rollbackAttributes();
          }).then(function () {
            _this2.set('saveInProgress', false);
          });
        });
      },
      deleteUser: function deleteUser(id) {
        var _this3 = this;
        this.set('errorMessage', '');
        this.store.findRecord('user', id).then(function (person) {
          _this3.set('username', person.get('username'));
        });
        this.set('userId', id);
        this.set('deleteUserDialogOpen', true);
      },
      confirmDelete: function confirmDelete(confirmed) {
        var _this4 = this;
        return new Ember.RSVP.Promise(function (resolve, reject) {
          if (confirmed) {
            // Deletion confirmed; actually delete the record.
            _this4.set('deleteInProgress', true);

            // Grab record from local store - no GET request, as that causes weirdness.
            // We only need the ID to issue the DELETE anyway, which can't change, so no need to use findRecord.
            var person = _this4.store.peekRecord('user', _this4.userId);

            // Delete record (locally + pushed to server).
            return person.destroyRecord().then(resolve).catch(function (err) {
              // Remove 'deleted' flag so that entity can be interacted with again.
              person.rollbackAttributes();
              reject(err);
            });
          } else {
            resolve();
          }
        }).then(function () {
          // Tidy up / close dialog.
          _this4.set('deleteUserDialogOpen', false);
          _this4.set('userId', '');
          _this4.set('username', '');
        }).catch(function (err) {
          _this4.handleError(err, 'delete');
        }).then(function () {
          _this4.set('deleteInProgress', false);
        });
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.set('schoolId', this.get('currentUser.user.schoolId'));
    },
    handleError: function handleError(err, action) {
      if (!Ember.isEmpty(err.errors[0].status)) {
        this.setErrorMessage(err.errors[0].status, action);
      } else {
        this.set('errorMessage', err.message);
      }
    },
    setErrorMessage: function setErrorMessage(code, action) {
      var message = 'An unknown error occurred.  Please try again.';
      var codes = {
        '400': 'Sorry, your request was invalid.  Please check all details below, and try again.',
        '403': {
          create: 'Sorry, you are not authorised to create a new user.  Please check that you are logged in as the correct user, and try again.',
          update: 'Sorry, you are not authorised to edit this user.  Please check that you are logged in as the correct user, and try again.',
          delete: 'Sorry, you are not authorised to delete this user.  Please check that you are logged in as the correct user, and try again.'
        },
        '409': 'A user already exists with that username.  Please double-check the details, or find and update the existing user.'
      };
      if (!Ember.isEmpty(codes[code][action])) {
        message = codes[code][action];
      } else if (!Ember.isEmpty(codes[code])) {
        message = codes[code];
      }
      this.set('errorMessage', message);
    },
    resetForm: function resetForm() {
      this.set('userId', '');
      this.set('username', '');
      this.set('password', '');
      this.set('schoolId', this.get('currentUser.user.schoolId'));
    }
  });
  _exports.default = _default;
});