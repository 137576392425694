define("linced-admin/controllers/people/schema/apply-templates", ["exports", "@linc-technologies/linced-common/enums/person-type", "linced-admin/config/environment"], function (_exports, _personType, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    fetch: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    selectedTemplates: Ember.A([]),
    selectedTemplateIds: Ember.A([]),
    inProgress: false,
    studentTemplates: Ember.computed('model.templates.[]', function () {
      return this.get('model.templates').filterBy('personType', _personType.default.LEARNER);
    }),
    staffTemplates: Ember.computed('model.templates.[]', function () {
      return this.get('model.templates').filterBy('personType', _personType.default.STAFF);
    }),
    caregiverTemplates: Ember.computed('model.templates.[]', function () {
      return this.get('model.templates').filterBy('personType', _personType.default.CAREGIVER);
    }),
    medicalTemplates: Ember.computed('model.templates.[]', function () {
      return this.get('model.templates').filterBy('personType', _personType.default.DOCTORS_AND_SPECIALISTS);
    }),
    actions: {
      toggleChecked: function toggleChecked(templateId) {
        var selected = this.selectedTemplates.toggleProperty(templateId);
        var ids = this.selectedTemplateIds;
        if (selected) {
          ids.pushObject(templateId);
        } else {
          ids.removeObject(templateId);
        }
      },
      apply: function apply() {
        var _this = this;
        var toaster = this.paperToaster;
        this.set('inProgress', true);
        this.fetch.post(_environment.default.APP.apiHost + '/people-schema/v4/templates/sets:apply', {
          dataType: 'json',
          data: JSON.stringify({
            schoolId: this.get('selectedSchool.id'),
            setIds: this.selectedTemplateIds
          })
        }).then(function () {
          toaster.show('Templates applied!', {
            duration: 4000,
            position: 'top right'
          });
        }).catch(function () {
          toaster.show('Failed to apply template!', {
            duration: 40000,
            position: 'top right'
          });
        }).then(function () {
          _this.set('inProgress', false);
        });
      }
    }
  });
  _exports.default = _default;
});