define("linced-admin/routes/people/schema/template/group", ["exports", "linced-admin/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    model: function model(params) {
      var templateSet = this.modelFor('people.schema.template');
      return Ember.RSVP.hash({
        templateSet: templateSet,
        group: this.store.findRecord('peopleSchema/group', params.group_id),
        fields: this.store.query('peopleSchema/field', {
          groupId: params.group_id,
          setId: templateSet.id
        })
      });
    }
  });
  _exports.default = _default;
});