define("linced-admin/components/new-post", ["exports", "@linc-technologies/linced-common/components/new-post", "@linc-technologies/linced-common/templates/components/new-post"], function (_exports, _newPost, _newPost2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _newPost.default.extend({
    layout: _newPost2.default,
    schoolId: null,
    heroDuoEnabled: true,
    heroPremierEnabled: true,
    userCanTag: false,
    schoolGroups: null,
    filteredGroups: Ember.computed('schoolGroups.[]', function () {
      if (Ember.isArray(this.schoolGroups)) {
        return this.schoolGroups.sortBy('grouping', 'name');
      }
      return [];
    })
  });
  _exports.default = _default;
});