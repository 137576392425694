define("linced-admin/controllers/people/schema/template/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    store: Ember.inject.service('store'),
    templatePrefix: Ember.computed('model.templateSet.key', function () {
      return this.get('model.templateSet.key') + ':';
    }),
    init: function init() {
      var store = this.store;
      store.pushPayload('person', {
        person: {
          id: 'dummy',
          createTime: 1501733810,
          modifiedTime: 1501732276,
          type: 1,
          jurisdiction: 1,
          jurisdictionalId: '0123456789',
          learner: {
            schoolId: 'd2729515-d1ca-436c-8e0a-11870867f7d9',
            schoolCampusId: '606581be-2a1c-4776-a8ba-beb0a8258cac',
            homeroomId: 'a8671d4f-5417-4d87-9d88-f080ae48cdf3',
            enrolmentId: '9215e060-bdef-4f7d-82e0-ad33d72181db',
            contacts: [{
              caregiverId: 'e1cf3885-c736-4678-abaf-9bd5a4a9dc12',
              relationship: 2,
              notes: 'Accountant for a school'
            }],
            enrolments: [{
              schoolId: '91d77d2e-3d0a-45de-8778-eb5b19294c27',
              enrolmentId: 'e73a8302-5243-4aa0-a165-29d0d919deb2',
              status: 3,
              fte: 0.8,
              entryDate: '2000-02-24',
              exitDate: '2007-12-20',
              departureReason: 'Graduated School',
              fields: {
                'nzMOE:nsn': {
                  datatype: 1,
                  valueString: '0123456789'
                }
              }
            }]
          },
          fieldsArray: [{
            key: 'core:primaryEmailAddress',
            datatype: 1,
            valueString: 'joe.bloggs@fake.school.nz'
          }, {
            key: 'core:firstNameLegal',
            datatype: 1,
            valueString: 'Joseph'
          }, {
            key: 'core:middleNames',
            datatype: 2,
            valueList: ['Randall']
          }, {
            key: 'core:lastNameLegal',
            datatype: 1,
            valueString: 'Bloggs'
          }, {
            key: 'core:firstNamePreferred',
            datatype: 1,
            valueString: 'Joe'
          }, {
            key: 'core:lastNamePreferred',
            datatype: 1,
            valueString: 'Bloggs'
          }, {
            key: 'core:genderLegal',
            datatype: 1,
            valueString: 'M'
          }, {
            key: 'core:genderIdentity',
            datatype: 1,
            valueString: 'M'
          }, {
            key: 'core:dateOfBirth',
            datatype: 1,
            valueString: '1989-05-14'
          }, {
            key: 'contact:homePhone',
            datatype: 1,
            valueString: '+64 3 123 4567'
          }, {
            key: 'contact:mobilePhone',
            datatype: 1,
            valueString: '+64 21 987 654321'
          }, {
            key: 'contact:physicalAddress1',
            datatype: 1,
            valueString: '63a Jigglypuff Ave'
          }, {
            key: 'contact:physicalAddress2',
            datatype: 1,
            valueString: 'Riccarton'
          }, {
            key: 'contact:physicalAddress3',
            datatype: 1,
            valueString: 'Christchurch'
          }, {
            key: 'contact:physicalAddress4',
            datatype: 1,
            valueString: '8042'
          }, {
            key: 'nzMOE:nsn',
            datatype: 1,
            valueString: '0123456789'
          }, {
            key: 'nzMOE:firstSchoolingDate',
            datatype: 1,
            valueString: '1994-02-06'
          }, {
            key: 'custom:catNames',
            datatype: 2,
            valueList: ['jimmy', 'kimmy', 'timmy', 'giddy']
          }]
        }
      });
      var dummy = store.peekRecord('person', 'dummy');
      dummy.initFields();
      this.set('person', dummy);
      this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});