define("linced-admin/templates/people/schema/template/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Y/zoP+pB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\",true],[8],[0,\"Preview: \"],[1,[24,[\"model\",\"templateSet\",\"name\"]],false],[9],[0,\"\\n\\n\"],[5,\"person-form\",[],[[\"@fieldGroups\",\"@person\",\"@prefix\",\"@preview\"],[[24,[\"model\",\"groups\"]],[22,\"person\"],[22,\"templatePrefix\"],true]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "linced-admin/templates/people/schema/template/view.hbs"
    }
  });
  _exports.default = _default;
});