define("linced-admin/routes/enrolment/schema/template", ["exports", "linced-admin/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    router: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        schema: this.store.findRecord('enrolments/schema', params.schema_id),
        fieldSets: this.store.findAll('people-schema/set'),
        fieldGroups: this.store.findAll('people-schema/group'),
        allFields: this.store.findAll('people-schema/field')
      });
    },
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.router.on('routeWillChange', function (transition) {
        if (Ember.isEmpty(transition.from) || !transition.from.find(function (route) {
          return route.name === _this.routeName;
        })) {
          return;
        }
        if (!transition.to.find(function (route) {
          return route.name === _this.routeName;
        })) {
          if (_this.get('controller.recordIsDirty')) {
            if (window.confirm('You have unsaved changes which will be lost if you leave this page.  Are you sure you wish to leave?')) {
              _this.get('controller.schema').rollbackAttributes();
            } else {
              transition.abort();
            }
          }
        }
      });
    },
    resetController: function resetController(controller) {
      controller.reset();
    }
  });
  _exports.default = _default;
});