define("linced-admin/models/client", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // createTime is when the resource was created in seconds from the epoch.
    createTime: (0, _model.attr)('number'),
    // updateTime is the last time the resource was modified in seconds from
    // the epoch.
    updateTime: (0, _model.attr)('number'),
    // allowedAudiences contains a list of Audiences that the client has been
    // given rights to access.
    allowedAudiences: (0, _model.attr)('array-string', {
      defaultValue: newArray
    }),
    // allowedRegions contains a list of regions that the client has been
    // given permission to access. This enables filtering for clients based on
    // geographic region.
    allowedRegions: (0, _model.attr)('array-string', {
      defaultValue: newArray
    }),
    // allowedTenantAccess contains a list of Tenants that the client has been
    // given rights to access.
    allowedTenantAccess: (0, _model.attr)('array-string', {
      defaultValue: newArray
    }),
    // grantTypes contains a list of grant types the client is allowed to use.
    //
    // Pattern: client_credentials|authorize_code|implicit|refresh_token
    grantTypes: (0, _model.attr)('array-string', {
      defaultValue: newArray
    }),
    // responseTypes contains a list of the OAuth 2.0 response type strings
    // that the client can use at the authorization endpoint.
    //
    // Pattern: id_token|code|token
    responseTypes: (0, _model.attr)('array-string', {
      defaultValue: newArray
    }),
    // scopes contains a list of values the client is entitled to use when
    // requesting an access token (as described in Section 3.3 of OAuth 2.0
    // [RFC6749]).
    //
    // Pattern: ([a-zA-Z0-9\.]+\s)+
    scopes: (0, _model.attr)('array-string', {
      defaultValue: newArray
    }),
    // public is a boolean that identifies this client as public, meaning that
    // it does not have a secret. It will disable the client_credentials grant
    // type for this client if set.
    public: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    // disabled stops the client from being able to authenticate to the system.
    disabled: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    // name contains a human-readable string name of the client to be presented
    // to the end-user during authorization.
    name: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    // secret is the client's secret. The secret will be included in the create
    // request as cleartext, and then never again. The secret is stored using
    // Argon2id so it is impossible to recover it.
    // Tell your users that they need to remember the client secret as it will
    // only be returned once, never to be seen again.
    secret: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    // RedirectUris contains a list of allowed redirect urls for the client, for
    // example: https://example.com/oauth/callback.
    redirectUris: (0, _model.attr)('array-string', {
      defaultValue: newArray
    }),
    // owner identifies the owner of the OAuth 2.0 Client.
    owner: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    // policyURI allows the application developer to provide a URI string that
    // points to a human-readable privacy policy document that describes how the
    // deployment organization collects, uses, retains, and discloses personal
    // data.
    policyUri: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    // termsOfServiceUri allows the application developer to provide a URI
    // string that points to a human-readable terms of service document that
    // describes and outlines the contractual relationship between the end-user
    // and the client application that the end-user accepts when authorizing
    // their use of the client.
    termsOfServiceUri: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    // clientUri allows the application developer to provide a URI string that
    // points to a human-readable web page that provides information about the
    // client application.
    // If present, the server SHOULD display this URL to the end-user in a
    // click-able fashion.
    clientUri: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    // logoUri is an URL string that references a logo for the client.
    logoUri: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    // contacts contains a list ways to contact the developers responsible for
    // this OAuth 2.0 client, typically email addresses.
    contacts: (0, _model.attr)('array-string', {
      defaultValue: newArray
    }),
    // published provides a switch to hide specific clients if not quite ready
    // for the prime time, or if wanting to keep them hidden.
    published: (0, _model.attr)('boolean', {
      defaultValue: false
    })
  }); // newArray returns a new empty array.
  _exports.default = _default;
  function newArray() {
    return [];
  }
});