define("linced-admin/templates/my-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Fp05IqTV",
    "block": "{\"symbols\":[],\"statements\":[[5,\"profile-banner\",[],[[\"@user\"],[[24,[\"currentUser\",\"person\"]]]]],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"layout-column layout-gt-sm-row layout-align-center constrain-md\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"layout-column flex-xs flex-gt-xs-50\"],[8],[0,\"\\n    \"],[5,\"change-profile-image\",[],[[],[]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"layout-column flex-xs flex-gt-xs-50\"],[8],[0,\"\\n    \"],[5,\"change-password\",[],[[],[]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"user-devices\",[],[[\"@devices\",\"@person\"],[[24,[\"model\",\"devices\"]],[24,[\"currentUser\",\"person\"]]]]],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "linced-admin/templates/my-profile.hbs"
    }
  });
  _exports.default = _default;
});