define("linced-admin/components/school-group-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    toaster: Ember.inject.service(),
    group: null,
    allSchools: null,
    selectedSchools: Ember.A(),
    preselectedSchools: null,
    currentTab: 0,
    saveInProgress: false,
    limitOptions: Ember.A([5, 10, 25, 50, 100]),
    limit: 10,
    schoolsPages: Ember.computed('limit', 'selectedSchools.[]', function () {
      var max = Math.max(1, Math.ceil(this.get('selectedSchools.length') / this.limit));
      var pages = Ember.A([]);
      for (var i = 1; i <= max; i++) {
        pages.pushObject(i);
      }
      return pages;
    }),
    schoolsPage: 1,
    sortProp: 'name',
    sortDir: 'asc',
    paginatedSchools: Ember.computed('schoolsPage', 'limit', 'sortProp', 'sortDir', 'selectedSchools.[]', function () {
      var ind = (this.schoolsPage - 1) * this.limit;
      var schools = this.selectedSchools.sortBy(this.sortProp, 'name');
      if (this.sortDir === 'desc') {
        schools = schools.reverseObjects();
      }
      return Ember.A(schools.toArray().splice(ind, this.limit));
    }),
    remainingSchools: Ember.computed.setDiff('allSchools', 'selectedSchools'),
    toggleDialog: function toggleDialog() {},
    didReceiveAttrs: function didReceiveAttrs() {
      if (Ember.isArray(this.preselectedSchools)) {
        // Component was fed a list of schools to preselect, for a new group.
        this.set('selectedSchools', this.preselectedSchools.slice());
      } else {
        // No preselected schools - pull from the group's existing schools.
        this.set('selectedSchools', this.group.schools.slice());
      }
      this._super.apply(this, arguments);
    },
    actions: {
      toggleDialog: function toggleDialog() {
        this.toggleDialog();
      },
      saveGroup: function saveGroup() {
        var _this = this;
        this.set('saveInProgress', true);
        this.group.set('schoolIds', this.selectedSchools);
        this.group.save().then(function () {
          _this.toaster.success("Group ".concat(_this.group.name, " saved successfully!"));
          _this.toggleDialog();
        }).catch(function () {
          _this.toaster.error('Failed to save group.');
        }).finally(function () {
          _this.set('saveInProgress', false);
        });
      },
      addSchool: function addSchool(school) {
        this.selectedSchools.pushObject(school);
      },
      removeSchool: function removeSchool(school) {
        this.selectedSchools.removeObject(school);
      },
      decrementPage: function decrementPage() {
        var page = this.schoolsPage;
        if (page > 1) {
          this.set('schoolsPage', page - 1);
        }
      },
      incrementPage: function incrementPage() {
        var page = this.schoolsPage;
        var max = this.schoolsPages.reduce(function (prev, curr) {
          return curr > prev ? curr : prev;
        }, 0);
        if (page < max) {
          this.set('schoolsPage', page + 1);
        }
      }
    }
  });
  _exports.default = _default;
});