define("linced-admin/controllers/school-options/groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    currentUser: Ember.inject.service('current-user'),
    store: Ember.inject.service('store'),
    paperToaster: Ember.inject.service(),
    groupName: '',
    groupDescription: '',
    groupKey: '',
    groupIcon: '',
    groupSequence: 0,
    heroOnly: false,
    groupId: '',
    groupDialogOpen: false,
    saveInProgress: false,
    deleteGroupDialogOpen: false,
    deleteInProgress: false,
    errorMessage: '',
    isSortable: false,
    // toggleDialog opens the group dialog.
    toggleDialog: function toggleDialog() {
      this.set('errorMessage', '');
      this.toggleProperty('groupDialogOpen');
      if (!this.groupDialogOpen) {
        this.resetForm();
      }
    },
    actions: {
      toggleGroupDialog: function toggleGroupDialog() {
        this.toggleDialog();
      },
      editGroup: function editGroup(id) {
        var _this = this;
        this.store.findRecord('schoolOptions/group', id).then(function (group) {
          _this.set('groupName', group.get('name'));
          _this.set('groupDescription', group.get('description'));
          _this.set('groupKey', group.get('key'));
          _this.set('groupIcon', group.get('icon'));
          _this.set('groupSequence', group.get('sequence'));
          _this.set('heroOnly', group.get('heroOnly'));
        });
        this.set('groupId', id);
        this.toggleDialog();
      },
      saveGroup: function saveGroup() {
        var _this2 = this;
        this.set('saveInProgress', true);
        this.set('errorMessage', '');
        var promise;
        var action = 'create';
        if (Ember.isEmpty(this.groupId)) {
          promise = new Ember.RSVP.Promise(function (resolve) {
            var record = _this2.store.createRecord('schoolOptions/group', {
              name: _this2.groupName,
              description: _this2.groupDescription,
              key: _this2.groupKey,
              icon: _this2.groupIcon,
              sequence: _this2.groupSequence,
              heroOnly: _this2.heroOnly
            });
            resolve(record);
          });
        } else {
          action = 'update';
          promise = new Ember.RSVP.Promise(function (resolve) {
            var id = _this2.groupId;
            _this2.store.findRecord('schoolOptions/group', id).then(function (group) {
              group.set('name', _this2.groupName);
              group.set('description', _this2.groupDescription);
              group.set('key', _this2.groupKey);
              group.set('icon', _this2.groupIcon);
              group.set('sequence', _this2.groupSequence);
              group.set('heroOnly', _this2.heroOnly);
              resolve(group);
            });
          });
        }
        promise.then(function (record) {
          return record.save().then(function () {
            _this2.set('groupDialogOpen', false);
            _this2.resetForm();
          }).catch(function (err) {
            _this2.handleError(err, action);
            record.rollbackAttributes();
          }).then(function () {
            _this2.set('saveInProgress', false);
          });
        });
      },
      deleteGroup: function deleteGroup(id) {
        var _this3 = this;
        this.set('errorMessage', '');
        this.store.findRecord('schoolOptions/group', id).then(function (group) {
          _this3.set('groupName', group.get('name'));
        });
        this.set('groupId', id);
        this.set('deleteGroupDialogOpen', true);
      },
      confirmDelete: function confirmDelete(confirmed) {
        var _this4 = this;
        return new Ember.RSVP.Promise(function (resolve, reject) {
          if (confirmed) {
            // Deletion confirmed; actually delete the record.
            _this4.set('deleteInProgress', true);

            // Grab record from local store - no GET request, as that causes weirdness.
            // We only need the ID to issue the DELETE anyway, which can't change, so no need to use findRecord.
            var group = _this4.store.peekRecord('schoolOptions/group', _this4.groupId);

            // Delete record (locally + pushed to server).
            return group.destroyRecord().then(resolve).catch(function (err) {
              // Remove 'deleted' flag so that entity can be interacted with again.
              group.rollbackAttributes();
              reject(err);
            });
          } else {
            resolve();
          }
        }).then(function () {
          // Tidy up / close dialog.
          _this4.set('deleteGroupDialogOpen', false);
          _this4.set('groupId', '');
          _this4.set('groupName', '');
        }).catch(function (err) {
          _this4.handleError(err, 'delete');
        }).then(function () {
          _this4.set('deleteInProgress', false);
        });
      },
      startSorting: function startSorting() {
        this.set('isSortable', true);
      },
      finishSorting: function finishSorting() {
        this.set('isSortable', false);
        var record = this.model;
        var toaster = this.paperToaster;
        record.save().then(function () {
          toaster.show('Sequence saved', {
            duration: 4000,
            position: 'top right'
          });
        }).catch(function () {
          toaster.show('Error saving sequence.  Please try again.', {
            duration: 4000,
            position: 'top right'
          });
          record.rollbackAttributes();
        });
      }
    },
    /*
    init() {
      this.set('schoolId', this.get('currentUser.user.schoolId'));
    },*/
    handleError: function handleError(err) {
      if (!Ember.isEmpty(err.errors.msg)) {
        this.set('errorMessage', err.errors.msg);
      } else {
        this.set('errorMessage', err.message);
      }
    },
    setErrorMessage: function setErrorMessage(code, action) {
      var message = 'An unknown error occurred.  Please try again.';
      var codes = {
        '400': 'Sorry, your request was invalid.  Please check all details below, and try again.',
        '403': {
          create: 'Sorry, you are not authorised to create a new user.  Please check that you are logged in as the correct user, and try again.',
          update: 'Sorry, you are not authorised to edit this user.  Please check that you are logged in as the correct user, and try again.',
          delete: 'Sorry, you are not authorised to delete this user.  Please check that you are logged in as the correct user, and try again.'
        },
        '409': 'A user already exists with that username.  Please double-check the details, or find and update the existing user.'
      };
      if (!Ember.isEmpty(codes[code][action])) {
        message = codes[code][action];
      } else if (!Ember.isEmpty(codes[code])) {
        message = codes[code];
      }
      this.set('errorMessage', message);
    },
    resetForm: function resetForm() {
      this.set('groupId', '');
      this.set('groupName', '');
      this.set('groupDescription', '');
      this.set('groupKey', '');
      this.set('groupIcon', '');
      this.set('groupSequence', '');
      this.set('heroOnly', false);
    }
  });
  _exports.default = _default;
});