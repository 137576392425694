define("linced-admin/components/venn-diagram", ["exports", "@linc-technologies/linced-common/components/venn-diagram"], function (_exports, _vennDiagram) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _vennDiagram.default;
    }
  });
});