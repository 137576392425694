define("linced-admin/components/async-button", ["exports", "@linc-technologies/linced-common/components/async-button", "@linc-technologies/linced-common/templates/components/async-button"], function (_exports, _asyncButton, _asyncButton2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
    TODO: Remove this stub component when linced-common has been updated to fix
          the async-button component (by importing the layout).
  */
  var _default = _asyncButton.default.extend({
    layout: _asyncButton2.default
  });
  _exports.default = _default;
});