define("linced-admin/utils/filtering", ["exports", "@linc-technologies/linced-common/enums/data-type", "linced-admin/utils/strings"], function (_exports, _dataType, _strings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.filterValue = filterValue;
  var numericOperators = /^[<>]=?/;
  function filterNumeric(filter, value) {
    if (numericOperators.test(filter)) {
      var operator = filter.match(numericOperators)[0];
      var remainder = filter.replace(numericOperators, '').trim();
      var _filterValue = Number(remainder);
      if (isNaN(_filterValue)) {
        // No valid number to check against - match everything if no value yet, nothing if there's an invalid value
        return remainder.length === 0;
      }
      switch (operator) {
        case '>':
          return value > _filterValue;
        case '>=':
          return value >= _filterValue;
        case '<':
          return value < _filterValue;
        case '<=':
          return value <= _filterValue;
      }
      return false;
    } else {
      return value === Number(filter.trim());
    }
  }
  function filterValue(value, datatype, filter) {
    var include = true;
    var negated = false;
    if (filter.charAt(0) === '!') {
      // Negated filter - flag and remove the leading "!"
      negated = true;
      filter = filter.substring(1).trim();
    }
    switch (datatype) {
      case _dataType.default.STRING:
        if (filter === '*') {
          include = Ember.isPresent(value);
        } else if (value.toLowerCase().indexOf(filter.toLowerCase()) === -1) {
          include = false;
        }
        break;
      case _dataType.default.BOOLEAN:
        if (filter === '*') {
          include = Ember.isPresent(value);
        } else {
          include = (0, _strings.isTruthy)(filter) === Boolean(value);
        }
        break;
      case _dataType.default.INTEGER:
      case _dataType.default.FLOAT:
        if (filter === '*') {
          include = Ember.isPresent(value);
        } else {
          include = filterNumeric(filter, value);
        }
        break;
      case undefined:
        // No value for this field
        include = false;
        break;
    }
    if (negated) {
      include = !include;
    }
    return include;
  }
});