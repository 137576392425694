define("linced-admin/models/milestones/set", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    createTime: (0, _model.attr)('number'),
    updateTime: (0, _model.attr)('number'),
    label: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    progressionType: (0, _model.attr)('number'),
    isPublished: (0, _model.attr)('boolean'),
    milestones: (0, _model.hasMany)('milestones/milestone', {
      inverse: 'templateSetId'
    }),
    levels: (0, _model.hasMany)('milestones/level', {
      inverse: 'templateSetId'
    }),
    expectations: (0, _model.hasMany)('milestones/expectations', {
      inverse: 'templateSetId'
    }),
    // Usage sets membership
    usageSetIds: (0, _model.hasMany)('schools/usage-set')
  });
  _exports.default = _default;
});