define("linced-admin/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Gd4lRdGl",
    "block": "{\"symbols\":[],\"statements\":[[2,\" `app/templates/head.hbs` \"],[0,\"\\n\"],[2,\" content from ember-page-title, injected by ember-cli-head \"],[0,\"\\n\"],[2,\" The 'model' available in this template can be populated by \"],[0,\"\\n\"],[2,\" setting values on the 'head-data' service. \"],[0,\"\\n\"],[7,\"title\",true],[8],[1,[24,[\"model\",\"title\"]],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "linced-admin/templates/head.hbs"
    }
  });
  _exports.default = _default;
});