define("linced-admin/routes/school-options/sets", ["exports", "linced-admin/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    store: Ember.inject.service(),
    model: function model() {
      return {
        sets: this.store.findAll('schoolOptions/set')
      };
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});