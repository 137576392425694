define("linced-admin/routes/cron/index", ["exports", "linced-admin/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    model: function model() {
      return this.store.findAll('cron/task');
    }
  });
  _exports.default = _default;
});