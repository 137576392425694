define("linced-admin/routes/milestones/sets", ["exports", "linced-admin/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    model: function model() {
      return Ember.RSVP.hash({
        sets: this.store.findAll('milestones/set'),
        milestones: this.store.findAll('milestones/milestone'),
        // Loaded so that we can recursively (un)publish templates.
        milestoneSets: this.store.query('milestones/milestone-set', {
          include: 'milestones'
        }),
        schools: this.store.findAll('school'),
        schoolGroups: this.store.findAll('schools/group'),
        usageSets: this.store.findAll('schools/usage-set')
      });
    },
    resetController: function resetController(controller) {
      controller.reset();
    }
  });
  _exports.default = _default;
});