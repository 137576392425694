define("linced-admin/services/offline", ["exports", "ember-offline/services/offline"], function (_exports, _offline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _offline.default;
    }
  });
});