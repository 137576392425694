define("linced-admin/routes/school-options/preview", ["exports", "linced-admin/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    model: function model(params) {
      var store = this.store;
      return Ember.RSVP.hash({
        optionSet: store.findRecord('schoolOptions/set', params.set_id),
        options: store.query('schoolOptions/field', {
          optionSetIds: JSON.stringify([params.set_id])
        })
      });
    }
  });
  _exports.default = _default;
});