define("linced-admin/models/enrolments/enrolment", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    nzMoe: (0, _model.attr)(),
    nzMoeText: Ember.computed('nzMoe', function () {
      return JSON.stringify(this.nzMoe);
    })
  });
  _exports.default = _default;
});