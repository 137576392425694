define("linced-admin/templates/components/school-address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "dW2BJZfT",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"or\",[[24,[\"address\",\"number\"]],[24,[\"address\",\"street\"]]],null]],null,{\"statements\":[[4,\"if\",[[24,[\"address\",\"number\"]]],null,{\"statements\":[[0,\"    \"],[1,[24,[\"address\",\"number\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"address\",\"street\"]]],null,{\"statements\":[[0,\"    \"],[1,[24,[\"address\",\"street\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"br\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"address\",\"locality\"]]],null,{\"statements\":[[0,\"  \"],[1,[24,[\"address\",\"locality\"]],false],[7,\"br\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"address\",\"district\"]]],null,{\"statements\":[[0,\"  \"],[1,[24,[\"address\",\"district\"]],false],[7,\"br\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"address\",\"region\"]]],null,{\"statements\":[[0,\"  \"],[1,[24,[\"address\",\"region\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"address\",\"postcode\"]]],null,{\"statements\":[[0,\"  \"],[1,[24,[\"address\",\"postcode\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "linced-admin/templates/components/school-address.hbs"
    }
  });
  _exports.default = _default;
});