define("linced-admin/models/timetable/day", ["exports", "ember-data-model-fragments", "@ember-data/model", "ember-data-model-fragments/attributes"], function (_exports, _emberDataModelFragments, _model, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberDataModelFragments.default.Fragment.extend({
    name: (0, _model.attr)('string'),
    isoDayNumber: (0, _model.attr)('number'),
    periodIds: (0, _attributes.array)('string'),
    // eslint-disable-next-line
    _watchPeriods: Ember.observer('periodIds.[]', function () {
      var _this = this;
      var periods = Ember.A();
      var promises = [];
      var store = this.store;
      this.periodIds.forEach(function (id, index) {
        promises.push(store.findRecord('timetable/period', id).then(function (record) {
          periods[index] = record;
        }));
      });
      return Ember.RSVP.all(promises).then(function () {
        _this.set('periods', periods);
      });
    })
  });
  _exports.default = _default;
});