define("linced-admin/helpers/build-topic-url", ["exports", "@linc-technologies/linced-common/helpers/build-topic-url"], function (_exports, _buildTopicUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "buildTopicUrl", {
    enumerable: true,
    get: function get() {
      return _buildTopicUrl.buildTopicUrl;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _buildTopicUrl.default;
    }
  });
});